import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnInit,
} from '@angular/core';
import { ExpertType } from '../../../../../../store/masters/expert-types/models/expert-type.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-expert-type-details',
  templateUrl: './expert-type-details.component.html',
  styleUrl: './expert-type-details.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpertTypeDetailsComponent implements OnInit {
  expertType: ExpertType;

  @Input('selectedExpertType')
  set selectedExpertType(data: any) {
    this.expertType = data;
  }
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}
}

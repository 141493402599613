import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TypeOfStoragesRoutingModule } from './type-of-storages-routing.module';

import { TypeOfStoragePageComponent } from './containers/type-of-storage-page/type-of-storage-page.component';
import { TypeOfStorageAddComponent } from './containers/type-of-storage-add/type-of-storage-add.component';
import { TypeOfStorageUpdateComponent } from './containers/type-of-storage-update/type-of-storage-update.component';
import { TypeOfStorageDetailsComponent } from './components/type-of-storage-details/type-of-storage-details.component';
import { TypeOfStorageDropdownComponent } from './components/type-of-storage-dropdown/type-of-storage-dropdown.component';
import { TypeOfStorageFormComponent } from './components/type-of-storage-form/type-of-storage-form.component';
import { TypeOfStorageListComponent } from './components/type-of-storage-list/type-of-storage-list.component';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { TYPE_OF_STORAGE_EFFECTS } from '../../../../store/masters/type-of-storages/effects';
import { TYPE_OF_STORAGE_REDUCERS } from '../../../../store/masters/type-of-storages/type-of-storage.state';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HeaderModule } from '../../../header/header.module';
import { SidemenuModule } from '../../../sidemenu/sidemenu.module';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import {
  NgbAlertModule,
  NgbModule,
  NgbPaginationModule,
} from '@ng-bootstrap/ng-bootstrap';
import { FooterModule } from '../../../../components/footer/footer.module';
import { TypeOfStorageMultiSelectDropdownComponent } from './components/type-of-storage-multi-select-dropdown/type-of-storage-multi-select-dropdown.component';
import { TypeOfStorageFilterListComponent } from './components/type-of-storage-filter-list/type-of-storage-filter-list.component';

@NgModule({
  declarations: [
    TypeOfStoragePageComponent,
    TypeOfStorageAddComponent,
    TypeOfStorageUpdateComponent,
    TypeOfStorageDetailsComponent,
    TypeOfStorageDropdownComponent,
    TypeOfStorageFormComponent,
    TypeOfStorageListComponent,
    TypeOfStorageMultiSelectDropdownComponent,
    TypeOfStorageFilterListComponent
  ],
  imports: [
    CommonModule,
    TypeOfStoragesRoutingModule,

    FormsModule,
    ReactiveFormsModule,

    HeaderModule,
    SidemenuModule,
    FooterModule,
    SelectDropDownModule,
    NgxDatatableModule,
    NgbModule,
    NgbPaginationModule,
    NgbAlertModule,

    StoreModule.forFeature('type_of_storages', TYPE_OF_STORAGE_REDUCERS),
    EffectsModule.forFeature(TYPE_OF_STORAGE_EFFECTS),
  ],
  exports: [
    TypeOfStorageDropdownComponent,
    TypeOfStorageMultiSelectDropdownComponent,
    TypeOfStorageFilterListComponent
  ],
})
export class TypeOfStoragesModule {}

import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  Optional,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { EquipmentSupplierModel } from '../../../../../store/cold-storage/equipment-suppliers/models/equipment-supplier.model';

import * as fromEquipmentSupplier from '../../../../../store/cold-storage/equipment-suppliers';
import { EquipmentSupplierItemActionTypes } from '../../../../../store/cold-storage/equipment-suppliers';
import { select, Store } from '@ngrx/store';
import { GridPayload } from '../../../../../shared/models/grid.payload';
// import { MatDialog } from "@angular/material/dialog";

import { Actions, ofType } from '@ngrx/effects';
import { takeUntil, tap } from 'rxjs/operators';
import lstore from 'store';
import {
  NgbActiveModal,
  NgbModal,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import { EquipmentSupplierAddComponent } from '../equipment-supplier-add/equipment-supplier-add.component';
import { EquipmentSupplierDetailsComponent } from '../../components/equipment-supplier-details/equipment-supplier-details.component';
import { EquipmentSupplierUpdateComponent } from '../equipment-supplier-update/equipment-supplier-update.component';

@Component({
  selector: 'app-equipment-supplier-page',
  templateUrl: './equipment-supplier-page.component.html',
  styleUrl: './equipment-supplier-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EquipmentSupplierPageComponent  implements OnInit, OnDestroy {
  loggedInUserAccess: boolean = false;
  // equipmentSuppliers$: Observable<EquipmentSupplier[]> = this.store.pipe(select(fromEquipmentSupplier.getAllEquipmentSuppliers));
  equipmentSuppliers$: Observable<any>;
  equipmentSuppliersLoading$ = this.store.pipe(
    select(fromEquipmentSupplier.getEquipmentSuppliersIsLoading)
  );

  equipmentSuppliersCount$: Observable<number> = this.store.pipe(
    select(fromEquipmentSupplier.getEquipmentSuppliersCount)
  );
  equipmentSuppliersOffset$: Observable<number> = this.store.pipe(
    select(fromEquipmentSupplier.getEquipmentSuppliersOffset)
  );
  equipmentSuppliersSearch$: Observable<string> = this.store.pipe(
    select(fromEquipmentSupplier.getEquipmentSuppliersSearch)
  );
  equipmentSuppliersSortProp$: Observable<string> = this.store.pipe(
    select(fromEquipmentSupplier.getEquipmentSuppliersSortProp)
  );
  equipmentSuppliersSortDir$: Observable<string> = this.store.pipe(
    select(fromEquipmentSupplier.getEquipmentSuppliersSortDir)
  );

  private destroyed$ = new Subject<boolean>();

  searchBy: any = '';
  modalReference: NgbModalRef;

  constructor(
    private readonly store: Store<fromEquipmentSupplier.State>,
    private modalService: NgbModal,
    @Optional() private activeModal: NgbActiveModal,
    actions$: Actions
  ) {
    this.loggedInUserAccess = lstore.get('aae');
    actions$
      .pipe(
        ofType(
          EquipmentSupplierItemActionTypes.AddRecordSuccess,
          EquipmentSupplierItemActionTypes.UpdateRecordSuccess
        ),
        takeUntil(this.destroyed$),
        tap(() => {
          this.modalReference.close();
        })
      )
      .subscribe();
  }

  ngOnInit() {
    this.loadEquipmentSuppliers();
    this.equipmentSuppliers$ = this.store.pipe(
      select(fromEquipmentSupplier.getAllEquipmentSuppliers)
    );
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  loadEquipmentSuppliers() {
    const info: GridPayload = {
      offset: 0,
      sortProp: 'organisation_name',
      sortDir: 'asc',
      search:
        this.searchBy == '' ||
        this.searchBy == undefined ||
        this.searchBy == null
          ? 'all'
          : this.searchBy,
    };
    this.store.dispatch(new fromEquipmentSupplier.LoadRecords(info));
  }

  search() {
    this.loadEquipmentSuppliers();
  }

  clear() {
    this.searchBy = '';
    this.loadEquipmentSuppliers();
  }

  addItem(): void {
    this.modalReference = this.modalService.open(EquipmentSupplierAddComponent, {
      backdrop: 'static',
      size: 'xl',
      keyboard: false,
      centered: true,
      backdropClass: 'light-blue-backdrop',
    });
  }

  onGridUpdate(info: GridPayload) {
    this.store.dispatch(new fromEquipmentSupplier.LoadRecords(info));
  }

  onItemSelected(equipmentSupplierData: any) {
    this.modalReference = this.modalService.open(EquipmentSupplierDetailsComponent, {
      backdrop: 'static',
      size: 'lg',
      keyboard: false,
      centered: true,
    });
    this.modalReference.componentInstance.selectedEquipmentSupplier = equipmentSupplierData;
  }

  onItemUpdate(client: any) {
    this.store.dispatch(new fromEquipmentSupplier.SelectRecord(client.id));
    this.modalReference = this.modalService.open(EquipmentSupplierUpdateComponent, {
      backdrop: 'static',
      size: 'lg',
      keyboard: false,
      centered: true,
    });
  }

  onItemUpdateStatusClick($event: EquipmentSupplierModel) {
    this.store.dispatch(new fromEquipmentSupplier.SelectRecord($event.id));
    this.store.dispatch(new fromEquipmentSupplier.UpdateStatusRecord($event));
  }

  onItemUpdateApprovalStatusClick($event: EquipmentSupplierModel) {
    this.store.dispatch(new fromEquipmentSupplier.SelectRecord($event.id));
    this.store.dispatch(new fromEquipmentSupplier.UpdateApprovalStatusRecord($event));
  }
}


import { DesignConsultantActionTypes, DesignConsultantItemActionTypes } from '../actions';

export interface DesignConsultantItemState {
  error: string | null;
  pending: boolean;
}

export const initialState: DesignConsultantItemState = {
  error: null,
  pending: false,
};

export function designConsultantItemReducer(
  state = initialState,
  action: DesignConsultantActionTypes
): DesignConsultantItemState {
  switch (action.type) {
    case DesignConsultantItemActionTypes.AddRecord:
    case DesignConsultantItemActionTypes.UpdateRecord:
    case DesignConsultantItemActionTypes.UpdateStatusRecord:
    case DesignConsultantItemActionTypes.UpdateApprovalStatusRecord: {
      return {
        ...state,
        error: null,
        pending: true,
      };
    }

    case DesignConsultantItemActionTypes.AddRecordSuccess:
    case DesignConsultantItemActionTypes.UpdateRecordSuccess:
    case DesignConsultantItemActionTypes.UpdateStatusRecordSuccess:
    case DesignConsultantItemActionTypes.UpdateApprovalStatusRecordSuccess: {
      return {
        ...state,
        error: null,
        pending: false,
      };
    }

    case DesignConsultantItemActionTypes.AddRecordFailure:
    case DesignConsultantItemActionTypes.UpdateRecordFailure:
    case DesignConsultantItemActionTypes.UpdateStatusRecordFailure:
    case DesignConsultantItemActionTypes.UpdateApprovalStatusRecordFailure: {
      return {
        ...state,
        error: action.payload,
        pending: false,
      };
    }

    default:
      return state;
  }
}

export const getError = (state: DesignConsultantItemState) => state.error;
export const getPending = (state: DesignConsultantItemState) => state.pending;

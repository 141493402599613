import { Injectable } from "@angular/core";

import { Actions, Effect, ofType } from "@ngrx/effects";
import { Action, select, Store } from "@ngrx/store";

import { Observable, of as observableOf } from "rxjs";
import {
  catchError,
  exhaustMap,
  map,
  tap,
  withLatestFrom,
} from "rxjs/operators";

import * as actions from "./../actions";
import { AddRecord, ExpertTypeItemActionTypes, UpdateRecord } from "./../actions";
import * as fromExpertType from "./../expert-type.state";

import { ExpertType } from "../models/expert-type.model";
import { ExpertTypeService } from "../../../../services/masters/expert-types";
import { GridPayload } from "../../../../shared/models/grid.payload";

@Injectable()
export class ExpertTypeItemEffects {
  @Effect()
  addRecord$: Observable<Action> = this.actions$.pipe(
    ofType<AddRecord>(ExpertTypeItemActionTypes.AddRecord),
    map((action) => action.payload),
    exhaustMap((record: ExpertType) =>
      this.batService.saveExpertType(record).pipe(
        map((payload: ExpertType) => new actions.AddRecordSuccess(payload)),
        catchError((e) =>
          observableOf(new actions.AddRecordFailure(e.error.error))
        )
      )
    )
  );

  @Effect()
  updateRecord$: Observable<Action> = this.actions$.pipe(
    ofType<UpdateRecord>(ExpertTypeItemActionTypes.UpdateRecord),
    map((action) => action.payload),
    exhaustMap((record: ExpertType) =>
      this.batService.saveExpertType(record).pipe(
        map((payload: ExpertType) => new actions.UpdateRecordSuccess(payload)),
        catchError((e) =>
          observableOf(new actions.UpdateRecordFailure(e.error.error))
        )
      )
    )
  );

  @Effect({ dispatch: false })
  reloadGrid$: Observable<any> = this.actions$.pipe(
    ofType(ExpertTypeItemActionTypes.AddRecordSuccess),
    withLatestFrom(
      this.store.pipe(select(fromExpertType.getExpertTypesOffset)),
      this.store.pipe(select(fromExpertType.getExpertTypesSortProp)),
      this.store.pipe(select(fromExpertType.getExpertTypesSortDir)),
      this.store.pipe(select(fromExpertType.getExpertTypesSearch)),
      (action, offset, sortProp, sortDir, search) => ({
        offset,
        sortProp,
        sortDir,
        search,
      })
    ),
    tap((info: GridPayload) => {
      this.store.dispatch(new actions.LoadRecords(info));
    })
  );

  @Effect({ dispatch: false })
  closeModal$: Observable<any> = this.actions$.pipe(
    ofType(
      ExpertTypeItemActionTypes.AddRecordSuccess,
      ExpertTypeItemActionTypes.UpdateRecordSuccess
    ),
    withLatestFrom(
      this.store.pipe(select(fromExpertType.getExpertTypesOffset)),
      this.store.pipe(select(fromExpertType.getExpertTypesSortProp)),
      this.store.pipe(select(fromExpertType.getExpertTypesSortDir)),
      this.store.pipe(select(fromExpertType.getExpertTypesSearch)),
      (action, offset, sortProp, sortDir, search) => ({
        offset,
        sortProp,
        sortDir,
        search,
      })
    ),
    tap((info: GridPayload) => {
      this.store.dispatch(new actions.LoadRecords(info));
    })
  );

  constructor(
    private store: Store<fromExpertType.State>,
    private actions$: Actions,
    private batService: ExpertTypeService
  ) {}
}

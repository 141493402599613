import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as fromContractor from '../../../../../store/cold-storage/contractors';
import { ContractorModel } from '../../../../../store/cold-storage/contractors/models/contractor.model';

@Component({
  selector: 'app-contractor-update',
  templateUrl: './update.component.html',
  styleUrl: './update.component.scss'
})
export class UpdateComponent {

  contractor$ = this.store.pipe(select(fromContractor.getSelectedContractor));
  contractorId$ = this.store.pipe(select(fromContractor.getSelectedContractorId));

  pending$ = this.store.pipe(select(fromContractor.getContractorItemPending));
  error$ = this.store.pipe(select(fromContractor.getContractorItemError));

  constructor(private readonly store: Store<fromContractor.State>) {
  }

  onSubmit($event: any) {
    this.store.dispatch(new fromContractor.UpdateRecord($event));
  }

}

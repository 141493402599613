import { GlobalState } from '../../../store';
import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromList from './reducers/list.reducer';
import * as fromItem from './reducers/item.reducer';


export interface ExperienceTypeState {
  list: fromList.ExperienceTypeListState;
  item: fromItem.ExperienceTypeItemState;
}

export interface State extends GlobalState {
  experience_types: ExperienceTypeState;
}

export const EXPERIENCE_TYPE_REDUCERS: ActionReducerMap<ExperienceTypeState, any> = {
  list: fromList.experienceTypeListReducer,
  item: fromItem.experienceTypeItemReducer
};


////// selectors

export const getProjectsState = createFeatureSelector<State, ExperienceTypeState>('experience_types');


export const getExperienceTypeListState = createSelector(getProjectsState, state => state.list);
export const getExperienceTypeItemState = createSelector(getProjectsState, state => state.item);


export const getExperienceTypesIsLoading = createSelector(getExperienceTypeListState, fromList.getIsLoading);

export const getExperienceTypesCount = createSelector(getExperienceTypeListState, fromList.getCount);
export const getExperienceTypesOffset = createSelector(getExperienceTypeListState, fromList.getOffset);

export const getExperienceTypesSortProp = createSelector(getExperienceTypeListState, fromList.getSortProp);
export const getExperienceTypesSortDir = createSelector(getExperienceTypeListState, fromList.getSortDir);
export const getExperienceTypesSearch = createSelector(getExperienceTypeListState, fromList.getSearch);

export const getSelectedExperienceTypeId = createSelector(getExperienceTypeListState, fromList.getSelectedId);


export const {
  selectEntities: getExperienceTypeEntities,
  selectAll: getAllExperienceTypes
} = fromList.adapter.getSelectors(getExperienceTypeListState);


export const getSelectedExperienceType = createSelector(
  getExperienceTypeEntities,
  getSelectedExperienceTypeId,
  (entities, selectedId) => {
    return selectedId !== null && entities[selectedId];
  }
);


// Item

export const getExperienceTypeItemPending = createSelector(getExperienceTypeItemState, fromItem.getPending);
export const getExperienceTypeItemError = createSelector(getExperienceTypeItemState, fromItem.getError);



import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as fromContractors from '../../../../../store/cold-storage/contractors';
import { ContractorModel } from '../../../../../store/cold-storage/contractors/models/contractor.model';

@Component({
  selector: 'app-contractor-add',
  templateUrl: './add.component.html',
  styleUrl: './add.component.scss'
})
export class AddComponent {
  
  pending$ = this.store.pipe(select(fromContractors.getContractorItemPending));
  error$ = this.store.pipe(select(fromContractors.getContractorItemError));

  constructor(private readonly store: Store<fromContractors.State>) {
  }

  onSubmit($event: any) {
    this.store.dispatch(new fromContractors.AddRecord($event));
  }

}

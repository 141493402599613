// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  authenticated: false,
  hmr: false,
  aws_s3_url: "https://apfps-pmfme.s3.us-east-2.amazonaws.com/",
  // api_url: "http://localhost:8080",
  api_url: "https://apfpsapi.pmfmeap.com",
  // api_url: "https://9239-223-185-68-166.ngrok.io",
}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ColdStorageContractorsModule } from '../pages/cold-storage-contractors/cold-storage-contractors.module';
import { ExperienceTypesModule } from '../pages/masters/experience-types/experience-types.module';
import { DistrictsModule } from '../pages/masters/districts/districts.module';
import { StatesModule } from '../pages/masters/states/states.module';
import { RegisterYourServiceModule } from '../pages/register-your-service/register-your-service.module';



@NgModule({
  declarations: [HeaderComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    // StatesModule,
    // DistrictsModule,
    // ExperienceTypesModule,
  ],
  exports:[
    HeaderComponent
  ]
})
export class HeaderModule { }

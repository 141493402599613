import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
import { HeaderModule } from '../../header/header.module';
import { SidemenuModule } from '../../sidemenu/sidemenu.module';
import { FooterModule } from '../../footer/footer.module';
import { StatsComponent } from './stats/stats.component';
import { RecentActivitiesComponent } from './recent-activities/recent-activities.component';
import { DashboardRoutingModule } from './dashboard-routing.module';

@NgModule({
  declarations: [
    DashboardComponent, 
    StatsComponent,
    RecentActivitiesComponent
  ],
  imports: [
    CommonModule, 
    DashboardRoutingModule, 
    HeaderModule, 
    SidemenuModule, 
    FooterModule
  ],
})
export class DashboardModule {}

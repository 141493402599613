import { NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ColdStorageOwnersRoutingModule } from './cold-storage-owners-routing.module';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { COLD_STORAGE_OWNER_EFFECTS } from '../../../store/cold-storage/owners/effects';
import { COLD_STORAGE_OWNER_REDUCERS } from '../../../store/cold-storage/owners/owner.state';

// import { FormComponent } from './form/form.component';

import { HeaderModule } from '../../header/header.module';
import { SidemenuModule } from '../../sidemenu/sidemenu.module';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgbAlertModule, NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { StatesModule } from '../masters/states/states.module';
import { ExperienceTypesModule } from '../masters/experience-types/experience-types.module';
import { DistrictsModule } from '../masters/districts/districts.module';
import { OwnerAddComponent } from './containers/owner-add/owner-add.component';
import { OwnerUpdateComponent } from './containers/owner-update/owner-update.component';
import { OwnerPageComponent } from './containers/owner-page/owner-page.component';
import { OwnerDetailsComponent } from './components/owner-details/owner-details.component';
import { OwnerFormComponent } from './components/owner-form/owner-form.component';
import { OwnerListComponent } from './components/owner-list/owner-list.component';
import { TypeOfStoragesModule } from '../masters/type-of-storages/type-of-storages.module';


@NgModule({
  declarations: [
    OwnerAddComponent,
    OwnerUpdateComponent,
    OwnerPageComponent,
    OwnerDetailsComponent,
    OwnerFormComponent,
    OwnerListComponent
  ],
  imports: [
    CommonModule,
    ColdStorageOwnersRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HeaderModule,
    SidemenuModule,
    SelectDropDownModule,
    NgxDatatableModule,
    NgbModule,
    NgbPaginationModule, 
    NgbAlertModule,
    StatesModule,
    DistrictsModule,
    TypeOfStoragesModule,
    StoreModule.forFeature('owners', COLD_STORAGE_OWNER_REDUCERS),
    EffectsModule.forFeature(COLD_STORAGE_OWNER_EFFECTS),
  ],
  schemas:[NO_ERRORS_SCHEMA]
})
export class ColdStorageOwnersModule { }

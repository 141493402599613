import { StateActionTypes, StateItemActionTypes } from '../actions';

export interface StateItemState {
    error: string | null;
    pending: boolean;
}

export const initialState: StateItemState = {
    error: null,
    pending: false,
};

export function stateItemReducer(state = initialState, action: StateActionTypes): StateItemState {
    switch (action.type) {
        case StateItemActionTypes.AddRecord:
        case StateItemActionTypes.UpdateRecord: {
            return {
                ...state,
                error: null,
                pending: true
            };
        }

        case StateItemActionTypes.AddRecordSuccess:
        case StateItemActionTypes.UpdateRecordSuccess: {
            return {
                ...state,
                error: null,
                pending: false
            };
        }

        case StateItemActionTypes.AddRecordFailure:
        case StateItemActionTypes.UpdateRecordFailure: {
            return {
                ...state,
                error: action.payload,
                pending: false
            };
        }

        default:
            return state;
    }
}

export const getError = (state: StateItemState) => state.error;
export const getPending = (state: StateItemState) => state.pending;

import { GlobalState } from '../../../store';
import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';

import * as fromList from './reducers/list.reducer';
import * as fromItem from './reducers/item.reducer';

export interface OwnerState {
  list: fromList.OwnerListState;
  item: fromItem.OwnerItemState;
}

export interface State extends GlobalState {
  owners: OwnerState;
}

export const COLD_STORAGE_OWNER_REDUCERS: ActionReducerMap<
  OwnerState,
  any
> = {
  list: fromList.ownerListReducer,
  item: fromItem.ownerItemReducer,
};

////// selectors

export const getProjectsState = createFeatureSelector<State, OwnerState>(
  'owners'
);

export const getOwnerListState = createSelector(
  getProjectsState,
  (state) => state.list
);
export const getOwnerItemState = createSelector(
  getProjectsState,
  (state) => state.item
);

export const getOwnersIsLoading = createSelector(
  getOwnerListState,
  fromList.getIsLoading
);

export const getOwnersCount = createSelector(
  getOwnerListState,
  fromList.getCount
);
export const getOwnersOffset = createSelector(
  getOwnerListState,
  fromList.getOffset
);

export const getOwnersSortProp = createSelector(
  getOwnerListState,
  fromList.getSortProp
);
export const getOwnersSortDir = createSelector(
  getOwnerListState,
  fromList.getSortDir
);
export const getOwnersSearch = createSelector(
  getOwnerListState,
  fromList.getSearch
);

export const getSelectedOwnerId = createSelector(
  getOwnerListState,
  fromList.getSelectedId
);

export const {
  selectEntities: getOwnerEntities,
  selectAll: getAllOwners,
} = fromList.adapter.getSelectors(getOwnerListState);

export const getSelectedOwner = createSelector(
  getOwnerEntities,
  getSelectedOwnerId,
  (entities, selectedId) => {
    return selectedId !== null && entities[selectedId];
  }
);

// Item

export const getOwnerItemPending = createSelector(
  getOwnerItemState,
  fromItem.getPending
);
export const getOwnerItemError = createSelector(
  getOwnerItemState,
  fromItem.getError
);

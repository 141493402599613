import { DesignConsultantModel } from './../../../../../store/cold-storage/design-consultants/models/design-consultant.model';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EmailValidator } from '../../../../../shared/form-validators/email.validator';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { select, Store } from '@ngrx/store';
import * as Reducers from '../../../../..//store/reducers';
import localstore from 'store';
import { DesignConsultantExpertTypeMappingModel } from '../../../../..//store/cold-storage/design-consultants/models/design-consultant-expert-type-mapping.model';
import lstore from "store";

@Component({
  selector: 'app-design-consultant-form',
  templateUrl: './design-consultant-form.component.html',
  styleUrl: './design-consultant-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DesignConsultantFormComponent {
  loggedInUserAccess: boolean = false;
  @Input() designConsultantId: string | null = null;

  createdOn: Date = null;
  lastUpdatedOn: Date = null;
  @Input()
  set designConsultant(designConsultantData: DesignConsultantModel | undefined) {
    if (designConsultantData) {
      this.form.patchValue(designConsultantData);
      this.selectedStateId = designConsultantData.state_id;
      this.selectedDistrictId = designConsultantData.district_id;

      this.selectedExpertTypes = [];
      
      designConsultantData.csds_expert_type_mapping_data.forEach((element) => {
        this.selectedExpertTypes.push({
          id: element.expert_type_id,
          name: element.expert_type,
        });
      });
      this.lastUpdatedOn = designConsultantData.updated_on;
      this.createdOn = designConsultantData.created_on;
    }
  }

  @Input()
  set pending(isPending: boolean) {
    this.isPending = isPending;
    
    if (isPending) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  @Input() error: string | null;
  @Output() submitted = new EventEmitter<DesignConsultantModel>();

  loggedInUserId: any;
  isPending: boolean;
  mask = [
    /[1-9]/,
    ' ',
    '(',
    /[1-9]/,
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    ' ',
    '-',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];
  form: FormGroup;
  selectedStateId: any = '0';
  selectedStateName: any = 'NA';
  selectedDistrictId: any = '0';
  selectedDistrictName: any = 'NA';
  selectedExpertInNames: any = '';
  selectedExpertInIds: any = [];
  selectedExpertTypes: any = [];

  constructor(private store: Store<any>, public activeModal: NgbActiveModal) {
    this.store.pipe(select(Reducers.getUser)).subscribe((state) => {
      this.loggedInUserId = state.id;
    });
    this.loggedInUserAccess = lstore.get("aae");

    this.form = new FormGroup({
      id: new FormControl(''),
      user_id: new FormControl(this.loggedInUserId, Validators.required),
      organisation_name: new FormControl(
        '',
        Validators.compose([Validators.required, Validators.maxLength(500)])
      ),
      contact_person: new FormControl(
        '',
        Validators.compose([Validators.required, Validators.maxLength(250)])
      ),
      contact_number: new FormControl(
        '',
        Validators.compose([Validators.required, Validators.maxLength(10)])
      ),
      email: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          EmailValidator.validate,
          Validators.maxLength(200),
        ])
      ),
      // state_id: new FormControl('', Validators.required),
      // district_id: new FormControl('', Validators.required),
      full_address: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
      total_experience: new FormControl('', Validators.required),
      anual_turnover: new FormControl(''),
      is_active: new FormControl('1'),
      is_approved: new FormControl('0'),
    });
  }

  onStateDropdownItemSelect(selectedElement: any) {
    this.selectedStateId = selectedElement.id;
    this.selectedStateName = selectedElement.text;

    this.selectedDistrictId = '0';
    this.selectedDistrictName = 'NA';
  }

  onDistrictDropdownItemSelect(selectedElement: any) {
    this.selectedDistrictId = selectedElement.id;
    this.selectedDistrictName = selectedElement.text;
  }

  searchChange($event) {
    console.log($event);
  }

  onExpertTypeDropdownItemSelect(selectedData: any) {
    console.log(`Unique selected value ${selectedData.ids.length}`);
    // console.log(`Unique selected value ${JSON.stringify(selectedData.text)}`);
    this.selectedExpertInIds = [];
    this.selectedExpertInNames = selectedData.text;
    this.selectedExpertInIds = selectedData.ids;
  }

  submit() {
    if (this.form.valid) {
      const model = this.form.value;
      const expert_type_data: Array<DesignConsultantExpertTypeMappingModel> =
        [];
      this.selectedExpertInIds.forEach((element) => {
        expert_type_data.push({
          expert_type_id: element,
        });
      });
      model.csds_expert_type_mapping_data = [...expert_type_data];
      model.state_id = this.selectedStateId;
      model.district_id = this.selectedDistrictId;

      if (typeof model.primary_contact_person_mobile_no === 'string') {
        model.primary_contact_person_mobile_no = Number(
          model.primary_contact_person_mobile_no.replace(/[^0-9]/g, '')
        );
      }
      console.log(`Form data: ${JSON.stringify(model)}`);
      this.submitted.emit(model);
    }
  }
}

import { Component, OnInit, HostListener } from '@angular/core';
import { getUser } from './../../store/user/reducers';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import * as Reducers from './../../store/reducers';
import * as UserActions from './../../store/user/actions';
import { Actions, ofType } from '@ngrx/effects';
import { Subject, takeUntil, tap } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { jwtAuthService } from './../../services';
import localstore from 'store';
import { environment } from 'src/environments/environment';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AddComponent } from '../pages/cold-storage-contractors/containers/add/add.component';
import { RegisterYourServiceComponent } from '../pages/register-your-service/register-your-service.component';
// import { RegisterYourServiceComponent } from '../pages/register-your-service/register-your-service.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent  implements OnInit {

  form: FormGroup;
  loginform: FormGroup;
  loading: boolean = false;
  showTypeOfServiceProvideBlock: boolean = false;
  //   private destroyed$ = new Subject<boolean>();

  s3Url: any = environment.aws_s3_url;
  aae:boolean=false;
  name: string = '';
  roles: string = '';
  avatar: string = 'assets/images/avatars/avatar.png';
  isUserLoggedIn: boolean;

  modalReference: NgbModalRef;
  
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private store: Store<any>,
    actions$: Actions,
    private notification: ToastrService,
    private modalService: NgbModal,
    private authService: jwtAuthService
  ) {
    this.form = fb.group({
      is_general_user: ['1'],
      user_type: ['0'],
      full_name: ['', [Validators.required, Validators.minLength(4)]],
      email: ['', [Validators.required, Validators.minLength(4)]],
      mobile: [
        '',
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(10),
        ],
      ],
      password: ['', [Validators.required]],
    });

    this.loginform = fb.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });

    this.store.pipe(select(Reducers.getUser)).subscribe((state) => {
      this.loading = state.loading;
      this.aae = localstore.get('aae');
      this.name = localstore.get('name');
      this.roles = localstore.get('roles');
      this.avatar = this.s3Url + state.image;
    });

    this.authService.isLogged.subscribe((logged) => {
      this.isUserLoggedIn = logged;
      console.log(`isLogged::constructor:: ${logged}`);
    });
    // actions$
    //   .pipe(
    //     ofType(UserActions.USER_REGISTER_UNSUCCESSFUL),
    //     takeUntil(this.destroyed$),
    //     tap(() => {
    //       this.closeSigninSignupPopup();

    //       this.notification.error('Registration UnSuccessful', 'Error');
    //     })
    //   )
    //   .subscribe();
  }

  ngOnInit(): void {}

  //   ngOnDestroy() {
  //     this.destroyed$.next(true);
  //     this.destroyed$.complete();
  //   }



  get username() {
    return this.loginform.controls.username;
  }
  get lpassword() {
    return this.loginform.controls.password;
  }

  get isGeneralUser() {
    return this.form.controls.is_general_user;
  }
  get userType() {
    return this.form.controls.user_type;
  }
  get fullName() {
    return this.form.controls.full_name;
  }
  get mobile() {
    return this.form.controls.mobile;
  }
  get email() {
    return this.form.controls.email;
  }
  get password() {
    return this.form.controls.password;
  }

  login() {

    this.username.markAsDirty();
    this.username.updateValueAndValidity();
    this.lpassword.markAsDirty();
    this.lpassword.updateValueAndValidity();
    if (this.username.invalid || this.lpassword.invalid) {
      return;
    }
    const payload = {
        username: this.username.value,
      // username: this.username.value,
      password: this.lpassword.value,
    };
    this.store.dispatch(new UserActions.Login(payload));
    this.closeSigninSignupPopup();
  }
  submitForm() {
    //this.router.navigate(['dashboard']);

    this.email.markAsDirty();
    this.email.updateValueAndValidity();
    this.mobile.markAsDirty();
    this.mobile.updateValueAndValidity();
    this.password.markAsDirty();
    this.password.updateValueAndValidity();
    // if (this.email.invalid || this.password.invalid) {
    if (
      this.isGeneralUser.invalid ||
      this.userType.invalid ||
      this.fullName.invalid ||
      this.email.invalid ||
      this.mobile.invalid ||
      this.password.invalid
    ) {
      return;
    }
    const payload = {
      is_general_user: this.isGeneralUser.value,
      user_type: this.userType.value,
      full_name: this.fullName.value,
      email: this.email.value,
      mobile: this.mobile.value,
      password: this.password.value,
    };
    this.store.dispatch(new UserActions.UserRegister(payload));
    this.closeSigninSignupPopup();
  }

  logout() {
    //console.log(`Logout...`)
    this.store.dispatch(new UserActions.Logout());
    this.authService.checkStatus(); // don't forget this!
    this.authService.isLogged.subscribe((logged) => {
      this.isUserLoggedIn = logged;
    });    
  }
  
  classApplied = false;
  toggleClass() {
    this.classApplied = !this.classApplied;
  }

  // Navbar Sticky
  isSticky: boolean = false;
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    const scrollPosition =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    if (scrollPosition >= 50) {
      this.isSticky = true;
    } else {
      this.isSticky = false;
    }
  }

  // Signin/Signup Popup
  isSigninSignupOpen = false;
  openSigninSignupPopup(): void {
    this.isSigninSignupOpen = true;
  }
  closeSigninSignupPopup(): void {
    this.isSigninSignupOpen = false;
  }

  // Signin/Signup Tabs
  currentTab = 'tab1';
  switchTab(event: MouseEvent, tab: string) {
    event.preventDefault();
    this.currentTab = tab;
  }
}

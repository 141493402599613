import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EquipmentSupplierPageComponent } from './containers/equipment-supplier-page/equipment-supplier-page.component';


const routes: Routes = [
  { path: '', component: EquipmentSupplierPageComponent }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ColdStorageEquipmentSuppliersRoutingModule { }

export interface OverallStatisticsReportApiModel {
  total_active_listings: number;
  total_pending_listings: number;
  total_views: number;
}

export class OverallStatisticsReport {
  total_active_listings: number;
  total_pending_listings: number;
  total_views: number;

  constructor(raw: OverallStatisticsReportApiModel) {
    this.total_active_listings = raw.total_active_listings;
    this.total_pending_listings = raw.total_pending_listings;
    this.total_views = raw.total_views;
  }
}

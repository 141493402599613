import { Action } from '@ngrx/store';
import { Equipment } from '../models/equipment.model';


export enum EquipmentItemActionTypes {
  AddRecord = '[Equipments] Add Record',
  AddRecordSuccess = '[Equipments] Add Record Success',
  AddRecordFailure = '[Equipments] Add Record Failure',

  UpdateRecord = '[Equipments] Update Record',
  UpdateRecordSuccess = '[Equipments] Update Record Success',
  UpdateRecordFailure = '[Equipments] Update Record Failure',
}

////////////

export class AddRecord implements Action {
  readonly type = EquipmentItemActionTypes.AddRecord;

  constructor(public payload: Equipment) {
  }
}

export class AddRecordSuccess implements Action {
  readonly type = EquipmentItemActionTypes.AddRecordSuccess;

  constructor(public payload: Equipment) {
  }
}

export class AddRecordFailure implements Action {
  readonly type = EquipmentItemActionTypes.AddRecordFailure;

  constructor(public payload: string) {
  }
}

export class UpdateRecord implements Action {
  readonly type = EquipmentItemActionTypes.UpdateRecord;

  constructor(public payload: Equipment) {
  }
}

export class UpdateRecordSuccess implements Action {
  readonly type = EquipmentItemActionTypes.UpdateRecordSuccess;

  constructor(public payload: Equipment) {
  }
}

export class UpdateRecordFailure implements Action {
  readonly type = EquipmentItemActionTypes.UpdateRecordFailure;

  constructor(public payload: string) {
  }
}

////////////

export type ItemActions =
  | AddRecord
  | AddRecordSuccess
  | AddRecordFailure
  | UpdateRecord
  | UpdateRecordSuccess
  | UpdateRecordFailure;

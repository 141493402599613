import { Injectable } from '@angular/core';

import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';

import { Observable, of as observableOf } from 'rxjs';
import {
  catchError,
  exhaustMap,
  map,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import * as actions from './../actions';
import { AddRecord, RegisterServiceItemActionTypes } from './../actions';
import * as fromRegisterService from './../register-service.state';

import { RegisterServiceModel } from '../models/register-service.model';
import { RegisterServiceService } from '../../../../services/cold-storage/register-service';
import { GridPayload } from '../../../../shared/models/grid.payload';

@Injectable()
export class RegisterServiceItemEffects {
  @Effect()
  addRecord$: Observable<Action> = this.actions$.pipe(
    ofType<AddRecord>(RegisterServiceItemActionTypes.AddRecord),
    map((action) => action.payload),
    exhaustMap((tp: RegisterServiceModel) =>
      this.contractorService.saveData(tp).pipe(
        map(
          (payload: RegisterServiceModel) =>
            new actions.AddRecordSuccess(payload)
        ),
        catchError((e) =>
          observableOf(new actions.AddRecordFailure(e.error.error))
        )
      )
    )
  );

  constructor(
    private store: Store<fromRegisterService.State>,
    private actions$: Actions,
    private contractorService: RegisterServiceService
  ) {}
}

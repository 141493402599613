import { ExpertTypeActionTypes, ExpertTypeItemActionTypes } from '../actions';

export interface ExpertTypeItemState {
    error: string | null;
    pending: boolean;
}

export const initialState: ExpertTypeItemState = {
    error: null,
    pending: false,
};

export function expertTypeItemReducer(state = initialState, action: ExpertTypeActionTypes): ExpertTypeItemState {
    switch (action.type) {
        case ExpertTypeItemActionTypes.AddRecord:
        case ExpertTypeItemActionTypes.UpdateRecord: {
            return {
                ...state,
                error: null,
                pending: true
            };
        }

        case ExpertTypeItemActionTypes.AddRecordSuccess:
        case ExpertTypeItemActionTypes.UpdateRecordSuccess: {
            return {
                ...state,
                error: null,
                pending: false
            };
        }

        case ExpertTypeItemActionTypes.AddRecordFailure:
        case ExpertTypeItemActionTypes.UpdateRecordFailure: {
            return {
                ...state,
                error: action.payload,
                pending: false
            };
        }

        default:
            return state;
    }
}

export const getError = (state: ExpertTypeItemState) => state.error;
export const getPending = (state: ExpertTypeItemState) => state.pending;

import { NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OwnersRoutingModule } from './owners-routing.module';

import { HeaderModule } from '../../../../components/header/header.module';
import { FooterModule } from '../../../../components/footer/footer.module';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { COLD_STORAGE_OWNER_EFFECTS } from '../../../../store/cold-storage/owners/effects';
import { COLD_STORAGE_OWNER_REDUCERS } from '../../../../store/cold-storage/owners/owner.state';
import { TypeOfStoragesModule } from '../../masters/type-of-storages/type-of-storages.module';
import { SearchOwnersComponent } from './search-owners/search-owners.component';

@NgModule({
  declarations: [
    SearchOwnersComponent
  ],
  imports: [
    CommonModule,
    OwnersRoutingModule,

    HeaderModule,
    FooterModule,
    SelectDropDownModule,
    FormsModule,
    ReactiveFormsModule,

    TypeOfStoragesModule,
    
    StoreModule.forFeature('owners', COLD_STORAGE_OWNER_REDUCERS),
    EffectsModule.forFeature(COLD_STORAGE_OWNER_EFFECTS),
  ],
  schemas:[NO_ERRORS_SCHEMA]
})
export class OwnersModule { }

import { Action } from '@ngrx/store';
import { EquipmentSupplierApiModel } from '../../../../store/cold-storage/equipment-suppliers/models/equipment-supplier.model';
import { GridPayload, LoadGridSuccessPayload } from '../../../../shared/models/grid.payload';
import { LoadSuccessPayload } from '../../../../shared/models/common.payload';




export enum EquipmentSupplierListActionTypes {
    LoadRecords = '[Cold Storage Equipment Suppliers] Load',
    LoadRecordsSuccess = '[Cold Storage Equipment Suppliers] Load Success',
    LoadRecordsFailure = '[Cold Storage Equipment Suppliers] Load Failure',

    LoadListingRecords = '[Cold Storage Equipment Suppliers Listings] Load',
    LoadListingRecordsSuccess = '[Cold Storage Equipment Suppliers Listings] Load Success',
    LoadListingRecordsFailure = '[Cold Storage Equipment Suppliers Listings] Load Failure',

    SelectRecord = '[Cold Storage Equipment Supplier] Select Record',
}

////////////

export class SelectRecord implements Action {
  readonly type = EquipmentSupplierListActionTypes.SelectRecord;

  constructor(public payload: string) {
  }
}

export class LoadRecords implements Action {
  readonly type = EquipmentSupplierListActionTypes.LoadRecords;

  constructor(public gridInfo: GridPayload) {
  }
}

export class LoadRecordsSuccess implements Action {
  readonly type = EquipmentSupplierListActionTypes.LoadRecordsSuccess;

  constructor(public payload: LoadGridSuccessPayload<EquipmentSupplierApiModel>) {
  }
}

export class LoadRecordsFailure implements Action {
  readonly type = EquipmentSupplierListActionTypes.LoadRecordsFailure;

  constructor(public payload: any) {
  }
}

export class LoadListingRecords implements Action {
  readonly type = EquipmentSupplierListActionTypes.LoadListingRecords;

  constructor(public gridInfo: GridPayload) {
  }
}

export class LoadListingRecordsSuccess implements Action {
  readonly type = EquipmentSupplierListActionTypes.LoadListingRecordsSuccess;

  constructor(public payload: LoadGridSuccessPayload<EquipmentSupplierApiModel>) {
  }
}

export class LoadListingRecordsFailure implements Action {
  readonly type = EquipmentSupplierListActionTypes.LoadListingRecordsFailure;

  constructor(public payload: any) {
  }
}
////////////

export type ListActions =
  | SelectRecord
  | LoadRecords
  | LoadRecordsSuccess
  | LoadRecordsFailure
  | LoadListingRecords
  | LoadListingRecordsSuccess
  | LoadListingRecordsFailure;

import { GlobalState } from '../../../store';
import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';

import * as fromItem from './reducers/item.reducer';

export interface RegisterServiceState {
  item: fromItem.RegisterServiceItemState;
}

export interface State extends GlobalState {
  register_services: RegisterServiceState;
}

export const COLD_STORAGE_REGISTER_SERVICE_REDUCERS: ActionReducerMap<
  RegisterServiceState,
  any
> = {
  item: fromItem.registerServiceItemReducer,
};

////// selectors

export const getProjectsState = createFeatureSelector<
  State,
  RegisterServiceState
>('register_services');

export const getRegisterServiceItemState = createSelector(
  getProjectsState,
  (state) => state.item
);

// Item

export const getRegisterServiceItemPending = createSelector(
  getRegisterServiceItemState,
  fromItem.getPending
);
export const getRegisterServiceItemError = createSelector(
  getRegisterServiceItemState,
  fromItem.getError
);

import { NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ColdStorageContractorsRoutingModule } from './cold-storage-contractors-routing.module';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { COLD_STORAGE_CONTRACTOR_EFFECTS } from '../../../store/cold-storage/contractors/effects';
import { COLD_STORAGE_CONTRACTOR_REDUCERS } from '../../../store/cold-storage/contractors/contractor.state';

// import { FormComponent } from './form/form.component';

import { HeaderModule } from '../../header/header.module';
import { SidemenuModule } from '../../sidemenu/sidemenu.module';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AddComponent } from './containers/add/add.component';
import { UpdateComponent } from './containers/update/update.component';
import { PageComponent } from './containers/page/page.component';
import { DetailsComponent } from './components/details/details.component';
import { ListComponent } from './components/list/list.component';
import { FormComponent } from './components/form/form.component';
import { NgbAlertModule, NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { StatesModule } from '../masters/states/states.module';
import { ExperienceTypesModule } from '../masters/experience-types/experience-types.module';
import { DistrictsModule } from '../masters/districts/districts.module';

@NgModule({
  declarations: [
    AddComponent,
    UpdateComponent,
    PageComponent,
    DetailsComponent,
    ListComponent,
    FormComponent

  ],
  imports: [
    CommonModule,
    ColdStorageContractorsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HeaderModule,
    SidemenuModule,
    SelectDropDownModule,
    NgxDatatableModule,
    NgbModule,
    NgbPaginationModule, 
    NgbAlertModule,
    StatesModule,
    DistrictsModule,
    ExperienceTypesModule,
    StoreModule.forFeature('contractors', COLD_STORAGE_CONTRACTOR_REDUCERS),
    EffectsModule.forFeature(COLD_STORAGE_CONTRACTOR_EFFECTS),
  ],
  schemas:[NO_ERRORS_SCHEMA],
  exports:[
    // AddComponent
  ]
})
export class ColdStorageContractorsModule { }

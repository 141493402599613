import { NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DesignConsultantsRoutingModule } from './design-consultants-routing.module';

import { HeaderModule } from '../../../../components/header/header.module';
import { FooterModule } from '../../../../components/footer/footer.module';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { COLD_STORAGE_DESIGN_CONSULTANT_EFFECTS } from '../../../../store/cold-storage/design-consultants/effects';
import { COLD_STORAGE_DESIGN_CONSULTANT_REDUCERS } from '../../../../store/cold-storage/design-consultants/design-consultant.state';
import { ExpertTypesModule } from '../../masters/expert-types/expert-types.module';
import { SearchDesignConsultantsComponent } from './search-design-consultants/search-design-consultants.component';


@NgModule({
  declarations: [
    SearchDesignConsultantsComponent
  ],
  imports: [
    CommonModule,
    DesignConsultantsRoutingModule,

    HeaderModule,
    FooterModule,
    SelectDropDownModule,
    FormsModule,
    ReactiveFormsModule,

    ExpertTypesModule,
    
    StoreModule.forFeature('design_consultants', COLD_STORAGE_DESIGN_CONSULTANT_REDUCERS),
    EffectsModule.forFeature(COLD_STORAGE_DESIGN_CONSULTANT_EFFECTS),
  ],
  schemas:[NO_ERRORS_SCHEMA]
})
export class DesignConsultantsModule { }

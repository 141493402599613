import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContractorsModule } from './contractors/contractors.module';
import { OwnersModule } from './owners/owners.module';
import { EquipmentSuppliersModule } from './equipment-suppliers/equipment-suppliers.module';
import { DesignConsultantsModule } from './design-consultants/design-consultants.module';

const routes: Routes = [
  {
    path: 'search/contractors',
    loadChildren: () => ContractorsModule,
  },
  {
    path: 'search/design-consultants',
    loadChildren: () => DesignConsultantsModule,
  },
  {
    path: 'search/equipment-suppliers',
    loadChildren: () => EquipmentSuppliersModule,
  },
  {
    path: 'search/owners',
    loadChildren: () => OwnersModule,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ListingsRoutingModule {}

import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnInit,
} from "@angular/core";
import { DesignConsultantModel } from "../../../../../store/cold-storage/design-consultants/models/design-consultant.model";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-design-consultant-details',
  templateUrl: './design-consultant-details.component.html',
  styleUrl: './design-consultant-details.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DesignConsultantDetailsComponent  implements OnInit {

  designConsultant: DesignConsultantModel;

  @Input("selectedDesignConsultant") 
  set selectedDesignConsultant(data: any) {
    this.designConsultant = data;
  }
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}

}
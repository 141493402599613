import { Action } from '@ngrx/store';
import { LoadSuccessPayload } from '../../../../shared/models/common.payload';
import { GridPayload, LoadGridSuccessPayload } from '../../../../shared/models/grid.payload';
import { TypeOfStorage, TypeOfStorageApiModel } from '../../../../store/masters/type-of-storages/models/type-of-storage.model';

export enum TypeOfStorageListActionTypes {
    LoadRecords = '[TypeOfStorages] Load',
    LoadRecordsSuccess = '[TypeOfStorages] Load Success',
    LoadRecordsFailure = '[TypeOfStorages] Load Failure',

    LoadDropdownRecords = '[TypeOfStorages Dropdown] Load',
    LoadDropdownRecordsSuccess = '[TypeOfStorages Dropdown] Load Success',
    LoadDropdownRecordsFailure = '[TypeOfStorages Dropdown] Load Failure',

    LoadDropdownByUserRecords = '[TypeOfStorages Dropdown By User] Load',
    LoadDropdownByUserRecordsSuccess = '[TypeOfStorages Dropdown By User] Load Success',
    LoadDropdownByUserRecordsFailure = '[TypeOfStorages Dropdown By User] Load Failure',

    SelectRecord = '[TypeOfStorage] Select Record',
}

////////////

export class SelectRecord implements Action {
  readonly type = TypeOfStorageListActionTypes.SelectRecord;

  constructor(public payload: string) {
  }
}

export class LoadRecords implements Action {
  readonly type = TypeOfStorageListActionTypes.LoadRecords;

  constructor(public gridInfo: GridPayload) {
  }
}

export class LoadRecordsSuccess implements Action {
  readonly type = TypeOfStorageListActionTypes.LoadRecordsSuccess;

  constructor(public payload: LoadGridSuccessPayload<TypeOfStorageApiModel>) {
  }
}

export class LoadRecordsFailure implements Action {
  readonly type = TypeOfStorageListActionTypes.LoadRecordsFailure;

  constructor(public payload: any) {
  }
}

export class LoadDropdownRecords implements Action {
    readonly type = TypeOfStorageListActionTypes.LoadDropdownRecords;

    constructor() {
    }
}

export class LoadDropdownRecordsSuccess implements Action {
    readonly type = TypeOfStorageListActionTypes.LoadDropdownRecordsSuccess;

    constructor(public payload: LoadSuccessPayload<TypeOfStorageApiModel>) {
    }
}

export class LoadDropdownRecordsFailure implements Action {
    readonly type = TypeOfStorageListActionTypes.LoadDropdownRecordsFailure;

    constructor(public payload: any) {
    }
}


export class LoadDropdownByUserRecords implements Action {
  readonly type = TypeOfStorageListActionTypes.LoadDropdownByUserRecords;

  constructor() {
  }
}

export class LoadDropdownByUserRecordsSuccess implements Action {
  readonly type = TypeOfStorageListActionTypes.LoadDropdownByUserRecordsSuccess;

  constructor(public payload: LoadSuccessPayload<TypeOfStorageApiModel>) {
  }
}

export class LoadDropdownByUserRecordsFailure implements Action {
  readonly type = TypeOfStorageListActionTypes.LoadDropdownByUserRecordsFailure;

  constructor(public payload: any) {
  }
}
////////////

export type ListActions =
  | SelectRecord
  | LoadRecords
  | LoadRecordsSuccess
  | LoadRecordsFailure
  | LoadDropdownRecords
  | LoadDropdownRecordsSuccess
  | LoadDropdownRecordsFailure
  | LoadDropdownByUserRecords
  | LoadDropdownByUserRecordsSuccess
  | LoadDropdownByUserRecordsFailure;


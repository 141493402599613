import { Injectable } from "@angular/core";

import { Actions, Effect, ofType } from "@ngrx/effects";
import { Action, select, Store } from "@ngrx/store";

import { Observable, of as observableOf } from "rxjs";
import {
  catchError,
  exhaustMap,
  map,
  tap,
  withLatestFrom,
} from "rxjs/operators";

import * as actions from "./../actions";
import { AddRecord, StateItemActionTypes, UpdateRecord } from "./../actions";
import * as fromState from "./../state.state";

import { State } from "../models/state.model";
import { StateService } from "../../../../services/masters/states";
import { GridPayload } from "../../../../shared/models/grid.payload";

@Injectable()
export class StateItemEffects {
  @Effect()
  addRecord$: Observable<Action> = this.actions$.pipe(
    ofType<AddRecord>(StateItemActionTypes.AddRecord),
    map((action) => action.payload),
    exhaustMap((record: State) =>
      this.batService.saveState(record).pipe(
        map((payload: State) => new actions.AddRecordSuccess(payload)),
        catchError((e) =>
          observableOf(new actions.AddRecordFailure(e.error.error))
        )
      )
    )
  );

  @Effect()
  updateRecord$: Observable<Action> = this.actions$.pipe(
    ofType<UpdateRecord>(StateItemActionTypes.UpdateRecord),
    map((action) => action.payload),
    exhaustMap((record: State) =>
      this.batService.saveState(record).pipe(
        map((payload: State) => new actions.UpdateRecordSuccess(payload)),
        catchError((e) =>
          observableOf(new actions.UpdateRecordFailure(e.error.error))
        )
      )
    )
  );

  @Effect({ dispatch: false })
  reloadGrid$: Observable<any> = this.actions$.pipe(
    ofType(StateItemActionTypes.AddRecordSuccess),
    withLatestFrom(
      this.store.pipe(select(fromState.getStatesOffset)),
      this.store.pipe(select(fromState.getStatesSortProp)),
      this.store.pipe(select(fromState.getStatesSortDir)),
      this.store.pipe(select(fromState.getStatesSearch)),
      (action, offset, sortProp, sortDir, search) => ({
        offset,
        sortProp,
        sortDir,
        search,
      })
    ),
    tap((info: GridPayload) => {
      this.store.dispatch(new actions.LoadRecords(info));
    })
  );

  @Effect({ dispatch: false })
  closeModal$: Observable<any> = this.actions$.pipe(
    ofType(
      StateItemActionTypes.AddRecordSuccess,
      StateItemActionTypes.UpdateRecordSuccess
    ),
    withLatestFrom(
      this.store.pipe(select(fromState.getStatesOffset)),
      this.store.pipe(select(fromState.getStatesSortProp)),
      this.store.pipe(select(fromState.getStatesSortDir)),
      this.store.pipe(select(fromState.getStatesSearch)),
      (action, offset, sortProp, sortDir, search) => ({
        offset,
        sortProp,
        sortDir,
        search,
      })
    ),
    tap((info: GridPayload) => {
      this.store.dispatch(new actions.LoadRecords(info));
    })
  );

  constructor(
    private store: Store<fromState.State>,
    private actions$: Actions,
    private batService: StateService
  ) {}
}

import { Injectable } from '@angular/core';
import { BaseService } from '../../../base/base.service';
import { map } from 'rxjs/operators';
import {
  EquipmentSupplierModel,
  EquipmentSupplierApiModel
} from '../../../store/cold-storage/equipment-suppliers/models/equipment-supplier.model';

import { GridPayload } from '../../../shared/models/grid.payload';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EquipmentSupplierService extends BaseService {
  apiUrl: any = environment.api_url

  getEquipmentSuppliersList(gridInfo: GridPayload) {
    return this.gridRequest<EquipmentSupplierApiModel>(`${this.apiUrl}/api/equipment/suppliers/records`, gridInfo.offset, gridInfo.sortProp, gridInfo.sortDir, gridInfo.search)
      .pipe(
        map(response => ({
          ...response,
          data: response.data.map(tp => new EquipmentSupplierModel(tp))
        }))
      );
  }

  getEquipmentSuppliersListings(gridInfo: GridPayload) {
    return this.gridRequest<EquipmentSupplierApiModel>(`${this.apiUrl}/api/equipment/suppliers/listings`, gridInfo.offset, gridInfo.sortProp, gridInfo.sortDir, gridInfo.search)
      .pipe(
        map(response => ({
          ...response,
          data: response.data.map(tp => new EquipmentSupplierModel(tp))
        }))
      );
  }

  saveEquipmentSupplierData(payload: EquipmentSupplierModel): Observable<EquipmentSupplierModel> {
    return this.http.post<EquipmentSupplierModel>(`${this.apiUrl}/api/equipment/suppliers/save`, payload);
  }
  
  updateStatus(payload: any): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/api/equipment/suppliers/update/record/status`,
      payload
    );
  }
  
  updateApprovalStatus(payload: any): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/api/equipment/suppliers/update/approval/status`,
      payload
    );
  }
  
}

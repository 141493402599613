<div
  class="input-group"
  *ngIf="
    typeOfStoragesDropdown$ | async as typeOfStoragesDropdown;
    else loading
  "
>
  <ngx-select-dropdown
    (searchChange)="searchChange($event)"
    class="ng-dropdown-width"
    tabindex="0"
    [(ngModel)]="selectedTypeOfStorages$"
    [multiple]="true"
    (change)="selectionChanged($event)"
    [config]="config"
    [options]="typeOfStoragesDropdown"
  ></ngx-select-dropdown>

  <ng-template #optionTemplate let-item="item" let-config="config">
    <i class="fa fa-plus"> </i>
    {{ item.name }}
    <span class="new badge"> </span>
  </ng-template>
</div>
<ng-template #loading>Loading type of storages...</ng-template>

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EmailValidator } from '../../../shared/form-validators/email.validator';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { select, Store } from '@ngrx/store';
import * as Reducers from '../../../store/reducers';
import lstore from 'store';
import * as fromRegisterServices from '../../../store/cold-storage/register-service';
import { Actions, ofType } from '@ngrx/effects';
import { Subject, takeUntil, tap } from 'rxjs';
import { RegisterServiceItemActionTypes } from '../../../store/cold-storage/register-service';
import { ServiceCategoryMappingModel } from 'src/app/store/cold-storage/register-service/models/service-category-mapping.model';

@Component({
  selector: 'app-register-your-service',
  templateUrl: './register-your-service.component.html',
  styleUrl: './register-your-service.component.scss',
})
export class RegisterYourServiceComponent implements OnInit, OnDestroy {
  pending$ = this.store.pipe(
    select(fromRegisterServices.getRegisterServiceItemPending)
  );
  error$ = this.store.pipe(
    select(fromRegisterServices.getRegisterServiceItemError)
  );
  private destroyed$ = new Subject<boolean>();

  loggedInUserAccess: boolean = false;

  loggedInUserId: any;

  isPending: boolean;

  form: FormGroup;
  selectedStateId: any = '0';
  selectedStateName: any = 'NA';
  selectedDistrictId: any = '0';
  selectedDistrictName: any = 'NA';
  selectedExperienceInNames: any = '';
  selectedExperienceInIds: any = [];
  selectedExperienceTypes: any = [];

  selectedExpertInNames: any = '';
  selectedExpertInIds: any = [];
  selectedExpertTypes: any = [];

  selectedEquipmentInNames: any = '';
  selectedEquipmentInIds: any = [];
  selectedEquipments: any = [];

  selectedTypeOfColdStorageInNames: any = '';
  selectedTypeOfColdStorageInIds: any = [];
  selectedTypeOfColdStorageTypes: any = [];
    message:any="";

  ngOnInit(): void {}

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  constructor(private store: Store<any>, actions$: Actions) {
    this.store.pipe(select(Reducers.getUser)).subscribe((state) => {
      this.loggedInUserId = state.id;
    });
    this.loggedInUserAccess = lstore.get('aae');

    this.form = new FormGroup({
      type:  new FormControl('', Validators.required),
      user_id: new FormControl(this.loggedInUserId),
      cold_storage_name: new FormControl(''),
      organisation_name: new FormControl(''),
      contact_person: new FormControl(
        '',
        Validators.compose([Validators.required, Validators.maxLength(250)])
      ),
      contact_number: new FormControl(
        '',
        Validators.compose([Validators.required, Validators.maxLength(10)])
      ),
      email: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          EmailValidator.validate,
          Validators.maxLength(200),
        ])
      ),
      // state_id: new FormControl('', Validators.required),
      // district_id: new FormControl('', Validators.required),
      full_address: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
      total_experience: new FormControl('0'),
      anual_turnover: new FormControl('0'),
      cold_storage_capacity: new FormControl('0'),
      cold_storage_area: new FormControl('0'),
      is_active: new FormControl('1'),
      is_approved: new FormControl('0'),
    });

    actions$
      .pipe(
        ofType(
          RegisterServiceItemActionTypes.AddRecordSuccess,
          RegisterServiceItemActionTypes.AddRecordFailure
        ),
        takeUntil(this.destroyed$),
        tap(() => {
          //Show message
          this.message = "Your request have been submitted for verification. You can login with the mobile number and default password to get updates/manage your services/account. After successful login you will see a Dashboard link as shown in the below image, click on it to access the account."
        })
      )
      .subscribe();
  }

  onStateDropdownItemSelect(selectedElement: any) {
    this.selectedStateId = selectedElement.id;
    this.selectedStateName = selectedElement.text;

    this.selectedDistrictId = '0';
    this.selectedDistrictName = 'NA';
  }

  onDistrictDropdownItemSelect(selectedElement: any) {
    this.selectedDistrictId = selectedElement.id;
    this.selectedDistrictName = selectedElement.text;
  }

  searchChange($event) {
    console.log($event);
  }

  onExperienceTypeDropdownItemSelect(selectedData: any) {
    console.log(`Unique selected value ${selectedData.ids.length}`);
    // console.log(`Unique selected value ${JSON.stringify(selectedData.text)}`);
    this.selectedExperienceInIds = [];
    this.selectedExperienceInNames = selectedData.text;
    this.selectedExperienceInIds = selectedData.ids;
  }

  onTypeOfColdStorageTypeDropdownItemSelect(selectedData: any) {
    console.log(`Unique selected value ${selectedData.ids.length}`);
    console.log(`Unique selected value ${JSON.stringify(selectedData.text)}`);
    this.selectedTypeOfColdStorageInIds = [];
    this.selectedTypeOfColdStorageInNames = selectedData.text;
    this.selectedTypeOfColdStorageInIds = selectedData.ids;
  }

  onEquipmentDropdownItemSelect(selectedData: any) {
    console.log(`Unique selected value ${selectedData.ids.length}`);
    // console.log(`Unique selected value ${JSON.stringify(selectedData.text)}`);
    this.selectedEquipmentInIds = [];
    this.selectedEquipmentInNames = selectedData.text;
    this.selectedEquipmentInIds = selectedData.ids;
  }

  onExpertTypeDropdownItemSelect(selectedData: any) {
    console.log(`Unique selected value ${selectedData.ids.length}`);
    // console.log(`Unique selected value ${JSON.stringify(selectedData.text)}`);
    this.selectedExpertInIds = [];
    this.selectedExpertInNames = selectedData.text;
    this.selectedExpertInIds = selectedData.ids;
  }

  submit() {
    if (this.form.valid) {
      var model = this.form.value;
      var service_category_mapping_data: Array<ServiceCategoryMappingModel> = [];
      this.selectedExperienceInIds.forEach((element) => {
        service_category_mapping_data.push({
          service_category_id: element,
        });
      });
      model.service_category_mapping_data = [...service_category_mapping_data];
      model.state_id = this.selectedStateId;
      model.district_id = this.selectedDistrictId;

      if (typeof model.primary_contact_person_mobile_no === 'string') {
        model.primary_contact_person_mobile_no = Number(
          model.primary_contact_person_mobile_no.replace(/[^0-9]/g, '')
        );
      }
      console.log(`Form data: ${JSON.stringify(model)}`);
      this.store.dispatch(new fromRegisterServices.AddRecord(model));
      this.form.reset();
    }
  }
}

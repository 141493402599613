import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnInit,
} from '@angular/core';
import { OwnerModel } from '../../../../../store/cold-storage/owners/models/owner.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-owner-details',
  templateUrl: './owner-details.component.html',
  styleUrl: './owner-details.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OwnerDetailsComponent implements OnInit {
  owner: OwnerModel;

  @Input('selectedOwner')
  set selectedOwner(data: any) {
    this.owner = data;
  }
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}
}

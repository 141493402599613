import { FooterModule } from './../../../footer/footer.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StatesRoutingModule } from './states-routing.module';
import { StateDropdownComponent } from './components/state-dropdown/state-dropdown.component';
import { StateDetailsComponent } from './components/state-details/state-details.component';
import { StateFormComponent } from './components/state-form/state-form.component';
import { StateListComponent } from './components/state-list/state-list.component';
import { StatePageComponent } from './containers/state-page/state-page.component';
import { StateAddComponent } from './containers/state-add/state-add.component';
import { StateUpdateComponent } from './containers/state-update/state-update.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { STATE_EFFECTS } from '../../../../store/masters/states/effects';
import { STATE_REDUCERS } from '../../../../store/masters/states/state.state';


import { HeaderModule } from '../../../header/header.module';
import { SidemenuModule } from '../../../sidemenu/sidemenu.module';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgbAlertModule, NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    StateDropdownComponent,
    StateDetailsComponent,
    StateFormComponent,
    StateListComponent,
    StateAddComponent,
    StateUpdateComponent,
    StatePageComponent,
  ],
  imports: [
    CommonModule,
    StatesRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HeaderModule,
    SidemenuModule,
    FooterModule,
    SelectDropDownModule,
    NgxDatatableModule,
    NgbModule,
    NgbPaginationModule, 
    NgbAlertModule,
    StoreModule.forFeature('states', STATE_REDUCERS),
    EffectsModule.forFeature(STATE_EFFECTS),
  ],
  exports: [
    StateDropdownComponent
  ]
})
export class StatesModule { }

import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { TypeOfStorage } from '../models/type-of-storage.model';
import { TypeOfStorageActionTypes, TypeOfStorageItemActionTypes, TypeOfStorageListActionTypes } from '../actions';


export interface TypeOfStorageListState extends EntityState<TypeOfStorage> {
  isLoading: boolean;
  selectedTypeOfStorageId: string | null;

  count: number;
  offset: number;

  sortProp: string;
  sortDir: string;

  search:string;
}


export const adapter: EntityAdapter<TypeOfStorage> = createEntityAdapter<TypeOfStorage>({
  selectId: (bt: TypeOfStorage) => bt.id,
});


export const initialState: TypeOfStorageListState = adapter.getInitialState({
  isLoading: false,
  selectedTypeOfStorageId: null,

  count: 0,
  offset: 0,

  sortProp: 'name',
  sortDir: 'asc',
  search: 'all'
});


export function typeOfStorageListReducer(state = initialState, action: TypeOfStorageActionTypes): TypeOfStorageListState {
  switch (action.type) {

    case TypeOfStorageListActionTypes.LoadRecords: {
      return {
        ...state,
        ...action.gridInfo,
        isLoading: true,
      };
    }

    case TypeOfStorageListActionTypes.LoadRecordsSuccess: {
      return adapter.addMany(action.payload.data as TypeOfStorage[], {
        ...adapter.removeAll(state),
        isLoading: false,
        count: action.payload.count
      });
    }

    case TypeOfStorageItemActionTypes.UpdateRecordSuccess: {
      return adapter.upsertOne(action.payload, state);
    }

    case TypeOfStorageListActionTypes.SelectRecord: {
      return {
        ...state,
        selectedTypeOfStorageId: action.payload
      };
    }

    case TypeOfStorageListActionTypes.LoadDropdownRecords: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case TypeOfStorageListActionTypes.LoadDropdownRecordsSuccess: {
      return adapter.addMany(action.payload.data as TypeOfStorage[], {
        ...adapter.removeAll(state),
        isLoading: false
      });
    }


    case TypeOfStorageListActionTypes.LoadDropdownByUserRecords: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case TypeOfStorageListActionTypes.LoadDropdownByUserRecordsSuccess: {
      return adapter.addMany(action.payload.data as TypeOfStorage[], {
        ...adapter.removeAll(state),
        isLoading: false
      });
    }

    default:
      return state;
  }
}


export const getIsLoading = (state: TypeOfStorageListState) => state.isLoading;

export const getCount = (state: TypeOfStorageListState) => state.count;
export const getOffset = (state: TypeOfStorageListState) => state.offset;

export const getSortProp = (state: TypeOfStorageListState) => state.sortProp;
export const getSortDir = (state: TypeOfStorageListState) => state.sortDir;
export const getSearch = (state: TypeOfStorageListState) => state.search;

export const getSelectedId = (state: TypeOfStorageListState) => state.selectedTypeOfStorageId;

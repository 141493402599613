import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ExperienceTypesRoutingModule } from './experience-types-routing.module';

import { ExperienceTypePageComponent } from './containers/experience-type-page/experience-type-page.component';
import { ExperienceTypeAddComponent } from './containers/experience-type-add/experience-type-add.component';
import { ExperienceTypeUpdateComponent } from './containers/experience-type-update/experience-type-update.component';
import { ExperienceTypeDetailsComponent } from './components/experience-type-details/experience-type-details.component';
import { ExperienceTypeDropdownComponent } from './components/experience-type-dropdown/experience-type-dropdown.component';
import { ExperienceTypeFormComponent } from './components/experience-type-form/experience-type-form.component';
import { ExperienceTypeListComponent } from './components/experience-type-list/experience-type-list.component';


import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { EXPERIENCE_TYPE_EFFECTS } from '../../../../store/masters/experience-types/effects';
import { EXPERIENCE_TYPE_REDUCERS } from '../../../../store/masters/experience-types/experience-type.state';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { HeaderModule } from '../../../header/header.module';
import { SidemenuModule } from '../../../sidemenu/sidemenu.module';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgbAlertModule, NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { FooterModule } from '../../../../components/footer/footer.module';
import { ExperienceTypeMultiSelectDropdownComponent } from './components/experience-type-multi-select-dropdown/experience-type-multi-select-dropdown.component';
import { ExperienceTypeFilterListComponent } from './components/experience-type-filter-list/experience-type-filter-list.component';



@NgModule({
  declarations: [
    ExperienceTypePageComponent,
    ExperienceTypeAddComponent,
    ExperienceTypeUpdateComponent,
    ExperienceTypeDetailsComponent,
    ExperienceTypeDropdownComponent,
    ExperienceTypeFormComponent,
    ExperienceTypeListComponent,
    ExperienceTypeMultiSelectDropdownComponent,
    ExperienceTypeFilterListComponent
  ],
  imports: [
    CommonModule,
    ExperienceTypesRoutingModule,
    
    FormsModule,
    ReactiveFormsModule,
    
    HeaderModule,
    SidemenuModule,
    FooterModule,
    SelectDropDownModule,
    NgxDatatableModule,
    NgbModule,
    NgbPaginationModule, 
    NgbAlertModule,

    StoreModule.forFeature('experience_types', EXPERIENCE_TYPE_REDUCERS),
    EffectsModule.forFeature(EXPERIENCE_TYPE_EFFECTS),
  ],
  exports:[
    ExperienceTypeDropdownComponent,
    ExperienceTypeMultiSelectDropdownComponent,
    ExperienceTypeFilterListComponent
  ]
})
export class ExperienceTypesModule { }

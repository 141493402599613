import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import * as fromExperienceTypes from '../../../../../../store/masters/experience-types';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ExperienceType } from '../../../../../../store/masters/experience-types/models/experience-type.model';

@Component({
  selector: 'app-experience-type-filter-list',
  templateUrl: './experience-type-filter-list.component.html',
  styleUrl: './experience-type-filter-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExperienceTypeFilterListComponent implements OnInit {
  experienceType$: any = '0';
  experienceTypesDropdown$: Observable<any>;

  @Input('selectedExperienceType')
  set selectedExperienceType(value: any) {
    this.experienceType$ =
      value == null || value == undefined || value == '' ? '0' : value;
  }

  @Output() selectExperienceTypeFilterListItem = new EventEmitter();

  constructor(
    public cdr: ChangeDetectorRef,
    private readonly store: Store<fromExperienceTypes.State>
  ) {}

  ngOnInit() {
    this.store.dispatch(new fromExperienceTypes.LoadDropdownRecords());

    this.experienceTypesDropdown$ = this.store.pipe(
      select(fromExperienceTypes.getAllExperienceTypes)
    );

    // this.experienceTypesDropdown$.subscribe((data)=>{
    //   console.log(`action.payload.data:: ${JSON.stringify(data)}`)
    // })
  }

  compareExperienceTypes(p1: ExperienceType, p2: ExperienceType) {
    return p1.id === p2.id;
  }

  
  selectedIds: any = [];
  onSelect(event, id, name) {
    if (event.target.checked == true) {
      // console.log('checkbox is checked');
      this.selectedIds = [...this.selectedIds, id];
    } else {
      // console.log('checkbox is unchecked');
      // const index: number = this.selectedIds.indexOf(id);
      // if (index !== -1) {
      //   this.selectedIds.splice(index, 1);
      // }
      const filteredItems = this.selectedIds.filter(item => item !== id);
      this.selectedIds = [...filteredItems];
    }

    /*
    const selectedId = id; 
    const selectedElement = {
      id: id,
      text: name,
    };
    */
    // console.log(`Filter componet selected ids ${this.selectedIds.toString()}`);
    this.selectExperienceTypeFilterListItem.emit(this.selectedIds);
  }
}

import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ExperienceType } from '../models/experience-type.model';
import { ExperienceTypeActionTypes, ExperienceTypeItemActionTypes, ExperienceTypeListActionTypes } from '../actions';


export interface ExperienceTypeListState extends EntityState<ExperienceType> {
  isLoading: boolean;
  selectedExperienceTypeId: string | null;

  count: number;
  offset: number;

  sortProp: string;
  sortDir: string;

  search:string;
}


export const adapter: EntityAdapter<ExperienceType> = createEntityAdapter<ExperienceType>({
  selectId: (bt: ExperienceType) => bt.id,
});


export const initialState: ExperienceTypeListState = adapter.getInitialState({
  isLoading: false,
  selectedExperienceTypeId: null,

  count: 0,
  offset: 0,

  sortProp: 'name',
  sortDir: 'asc',
  search: 'all'
});


export function experienceTypeListReducer(state = initialState, action: ExperienceTypeActionTypes): ExperienceTypeListState {
  switch (action.type) {

    case ExperienceTypeListActionTypes.LoadRecords: {
      return {
        ...state,
        ...action.gridInfo,
        isLoading: true,
      };
    }

    case ExperienceTypeListActionTypes.LoadRecordsSuccess: {
      return adapter.addMany(action.payload.data as ExperienceType[], {
        ...adapter.removeAll(state),
        isLoading: false,
        count: action.payload.count
      });
    }

    case ExperienceTypeItemActionTypes.UpdateRecordSuccess: {
      return adapter.upsertOne(action.payload, state);
    }

    case ExperienceTypeListActionTypes.SelectRecord: {
      return {
        ...state,
        selectedExperienceTypeId: action.payload
      };
    }

    case ExperienceTypeListActionTypes.LoadDropdownRecords: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case ExperienceTypeListActionTypes.LoadDropdownRecordsSuccess: {
      return adapter.addMany(action.payload.data as ExperienceType[], {
        ...adapter.removeAll(state),
        isLoading: false
      });
    }


    case ExperienceTypeListActionTypes.LoadDropdownByUserRecords: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case ExperienceTypeListActionTypes.LoadDropdownByUserRecordsSuccess: {
      return adapter.addMany(action.payload.data as ExperienceType[], {
        ...adapter.removeAll(state),
        isLoading: false
      });
    }

    default:
      return state;
  }
}


export const getIsLoading = (state: ExperienceTypeListState) => state.isLoading;

export const getCount = (state: ExperienceTypeListState) => state.count;
export const getOffset = (state: ExperienceTypeListState) => state.offset;

export const getSortProp = (state: ExperienceTypeListState) => state.sortProp;
export const getSortDir = (state: ExperienceTypeListState) => state.sortDir;
export const getSearch = (state: ExperienceTypeListState) => state.search;

export const getSelectedId = (state: ExperienceTypeListState) => state.selectedExperienceTypeId;

<app-header></app-header>

<app-home-banner></app-home-banner>

<app-home-events></app-home-events>

<section class="how-it-works-area pt-100 pb-70 bg-f9f9f9">
  <!-- <app-how-it-works></app-how-it-works> -->
</section>


<app-footer></app-footer>

import { SupplierEquipmentMappingApiModel, SupplierEquipmentMappingModel } from "./supplier-equipment-mapping.model";

export interface EquipmentSupplierApiModel {
  id: string;

  user_id: string;
  username: string;

  organisation_name: string;
  contact_person: string;
  contact_number: string;
  email: string;

  state_id: string;
  state: string;

  district_id: string;
  district: string;

  full_address: string;

  description: string;

  total_experience: number;
  anual_turnover: number;

  is_approved: number;
  is_active: number;

  created_on: Date;
  updated_on: Date;

  cses_equipment_mapping_data?: Array<SupplierEquipmentMappingApiModel>;
}

export class EquipmentSupplierModel {
  id: string;

  user_id: string;
  username: string;

  organisation_name: string;
  contact_person: string;
  contact_number: string;
  email: string;

  state_id: string;
  state: string;

  district_id: string;
  district: string;

  full_address: string;

  description: string;

  total_experience: number;
  anual_turnover: number;

  is_approved: number;
  is_active: number;

  created_on: Date;
  updated_on: Date;

  cses_equipment_mapping_data?: Array<SupplierEquipmentMappingModel>;

  constructor(raw: EquipmentSupplierApiModel) {
    this.id = raw.id;

    this.user_id = raw.user_id;
    this.username = raw.username;

    this.organisation_name = raw.organisation_name;
    this.contact_person = raw.contact_person;
    this.contact_number = raw.contact_number;
    this.email = raw.email;

    this.state_id = raw.state_id;
    this.state = raw.state;

    this.district_id = raw.district_id;
    this.district = raw.district;

    this.full_address = raw.full_address;
    this.description = raw.description;

    this.total_experience = raw.total_experience;
    this.anual_turnover = raw.anual_turnover;

    this.is_approved = raw.is_approved;
    this.is_active = raw.is_active;

    this.created_on = raw.created_on;
    this.updated_on = raw.updated_on;

    this.cses_equipment_mapping_data =
      raw.cses_equipment_mapping_data != undefined &&
      raw.cses_equipment_mapping_data.length > 0
        ? [...raw.cses_equipment_mapping_data]
        : [];
  }

  get emailLink() {
    return `mailto: ${this.email}`;
  }

  get telLink() {
    return `tel: ${this.contact_number}`;
  }
}

<div
  class="input-group"
  *ngIf="equipmentsDropdown$ | async as equipmentsDropdown; else loading"
>
  <ngx-select-dropdown
    (searchChange)="searchChange($event)"
    class="ng-dropdown-width"
    tabindex="0"
    [(ngModel)]="selectedEquipments$"
    [multiple]="true"
    (change)="selectionChanged($event)"
    [config]="config"
    [options]="equipmentsDropdown"
  ></ngx-select-dropdown>
</div>
<ng-template #loading>Loading equipment...</ng-template>

import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnInit,
} from "@angular/core";
import { ContractorModel } from "../../../../../store/cold-storage/contractors/models/contractor.model";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrl: './details.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailsComponent  implements OnInit {

  contractor: ContractorModel;

  @Input("selectedContractor") 
  set selectedContractor(data: any) {
    this.contractor = data;
  }
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}

}

<div
  class="input-group"
  *ngIf="
    expertTypesDropdown$ | async as expertTypesDropdown;
    else loading
  "
>
  <ngx-select-dropdown
    (searchChange)="searchChange($event)"
    class="ng-dropdown-width"
    tabindex="0"
    [(ngModel)]="selectedExpertTypes$"
    [multiple]="true"
    (change)="selectionChanged($event)"
    [config]="config"
    [options]="expertTypesDropdown"
  ></ngx-select-dropdown>
</div>
<ng-template #loading>Loading expert types...</ng-template>

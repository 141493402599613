import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ContractorsRoutingModule } from './contractors-routing.module';
import { HeaderModule } from '../../../../components/header/header.module';
import { FooterModule } from '../../../../components/footer/footer.module';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { COLD_STORAGE_CONTRACTOR_EFFECTS } from './../../../../store/cold-storage/contractors/effects';
import { COLD_STORAGE_CONTRACTOR_REDUCERS } from './../../../../store/cold-storage/contractors/contractor.state';
import { SearchContractorsComponent } from './search-contractors/search-contractors.component';
import { ExperienceTypesModule } from '../../masters/experience-types/experience-types.module';


@NgModule({
  declarations: [
    SearchContractorsComponent
  ],
  imports: [
    CommonModule,
    ContractorsRoutingModule,
    HeaderModule,
    FooterModule,
    SelectDropDownModule,
    FormsModule,
    ReactiveFormsModule,
    ExperienceTypesModule,
    StoreModule.forFeature('contractors', COLD_STORAGE_CONTRACTOR_REDUCERS),
    EffectsModule.forFeature(COLD_STORAGE_CONTRACTOR_EFFECTS),
  ]
})
export class ContractorsModule { }

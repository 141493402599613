import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-home-banner',
    templateUrl: './home-banner.component.html',
    styleUrls: ['./home-banner.component.scss']
})
export class HomeBannerComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
        this.resetOption = [this.options[0]];
    }

    mainBannerContent = [
        {
            title: 'Find Nearby',
            paragraph: 'Expolore top-rated attractions, activities and more...',
            popularSearchList: [
                {
                    title: 'Cold Storage Design Consultants',
                    link: 'grid-listings-left-sidebar'
                },
                {
                    title: 'Cold Storage Erectors/ Contractors',
                    link: 'grid-listings-left-sidebar'
                },
                {
                    title: 'Cold Storage Equipment Suppliers',
                    link: 'grid-listings-left-sidebar'
                },
                {
                    title: 'Cold Storage Owners',
                    link: 'grid-listings-left-sidebar'
                },
            ]
        }
    ]

    // Category Select
    singleSelect: any = [];
    multiSelect: any = [];
    stringArray: any = [];
    objectsArray: any = [];
    resetOption: any;
    config:any = {
        displayKey: "name",
        search: true
    };
    options = [
        // Type here your category name
        {
            name: "Cold Storage Design Consultants",
        },
        {
            name: "Cold Storage Erectors/ Contractors",
        },
        {
            name: "Cold Storage Owners",
        },
        {
            name: "Cold Storage Equipment Suppliers",
        },
    ];
    searchChange($event) {
        console.log($event);
    }
    reset() {
        this.resetOption = [];
    }

}
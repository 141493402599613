import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OwnerPageComponent } from './containers/owner-page/owner-page.component';

const routes: Routes = [
  { path: '', component: OwnerPageComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ColdStorageOwnersRoutingModule { }

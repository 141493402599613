<!-- Start Sidemenu Area -->
<div class="sidemenu-area" [class.active]="classApplied">
    <!-- <div class="sidemenu-header">
        <a routerLink="/dashboard" class="navbar-brand d-flex align-items-center">
            <img src="assets/img/black-logo.png" alt="image">
        </a>
        <div class="responsive-burger-menu d-block d-xl-none" (click)="toggleClass()">
            <span class="top-bar"></span>
            <span class="middle-bar"></span>
            <span class="bottom-bar"></span>
        </div>
    </div> -->
    <div class="sidemenu-body">
        <ul class="sidemenu-nav h-100">
            <li class="nav-item-title">
                Main
            </li>
            <li class="nav-item" routerLinkActive="active mm-active">
                <a routerLink="/dashboard" class="nav-link">
                    <span class="icon"><i class='bx bx-home-circle'></i></span>
                    <span class="menu-title">Dashboard</span>
                </a>
            </li>
            <li class="nav-item-title">
                Cold Storage
            </li>
            <li class="nav-item" routerLinkActive="active mm-active">
                <a routerLink="/contractors" class="nav-link">
                    <span class="icon"><i class='bx bx-home-circle'></i></span>
                    <span class="menu-title">Contractors</span>
                </a>
            </li>
            <li class="nav-item" routerLinkActive="active mm-active">
                <a routerLink="/design-consultants" class="nav-link">
                    <span class="icon"><i class='bx bx-home-circle'></i></span>
                    <span class="menu-title">Design Consultants</span>
                </a>
            </li>
            <li class="nav-item" routerLinkActive="active mm-active">
                <a routerLink="/equipment-suppliers" class="nav-link">
                    <span class="icon"><i class='bx bx-home-circle'></i></span>
                    <span class="menu-title">Equipment Suppliers</span>
                </a>
            </li>
            <li class="nav-item" routerLinkActive="active mm-active">
                <a routerLink="/owners" class="nav-link">
                    <span class="icon"><i class='bx bx-home-circle'></i></span>
                    <span class="menu-title">Owners</span>
                </a>
            </li>
            <li class="nav-item-title" *ngIf="loggedInUserAccess">
                Masters
            </li>
            <li class="nav-item" routerLinkActive="active mm-active" *ngIf="loggedInUserAccess">
                <a routerLink="/states" class="nav-link">
                    <span class="icon"><i class='bx bxs-user-circle'></i></span>
                    <span class="menu-title">State</span>
                </a>
            </li>
            <li class="nav-item" routerLinkActive="active mm-active" *ngIf="loggedInUserAccess">
                <a routerLink="/districts" class="nav-link">
                    <span class="icon"><i class='bx bxs-user-circle'></i></span>
                    <span class="menu-title">District</span>
                </a>
            </li>
            <li class="nav-item" routerLinkActive="active mm-active" *ngIf="loggedInUserAccess">
                <a routerLink="/type-of-storages" class="nav-link">
                    <span class="icon"><i class='bx bxs-user-circle'></i></span>
                    <span class="menu-title">Type of Storages</span>
                </a>
            </li>
            <li class="nav-item" routerLinkActive="active mm-active" *ngIf="loggedInUserAccess">
                <a routerLink="/equipments" class="nav-link">
                    <span class="icon"><i class='bx bxs-user-circle'></i></span>
                    <span class="menu-title">Equipment</span>
                </a>
            </li>
            <li class="nav-item" routerLinkActive="active mm-active" *ngIf="loggedInUserAccess">
                <a routerLink="/experience-types" class="nav-link">
                    <span class="icon"><i class='bx bxs-user-circle'></i></span>
                    <span class="menu-title">Experience Types</span>
                </a>
            </li>
            <li class="nav-item" routerLinkActive="active mm-active" *ngIf="loggedInUserAccess">
                <a routerLink="/expert-types" class="nav-link">
                    <span class="icon"><i class='bx bxs-user-circle'></i></span>
                    <span class="menu-title">Expert Types</span>
                </a>
            </li>
            <li class="nav-item-title">
                Account
            </li>
            <li class="nav-item" routerLinkActive="active mm-active">
                <a routerLink="/dashboard" class="nav-link">
                    <span class="icon"><i class='bx bxs-user-circle'></i></span>
                    <span class="menu-title">Profile</span>
                </a>
            </li>
            <li class="nav-item">
                <a (click)="logout()" class="nav-link" style="cursor: pointer !important;">
                    <span class="icon"><i class='bx bx-log-out'></i></span>
                    <span class="menu-title">Logout</span>
                </a>
            </li>
        </ul>
    </div>
</div>
<!-- End Sidemenu Area -->
import { Injectable } from "@angular/core";

import { Actions, Effect, ofType } from "@ngrx/effects";
import { Action, select, Store } from "@ngrx/store";

import { Observable, of as observableOf } from "rxjs";
import {
  catchError,
  exhaustMap,
  map,
  tap,
  withLatestFrom,
} from "rxjs/operators";

import * as actions from "./../actions";
import { AddRecord, EquipmentItemActionTypes, UpdateRecord } from "./../actions";
import * as fromEquipment from "./../equipment.state";

import { Equipment } from "../models/equipment.model";
import { EquipmentService } from "../../../../services/masters/equipments";
import { GridPayload } from "../../../../shared/models/grid.payload";

@Injectable()
export class EquipmentItemEffects {
  @Effect()
  addRecord$: Observable<Action> = this.actions$.pipe(
    ofType<AddRecord>(EquipmentItemActionTypes.AddRecord),
    map((action) => action.payload),
    exhaustMap((record: Equipment) =>
      this.batService.saveEquipment(record).pipe(
        map((payload: Equipment) => new actions.AddRecordSuccess(payload)),
        catchError((e) =>
          observableOf(new actions.AddRecordFailure(e.error.error))
        )
      )
    )
  );

  @Effect()
  updateRecord$: Observable<Action> = this.actions$.pipe(
    ofType<UpdateRecord>(EquipmentItemActionTypes.UpdateRecord),
    map((action) => action.payload),
    exhaustMap((record: Equipment) =>
      this.batService.saveEquipment(record).pipe(
        map((payload: Equipment) => new actions.UpdateRecordSuccess(payload)),
        catchError((e) =>
          observableOf(new actions.UpdateRecordFailure(e.error.error))
        )
      )
    )
  );

  @Effect({ dispatch: false })
  reloadGrid$: Observable<any> = this.actions$.pipe(
    ofType(EquipmentItemActionTypes.AddRecordSuccess),
    withLatestFrom(
      this.store.pipe(select(fromEquipment.getEquipmentsOffset)),
      this.store.pipe(select(fromEquipment.getEquipmentsSortProp)),
      this.store.pipe(select(fromEquipment.getEquipmentsSortDir)),
      this.store.pipe(select(fromEquipment.getEquipmentsSearch)),
      (action, offset, sortProp, sortDir, search) => ({
        offset,
        sortProp,
        sortDir,
        search,
      })
    ),
    tap((info: GridPayload) => {
      this.store.dispatch(new actions.LoadRecords(info));
    })
  );

  @Effect({ dispatch: false })
  closeModal$: Observable<any> = this.actions$.pipe(
    ofType(
      EquipmentItemActionTypes.AddRecordSuccess,
      EquipmentItemActionTypes.UpdateRecordSuccess
    ),
    withLatestFrom(
      this.store.pipe(select(fromEquipment.getEquipmentsOffset)),
      this.store.pipe(select(fromEquipment.getEquipmentsSortProp)),
      this.store.pipe(select(fromEquipment.getEquipmentsSortDir)),
      this.store.pipe(select(fromEquipment.getEquipmentsSearch)),
      (action, offset, sortProp, sortDir, search) => ({
        offset,
        sortProp,
        sortDir,
        search,
      })
    ),
    tap((info: GridPayload) => {
      this.store.dispatch(new actions.LoadRecords(info));
    })
  );

  constructor(
    private store: Store<fromEquipment.State>,
    private actions$: Actions,
    private batService: EquipmentService
  ) {}
}

import { Injectable } from "@angular/core";

import { Actions, Effect, ofType } from "@ngrx/effects";
import { Action, select, Store } from "@ngrx/store";

import { Observable, of as observableOf } from "rxjs";
import {
  catchError,
  exhaustMap,
  map,
  tap,
  withLatestFrom,
} from "rxjs/operators";

import * as actions from "./../actions";
import { AddRecord, ExperienceTypeItemActionTypes, UpdateRecord } from "./../actions";
import * as fromExperienceType from "./../experience-type.state";

import { ExperienceType } from "../models/experience-type.model";
import { ExperienceTypeService } from "../../../../services/masters/experience-types";
import { GridPayload } from "../../../../shared/models/grid.payload";

@Injectable()
export class ExperienceTypeItemEffects {
  @Effect()
  addRecord$: Observable<Action> = this.actions$.pipe(
    ofType<AddRecord>(ExperienceTypeItemActionTypes.AddRecord),
    map((action) => action.payload),
    exhaustMap((record: ExperienceType) =>
      this.batService.saveExperienceType(record).pipe(
        map((payload: ExperienceType) => new actions.AddRecordSuccess(payload)),
        catchError((e) =>
          observableOf(new actions.AddRecordFailure(e.error.error))
        )
      )
    )
  );

  @Effect()
  updateRecord$: Observable<Action> = this.actions$.pipe(
    ofType<UpdateRecord>(ExperienceTypeItemActionTypes.UpdateRecord),
    map((action) => action.payload),
    exhaustMap((record: ExperienceType) =>
      this.batService.saveExperienceType(record).pipe(
        map((payload: ExperienceType) => new actions.UpdateRecordSuccess(payload)),
        catchError((e) =>
          observableOf(new actions.UpdateRecordFailure(e.error.error))
        )
      )
    )
  );

  @Effect({ dispatch: false })
  reloadGrid$: Observable<any> = this.actions$.pipe(
    ofType(ExperienceTypeItemActionTypes.AddRecordSuccess),
    withLatestFrom(
      this.store.pipe(select(fromExperienceType.getExperienceTypesOffset)),
      this.store.pipe(select(fromExperienceType.getExperienceTypesSortProp)),
      this.store.pipe(select(fromExperienceType.getExperienceTypesSortDir)),
      this.store.pipe(select(fromExperienceType.getExperienceTypesSearch)),
      (action, offset, sortProp, sortDir, search) => ({
        offset,
        sortProp,
        sortDir,
        search,
      })
    ),
    tap((info: GridPayload) => {
      this.store.dispatch(new actions.LoadRecords(info));
    })
  );

  @Effect({ dispatch: false })
  closeModal$: Observable<any> = this.actions$.pipe(
    ofType(
      ExperienceTypeItemActionTypes.AddRecordSuccess,
      ExperienceTypeItemActionTypes.UpdateRecordSuccess
    ),
    withLatestFrom(
      this.store.pipe(select(fromExperienceType.getExperienceTypesOffset)),
      this.store.pipe(select(fromExperienceType.getExperienceTypesSortProp)),
      this.store.pipe(select(fromExperienceType.getExperienceTypesSortDir)),
      this.store.pipe(select(fromExperienceType.getExperienceTypesSearch)),
      (action, offset, sortProp, sortDir, search) => ({
        offset,
        sortProp,
        sortDir,
        search,
      })
    ),
    tap((info: GridPayload) => {
      this.store.dispatch(new actions.LoadRecords(info));
    })
  );

  constructor(
    private store: Store<fromExperienceType.State>,
    private actions$: Actions,
    private batService: ExperienceTypeService
  ) {}
}

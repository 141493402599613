<app-header></app-header>

<!-- Start Page Title Area -->
<div class="page-title-bg" *ngFor="let Content of pageTitleContent;">
    <div class="container" style="margin-top: 45px !important;">
        <h2>{{Content.title}}</h2>
        <form>
            <div class="row m-0 align-items-center">
                <div class="col-lg-5 col-md-12 p-0">
                    <div class="form-group">
                        <label><i class="flaticon-search"></i></label>
                        <input type="text" class="form-control" placeholder="What are you looking for?">
                    </div>
                </div>

                <div class="col-lg-5 col-md-12 p-0">
                    <div class="form-group">
                        <label><i class="flaticon-pin"></i></label>
                        <input type="text" class="form-control" placeholder="Location">
                    </div>
                </div>

                <div class="col-lg-2 col-md-12 p-0">
                    <div class="submit-btn">
                        <button type="submit">Search Now</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Listings Area -->
<section class="listings-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <aside class="listings-widget-area">
                    <section class="widget widget_filters">
                        <h3 class="widget-title">Filters</h3>

                        <ul>
                            <li><button type="button">$</button></li>
                            <li><button type="button">$$</button></li>
                            <li><button type="button">$$$</button></li>
                            <li><button type="button">$$$$</button></li>
                        </ul>
                    </section>

                    <section class="widget widget_categories">
                        <h3 class="widget-title">Equipment Types</h3>

                        <app-equipment-filter-list 
                        (selectEquipmentFilterListItem)="
                          onEquipmentFilterListItemSelect($event)">
                          </app-equipment-filter-list>
                    </section>

                </aside>
            </div>

            <div class="col-lg-8 col-md-12">
                <div class="listings-grid-sorting row align-items-center">
                    <div class="col-lg-5 col-md-6 result-count">
                        <p>We found <span class="count">{{equipmentSuppliersCount$ | async}}</span> listings available for you</p>
                    </div>

                    <div class="col-lg-7 col-md-6 ordering">
                        <div class="d-flex justify-content-end">
                            <div class="select-box">
                                <label>Sort By:</label>
                                <ngx-select-dropdown (searchChange)="searchChange($event)" name="ordering-select" tabindex="0" [multiple]="false" [(ngModel)]="singleSelect" [config]="config" [options]="options2"></ngx-select-dropdown>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-12 col-md-12" *ngFor="let Content of equipmentSuppliers$ | async">
                        <div class="single-listings-item">
                            <div class="row m-0">

                                <div class="col-lg-12 col-md-8 p-0">
                                    <div class="listings-content">
                                        
                                        <h3><a routerLink="/">{{Content.organisation_name}}</a></h3>
                                        <div class="d-flex align-items-center justify-content-between">
                                            <div class="rating">
                                                <i class='{{Star.icon}}' *ngFor="let Star of rating;"></i>
                                                <span class="count">(0)</span>
                                            </div>
                                            <div class="price">
                                                <strong>Total Experience:</strong>&nbsp;{{Content.total_experience}}
                                            </div>
                                        </div>
                                        <ul class="listings-meta">
                                            <li>
                                                <i class="flaticon-pin"></i> 
                                                {{Content.full_address}},&nbsp;
                                                {{Content.district}},&nbsp;
                                                {{Content.state}}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="col-lg-12 col-md-12">
                        <div class="pagination-area text-center">
                            <pagination-controls (pageChange)="verticalListings = $event"></pagination-controls>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Listings Area -->
<app-footer></app-footer>
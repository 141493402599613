import { Injectable } from "@angular/core";
import { BaseService } from "../../../base/base.service";
import { map } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
import {
  State,
  StateApiModel,
} from "../../../store/masters/states/models/state.model";
import { GridPayload } from '../../../shared/models/grid.payload';
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class StateService extends BaseService {
  apiUrl: any = environment.api_url;

  getDropdownList(): Observable<any> {
    return this.http.get(`${this.apiUrl}/api/masters/states/dropdown/records`)
      .pipe(
        map((response: StateApiModel[]) => ({
          data: response.map(record => new State(record))
        }))
      );
  }

  getDropdownListByUser(): Observable<any> {
    return this.http.get(`${this.apiUrl}/api/masters/states/dropdown/records/by/user`)
      .pipe(
        map((response: StateApiModel[]) => ({
          data: response.map(record => new State(record))
        }))
      );
  }

  getStateList(gridInfo: GridPayload) {
    return this.gridRequest<StateApiModel>(`${this.apiUrl}/api/masters/states/records`, gridInfo.offset, gridInfo.sortProp, gridInfo.sortDir, gridInfo.search)
      .pipe(
        map(response => ({
          ...response,
          data: response.data.map(record => new State(record))
        }))
      );
  }

  saveState(payload: State): Observable<State> {
    return this.http.post<State>(`${this.apiUrl}/api/masters/states/save`, payload);
  }
}

import { Action } from '@ngrx/store';
import { LoadSuccessPayload } from '../../../../shared/models/common.payload';
import { GridPayload, LoadGridSuccessPayload } from '../../../../shared/models/grid.payload';
import { Equipment, EquipmentApiModel } from '../models/equipment.model';

export enum EquipmentListActionTypes {
    LoadRecords = '[Equipments] Load',
    LoadRecordsSuccess = '[Equipments] Load Success',
    LoadRecordsFailure = '[Equipments] Load Failure',

    LoadDropdownRecords = '[Equipments Dropdown] Load',
    LoadDropdownRecordsSuccess = '[Equipments Dropdown] Load Success',
    LoadDropdownRecordsFailure = '[Equipments Dropdown] Load Failure',

    LoadDropdownByUserRecords = '[Equipments Dropdown By User] Load',
    LoadDropdownByUserRecordsSuccess = '[Equipments Dropdown By User] Load Success',
    LoadDropdownByUserRecordsFailure = '[Equipments Dropdown By User] Load Failure',

    SelectRecord = '[Equipment] Select Record',
}

////////////

export class SelectRecord implements Action {
  readonly type = EquipmentListActionTypes.SelectRecord;

  constructor(public payload: string) {
  }
}

export class LoadRecords implements Action {
  readonly type = EquipmentListActionTypes.LoadRecords;

  constructor(public gridInfo: GridPayload) {
  }
}

export class LoadRecordsSuccess implements Action {
  readonly type = EquipmentListActionTypes.LoadRecordsSuccess;

  constructor(public payload: LoadGridSuccessPayload<EquipmentApiModel>) {
  }
}

export class LoadRecordsFailure implements Action {
  readonly type = EquipmentListActionTypes.LoadRecordsFailure;

  constructor(public payload: any) {
  }
}

export class LoadDropdownRecords implements Action {
    readonly type = EquipmentListActionTypes.LoadDropdownRecords;

    constructor() {
    }
}

export class LoadDropdownRecordsSuccess implements Action {
    readonly type = EquipmentListActionTypes.LoadDropdownRecordsSuccess;

    constructor(public payload: LoadSuccessPayload<EquipmentApiModel>) {
    }
}

export class LoadDropdownRecordsFailure implements Action {
    readonly type = EquipmentListActionTypes.LoadDropdownRecordsFailure;

    constructor(public payload: any) {
    }
}


export class LoadDropdownByUserRecords implements Action {
  readonly type = EquipmentListActionTypes.LoadDropdownByUserRecords;

  constructor() {
  }
}

export class LoadDropdownByUserRecordsSuccess implements Action {
  readonly type = EquipmentListActionTypes.LoadDropdownByUserRecordsSuccess;

  constructor(public payload: LoadSuccessPayload<EquipmentApiModel>) {
  }
}

export class LoadDropdownByUserRecordsFailure implements Action {
  readonly type = EquipmentListActionTypes.LoadDropdownByUserRecordsFailure;

  constructor(public payload: any) {
  }
}
////////////

export type ListActions =
  | SelectRecord
  | LoadRecords
  | LoadRecordsSuccess
  | LoadRecordsFailure
  | LoadDropdownRecords
  | LoadDropdownRecordsSuccess
  | LoadDropdownRecordsFailure
  | LoadDropdownByUserRecords
  | LoadDropdownByUserRecordsSuccess
  | LoadDropdownByUserRecordsFailure;


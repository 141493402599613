import { ExperienceTypeUpdateComponent } from './../experience-type-update/experience-type-update.component';
import { ExperienceTypeDetailsComponent } from './../../components/experience-type-details/experience-type-details.component';
import { ExperienceTypeAddComponent } from './../experience-type-add/experience-type-add.component';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  Optional,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ExperienceType } from './../../../../../../store/masters/experience-types/models/experience-type.model';

import * as fromExperienceType from './../../../../../../store/masters/experience-types';
import { ExperienceTypeItemActionTypes } from './../../../../../../store/masters/experience-types';
import { select, Store } from '@ngrx/store';
import { GridPayload } from './../../../../../../shared/models/grid.payload';

import { Actions, ofType } from '@ngrx/effects';
import { takeUntil, tap } from 'rxjs/operators';
import lstore from 'store';
import {
  NgbActiveModal,
  NgbModal,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-experience-type-page',
  templateUrl: './experience-type-page.component.html',
  styleUrl: './experience-type-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExperienceTypePageComponent implements OnInit, OnDestroy {
  loggedInUserAccess: boolean = false;
  // tps$: Observable<ExperienceType[]> = this.store.pipe(select(fromExperienceType.getAllExperienceTypes));
  experienceTypes$: Observable<any>;
  experienceTypesLoading$ = this.store.pipe(select(fromExperienceType.getExperienceTypesIsLoading));

  experienceTypesCount$: Observable<number> = this.store.pipe(
    select(fromExperienceType.getExperienceTypesCount)
  );
  experienceTypesOffset$: Observable<number> = this.store.pipe(
    select(fromExperienceType.getExperienceTypesOffset)
  );
  experienceTypesSearch$: Observable<string> = this.store.pipe(
    select(fromExperienceType.getExperienceTypesSearch)
  );
  experienceTypesSortProp$: Observable<string> = this.store.pipe(
    select(fromExperienceType.getExperienceTypesSortProp)
  );
  experienceTypesSortDir$: Observable<string> = this.store.pipe(
    select(fromExperienceType.getExperienceTypesSortDir)
  );

  private destroyed$ = new Subject<boolean>();
  //private dialogRef = null;
  modalReference: NgbModalRef;

  searchBy: any = '';

  constructor(
    private readonly store: Store<fromExperienceType.State>,
    private modalService: NgbModal,
    @Optional() private activeModal: NgbActiveModal,
    actions$: Actions
  ) {
    this.loggedInUserAccess = lstore.get('aae');
    actions$
      .pipe(
        ofType(
          ExperienceTypeItemActionTypes.AddRecordSuccess,
          ExperienceTypeItemActionTypes.UpdateRecordSuccess
        ),
        takeUntil(this.destroyed$),
        tap(() => {
          this.modalReference.close();
        })
      )
      .subscribe();
  }

  ngOnInit() {
    this.loadExperienceTypes();
    this.experienceTypes$ = this.store.pipe(select(fromExperienceType.getAllExperienceTypes));
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  loadExperienceTypes() {
    const info: GridPayload = {
      offset: 0,
      sortProp: 'name',
      sortDir: 'asc',
      search:
        this.searchBy == '' ||
        this.searchBy == undefined ||
        this.searchBy == null
          ? 'all'
          : this.searchBy,
    };
    this.store.dispatch(new fromExperienceType.LoadRecords(info));
  }

  search() {
    this.loadExperienceTypes();
  }

  clear() {
    this.searchBy = '';
    this.loadExperienceTypes();
  }
  
  addItem(): void {
    this.modalReference = this.modalService.open(ExperienceTypeAddComponent, {
      backdrop: 'static',
      size: 'lg',
      keyboard: false,
      centered: true,
    });
  }

  onGridUpdate(info: GridPayload) {
    this.store.dispatch(new fromExperienceType.LoadRecords(info));
  }
  onItemSelected(experienceTypeData: ExperienceType) {
    this.modalReference = this.modalService.open(ExperienceTypeDetailsComponent, {
      backdrop: 'static',
      size: 'lg',
      keyboard: false,
      centered: true,
    });
    this.modalReference.componentInstance.selectedExperienceType = experienceTypeData;
  }

  onItemUpdate(experienceType: ExperienceType) {
    this.store.dispatch(new fromExperienceType.SelectRecord(experienceType.id));
    this.modalReference = this.modalService.open(ExperienceTypeUpdateComponent, {
      backdrop: 'static',
      size: 'lg',
      keyboard: false,
      centered: true,
    });
  }
}



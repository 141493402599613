<div class="row">
    <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="stats-card-box">
            <div class="icon-box">
                <i class='bx bx-map-alt'></i>
            </div>
            <span class="sub-title">Active Listings</span>
            <h3>{{activeListingsCount}}</h3>
        </div>
    </div>

    <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="stats-card-box">
            <div class="icon-box">
                <i class='bx bx-line-chart'></i>
            </div>
            <span class="sub-title">Total Views</span>
            <h3>{{totalViewsCount}}</h3>
        </div>
    </div>

    <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="stats-card-box">
            <div class="icon-box">
                <i class='bx bx-star'></i>
            </div>
            <span class="sub-title">Pending Listings</span>
            <h3>{{pendingListingsCount}}</h3>
        </div>
    </div>

    <!-- <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="stats-card-box">
            <div class="icon-box">
                <i class='bx bx-heart'></i>
            </div>
            <span class="sub-title">Bookmarked</span>
            <h3>150</h3>
        </div>
    </div> -->
</div>
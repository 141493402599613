import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SearchEquipmentSuppliersComponent } from './search-equipment-suppliers/search-equipment-suppliers.component';


const routes: Routes = [
  { path: '', component: SearchEquipmentSuppliersComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EquipmentSuppliersRoutingModule { }

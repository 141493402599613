<!-- Start Footer Area -->
<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-footer-widget">
                    <h3>About</h3>

                    <ul class="link-list">
                        <li><a routerLink="/"><i class="flaticon-left-chevron"></i> About SECM</a></li>
                        <li><a routerLink="/"><i class="flaticon-left-chevron"></i> Careers</a></li>
                        <li><a routerLink="/"><i class="flaticon-left-chevron"></i> Recent News</a></li>
                        <li><a routerLink="/"><i class="flaticon-left-chevron"></i> Terms of Service</a></li>
                        <li><a routerLink="/"><i class="flaticon-left-chevron"></i> Privacy Policy</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-footer-widget">
                    <h3>Discover</h3>

                    <ul class="link-list">
                        <li><a routerLink="/"><i class="flaticon-left-chevron"></i> Upcoming Events</a></li>
                        <li><a routerLink="/"><i class="flaticon-left-chevron"></i> Customer Support</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-footer-widget">
                    <h3>Business With Vesax</h3>

                    <ul class="link-list">
                        <li><a routerLink="/"><i class="flaticon-left-chevron"></i> Privacy Policy</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-footer-widget">
                    <h3>Contact Info</h3>

                    <ul class="footer-contact-info">
                        <li><i class="bx bx-map"></i> 2nd Floor,SBI, Vidyut Soudha, Gunadala, Vijayawada, Andhra Pradesh 520004</li>
                        <li><i class="bx bx-phone-call"></i><a href="tel:+11234567890">+91 866 257 7620</a></li>
                        <li><i class="bx bx-envelope"></i><a href="mailto:hello&#64;vesax.com">secm.com</a></li>
                        <li><i class="bx bxs-inbox"></i><a href="tel:+557854578964">+91 866 257 7620</a></li>
                    </ul>

                    <ul class="social-link">
                        <li><a href="#" class="d-block" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="copyright-area">
            <p>© State Energy Conservation Mission (SECM) - Good Practices in Operation and Maintenance of Integrated Cold Chain facilities MIS</p>
        </div>
    </div>

    <div class="footer-image text-center">
        <img src="assets/img/footer-image.png" alt="image">
    </div>
</footer>
<!-- End Footer Area -->

<div class="go-top"><i class='bx bx-up-arrow-alt'></i></div>
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { State } from '../models/state.model';
import { StateActionTypes, StateItemActionTypes, StateListActionTypes } from '../actions';


export interface StateListState extends EntityState<State> {
  isLoading: boolean;
  selectedStateId: string | null;

  count: number;
  offset: number;

  sortProp: string;
  sortDir: string;

  search:string;
}


export const adapter: EntityAdapter<State> = createEntityAdapter<State>({
  selectId: (bt: State) => bt.id,
});


export const initialState: StateListState = adapter.getInitialState({
  isLoading: false,
  selectedStateId: null,

  count: 0,
  offset: 0,

  sortProp: 'name',
  sortDir: 'asc',
  search: 'all'
});


export function stateListReducer(state = initialState, action: StateActionTypes): StateListState {
  switch (action.type) {

    case StateListActionTypes.LoadRecords: {
      return {
        ...state,
        ...action.gridInfo,
        isLoading: true,
      };
    }

    case StateListActionTypes.LoadRecordsSuccess: {
      return adapter.addMany(action.payload.data as State[], {
        ...adapter.removeAll(state),
        isLoading: false,
        count: action.payload.count
      });
    }

    case StateItemActionTypes.UpdateRecordSuccess: {
      return adapter.upsertOne(action.payload, state);
    }

    case StateListActionTypes.SelectRecord: {
      return {
        ...state,
        selectedStateId: action.payload
      };
    }

    case StateListActionTypes.LoadDropdownRecords: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case StateListActionTypes.LoadDropdownRecordsSuccess: {
      return adapter.addMany(action.payload.data as State[], {
        ...adapter.removeAll(state),
        isLoading: false
      });
    }


    case StateListActionTypes.LoadDropdownByUserRecords: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case StateListActionTypes.LoadDropdownByUserRecordsSuccess: {
      return adapter.addMany(action.payload.data as State[], {
        ...adapter.removeAll(state),
        isLoading: false
      });
    }

    default:
      return state;
  }
}


export const getIsLoading = (state: StateListState) => state.isLoading;

export const getCount = (state: StateListState) => state.count;
export const getOffset = (state: StateListState) => state.offset;

export const getSortProp = (state: StateListState) => state.sortProp;
export const getSortDir = (state: StateListState) => state.sortDir;
export const getSearch = (state: StateListState) => state.search;

export const getSelectedId = (state: StateListState) => state.selectedStateId;

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { GridPayload } from '../../../../../../shared/models/grid.payload';
import lstore from 'store';

@Component({
  selector: 'app-equipment-list',
  templateUrl: './equipment-list.component.html',
  styleUrl: './equipment-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EquipmentListComponent implements OnInit {
  loggedInUserAccess: boolean = false;

  equipments: any[] = [];
  @Input('equipmentsData')
  set equipmentsData(data: any) {
    this.equipments = data;
  }

  @Input() loading = true;

  @Input() count: any;
  @Input() offset: any;
  @Input() search: any;
  @Input() sortProp: any;
  @Input() sortDir: any;

  @Output() selectItem = new EventEmitter();
  @Output() editItem = new EventEmitter();

  @Output() reload = new EventEmitter<GridPayload>();

  @ViewChild(DatatableComponent) table: DatatableComponent;

  gridInfo: GridPayload = {
    offset: 0,
    sortProp: 'name',
    sortDir: 'asc',
    search: 'all',
  };

  constructor(public cdr: ChangeDetectorRef) {
    this.loggedInUserAccess = lstore.get('aae');
  }

  ngOnInit() {
    //console.log(`list::page::count::inside::loop:: ${this.count}`)
  }

  onActive({ type, row, column }) {
    if (type === 'click' && column.prop) {
      this.selectItem.emit(row);
    }
  }

  onPaginate({ offset }) {
    this.gridInfo = {
      offset: offset,
      sortProp:
        this.sortProp == '' ||
        this.sortProp == undefined ||
        this.sortProp == null
          ? 'name'
          : this.sortProp,
      sortDir:
        this.sortDir == '' || this.sortDir == undefined || this.sortDir == null
          ? 'asc'
          : this.sortDir,
      search:
        this.search == '' || this.search == undefined || this.search == null
          ? 'all'
          : this.search,
    };
    this.updateGrid();
  }

  onSort({ sorts }) {
    this.gridInfo = {
      offset: 0,
      sortProp: sorts[0].prop,
      sortDir: sorts[0].dir,
      search:
        this.search == '' || this.search == undefined || this.search == null
          ? 'all'
          : this.search,
    };
    this.updateGrid();
  }

  updateGrid() {
    this.reload.emit(this.gridInfo);
  }
}

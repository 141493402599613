import { EquipmentUpdateComponent } from './../equipment-update/equipment-update.component';
import { EquipmentDetailsComponent } from './../../components/equipment-details/equipment-details.component';
import { EquipmentAddComponent } from './../equipment-add/equipment-add.component';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  Optional,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Equipment } from './../../../../../../store/masters/equipments/models/equipment.model';

import * as fromEquipment from './../../../../../../store/masters/equipments';
import { EquipmentItemActionTypes } from './../../../../../../store/masters/equipments';
import { select, Store } from '@ngrx/store';
import { GridPayload } from './../../../../../../shared/models/grid.payload';

import { Actions, ofType } from '@ngrx/effects';
import { takeUntil, tap } from 'rxjs/operators';
import lstore from 'store';
import {
  NgbActiveModal,
  NgbModal,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-equipment-page',
  templateUrl: './equipment-page.component.html',
  styleUrl: './equipment-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EquipmentPageComponent  implements OnInit, OnDestroy {
  loggedInUserAccess: boolean = false;
  // tps$: Observable<Equipment[]> = this.store.pipe(select(fromEquipment.getAllEquipments));
  equipments$: Observable<any>;
  equipmentsLoading$ = this.store.pipe(select(fromEquipment.getEquipmentsIsLoading));

  equipmentsCount$: Observable<number> = this.store.pipe(
    select(fromEquipment.getEquipmentsCount)
  );
  equipmentsOffset$: Observable<number> = this.store.pipe(
    select(fromEquipment.getEquipmentsOffset)
  );
  equipmentsSearch$: Observable<string> = this.store.pipe(
    select(fromEquipment.getEquipmentsSearch)
  );
  equipmentsSortProp$: Observable<string> = this.store.pipe(
    select(fromEquipment.getEquipmentsSortProp)
  );
  equipmentsSortDir$: Observable<string> = this.store.pipe(
    select(fromEquipment.getEquipmentsSortDir)
  );

  private destroyed$ = new Subject<boolean>();
  //private dialogRef = null;
  modalReference: NgbModalRef;

  searchBy: any = '';

  constructor(
    private readonly store: Store<fromEquipment.State>,
    private modalService: NgbModal,
    @Optional() private activeModal: NgbActiveModal,
    actions$: Actions
  ) {
    this.loggedInUserAccess = lstore.get('aae');
    actions$
      .pipe(
        ofType(
          EquipmentItemActionTypes.AddRecordSuccess,
          EquipmentItemActionTypes.UpdateRecordSuccess
        ),
        takeUntil(this.destroyed$),
        tap(() => {
          this.modalReference.close();
        })
      )
      .subscribe();
  }

  ngOnInit() {
    this.loadEquipments();
    this.equipments$ = this.store.pipe(select(fromEquipment.getAllEquipments));
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  loadEquipments() {
    const info: GridPayload = {
      offset: 0,
      sortProp: 'name',
      sortDir: 'asc',
      search:
        this.searchBy == '' ||
        this.searchBy == undefined ||
        this.searchBy == null
          ? 'all'
          : this.searchBy,
    };
    this.store.dispatch(new fromEquipment.LoadRecords(info));
  }

  search() {
    this.loadEquipments();
  }

  clear() {
    this.searchBy = '';
    this.loadEquipments();
  }
  
  addItem(): void {
    this.modalReference = this.modalService.open(EquipmentAddComponent, {
      backdrop: 'static',
      size: 'lg',
      keyboard: false,
      centered: true,
    });
  }

  onGridUpdate(info: GridPayload) {
    this.store.dispatch(new fromEquipment.LoadRecords(info));
  }
  onItemSelected(batchTypeData: Equipment) {
    this.modalReference = this.modalService.open(EquipmentDetailsComponent, {
      backdrop: 'static',
      size: 'lg',
      keyboard: false,
      centered: true,
    });
    this.modalReference.componentInstance.selectedEquipment = batchTypeData;
  }

  onItemUpdate(batchType: Equipment) {
    this.store.dispatch(new fromEquipment.SelectRecord(batchType.id));
    this.modalReference = this.modalService.open(EquipmentUpdateComponent, {
      backdrop: 'static',
      size: 'lg',
      keyboard: false,
      centered: true,
    });
  }
}


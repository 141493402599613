import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnInit,
} from '@angular/core';
import { ExperienceType } from '../../../../../../store/masters/experience-types/models/experience-type.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-experience-type-details',
  templateUrl: './experience-type-details.component.html',
  styleUrl: './experience-type-details.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExperienceTypeDetailsComponent implements OnInit {
  experienceType: ExperienceType;

  @Input('selectedExperienceType')
  set selectedExperienceType(data: any) {
    this.experienceType = data;
  }
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}
}

import { Action } from '@ngrx/store';
import { OwnerApiModel } from '../../../../store/cold-storage/owners/models/owner.model';
import { GridPayload, LoadGridSuccessPayload } from '../../../../shared/models/grid.payload';
import { LoadSuccessPayload } from '../../../../shared/models/common.payload';




export enum OwnerListActionTypes {
    LoadRecords = '[Cold Storage Owners] Load',
    LoadRecordsSuccess = '[Cold Storage Owners] Load Success',
    LoadRecordsFailure = '[Cold Storage Owners] Load Failure',

    LoadListingRecords = '[Cold Storage Owners Listing] Load',
    LoadListingRecordsSuccess = '[Cold Storage Owners Listing] Load Success',
    LoadListingRecordsFailure = '[Cold Storage Owners Listing] Load Failure',

    SelectRecord = '[Cold Storage Owner] Select Record',
}

////////////

export class SelectRecord implements Action {
  readonly type = OwnerListActionTypes.SelectRecord;

  constructor(public payload: string) {
  }
}

export class LoadRecords implements Action {
  readonly type = OwnerListActionTypes.LoadRecords;

  constructor(public gridInfo: GridPayload) {
  }
}

export class LoadRecordsSuccess implements Action {
  readonly type = OwnerListActionTypes.LoadRecordsSuccess;

  constructor(public payload: LoadGridSuccessPayload<OwnerApiModel>) {
  }
}

export class LoadRecordsFailure implements Action {
  readonly type = OwnerListActionTypes.LoadRecordsFailure;

  constructor(public payload: any) {
  }
}

export class LoadListingRecords implements Action {
  readonly type = OwnerListActionTypes.LoadListingRecords;

  constructor(public gridInfo: GridPayload) {
  }
}

export class LoadListingRecordsSuccess implements Action {
  readonly type = OwnerListActionTypes.LoadListingRecordsSuccess;

  constructor(public payload: LoadGridSuccessPayload<OwnerApiModel>) {
  }
}

export class LoadListingRecordsFailure implements Action {
  readonly type = OwnerListActionTypes.LoadListingRecordsFailure;

  constructor(public payload: any) {
  }
}
////////////

export type ListActions =
  | SelectRecord
  | LoadRecords
  | LoadRecordsSuccess
  | LoadRecordsFailure
  | LoadListingRecords
  | LoadListingRecordsSuccess
  | LoadListingRecordsFailure;

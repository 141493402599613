import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  Optional,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { DesignConsultantModel } from '../../../../../store/cold-storage/design-consultants/models/design-consultant.model';

import * as fromDesignConsultant from '../../../../../store/cold-storage/design-consultants';
import { DesignConsultantItemActionTypes } from '../../../../../store/cold-storage/design-consultants';
import { select, Store } from '@ngrx/store';
import { GridPayload } from '../../../../../shared/models/grid.payload';
// import { MatDialog } from "@angular/material/dialog";

import { Actions, ofType } from '@ngrx/effects';
import { takeUntil, tap } from 'rxjs/operators';
import lstore from 'store';
import {
  NgbActiveModal,
  NgbModal,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import { DesignConsultantAddComponent } from '../design-consultant-add/design-consultant-add.component';
import { DesignConsultantDetailsComponent } from '../../components/design-consultant-details/design-consultant-details.component';
import { DesignConsultantUpdateComponent } from '../design-consultant-update/design-consultant-update.component';

@Component({
  selector: 'app-design-consultant-page',
  templateUrl: './design-consultant-page.component.html',
  styleUrl: './design-consultant-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DesignConsultantPageComponent implements OnInit, OnDestroy {
  loggedInUserAccess: boolean = false;
  // designConsultants$: Observable<DesignConsultant[]> = this.store.pipe(select(fromDesignConsultant.getAllDesignConsultants));
  designConsultants$: Observable<any>;
  designConsultantsLoading$ = this.store.pipe(
    select(fromDesignConsultant.getDesignConsultantsIsLoading)
  );

  designConsultantsCount$: Observable<number> = this.store.pipe(
    select(fromDesignConsultant.getDesignConsultantsCount)
  );
  designConsultantsOffset$: Observable<number> = this.store.pipe(
    select(fromDesignConsultant.getDesignConsultantsOffset)
  );
  designConsultantsSearch$: Observable<string> = this.store.pipe(
    select(fromDesignConsultant.getDesignConsultantsSearch)
  );
  designConsultantsSortProp$: Observable<string> = this.store.pipe(
    select(fromDesignConsultant.getDesignConsultantsSortProp)
  );
  designConsultantsSortDir$: Observable<string> = this.store.pipe(
    select(fromDesignConsultant.getDesignConsultantsSortDir)
  );

  private destroyed$ = new Subject<boolean>();

  searchBy: any = '';
  modalReference: NgbModalRef;

  constructor(
    private readonly store: Store<fromDesignConsultant.State>,
    private modalService: NgbModal,
    @Optional() private activeModal: NgbActiveModal,
    actions$: Actions
  ) {
    this.loggedInUserAccess = lstore.get('aae');
    actions$
      .pipe(
        ofType(
          DesignConsultantItemActionTypes.AddRecordSuccess,
          DesignConsultantItemActionTypes.UpdateRecordSuccess
        ),
        takeUntil(this.destroyed$),
        tap(() => {
          this.modalReference.close();
        })
      )
      .subscribe();
  }

  ngOnInit() {
    this.loadDesignConsultants();
    this.designConsultants$ = this.store.pipe(
      select(fromDesignConsultant.getAllDesignConsultants)
    );
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  loadDesignConsultants() {
    const info: GridPayload = {
      offset: 0,
      sortProp: 'organisation_name',
      sortDir: 'asc',
      search:
        this.searchBy == '' ||
        this.searchBy == undefined ||
        this.searchBy == null
          ? 'all'
          : this.searchBy,
    };
    this.store.dispatch(new fromDesignConsultant.LoadRecords(info));
  }

  search() {
    this.loadDesignConsultants();
  }

  clear() {
    this.searchBy = '';
    this.loadDesignConsultants();
  }

  addItem(): void {
    this.modalReference = this.modalService.open(DesignConsultantAddComponent, {
      backdrop: 'static',
      size: 'xl',
      keyboard: false,
      centered: true,
      backdropClass: 'light-blue-backdrop',
    });
  }

  onGridUpdate(info: GridPayload) {
    this.store.dispatch(new fromDesignConsultant.LoadRecords(info));
  }

  onItemSelected(designConsultantData: any) {
    this.modalReference = this.modalService.open(DesignConsultantDetailsComponent, {
      backdrop: 'static',
      size: 'lg',
      keyboard: false,
      centered: true,
    });
    this.modalReference.componentInstance.selectedDesignConsultant = designConsultantData;
  }

  onItemUpdate(client: any) {
    this.store.dispatch(new fromDesignConsultant.SelectRecord(client.id));
    this.modalReference = this.modalService.open(DesignConsultantUpdateComponent, {
      backdrop: 'static',
      size: 'lg',
      keyboard: false,
      centered: true,
    });
  }

  onItemUpdateStatusClick($event: DesignConsultantModel) {
    this.store.dispatch(new fromDesignConsultant.SelectRecord($event.id));
    this.store.dispatch(new fromDesignConsultant.UpdateStatusRecord($event));
  }

  onItemUpdateApprovalStatusClick($event: DesignConsultantModel) {
    this.store.dispatch(new fromDesignConsultant.SelectRecord($event.id));
    this.store.dispatch(new fromDesignConsultant.UpdateApprovalStatusRecord($event));
  }
}

<!-- Start Funfacts Area -->
<div class="container">
    <div class="row">
        <div class="col-lg-3 col-md-3 col-sm-3 col-6" *ngFor="let funFacts of singleFunfacts;">
            <div class="single-funfacts">
                <i class='{{funFacts.icon}}'></i>
                <p>{{funFacts.title}}</p>
                <h3>{{funFacts.subTitle}}</h3>
            </div>
        </div>
    </div>
</div>
<!-- End Funfacts Area -->
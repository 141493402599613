<div class="input-group" *ngIf="equipmentsDropdown$ | async as equipmentsDropdown; else loading">
    <ul>
        <li *ngFor="let d of equipmentsDropdown">
            <input id="d.id" type="checkbox" (click)="onSelect($event,d.id, d.name)">
            <label for="d.id">{{ d.name }}</label>
        </li>
        <li class="see-all-btn">
            <span>See All</span>
        </li>
    </ul>    
</div>
<ng-template #loading>Loading equipments...</ng-template>
import { GlobalState } from '../../../store';
import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';

import * as fromList from './reducers/list.reducer';
import * as fromItem from './reducers/item.reducer';

export interface ContractorState {
  list: fromList.ContractorListState;
  item: fromItem.ContractorItemState;
}

export interface State extends GlobalState {
  contractors: ContractorState;
}

export const COLD_STORAGE_CONTRACTOR_REDUCERS: ActionReducerMap<
  ContractorState,
  any
> = {
  list: fromList.contractorListReducer,
  item: fromItem.contractorItemReducer,
};

////// selectors

export const getProjectsState = createFeatureSelector<State, ContractorState>(
  'contractors'
);

export const getContractorListState = createSelector(
  getProjectsState,
  (state) => state.list
);
export const getContractorItemState = createSelector(
  getProjectsState,
  (state) => state.item
);

export const getContractorsIsLoading = createSelector(
  getContractorListState,
  fromList.getIsLoading
);

export const getContractorsCount = createSelector(
  getContractorListState,
  fromList.getCount
);
export const getContractorsOffset = createSelector(
  getContractorListState,
  fromList.getOffset
);

export const getContractorsSortProp = createSelector(
  getContractorListState,
  fromList.getSortProp
);
export const getContractorsSortDir = createSelector(
  getContractorListState,
  fromList.getSortDir
);
export const getContractorsSearch = createSelector(
  getContractorListState,
  fromList.getSearch
);

export const getSelectedContractorId = createSelector(
  getContractorListState,
  fromList.getSelectedId
);

export const {
  selectEntities: getContractorEntities,
  selectAll: getAllContractors,
} = fromList.adapter.getSelectors(getContractorListState);

export const getSelectedContractor = createSelector(
  getContractorEntities,
  getSelectedContractorId,
  (entities, selectedId) => {
    return selectedId !== null && entities[selectedId];
  }
);

// Item

export const getContractorItemPending = createSelector(
  getContractorItemState,
  fromItem.getPending
);
export const getContractorItemError = createSelector(
  getContractorItemState,
  fromItem.getError
);

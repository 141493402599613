import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as fromEquipmentSupplier from '../../../../../store/cold-storage/equipment-suppliers';
import { EquipmentSupplierModel } from '../../../../../store/cold-storage/equipment-suppliers/models/equipment-supplier.model';

@Component({
  selector: 'app-equipment-supplier-update',
  templateUrl: './equipment-supplier-update.component.html',
})
export class EquipmentSupplierUpdateComponent {

  equipmentSupplier$ = this.store.pipe(select(fromEquipmentSupplier.getSelectedEquipmentSupplier));
  equipmentSupplierId$ = this.store.pipe(select(fromEquipmentSupplier.getSelectedEquipmentSupplierId));

  pending$ = this.store.pipe(select(fromEquipmentSupplier.getEquipmentSupplierItemPending));
  error$ = this.store.pipe(select(fromEquipmentSupplier.getEquipmentSupplierItemError));

  constructor(private readonly store: Store<fromEquipmentSupplier.State>) {
  }

  onSubmit($event: any) {
    this.store.dispatch(new fromEquipmentSupplier.UpdateRecord($event));
  }
}

<app-header></app-header>

<!-- Start Listings Area -->
<section class="listings-area ptb-100">
  <div class="container">
    <h2>Registration Form</h2>
    <form [formGroup]="form" (ngSubmit)="submit()">
      <div class="row">
        <div class="col-md-12">
          <p  style="font-size: 20px !important;" *ngIf="message" class="error-message">
            {{ message }}
            <img src="assets/img/dashboard.png">
          </p>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <!-- <div class="type"> -->
            <input
              type="radio"
              id="type_2"
              name="type"
              formControlName="type"
              value="2"
            /><label for="type_2">&nbsp;Cold Storage Design Consultants</label>
            &nbsp;&nbsp;
            <input
              type="radio"
              id="type_3"
              name="type"
              formControlName="type"
              value="3"
            /><label for="type_3">&nbsp;Cold Storage Owners</label>

            &nbsp;&nbsp;
            <input
              type="radio"
              id="type_4"
              name="type"
              formControlName="type"
              value="4"
            /><label for="type_4"
              >&nbsp;Cold Storage Erectors/ Contractors</label
            >

            &nbsp;&nbsp;
            <input
              type="radio"
              id="type_5"
              name="type"
              formControlName="type"
              value="5"
            /><label for="type_5">&nbsp;Cold Storage Equipment Suppliers</label>
          </div>
        </div>

        <div class="col-lg-2 col-md-12">&nbsp;</div>

        <div class="row">
          <div class="col-md-12">
            <div class="add-listings-box">
              <h4>Basic Information</h4>

              <div class="row">
                <div
                  class="col-lg-12 col-md-12"
                  *ngIf="
                    form.controls['type'].value !== '' &&
                    form.controls['type'].value === '3'
                  "
                >
                  <div class="form-group">
                    <label
                      ><i class="bx bx-briefcase-alt"></i> Name of the Storage
                      Unit:</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Name of storage unit"
                      formControlName="cold_storage_name"
                    />
                  </div>
                </div>
                <div
                  class="col-lg-12 col-md-12"
                  *ngIf="
                    form.controls['type'].value !== '' &&
                    form.controls['type'].value !== '3'
                  "
                >
                  <div class="form-group">
                    <label
                      ><i class="bx bx-briefcase-alt"></i> Name of the
                      Organization:</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Name of your Organisation"
                      formControlName="organisation_name"
                    />
                  </div>
                </div>

                <div class="col-lg-6 col-md-6">
                  <div class="form-group">
                    <label
                      ><i class="bx bx-duplicate"></i> Contact Person:</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Name of the contact person"
                      formControlName="contact_person"
                    />
                  </div>
                </div>

                <div class="col-lg-6 col-md-6">
                  <div class="form-group">
                    <label><i class="bx bxs-key"></i> Contact Number:</label>
                    <input
                      type="number"
                      class="form-control"
                      placeholder="Mobile number of the contact person"
                      formControlName="contact_number"
                    />
                  </div>
                </div>
                <div class="col-lg-6 col-md-6">
                  <div class="form-group">
                    <label><i class="bx bxs-key"></i> Email:</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Email of the contact person"
                      formControlName="email"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="add-listings-box">
              <h4>Location</h4>

              <div class="row">
                <div class="col-lg-6 col-md-6">
                  <div class="form-group">
                    <label><i class="bx bx-menu-alt-left"></i> State:</label>
                    <app-state-dropdown
                      (selectStateDropdownItem)="
                        onStateDropdownItemSelect($event)
                      "
                      [selectedState]="selectedStateId"
                    >
                    </app-state-dropdown>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6">
                  <div class="form-group">
                    <label><i class="bx bx-menu-alt-left"></i> District:</label>
                    <app-district-dropdown
                      (selectDistrictDropdownItem)="
                        onDistrictDropdownItemSelect($event)
                      "
                      [selectedState]="selectedStateId"
                      [selectedDistrict]="selectedDistrictId"
                    >
                    </app-district-dropdown>
                  </div>
                </div>

                <div class="col-lg-12 col-md-12">
                  <div class="form-group">
                    <label
                      ><i class="bx bx-menu-alt-left"></i> Full Address:</label
                    >
                    <textarea
                      class="form-control"
                      placeholder="e.g. H.No 55/25/1 A, Street Name, Area, District/City, State Pincode"
                      formControlName="full_address"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="add-listings-box">
              <h4>Other Details</h4>

              <div class="row">
                <div class="col-lg-12 col-md-12">
                  <div class="form-group">
                    <label
                      ><i class="bx bx-menu-alt-left"></i> Detailed Information
                      (About you/Experience/Organization etc.):</label
                    >
                    <textarea
                      class="form-control"
                      formControlName="description"
                    ></textarea>
                  </div>
                </div>
                <div
                  class="col-lg-6 col-md-6"
                  *ngIf="form.controls['type'].value !== '3'"
                >
                  <div class="form-group">
                    <label
                      ><i class="bx bx-menu-alt-left"></i> Experience (in
                      years):</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Total experience in the current field"
                      formControlName="total_experience"
                    />
                  </div>
                </div>

                <div
                  class="col-lg-6 col-md-6"
                  *ngIf="form.controls['type'].value !== '3'"
                >
                  <div class="form-group">
                    <label
                      ><i class="bx bx-menu-alt-left"></i> Annual
                      turnover:</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Annual turnover in rupees"
                      formControlName="anual_turnover"
                    />
                  </div>
                </div>

                <div
                  class="col-lg-6 col-md-6"
                  *ngIf="form.controls['type'].value === '3'"
                >
                  <div class="form-group">
                    <label
                      ><i class="bx bx-menu-alt-left"></i> Cold Storage
                      Capacity:</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Total Storage Unit Capacity"
                      formControlName="cold_storage_capacity"
                    />
                  </div>
                </div>

                <div
                  class="col-lg-6 col-md-6"
                  *ngIf="form.controls['type'].value === '3'"
                >
                  <div class="form-group">
                    <label
                      ><i class="bx bx-menu-alt-left"></i> Cold Storage
                      Area:</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Total Unit Area"
                      formControlName="cold_storage_area"
                    />
                  </div>
                </div>
                <!--For Contractors start-->
                <div
                  class="col-lg-12 col-md-12"
                  *ngIf="form.controls['type'].value === '4'"
                >
                  <div class="form-group">
                    <label
                      ><i class="bx bx-menu-alt-left"></i> Experience in
                      :</label
                    >

                    <app-experience-type-multi-select-dropdown
                      (selectExperienceTypeDropdownItem)="
                        onExperienceTypeDropdownItemSelect($event)
                      "
                      [selectedExperienceType]="selectedExperienceTypes"
                    >
                    </app-experience-type-multi-select-dropdown>
                  </div>
                </div>

                <div
                  class="col-lg-12 col-md-12"
                  *ngIf="
                    form.controls['type'].value === '4' &&
                    selectedExperienceInIds.length === 0
                  "
                >
                  <span class="error-message">
                    Please select atleast one experience type
                  </span>
                </div>
                <div
                  class="col-lg-12 col-md-12"
                  *ngIf="form.controls['type'].value === '4'"
                >
                  <span
                    class="badge yellow"
                    *ngFor="let name of selectedExperienceInNames"
                  >
                    {{ name }}
                  </span>
                </div>
                <!--For Contractors end-->
                <!--For Design Consultants start-->
                <div
                  class="col-lg-12 col-md-12"
                  *ngIf="form.controls['type'].value === '2'"
                >
                  <div class="form-group">
                    <label
                      ><i class="bx bx-menu-alt-left"></i> Expert in :</label
                    >

                    <app-expert-type-multi-select-dropdown
                      (selectExpertTypeDropdownItem)="
                        onExpertTypeDropdownItemSelect($event)
                      "
                      [selectedExpertType]="selectedExpertTypes"
                    >
                    </app-expert-type-multi-select-dropdown>
                  </div>
                </div>

                <div
                  class="col-lg-12 col-md-12"
                  *ngIf="
                    form.controls['type'].value === '2' &&
                    selectedExpertInIds.length === 0
                  "
                >
                  <span class="error-message">
                    Please select atleast one expert type
                  </span>
                </div>
                <div
                  class="col-lg-12 col-md-12"
                  *ngIf="form.controls['type'].value === '2'"
                >
                  <span
                    class="badge yellow"
                    *ngFor="let name of selectedExpertInNames"
                  >
                    {{ name }}
                  </span>
                </div>
                <!--For Design Consultatns end-->

                <!--For Equipment supplier start-->
                <div
                  class="col-lg-12 col-md-12"
                  *ngIf="form.controls['type'].value === '5'"
                >
                  <div class="form-group">
                    <label
                      ><i class="bx bx-menu-alt-left"></i> Equipment :</label
                    >

                    <app-equipment-multi-select-dropdown
                      (selectEquipmentDropdownItem)="
                        onEquipmentDropdownItemSelect($event)
                      "
                      [selectedEquipment]="selectedEquipments"
                    >
                    </app-equipment-multi-select-dropdown>
                  </div>
                </div>

                <div
                  class="col-lg-12 col-md-12"
                  *ngIf="
                    form.controls['type'].value === '5' &&
                    selectedEquipmentInIds.length === 0
                  "
                >
                  <span class="error-message">
                    Please select atleast one equipment
                  </span>
                </div>
                <div
                  class="col-lg-12 col-md-12"
                  *ngIf="form.controls['type'].value === '5'"
                >
                  <span
                    class="badge yellow"
                    *ngFor="let name of selectedEquipmentInNames"
                  >
                    {{ name }}
                  </span>
                </div>
                <!--For Equipment supplier end-->
                <!--For Owners start-->
                <div
                  class="col-lg-12 col-md-12"
                  *ngIf="form.controls['type'].value === '3'"
                >
                  <div class="form-group">
                    <label
                      ><i class="bx bx-menu-alt-left"></i> Type Of Cold Storage
                      :</label
                    >

                    <app-type-of-storage-multi-select-dropdown
                      (selectTypeOfStorageDropdownItem)="
                        onTypeOfColdStorageTypeDropdownItemSelect($event)
                      "
                      [selectedTypeOfStorage]="selectedTypeOfColdStorageTypes"
                    >
                    </app-type-of-storage-multi-select-dropdown>
                  </div>
                </div>

                <div
                  class="col-lg-12 col-md-12"
                  *ngIf="
                    form.controls['type'].value === '3' &&
                    selectedTypeOfColdStorageInIds.length === 0
                  "
                >
                  <span class="error-message">
                    Please select atleast one type of cold storage
                  </span>
                </div>
                <div
                  class="col-lg-12 col-md-12"
                  *ngIf="form.controls['type'].value === '3'"
                >
                  <span
                    class="badge yellow"
                    *ngFor="let name of selectedTypeOfColdStorageInNames"
                  >
                    {{ name }}
                  </span>
                </div>
                <!--For Owners end-->
                <div class="col-lg-12 col-md-12">&nbsp;</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-10 col-md-12">
          <button
            type="submit"
            class="btn btn-outline-success"
            [disabled]="!form.valid"
            (click)="submit()"
          >
            Submit
          </button>
          <!-- <button mat-stroked-button color="accent" [disabled]="form.invalid" (click)="submit()">Save</button> -->
        </div>
      </div>
    </form>
  </div>
</section>
<!-- End Listings Area -->
<app-footer></app-footer>

import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as fromDistrict from '../../../../../../store/masters/districts';
import { District } from '../../../../../../store/masters/districts/models/district.model';

@Component({
  selector: 'app-district-add',
  templateUrl: './district-add.component.html',
})
export class DistrictAddComponent {
  pending$ = this.store.pipe(select(fromDistrict.getDistrictItemPending));
  error$ = this.store.pipe(select(fromDistrict.getDistrictItemError));

  constructor(private readonly store: Store<fromDistrict.State>) {}

  onSubmit($event: District) {
    this.store.dispatch(new fromDistrict.AddRecord($event));
  }
}

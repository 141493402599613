import { Action } from '@ngrx/store';
import { EquipmentSupplierModel } from '../../../../store/cold-storage/equipment-suppliers/models/equipment-supplier.model';

export enum EquipmentSupplierItemActionTypes {
  AddRecord = '[Cold Storage EquipmentSuppliers] Add Record',
  AddRecordSuccess = '[Cold Storage Equipment Suppliers] Add Record Success',
  AddRecordFailure = '[Cold Storage Equipment Suppliers] Add Record Failure',

  UpdateRecord = '[Cold Storage Equipment Suppliers] Update Record',
  UpdateRecordSuccess = '[Cold Storage Equipment Suppliers] Update Record Success',
  UpdateRecordFailure = '[Cold Storage Equipment Suppliers] Update Record Failure',

  UpdateStatusRecord = '[Cold Storage Equipment Suppliers] Update Status Record',
  UpdateStatusRecordSuccess = '[Cold Storage Equipment Suppliers] Update Status Record Success',
  UpdateStatusRecordFailure = '[Cold Storage Equipment Suppliers] Update Status Record Failure',

  UpdateApprovalStatusRecord = '[Cold Storage Equipment Suppliers] Update Approval Status Record',
  UpdateApprovalStatusRecordSuccess = '[Cold Storage Equipment Suppliers] Update Approval Status Record Success',
  UpdateApprovalStatusRecordFailure = '[Cold Storage Equipment Suppliers] Update Approval Status Record Failure',
}

////////////

export class AddRecord implements Action {
  readonly type = EquipmentSupplierItemActionTypes.AddRecord;

  constructor(public payload: EquipmentSupplierModel) {}
}

export class AddRecordSuccess implements Action {
  readonly type = EquipmentSupplierItemActionTypes.AddRecordSuccess;

  constructor(public payload: EquipmentSupplierModel) {}
}

export class AddRecordFailure implements Action {
  readonly type = EquipmentSupplierItemActionTypes.AddRecordFailure;

  constructor(public payload: string) {}
}

export class UpdateRecord implements Action {
  readonly type = EquipmentSupplierItemActionTypes.UpdateRecord;

  constructor(public payload: EquipmentSupplierModel) {}
}

export class UpdateRecordSuccess implements Action {
  readonly type = EquipmentSupplierItemActionTypes.UpdateRecordSuccess;

  constructor(public payload: EquipmentSupplierModel) {}
}

export class UpdateRecordFailure implements Action {
  readonly type = EquipmentSupplierItemActionTypes.UpdateRecordFailure;

  constructor(public payload: string) {}
}

export class UpdateStatusRecord implements Action {
  readonly type = EquipmentSupplierItemActionTypes.UpdateStatusRecord;

  constructor(public payload: any) {}
}

export class UpdateStatusRecordSuccess implements Action {
  readonly type = EquipmentSupplierItemActionTypes.UpdateStatusRecordSuccess;

  constructor(public payload: EquipmentSupplierModel) {}
}

export class UpdateStatusRecordFailure implements Action {
  readonly type = EquipmentSupplierItemActionTypes.UpdateStatusRecordFailure;

  constructor(public payload: string) {}
}

export class UpdateApprovalStatusRecord implements Action {
  readonly type = EquipmentSupplierItemActionTypes.UpdateApprovalStatusRecord;

  constructor(public payload: any) {}
}

export class UpdateApprovalStatusRecordSuccess implements Action {
  readonly type = EquipmentSupplierItemActionTypes.UpdateApprovalStatusRecordSuccess;

  constructor(public payload: EquipmentSupplierModel) {}
}

export class UpdateApprovalStatusRecordFailure implements Action {
  readonly type = EquipmentSupplierItemActionTypes.UpdateApprovalStatusRecordFailure;

  constructor(public payload: string) {}
}
////////////

export type ItemActions =
  | AddRecord
  | AddRecordSuccess
  | AddRecordFailure
  | UpdateRecord
  | UpdateRecordSuccess
  | UpdateRecordFailure
  | UpdateStatusRecord
  | UpdateStatusRecordSuccess
  | UpdateStatusRecordFailure
  | UpdateApprovalStatusRecord
  | UpdateApprovalStatusRecordSuccess
  | UpdateApprovalStatusRecordFailure;

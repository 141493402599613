import { Action } from '@ngrx/store';
import { OwnerModel } from '../../../../store/cold-storage/owners/models/owner.model';

export enum OwnerItemActionTypes {
  AddRecord = '[Cold Storage Owners] Add Record',
  AddRecordSuccess = '[Cold Storage Owners] Add Record Success',
  AddRecordFailure = '[Cold Storage Owners] Add Record Failure',

  UpdateRecord = '[Cold Storage Owners] Update Record',
  UpdateRecordSuccess = '[Cold Storage Owners] Update Record Success',
  UpdateRecordFailure = '[Cold Storage Owners] Update Record Failure',

  UpdateStatusRecord = '[Cold Storage Owners] Update Status Record',
  UpdateStatusRecordSuccess = '[Cold Storage Owners] Update Status Record Success',
  UpdateStatusRecordFailure = '[Cold Storage Owners] Update Status Record Failure',

  UpdateApprovalStatusRecord = '[Cold Storage Owners] Update Approval Status Record',
  UpdateApprovalStatusRecordSuccess = '[Cold Storage Owners] Update Approval Status Record Success',
  UpdateApprovalStatusRecordFailure = '[Cold Storage Owners] Update Approval Status Record Failure',
}

////////////

export class AddRecord implements Action {
  readonly type = OwnerItemActionTypes.AddRecord;

  constructor(public payload: OwnerModel) {}
}

export class AddRecordSuccess implements Action {
  readonly type = OwnerItemActionTypes.AddRecordSuccess;

  constructor(public payload: OwnerModel) {}
}

export class AddRecordFailure implements Action {
  readonly type = OwnerItemActionTypes.AddRecordFailure;

  constructor(public payload: string) {}
}

export class UpdateRecord implements Action {
  readonly type = OwnerItemActionTypes.UpdateRecord;

  constructor(public payload: OwnerModel) {}
}

export class UpdateRecordSuccess implements Action {
  readonly type = OwnerItemActionTypes.UpdateRecordSuccess;

  constructor(public payload: OwnerModel) {}
}

export class UpdateRecordFailure implements Action {
  readonly type = OwnerItemActionTypes.UpdateRecordFailure;

  constructor(public payload: string) {}
}

export class UpdateStatusRecord implements Action {
  readonly type = OwnerItemActionTypes.UpdateStatusRecord;

  constructor(public payload: any) {}
}

export class UpdateStatusRecordSuccess implements Action {
  readonly type = OwnerItemActionTypes.UpdateStatusRecordSuccess;

  constructor(public payload: OwnerModel) {}
}

export class UpdateStatusRecordFailure implements Action {
  readonly type = OwnerItemActionTypes.UpdateStatusRecordFailure;

  constructor(public payload: string) {}
}

export class UpdateApprovalStatusRecord implements Action {
  readonly type = OwnerItemActionTypes.UpdateApprovalStatusRecord;

  constructor(public payload: any) {}
}

export class UpdateApprovalStatusRecordSuccess implements Action {
  readonly type = OwnerItemActionTypes.UpdateApprovalStatusRecordSuccess;

  constructor(public payload: OwnerModel) {}
}

export class UpdateApprovalStatusRecordFailure implements Action {
  readonly type = OwnerItemActionTypes.UpdateApprovalStatusRecordFailure;

  constructor(public payload: string) {}
}
////////////

export type ItemActions =
  | AddRecord
  | AddRecordSuccess
  | AddRecordFailure
  | UpdateRecord
  | UpdateRecordSuccess
  | UpdateRecordFailure
  | UpdateStatusRecord
  | UpdateStatusRecordSuccess
  | UpdateStatusRecordFailure
  | UpdateApprovalStatusRecord
  | UpdateApprovalStatusRecordSuccess
  | UpdateApprovalStatusRecordFailure;

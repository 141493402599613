import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HowItWorksPageRoutingModule } from './how-it-works-page-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FooterModule } from '../../footer/footer.module';
import { HeaderModule } from '../../header/header.module';
import { HowItWorksPageComponent } from './how-it-works-page.component';


@NgModule({
  declarations: [
    HowItWorksPageComponent
  ],
  imports: [
    CommonModule,
    HowItWorksPageRoutingModule,
    HeaderModule,
    FooterModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class HowItWorksPageModule { }

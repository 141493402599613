import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import * as fromTypeOfStorages from '../../../../../../store/masters/type-of-storages';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { TypeOfStorage } from '../../../../../../store/masters/type-of-storages/models/type-of-storage.model';

@Component({
  selector: 'app-type-of-storage-dropdown',
  templateUrl: './type-of-storage-dropdown.component.html',
  styleUrl: './type-of-storage-dropdown.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TypeOfStorageDropdownComponent implements OnInit {
  typeOfStorage$: any = '0';
  typeOfStoragesDropdown$: Observable<any>;

  @Input('selectedTypeOfStorage')
  set selectedTypeOfStorage(value: any) {
    this.typeOfStorage$ =
      value == null || value == undefined || value == '' ? '0' : value;
  }

  @Output() selectTypeOfStorageDropdownItem = new EventEmitter();

  constructor(
    public cdr: ChangeDetectorRef,
    private readonly store: Store<fromTypeOfStorages.State>
  ) {}

  ngOnInit() {
    this.store.dispatch(new fromTypeOfStorages.LoadDropdownRecords());

    this.typeOfStoragesDropdown$ = this.store.pipe(
      select(fromTypeOfStorages.getAllTypeOfStorages)
    );

    // this.typeOfStoragesDropdown$.subscribe((data)=>{
    //   console.log(`action.payload.data:: ${JSON.stringify(data)}`)
    // })
  }

  compareTypeOfStorages(p1: TypeOfStorage, p2: TypeOfStorage) {
    return p1.id === p2.id;
  }

  onSelect(event) {
    const selectedId = event.target.value.split(':').pop().trim();
    const selectedElement = {
      id: selectedId,
      text: event.target.options[event.target.options.selectedIndex].text,
    };
    this.selectTypeOfStorageDropdownItem.emit(selectedElement);
  }
}

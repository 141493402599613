import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as fromDesignConsultants from '../../../../../store/cold-storage/design-consultants';
import { DesignConsultantModel } from '../../../../../store/cold-storage/design-consultants/models/design-consultant.model';

@Component({
  selector: 'app-design-consultant-add',
  templateUrl: './design-consultant-add.component.html'
})
export class DesignConsultantAddComponent {

  pending$ = this.store.pipe(select(fromDesignConsultants.getDesignConsultantItemPending));
  error$ = this.store.pipe(select(fromDesignConsultants.getDesignConsultantItemError));

  constructor(private readonly store: Store<fromDesignConsultants.State>) {
  }

  onSubmit($event: any) {
    this.store.dispatch(new fromDesignConsultants.AddRecord($event));
  }
}

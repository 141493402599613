import { DistrictUpdateComponent } from './../district-update/district-update.component';
import { DistrictDetailsComponent } from './../../components/district-details/district-details.component';
import { DistrictAddComponent } from './../district-add/district-add.component';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  Optional,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { District } from './../../../../../../store/masters/districts/models/district.model';

import * as fromDistrict from './../../../../../../store/masters/districts';
import { DistrictItemActionTypes } from './../../../../../../store/masters/districts';
import { select, Store } from '@ngrx/store';
import { GridPayload } from './../../../../../../shared/models/grid.payload';

import { Actions, ofType } from '@ngrx/effects';
import { takeUntil, tap } from 'rxjs/operators';
import lstore from 'store';
import {
  NgbActiveModal,
  NgbModal,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-district-page',
  templateUrl: './district-page.component.html',
  styleUrls: ['./district-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DistrictPageComponent implements OnInit, OnDestroy {
  loggedInUserAccess: boolean = false;
  // tps$: Observable<District[]> = this.store.pipe(select(fromDistrict.getAllDistricts));
  districts$: Observable<any>;
  districtsLoading$ = this.store.pipe(select(fromDistrict.getDistrictsIsLoading));

  districtsCount$: Observable<number> = this.store.pipe(
    select(fromDistrict.getDistrictsCount)
  );
  districtsOffset$: Observable<number> = this.store.pipe(
    select(fromDistrict.getDistrictsOffset)
  );
  districtsSearch$: Observable<string> = this.store.pipe(
    select(fromDistrict.getDistrictsSearch)
  );
  districtsSortProp$: Observable<string> = this.store.pipe(
    select(fromDistrict.getDistrictsSortProp)
  );
  districtsSortDir$: Observable<string> = this.store.pipe(
    select(fromDistrict.getDistrictsSortDir)
  );

  private destroyed$ = new Subject<boolean>();
  //private dialogRef = null;
  modalReference: NgbModalRef;

  searchBy: any = '';

  constructor(
    private readonly store: Store<fromDistrict.State>,
    private modalService: NgbModal,
    @Optional() private activeModal: NgbActiveModal,
    actions$: Actions
  ) {
    this.loggedInUserAccess = lstore.get('aae');
    actions$
      .pipe(
        ofType(
          DistrictItemActionTypes.AddRecordSuccess,
          DistrictItemActionTypes.UpdateRecordSuccess
        ),
        takeUntil(this.destroyed$),
        tap(() => {
          this.modalReference.close();
        })
      )
      .subscribe();
  }

  ngOnInit() {
    this.loadDistricts();
    this.districts$ = this.store.pipe(select(fromDistrict.getAllDistricts));
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  loadDistricts() {
    const info: GridPayload = {
      offset: 0,
      sortProp: 'name',
      sortDir: 'asc',
      search:
        this.searchBy == '' ||
        this.searchBy == undefined ||
        this.searchBy == null
          ? 'all'
          : this.searchBy,
    };
    this.store.dispatch(new fromDistrict.LoadRecords(info));
  }

  search() {
    this.loadDistricts();
  }

  clear() {
    this.searchBy = '';
    this.loadDistricts();
  }
  
  addItem(): void {
    this.modalReference = this.modalService.open(DistrictAddComponent, {
      backdrop: 'static',
      size: 'lg',
      keyboard: false,
      centered: true,
    });
  }

  onGridUpdate(info: GridPayload) {
    this.store.dispatch(new fromDistrict.LoadRecords(info));
  }
  onItemSelected(batchTypeData: District) {
    this.modalReference = this.modalService.open(DistrictDetailsComponent, {
      backdrop: 'static',
      size: 'lg',
      keyboard: false,
      centered: true,
    });
    this.modalReference.componentInstance.selectedDistrict = batchTypeData;
  }

  onItemUpdate(batchType: District) {
    this.store.dispatch(new fromDistrict.SelectRecord(batchType.id));
    this.modalReference = this.modalService.open(DistrictUpdateComponent, {
      backdrop: 'static',
      size: 'lg',
      keyboard: false,
      centered: true,
    });
  }
}

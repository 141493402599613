<ngx-datatable
  class="material"
  [rows]="owners"
  [loadingIndicator]="loading"
  [columnMode]="'force'"
  [reorderable]="false"
  [headerHeight]="40"
  [footerHeight]="50"
  [rowHeight]="'auto'"
  [scrollbarV]="false"
  [scrollbarH]="true"
  [virtualization]="false"
  [externalSorting]="true"
  [sorts]="[{ prop: 'cold_storage_name', dir: 'asc' }]"
  (sort)="onSort($event)"
  [externalPaging]="true"
  [count]="count"
  [offset]="offset"
  [limit]="10"
  (page)="onPaginate($event)"
  (activate)="onActive($event)"
>
  <ngx-datatable-column
    name="Basic Detail(s)"
    [sortable]="false"
    [maxWidth]="250"
  >
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      <strong>{{ row.cold_storage_name }}</strong>
      <br />
      <span *ngIf="row.is_approved === 0" class="badge red" style="margin-left: -5px !important;"
        >On Hold / Verification Pending</span
      >
      <span *ngIf="row.is_approved === 1" class="badge yellow">Approved</span>
      <span *ngIf="row.is_approved === 2" class="badge red">Rejected</span>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column
    name="Address"
    prop="full_address"
    [sortable]="false"
    [maxWidth]="180"
  >
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template
      >{{ value }}
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column
    name="Primary Contact Person Detail(s)"
    [sortable]="false"
    [maxWidth]="180"
  >
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      <strong>Name: </strong>{{ row.contact_person }}
      <br />
      <strong>Mobile No: </strong>{{ row.contact_number }}
      <br />
      <strong>Email: </strong>{{ row.email }}
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column
    name="Other Detail(s)"
    [sortable]="false"
    [maxWidth]="160"
  >
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      <strong>State: </strong>{{ row.state }}
      <br />
      <strong>District: </strong>{{ row.district }}
      <br />
      <strong>Cold Storage Capacity: </strong>{{ row.cold_storage_capacity }}
      <br />
      <strong>Cold Storage Area: </strong>{{ row.cold_storage_area }}
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="Date & Time" [sortable]="false" [maxWidth]="180">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      <strong>Created On: </strong>
      <br />&nbsp;&nbsp;{{ row.created_on | date : "short" }}
      <br />
      <strong>Last Updated On: </strong>
      <br />&nbsp;&nbsp;{{ row.updated_on | date : "short" }}
    </ng-template>
  </ngx-datatable-column>

  <!-- <ngx-datatable-column name="Last Updated On" prop="updated_on">
        <ng-template let-value="value" ngx-datatable-cell-template>
          {{ value | date : "short" }}
        </ng-template>
      </ngx-datatable-column> -->

  <ngx-datatable-column [maxWidth]="200" [sortable]="false">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <button class="btn btn-outline-success btn-sm" (click)="editItem.emit(row)" *ngIf="loggedInUserAccess">
      <!-- <button
        class="btn btn-outline-success btn-sm"
        (click)="editItem.emit(row)"
      > -->
        <i class="fa fa-edit"></i> Edit
      </button>
      <br />
      <br />
      <button
        *ngIf="row.is_approved !== 2"
        type="button"
        class="btn btn-sm btn-outline-dark"
        (click)="updateStatusItem.emit(row)"
      >
        {{ row.is_active == 1 ? "Inactivate" : "Activate" }}
      </button>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>

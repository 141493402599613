import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DistrictsRoutingModule } from './districts-routing.module';
import { DistrictPageComponent } from './containers/district-page/district-page.component';
import { DistrictAddComponent } from './containers/district-add/district-add.component';
import { DistrictUpdateComponent } from './containers/district-update/district-update.component';
import { DistrictDetailsComponent } from './components/district-details/district-details.component';
import { DistrictDropdownComponent } from './components/district-dropdown/district-dropdown.component';
import { DistrictFormComponent } from './components/district-form/district-form.component';
import { DistrictListComponent } from './components/district-list/district-list.component';


import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { DISTRICT_EFFECTS } from '../../../../store/masters/districts/effects';
import { DISTRICT_REDUCERS } from '../../../../store/masters/districts/district.state';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { HeaderModule } from '../../../header/header.module';
import { SidemenuModule } from '../../../sidemenu/sidemenu.module';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgbAlertModule, NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { FooterModule } from '../../../../components/footer/footer.module';
import { StatesModule } from '../states/states.module';


@NgModule({
  declarations: [
    DistrictPageComponent,
    DistrictAddComponent,
    DistrictUpdateComponent,
    DistrictDetailsComponent,
    DistrictDropdownComponent,
    DistrictFormComponent,
    DistrictListComponent
  ],
  imports: [
    CommonModule,
    DistrictsRoutingModule,
    
    FormsModule,
    ReactiveFormsModule,
    
    StatesModule,
    
    HeaderModule,
    SidemenuModule,
    FooterModule,
    SelectDropDownModule,
    NgxDatatableModule,
    NgbModule,
    NgbPaginationModule, 
    NgbAlertModule,

    StoreModule.forFeature('districts', DISTRICT_REDUCERS),
    EffectsModule.forFeature(DISTRICT_EFFECTS),
  ],
  exports:[
    DistrictDropdownComponent
  ]
})
export class DistrictsModule { }

import { OwnerTypeOfColdStorageMappingApiModel, OwnerTypeOfColdStorageMappingModel } from "./owner-type-of-cold-storage-mapping.model";

export interface OwnerApiModel {
  id: string;

  user_id: string;
  username: string;

  cold_storage_name: string;
  contact_person: string;
  contact_number: string;
  email: string;

  state_id: string;
  state: string;

  district_id: string;
  district: string;

  full_address: string;

  description: string;

  cold_storage_capacity: number;
  cold_storage_area: number;
  
  type_of_products: String;

  is_approved: number;
  is_active: number;

  created_on: Date;
  updated_on: Date;

  cso_tocs_mapping_data?: Array<OwnerTypeOfColdStorageMappingApiModel>;
}

export class OwnerModel {
  id: string;

  user_id: string;
  username: string;

  cold_storage_name: string;
  contact_person: string;
  contact_number: string;
  email: string;

  state_id: string;
  state: string;

  district_id: string;
  district: string;

  full_address: string;

  description: string;

  cold_storage_capacity: number;
  cold_storage_area: number;
  
  type_of_products: String;

  is_approved: number;
  is_active: number;

  created_on: Date;
  updated_on: Date;

  cso_tocs_mapping_data?: Array<OwnerTypeOfColdStorageMappingModel>;

  constructor(raw: OwnerApiModel) {
    this.id = raw.id;

    this.user_id = raw.user_id;
    this.username = raw.username;

    this.cold_storage_name = raw.cold_storage_name;
    this.contact_person = raw.contact_person;
    this.contact_number = raw.contact_number;
    this.email = raw.email;

    this.state_id = raw.state_id;
    this.state = raw.state;

    this.district_id = raw.district_id;
    this.district = raw.district;

    this.full_address = raw.full_address;
    this.description = raw.description;

    this.cold_storage_capacity = raw.cold_storage_capacity;
    this.cold_storage_area = raw.cold_storage_area;

    this.type_of_products = raw.type_of_products;
    
    this.is_approved = raw.is_approved;
    this.is_active = raw.is_active;

    this.created_on = raw.created_on;
    this.updated_on = raw.updated_on;

    this.cso_tocs_mapping_data =
      raw.cso_tocs_mapping_data != undefined &&
      raw.cso_tocs_mapping_data.length > 0
        ? [...raw.cso_tocs_mapping_data]
        : [];
  }

  get emailLink() {
    return `mailto: ${this.email}`;
  }

  get telLink() {
    return `tel: ${this.contact_number}`;
  }
}

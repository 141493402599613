import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RegisterYourServiceComponent } from './register-your-service.component';

const routes: Routes = [
  { path: '', component: RegisterYourServiceComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RegisterYourServiceRoutingModule { }

import { Action } from '@ngrx/store';
import { DesignConsultantApiModel } from '../models/design-consultant.model';
import { GridPayload, LoadGridSuccessPayload } from '../../../../shared/models/grid.payload';
import { LoadSuccessPayload } from '../../../../shared/models/common.payload';




export enum DesignConsultantListActionTypes {
    LoadRecords = '[Cold Storage Design Consultants] Load',
    LoadRecordsSuccess = '[Cold Storage Design Consultants] Load Success',
    LoadRecordsFailure = '[Cold Storage Design Consultants] Load Failure',

    LoadListingRecords = '[Cold Storage Design Consultants Listing] Load',
    LoadListingRecordsSuccess = '[Cold Storage Design Consultants Listing] Load Success',
    LoadListingRecordsFailure = '[Cold Storage Design Consultants Listing] Load Failure',

    SelectRecord = '[Cold Storage Design Consultant] Select Record',
}

////////////

export class SelectRecord implements Action {
  readonly type = DesignConsultantListActionTypes.SelectRecord;

  constructor(public payload: string) {
  }
}

export class LoadRecords implements Action {
  readonly type = DesignConsultantListActionTypes.LoadRecords;

  constructor(public gridInfo: GridPayload) {
  }
}

export class LoadRecordsSuccess implements Action {
  readonly type = DesignConsultantListActionTypes.LoadRecordsSuccess;

  constructor(public payload: LoadGridSuccessPayload<DesignConsultantApiModel>) {
  }
}

export class LoadRecordsFailure implements Action {
  readonly type = DesignConsultantListActionTypes.LoadRecordsFailure;

  constructor(public payload: any) {
  }
}

export class LoadListingRecords implements Action {
  readonly type = DesignConsultantListActionTypes.LoadListingRecords;

  constructor(public gridInfo: GridPayload) {
  }
}

export class LoadListingRecordsSuccess implements Action {
  readonly type = DesignConsultantListActionTypes.LoadListingRecordsSuccess;

  constructor(public payload: LoadGridSuccessPayload<DesignConsultantApiModel>) {
  }
}

export class LoadListingRecordsFailure implements Action {
  readonly type = DesignConsultantListActionTypes.LoadListingRecordsFailure;

  constructor(public payload: any) {
  }
}
////////////

export type ListActions =
  | SelectRecord
  | LoadRecords
  | LoadRecordsSuccess
  | LoadRecordsFailure
  | LoadListingRecords
  | LoadListingRecordsSuccess
  | LoadListingRecordsFailure;

import { Component } from "@angular/core";
import { select, Store } from "@ngrx/store";

import * as fromState from "../../../../../../store/masters/states";
import { State } from "../../../../../../store/masters/states/models/state.model";

@Component({
  selector: 'app-state-add',
  templateUrl: './state-add.component.html'
})
export class StateAddComponent {
  pending$ = this.store.pipe(select(fromState.getStateItemPending));
  error$ = this.store.pipe(select(fromState.getStateItemError));

  constructor(private readonly store: Store<fromState.State>) {}

  onSubmit($event: State) {
    this.store.dispatch(new fromState.AddRecord($event));
  }

}

<div class="modalDialogBox">
    <div class="modal-header bg-green-400 text-white">
      <h4 class="modal-title" id="modal-basic-title">
        {{ equipmentSupplier.organisation_name }}
        <br />
        <small class="small-text">
          <strong>Created On:</strong>&nbsp;
          {{ equipmentSupplier.created_on | date : "short" }}
          &nbsp;&nbsp;
          <strong>Last Updated On:</strong>&nbsp;
          {{ equipmentSupplier.updated_on | date : "short" }}
        </small>
      </h4>
      <button
        type="button"
        class="btn btn-outline-white"
        aria-label="Close"
        (click)="activeModal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-md-12">
          <div class="table-responsive">
            <table class="table table-bordered mb-0">
              <tbody>
                <tr>
                  <td width="25%"><strong>State</strong></td>
                  <td width="75%">{{ equipmentSupplier.state }}</td>
                </tr>
                <tr>
                  <td width="25%"><strong>District</strong></td>
                  <td width="75%">{{ equipmentSupplier.district }}</td>
                </tr>
                <tr>
                  <td width="25%"><strong>Address</strong></td>
                  <td width="75%">{{ equipmentSupplier.full_address }}</td>
                </tr>
                <tr>
                  <td width="100%" colspan="2">
                    <strong>Primary Contact Person Detail(s)</strong>
                  </td>
                </tr>
                <tr>
                  <td width="25%"><strong>Name</strong></td>
                  <td width="75%">{{ equipmentSupplier.contact_person }}</td>
                </tr>
                <tr>
                  <td width="25%"><strong>Mobile No</strong></td>
                  <td width="75%">{{ equipmentSupplier.contact_number }}</td>
                </tr>
                <tr>
                  <td width="25%"><strong>Email</strong></td>
                  <td width="75%">{{ equipmentSupplier.email }}</td>
                </tr>
                <tr>
                  <td width="100%" colspan="2">
                    <strong>Other Detail(s)</strong>
                  </td>
                </tr>
                <tr>
                  <td width="25%"><strong>Total Experience</strong></td>
                  <td width="75%">{{ equipmentSupplier.total_experience }}</td>
                </tr>
                <tr>
                  <td width="25%"><strong>Annual Turnover</strong></td>
                  <td width="75%">{{ equipmentSupplier.anual_turnover }}</td>
                </tr>
                <tr>
                  <td width="25%"><strong>Equipment</strong></td>
                  <td width="75%">
                    <span
                      class="badge yellow"
                      *ngFor="
                        let et of equipmentSupplier.cses_equipment_mapping_data
                      "
                    >
                      {{ et.equipment }}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td width="25%"><strong>Status</strong></td>
                  <td width="75%">
                    {{ equipmentSupplier.is_active == 1 ? "Active" : "Inactive" }}
                  </td>
                </tr>
                <tr>
                  <td width="25%"><strong>Detailed Information</strong></td>
                  <td width="75%">{{ equipmentSupplier.description }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">&nbsp;</div>
      </div>
    </div>
  </div>
  
<div class="modalDialogBox">
  <form [formGroup]="form" (ngSubmit)="submit()">
    <div class="modal-header bg-green-400 text-white">
      <h4 class="modal-title" id="modal-basic-title">
        {{ districtId ? "Update" : "Add" }} District Detail(s)<br />
        <small class="text-white-100" *ngIf="districtId"
          ><strong>Last Update On:</strong>&nbsp;{{
            lastUpdatedOn | date : "short"
          }}</small
        >
      </h4>
      <button
        type="button"
        class="btn btn-outline-white"
        aria-label="Close"
        (click)="activeModal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div>
        <p *ngIf="error" class="error-message">{{ error }}</p>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label class="col-form-label" for="name">
              <strong>State</strong>
              <small class="text-danger">*</small>
            </label>
            <app-state-dropdown
              (selectStateDropdownItem)="onStateDropdownItemSelect($event)"
              [selectedState]="selectedStateId"
            >
            </app-state-dropdown>
          </div>
          <div
            class="error-message"
            *ngIf="!stateFieldErrorMsg"
          >
            State is <strong>required</strong>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <label class="col-form-label" for="name">
              <strong>District</strong>
              <small class="text-danger">*</small>
            </label>
            <input
              type="text"
              class="form-control"
              placeholder="Please provide district name."
              formControlName="name"
              required
            />
          </div>
          <div
            class="error-message"
            *ngIf="form.get('name').hasError('maxlength')"
          >
            District name is <strong>too long</strong>
          </div>
          <div
            class="error-message"
            *ngIf="form.get('name').hasError('required')"
          >
            District name is <strong>required</strong>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <label class="col-form-label" for="description">
              <strong>Abbreviation</strong>
              <small class="text-danger">*</small>
            </label>
            <input
              type="text"
              class="form-control"
              placeholder="Please provide district name."
              formControlName="abbr"
              required
            />
          </div>
          <div
            class="error-message"
            *ngIf="form.get('name').hasError('maxlength')"
          >
            Abbreviation is <strong>too long</strong>
          </div>
          <div
            class="error-message"
            *ngIf="form.get('name').hasError('required')"
          >
            Abbreviation is <strong>required</strong>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="col-form-label" for="status">
              <strong>Status</strong>
            </label>
            <select
              formControlName="status"
              class="form-control select-box"
              required
            >
              <option selected value="1">Active</option>
              <option value="0">Inactive</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">&nbsp;</div>
      </div>
    </div>
    <div class="modal-footer bg-gray-300">
      <button
        type="submit"
        class="btn btn-outline-success"
        [disabled]="!form.valid"
        (click)="submit()"
      >
        Submit
      </button>
      <button
        type="button"
        class="btn btn-outline-dark"
        (click)="activeModal.close('Save click')"
      >
        Cancel
      </button>
      <!-- <button mat-stroked-button color="accent" [disabled]="form.invalid" (click)="submit()">Save</button> -->
    </div>
  </form>
</div>

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import * as fromExpertTypes from '../../../../../../store/masters/expert-types';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ExpertType } from '../../../../../../store/masters/expert-types/models/expert-type.model';

@Component({
  selector: 'app-expert-type-filter-list',
  templateUrl: './expert-type-filter-list.component.html',
  styleUrl: './expert-type-filter-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpertTypeFilterListComponent {
  expertType$: any = '0';
  expertTypesDropdown$: Observable<any>;

  @Input('selectedExpertType')
  set selectedExpertType(value: any) {
    this.expertType$ =
      value == null || value == undefined || value == '' ? '0' : value;
  }

  @Output() selectExpertTypeFilterListItem = new EventEmitter();

  constructor(
    public cdr: ChangeDetectorRef,
    private readonly store: Store<fromExpertTypes.State>
  ) {}

  ngOnInit() {
    this.store.dispatch(new fromExpertTypes.LoadDropdownRecords());

    this.expertTypesDropdown$ = this.store.pipe(
      select(fromExpertTypes.getAllExpertTypes)
    );

    // this.expertTypesDropdown$.subscribe((data)=>{
    //   console.log(`action.payload.data:: ${JSON.stringify(data)}`)
    // })
  }

  compareExpertTypes(p1: ExpertType, p2: ExpertType) {
    return p1.id === p2.id;
  }
  selectedIds: any = [];
  onSelect(event, id, name) {
    if (event.target.checked == true) {
      // console.log('checkbox is checked');
      this.selectedIds = [...this.selectedIds, id];
    } else {
      // console.log('checkbox is unchecked');
      // const index: number = this.selectedIds.indexOf(id);
      // if (index !== -1) {
      //   this.selectedIds.splice(index, 1);
      // }
      const filteredItems = this.selectedIds.filter(item => item !== id);
      this.selectedIds = [...filteredItems];
    }

    /*
    const selectedId = id; 
    const selectedElement = {
      id: id,
      text: name,
    };
    */
    // console.log(`Filter componet selected ids ${this.selectedIds.toString()}`);
    this.selectExpertTypeFilterListItem.emit(this.selectedIds);
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import * as fromEquipments from '../../../../../../store/masters/equipments';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Equipment } from '../../../../../../store/masters/equipments/models/equipment.model';

@Component({
  selector: 'app-equipment-dropdown',
  templateUrl: './equipment-dropdown.component.html',
  styleUrls: ['./equipment-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EquipmentDropdownComponent implements OnInit {
  equipment$: any = '0';
  equipmentsDropdown$: Observable<any>;

  @Input('selectedEquipment')
  set selectedEquipment(value: any) {
    this.equipment$ =
      value == null || value == undefined || value == '' ? '0' : value;
  }

  @Output() selectEquipmentDropdownItem = new EventEmitter();

  constructor(
    public cdr: ChangeDetectorRef,
    private readonly store: Store<fromEquipments.State>
  ) {}

  ngOnInit() {
    this.store.dispatch(new fromEquipments.LoadDropdownRecords());

    this.equipmentsDropdown$ = this.store.pipe(select(fromEquipments.getAllEquipments));

    // this.equipmentsDropdown$.subscribe((data)=>{
    //   console.log(`action.payload.data:: ${JSON.stringify(data)}`)
    // })
  }

  compareEquipments(p1: Equipment, p2: Equipment) {
    return p1.id === p2.id;
  }

  onSelect(event) {
    const selectedId = event.target.value.split(':').pop().trim();
    const selectedElement = {
      id: selectedId,
      text: event.target.options[event.target.options.selectedIndex].text,
    };
    this.selectEquipmentDropdownItem.emit(selectedElement);
  }
}

import { EquipmentSupplierActionTypes, EquipmentSupplierItemActionTypes } from '../actions';

export interface EquipmentSupplierItemState {
  error: string | null;
  pending: boolean;
}

export const initialState: EquipmentSupplierItemState = {
  error: null,
  pending: false,
};

export function equipmentSupplierItemReducer(
  state = initialState,
  action: EquipmentSupplierActionTypes
): EquipmentSupplierItemState {
  switch (action.type) {
    case EquipmentSupplierItemActionTypes.AddRecord:
    case EquipmentSupplierItemActionTypes.UpdateRecord:
    case EquipmentSupplierItemActionTypes.UpdateStatusRecord:
    case EquipmentSupplierItemActionTypes.UpdateApprovalStatusRecord: {
      return {
        ...state,
        error: null,
        pending: true,
      };
    }

    case EquipmentSupplierItemActionTypes.AddRecordSuccess:
    case EquipmentSupplierItemActionTypes.UpdateRecordSuccess:
    case EquipmentSupplierItemActionTypes.UpdateStatusRecordSuccess:
    case EquipmentSupplierItemActionTypes.UpdateApprovalStatusRecordSuccess: {
      return {
        ...state,
        error: null,
        pending: false,
      };
    }

    case EquipmentSupplierItemActionTypes.AddRecordFailure:
    case EquipmentSupplierItemActionTypes.UpdateRecordFailure:
    case EquipmentSupplierItemActionTypes.UpdateStatusRecordFailure:
    case EquipmentSupplierItemActionTypes.UpdateApprovalStatusRecordFailure: {
      return {
        ...state,
        error: action.payload,
        pending: false,
      };
    }

    default:
      return state;
  }
}

export const getError = (state: EquipmentSupplierItemState) => state.error;
export const getPending = (state: EquipmentSupplierItemState) => state.pending;

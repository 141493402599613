import { Action } from '@ngrx/store';
import { ContractorApiModel } from '../../../../store/cold-storage/contractors/models/contractor.model';
import { GridPayload, LoadGridSuccessPayload } from '../../../../shared/models/grid.payload';
import { LoadSuccessPayload } from '../../../../shared/models/common.payload';




export enum ContractorListActionTypes {
    LoadRecords = '[Cold Storage Contractors] Load',
    LoadRecordsSuccess = '[Cold Storage Contractors] Load Success',
    LoadRecordsFailure = '[Cold Storage Contractors] Load Failure',

    LoadListingRecords = '[Cold Storage Contractors Listing] Load',
    LoadListingRecordsSuccess = '[Cold Storage Contractors Listing] Load Success',
    LoadListingRecordsFailure = '[Cold Storage Contractors Listing] Load Failure',

    SelectRecord = '[Cold Storage Contractor] Select Record',
}

////////////

export class SelectRecord implements Action {
  readonly type = ContractorListActionTypes.SelectRecord;

  constructor(public payload: string) {
  }
}

export class LoadRecords implements Action {
  readonly type = ContractorListActionTypes.LoadRecords;

  constructor(public gridInfo: GridPayload) {
  }
}

export class LoadRecordsSuccess implements Action {
  readonly type = ContractorListActionTypes.LoadRecordsSuccess;

  constructor(public payload: LoadGridSuccessPayload<ContractorApiModel>) {
  }
}

export class LoadRecordsFailure implements Action {
  readonly type = ContractorListActionTypes.LoadRecordsFailure;

  constructor(public payload: any) {
  }
}

export class LoadListingRecords implements Action {
  readonly type = ContractorListActionTypes.LoadListingRecords;

  constructor(public gridInfo: GridPayload) {
  }
}

export class LoadListingRecordsSuccess implements Action {
  readonly type = ContractorListActionTypes.LoadListingRecordsSuccess;

  constructor(public payload: LoadGridSuccessPayload<ContractorApiModel>) {
  }
}

export class LoadListingRecordsFailure implements Action {
  readonly type = ContractorListActionTypes.LoadListingRecordsFailure;

  constructor(public payload: any) {
  }
}
////////////

export type ListActions =
  | SelectRecord
  | LoadRecords
  | LoadRecordsSuccess
  | LoadRecordsFailure
  | LoadListingRecords
  | LoadListingRecordsSuccess
  | LoadListingRecordsFailure;

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SearchDesignConsultantsComponent } from './search-design-consultants/search-design-consultants.component';


const routes: Routes = [
  { path: '', component: SearchDesignConsultantsComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DesignConsultantsRoutingModule { }

import { NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ColdStorageDesignConsultantsRoutingModule } from './cold-storage-design-consultants-routing.module';


import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { COLD_STORAGE_DESIGN_CONSULTANT_EFFECTS } from '../../../store/cold-storage/design-consultants/effects';
import { COLD_STORAGE_DESIGN_CONSULTANT_REDUCERS } from '../../../store/cold-storage/design-consultants/design-consultant.state';

// import { FormComponent } from './form/form.component';

import { HeaderModule } from '../../header/header.module';
import { SidemenuModule } from '../../sidemenu/sidemenu.module';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgbAlertModule, NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { StatesModule } from '../masters/states/states.module';
import { ExperienceTypesModule } from '../masters/experience-types/experience-types.module';
import { DistrictsModule } from '../masters/districts/districts.module';
import { DesignConsultantAddComponent } from './containers/design-consultant-add/design-consultant-add.component';
import { DesignConsultantUpdateComponent } from './containers/design-consultant-update/design-consultant-update.component';
import { DesignConsultantPageComponent } from './containers/design-consultant-page/design-consultant-page.component';
import { DesignConsultantDetailsComponent } from './components/design-consultant-details/design-consultant-details.component';
import { DesignConsultantListComponent } from './components/design-consultant-list/design-consultant-list.component';
import { DesignConsultantFormComponent } from './components/design-consultant-form/design-consultant-form.component';
import { ExpertTypesModule } from '../masters/expert-types/expert-types.module';


@NgModule({
  declarations: [
    DesignConsultantAddComponent,
    DesignConsultantUpdateComponent,
    DesignConsultantPageComponent,
    DesignConsultantDetailsComponent,
    DesignConsultantListComponent,
    DesignConsultantFormComponent
  ],
  imports: [
    CommonModule,
    ColdStorageDesignConsultantsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HeaderModule,
    SidemenuModule,
    SelectDropDownModule,
    NgxDatatableModule,
    NgbModule,
    NgbPaginationModule, 
    NgbAlertModule,
    StatesModule,
    DistrictsModule,
    ExpertTypesModule,
    StoreModule.forFeature('design_consultants', COLD_STORAGE_DESIGN_CONSULTANT_REDUCERS),
    EffectsModule.forFeature(COLD_STORAGE_DESIGN_CONSULTANT_EFFECTS),
  ],
  schemas:[NO_ERRORS_SCHEMA]
})
export class ColdStorageDesignConsultantsModule { }

import { Action } from '@ngrx/store';
import { District } from '../models/district.model';


export enum DistrictItemActionTypes {
  AddRecord = '[Districts] Add Record',
  AddRecordSuccess = '[Districts] Add Record Success',
  AddRecordFailure = '[Districts] Add Record Failure',

  UpdateRecord = '[Districts] Update Record',
  UpdateRecordSuccess = '[Districts] Update Record Success',
  UpdateRecordFailure = '[Districts] Update Record Failure',
}

////////////

export class AddRecord implements Action {
  readonly type = DistrictItemActionTypes.AddRecord;

  constructor(public payload: District) {
  }
}

export class AddRecordSuccess implements Action {
  readonly type = DistrictItemActionTypes.AddRecordSuccess;

  constructor(public payload: District) {
  }
}

export class AddRecordFailure implements Action {
  readonly type = DistrictItemActionTypes.AddRecordFailure;

  constructor(public payload: string) {
  }
}

export class UpdateRecord implements Action {
  readonly type = DistrictItemActionTypes.UpdateRecord;

  constructor(public payload: District) {
  }
}

export class UpdateRecordSuccess implements Action {
  readonly type = DistrictItemActionTypes.UpdateRecordSuccess;

  constructor(public payload: District) {
  }
}

export class UpdateRecordFailure implements Action {
  readonly type = DistrictItemActionTypes.UpdateRecordFailure;

  constructor(public payload: string) {
  }
}

////////////

export type ItemActions =
  | AddRecord
  | AddRecordSuccess
  | AddRecordFailure
  | UpdateRecord
  | UpdateRecordSuccess
  | UpdateRecordFailure;

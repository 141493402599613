import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  Actions,
  Effect,
  ofType,
  // OnInitEffects
} from '@ngrx/effects';
import {
  Action,
  // Action,
  select,
  Store,
} from '@ngrx/store';
import {
  map,
  switchMap,
  catchError,
  withLatestFrom,
  concatMap,
} from 'rxjs/operators';
import { Observable, from, of as observableOf, of } from 'rxjs';

import { ReportsService } from '../../services/reports';

import * as ReportsActions from './actions';

@Injectable()
export class ReportsEffects {
  // export class UsersActionsEffects implements OnInitEffects {
  constructor(
    private actions$: Actions,
    private reportsService: ReportsService
  ) {}

  // ngrxOnInitEffects(): Action {
  //   return { type: UsersActions.LOAD_DOCUMENTS }
  // }

  @Effect()
  loadOverallStatisticsReportData$: Observable<any> = this.actions$.pipe(
    ofType(ReportsActions.LOAD_OVERALL_STATS_REPORT_RECORDS),
    map((action: ReportsActions.LoadOverallStatisticsReportRecords) => true),
    switchMap(() => {
      // jwt load current account
      console.log(`Inside effects get report...`);
      return this.reportsService.getOverallStatistics().pipe(
        map((response) => {
          // this.notification.success('UsersActions', 'Data retrieved successfully!')
          if (response) {
            return new ReportsActions.LoadOverallStatisticsReportRecordsSuccess(
              response.data
            );
          }
          return new ReportsActions.LoadOverallStatisticsReportRecordsFailure();
        }),
        catchError((error) => {
          console.log('REPORTS LOAD ERROR: ', error);
          return from([
            { type: ReportsActions.LOAD_OVERALL_STATS_REPORT_RECORDS_FAILURE },
          ]);
        })
      );

      // do nothing for firebase, as user state subscribed inside firebase service
      return of(new ReportsActions.EmptyAction());
    })
  );
}

import { Action } from '@ngrx/store';
import { ExperienceType } from '../models/experience-type.model';


export enum ExperienceTypeItemActionTypes {
  AddRecord = '[ExperienceTypes] Add Record',
  AddRecordSuccess = '[ExperienceTypes] Add Record Success',
  AddRecordFailure = '[ExperienceTypes] Add Record Failure',

  UpdateRecord = '[ExperienceTypes] Update Record',
  UpdateRecordSuccess = '[ExperienceTypes] Update Record Success',
  UpdateRecordFailure = '[ExperienceTypes] Update Record Failure',
}

////////////

export class AddRecord implements Action {
  readonly type = ExperienceTypeItemActionTypes.AddRecord;

  constructor(public payload: ExperienceType) {
  }
}

export class AddRecordSuccess implements Action {
  readonly type = ExperienceTypeItemActionTypes.AddRecordSuccess;

  constructor(public payload: ExperienceType) {
  }
}

export class AddRecordFailure implements Action {
  readonly type = ExperienceTypeItemActionTypes.AddRecordFailure;

  constructor(public payload: string) {
  }
}

export class UpdateRecord implements Action {
  readonly type = ExperienceTypeItemActionTypes.UpdateRecord;

  constructor(public payload: ExperienceType) {
  }
}

export class UpdateRecordSuccess implements Action {
  readonly type = ExperienceTypeItemActionTypes.UpdateRecordSuccess;

  constructor(public payload: ExperienceType) {
  }
}

export class UpdateRecordFailure implements Action {
  readonly type = ExperienceTypeItemActionTypes.UpdateRecordFailure;

  constructor(public payload: string) {
  }
}

////////////

export type ItemActions =
  | AddRecord
  | AddRecordSuccess
  | AddRecordFailure
  | UpdateRecord
  | UpdateRecordSuccess
  | UpdateRecordFailure;

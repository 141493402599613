import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { District } from '../models/district.model';
import { DistrictActionTypes, DistrictItemActionTypes, DistrictListActionTypes } from '../actions';


export interface DistrictListState extends EntityState<District> {
  isLoading: boolean;
  selectedDistrictId: string | null;

  count: number;
  offset: number;

  sortProp: string;
  sortDir: string;

  search:string;
}


export const adapter: EntityAdapter<District> = createEntityAdapter<District>({
  selectId: (bt: District) => bt.id,
});


export const initialState: DistrictListState = adapter.getInitialState({
  isLoading: false,
  selectedDistrictId: null,

  count: 0,
  offset: 0,

  sortProp: 'name',
  sortDir: 'asc',
  search: 'all'
});


export function districtListReducer(state = initialState, action: DistrictActionTypes): DistrictListState {
  switch (action.type) {

    case DistrictListActionTypes.LoadRecords: {
      return {
        ...state,
        ...action.gridInfo,
        isLoading: true,
      };
    }

    case DistrictListActionTypes.LoadRecordsSuccess: {
      return adapter.addMany(action.payload.data as District[], {
        ...adapter.removeAll(state),
        isLoading: false,
        count: action.payload.count
      });
    }

    case DistrictItemActionTypes.UpdateRecordSuccess: {
      return adapter.upsertOne(action.payload, state);
    }

    case DistrictListActionTypes.SelectRecord: {
      return {
        ...state,
        selectedDistrictId: action.payload
      };
    }

    case DistrictListActionTypes.LoadDropdownRecords: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case DistrictListActionTypes.LoadDropdownRecordsSuccess: {
      return adapter.addMany(action.payload.data as District[], {
        ...adapter.removeAll(state),
        isLoading: false
      });
    }


    case DistrictListActionTypes.LoadDropdownByUserRecords: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case DistrictListActionTypes.LoadDropdownByUserRecordsSuccess: {
      return adapter.addMany(action.payload.data as District[], {
        ...adapter.removeAll(state),
        isLoading: false
      });
    }

    default:
      return state;
  }
}


export const getIsLoading = (state: DistrictListState) => state.isLoading;

export const getCount = (state: DistrictListState) => state.count;
export const getOffset = (state: DistrictListState) => state.offset;

export const getSortProp = (state: DistrictListState) => state.sortProp;
export const getSortDir = (state: DistrictListState) => state.sortDir;
export const getSearch = (state: DistrictListState) => state.search;

export const getSelectedId = (state: DistrictListState) => state.selectedDistrictId;

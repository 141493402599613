import { NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ColdStorageEquipmentSuppliersRoutingModule } from './cold-storage-equipment-suppliers-routing.module';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { COLD_STORAGE_EQUIPMENT_SUPPLIER_EFFECTS } from '../../../store/cold-storage/equipment-suppliers/effects';
import { COLD_STORAGE_EQUIPMENT_SUPPLIER_REDUCERS } from '../../../store/cold-storage/equipment-suppliers/equipment-supplier.state';

// import { FormComponent } from './form/form.component';

import { HeaderModule } from '../../header/header.module';
import { SidemenuModule } from '../../sidemenu/sidemenu.module';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgbAlertModule, NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { StatesModule } from '../masters/states/states.module';
import { ExperienceTypesModule } from '../masters/experience-types/experience-types.module';
import { DistrictsModule } from '../masters/districts/districts.module';
import { EquipmentSupplierAddComponent } from './containers/equipment-supplier-add/equipment-supplier-add.component';
import { EquipmentSupplierUpdateComponent } from './containers/equipment-supplier-update/equipment-supplier-update.component';
import { EquipmentSupplierPageComponent } from './containers/equipment-supplier-page/equipment-supplier-page.component';
import { EquipmentSupplierDetailsComponent } from './components/equipment-supplier-details/equipment-supplier-details.component';
import { EquipmentSupplierFormComponent } from './components/equipment-supplier-form/equipment-supplier-form.component';
import { EquipmentSupplierListComponent } from './components/equipment-supplier-list/equipment-supplier-list.component';
import { EquipmentsModule } from '../masters/equipments/equipments.module';


@NgModule({
  declarations: [
    EquipmentSupplierAddComponent,
    EquipmentSupplierUpdateComponent,
    EquipmentSupplierPageComponent,
    EquipmentSupplierDetailsComponent,
    EquipmentSupplierFormComponent,
    EquipmentSupplierListComponent,
  ],
  imports: [
    CommonModule,
    ColdStorageEquipmentSuppliersRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HeaderModule,
    SidemenuModule,
    SelectDropDownModule,
    NgxDatatableModule,
    NgbModule,
    NgbPaginationModule, 
    NgbAlertModule,
    StatesModule,
    DistrictsModule,
    EquipmentsModule,
    StoreModule.forFeature('equipment_suppliers', COLD_STORAGE_EQUIPMENT_SUPPLIER_REDUCERS),
    EffectsModule.forFeature(COLD_STORAGE_EQUIPMENT_SUPPLIER_EFFECTS),
  ],
  schemas:[NO_ERRORS_SCHEMA]
})
export class ColdStorageEquipmentSuppliersModule { }

import { OwnerActionTypes, OwnerItemActionTypes } from '../actions';

export interface OwnerItemState {
  error: string | null;
  pending: boolean;
}

export const initialState: OwnerItemState = {
  error: null,
  pending: false,
};

export function ownerItemReducer(
  state = initialState,
  action: OwnerActionTypes
): OwnerItemState {
  switch (action.type) {
    case OwnerItemActionTypes.AddRecord:
    case OwnerItemActionTypes.UpdateRecord:
    case OwnerItemActionTypes.UpdateStatusRecord:
    case OwnerItemActionTypes.UpdateApprovalStatusRecord: {
      return {
        ...state,
        error: null,
        pending: true,
      };
    }

    case OwnerItemActionTypes.AddRecordSuccess:
    case OwnerItemActionTypes.UpdateRecordSuccess:
    case OwnerItemActionTypes.UpdateStatusRecordSuccess:
    case OwnerItemActionTypes.UpdateApprovalStatusRecordSuccess: {
      return {
        ...state,
        error: null,
        pending: false,
      };
    }

    case OwnerItemActionTypes.AddRecordFailure:
    case OwnerItemActionTypes.UpdateRecordFailure:
    case OwnerItemActionTypes.UpdateStatusRecordFailure:
    case OwnerItemActionTypes.UpdateApprovalStatusRecordFailure: {
      return {
        ...state,
        error: action.payload,
        pending: false,
      };
    }

    default:
      return state;
  }
}

export const getError = (state: OwnerItemState) => state.error;
export const getPending = (state: OwnerItemState) => state.pending;

import { GlobalState } from '../../../store';
import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromList from './reducers/list.reducer';
import * as fromItem from './reducers/item.reducer';


export interface EquipmentState {
  list: fromList.EquipmentListState;
  item: fromItem.EquipmentItemState;
}

export interface State extends GlobalState {
  equipments: EquipmentState;
}

export const EQUIPMENT_REDUCERS: ActionReducerMap<EquipmentState, any> = {
  list: fromList.equipmentListReducer,
  item: fromItem.equipmentItemReducer
};


////// selectors

export const getProjectsState = createFeatureSelector<State, EquipmentState>('equipments');


export const getEquipmentListState = createSelector(getProjectsState, state => state.list);
export const getEquipmentItemState = createSelector(getProjectsState, state => state.item);


export const getEquipmentsIsLoading = createSelector(getEquipmentListState, fromList.getIsLoading);

export const getEquipmentsCount = createSelector(getEquipmentListState, fromList.getCount);
export const getEquipmentsOffset = createSelector(getEquipmentListState, fromList.getOffset);

export const getEquipmentsSortProp = createSelector(getEquipmentListState, fromList.getSortProp);
export const getEquipmentsSortDir = createSelector(getEquipmentListState, fromList.getSortDir);
export const getEquipmentsSearch = createSelector(getEquipmentListState, fromList.getSearch);

export const getSelectedEquipmentId = createSelector(getEquipmentListState, fromList.getSelectedId);


export const {
  selectEntities: getEquipmentEntities,
  selectAll: getAllEquipments
} = fromList.adapter.getSelectors(getEquipmentListState);


export const getSelectedEquipment = createSelector(
  getEquipmentEntities,
  getSelectedEquipmentId,
  (entities, selectedId) => {
    return selectedId !== null && entities[selectedId];
  }
);


// Item

export const getEquipmentItemPending = createSelector(getEquipmentItemState, fromItem.getPending);
export const getEquipmentItemError = createSelector(getEquipmentItemState, fromItem.getError);



<!-- Start Navbar Area -->
<div class="navbar-area navbar-style-two" [ngClass]="{ sticky: true }">
  <div class="vesax-nav">
    <div class="container-fluid">
      <nav
        class="navbar navbar-expand-xl navbar-light"
        [class.active]="classApplied"
      >
        <a class="navbar-brand" routerLink="/">
          <img
            class="header-logo"
            src="assets/img/apsecm_logo.png"
            alt="logo"
          />
        </a>
        <button class="navbar-toggler" type="button" (click)="toggleClass()">
          <span class="burger-menu">
            <span class="top-bar"></span>
            <span class="middle-bar"></span>
            <span class="bottom-bar"></span>
          </span>
        </button>
        <div class="collapse navbar-collapse">
          <!-- <form class="navbar-search-box">
            <label><i class="flaticon-search"></i></label>
            <input
              type="text"
              class="input-search"
              placeholder="What are you looking for?"
            />
          </form> -->
          <ul class="navbar-nav">
            <li class="nav-item">
              <a
                routerLink="/"
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                Home
              </a>
            </li>

            <li class="nav-item">
              <a href="javascript:void(0)" class="dropdown-toggle nav-link">
                Cold Storage
              </a>
              <ul class="dropdown-menu">
                <li class="nav-item">
                  <a
                    routerLink="/listing/search/design-consultants"
                    class="nav-link"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    >Design Consultants</a
                  >
                </li>

                <li class="nav-item">
                  <a
                    routerLink="/listing/search/owners"
                    class="nav-link"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    >Owners</a
                  >
                </li>

                <li class="nav-item">
                  <a
                    routerLink="/listing/search/contractors"
                    class="nav-link"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    >Erectors/ Contractors</a
                  >
                </li>

                <li class="nav-item">
                  <a
                    routerLink="/listing/search/equipment-suppliers"
                    class="nav-link"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    >Equipment Suppliers</a
                  >
                </li>
              </ul>
            </li>
            <li class="nav-item">
              <a
                routerLink="/"
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >About Us</a
              >
            </li>

            <li class="nav-item">
              <a
                routerLink="/how-it-works"
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >How It Work</a
              >
            </li>

            <li class="nav-item">
              <a
                routerLink="/faq"
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >FAQ</a
              >
            </li>

            <li class="nav-item">
              <a
                routerLink="/contact-us"
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                Contact Us
              </a>
            </li>
          </ul>
          <div class="others-option d-flex align-items-center">
            <div class="option-item" *ngIf="!isUserLoggedIn">
              <span (click)="openSigninSignupPopup()">
                <i class="flaticon-user"></i> Login / Register
              </span>
            </div>
            <div class="option-item">
              <a routerLink="/register-your-service" class="default-btn">
                <i class="flaticon-more"></i> Register Your Service
              </a>
            </div>
            <div class="option-item" *ngIf="isUserLoggedIn">
              <div class="collapse navbar-collapse mean-menu">
                <ul class="navbar-nav">
                  <li class="nav-item">
                    <a
                      href="javascript:void(0)"
                      class="dropdown-toggle nav-link"
                    >
                      Welcome, {{ name }}
                    </a>
                    <ul class="dropdown-menu">
                      <li class="nav-item">
                        <a
                          routerLink="/dashboard"
                          class="nav-link"
                          routerLinkActive="active"
                          [routerLinkActiveOptions]="{ exact: true }"
                          >Dashboard</a
                        >
                      </li>
                      <li class="nav-item">
                        <a
                          (click)="logout()"
                          class="nav-link"
                          routerLinkActive="active"
                          [routerLinkActiveOptions]="{ exact: true }"
                          style="cursor: pointer !important"
                        >
                          <i class="bx bx-log-out"></i> Logout
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>
  <!-- <div id="sticky">
    <span>
      If you are a service provider, Please click
      <a>here</a>
      to register.
    </span>
  </div> -->
</div>
<!-- End Navbar Area -->

<!-- Start Login & Register Modal -->
<div
  class="modal fade loginRegisterModal"
  tabindex="-1"
  aria-hidden="true"
  *ngIf="isSigninSignupOpen"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <button type="button" class="close" (click)="closeSigninSignupPopup()">
        <i class="bx bx-x"></i>
      </button>
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <button
            class="nav-link"
            (click)="switchTab($event, 'tab1')"
            [ngClass]="{ active: currentTab === 'tab1' }"
          >
            Login
          </button>
        </li>
        <li class="nav-item">
          <button
            class="nav-link"
            (click)="switchTab($event, 'tab2')"
            [ngClass]="{ active: currentTab === 'tab2' }"
          >
            Register
          </button>
        </li>
      </ul>
      <div class="tab-content">
        <div class="tab-pane fade show active" *ngIf="currentTab === 'tab1'">
          <div class="vesax-login">
            <form [formGroup]="loginform" (ngSubmit)="login()">
              <div class="form-group">
                <input
                  type="text"
                  placeholder="Mobile No"
                  class="form-control"
                  formControlName="username"
                />
              </div>
              <div class="form-group">
                <input
                  type="password"
                  placeholder="Password"
                  class="form-control"
                  formControlName="password"
                />
              </div>
              <button type="submit">Login Now</button>
            </form>
            <span class="dont-account">
              <!-- Don't have an account? <a href="#">Register Now</a> -->
              Don't have an account? Register Now
              </span>
          </div>
        </div>
        <div class="tab-pane fade show active" *ngIf="currentTab === 'tab2'">
          <div class="vesax-register">
            <form [formGroup]="form" (ngSubmit)="submitForm()">
              <!-- <div class="form-group">
                <input
                  type="radio"
                  id="general_user_type_1"
                  name="is_general_user"
                  formControlName="is_general_user"
                  value="1"
                /><label for="general_user_type_1">&nbsp;General User</label>
                &nbsp;&nbsp;
                <input
                  type="radio"
                  id="general_user_type_2"
                  name="is_general_user"
                  formControlName="is_general_user"
                  value="2"
                /><label for="general_user_type_2"
                  >&nbsp;Service Provider</label
                >
              </div> -->

              <!-- <div
                class="form-group"
                *ngIf="form.controls['is_general_user'].value === '2'"
              >
                <select class="select" formControlName="user_type">
                  <option value="0" selected>
                    Select Type of Service Provider
                  </option>
                  <option value="2">Cold Storage Design Consultants</option>
                  <option value="3">Cold Storage Owners</option>
                  <option value="4">Cold Storage Erectors/ Contractors</option>
                  <option value="5">Cold Storage Equipment Suppliers</option>
                </select>
              </div> -->
              <div class="form-group">
                <input
                  type="text"
                  placeholder="Full Name"
                  class="form-control"
                  formControlName="full_name"
                />
              </div>
              <div class="form-group">
                <input
                  type="number"
                  placeholder="Phone Number"
                  class="form-control"
                  formControlName="mobile"
                />
              </div>
              <div class="form-group">
                <input
                  type="email"
                  placeholder="Email"
                  class="form-control"
                  formControlName="email"
                />
              </div>
              <div class="form-group">
                <input
                  type="password"
                  placeholder="Password"
                  class="form-control"
                  formControlName="password"
                />
              </div>
              <!-- <div class="form-group">
                  <input
                    type="password"
                    placeholder="Confirm Password"
                    class="form-control"
                  />
                </div> -->
              <button type="submit">Register Now</button>
            </form>
            <span class="already-account">
              <!-- Already have an account? <a href="#">Login Now</a> -->
              Already have an account? Login Now
              </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-backdrop fade show"></div>
</div>
<!-- End Login & Register Modal -->

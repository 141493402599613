import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as fromEquipmentSuppliers from '../../../../../store/cold-storage/equipment-suppliers';
import { EquipmentSupplierModel } from '../../../../../store/cold-storage/equipment-suppliers/models/equipment-supplier.model';

@Component({
  selector: 'app-equipment-supplier-add',
  templateUrl: './equipment-supplier-add.component.html',
})
export class EquipmentSupplierAddComponent {

  pending$ = this.store.pipe(select(fromEquipmentSuppliers.getEquipmentSupplierItemPending));
  error$ = this.store.pipe(select(fromEquipmentSuppliers.getEquipmentSupplierItemError));

  constructor(private readonly store: Store<fromEquipmentSuppliers.State>) {
  }

  onSubmit($event: any) {
    this.store.dispatch(new fromEquipmentSuppliers.AddRecord($event));
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import * as fromStates from '../../../../../../store/masters/states';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { State } from '../../../../../../store/masters/states/models/state.model';

@Component({
  selector: 'app-state-dropdown',
  templateUrl: './state-dropdown.component.html',
  styleUrls: ['./state-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StateDropdownComponent implements OnInit {
  state$: any = '0';
  statesDropdown$: Observable<any>;

  @Input('selectedState')
  set selectedState(value: any) {
    this.state$ =
      value == null || value == undefined || value == '' ? '0' : value;
  }

  @Output() selectStateDropdownItem = new EventEmitter();

  constructor(
    public cdr: ChangeDetectorRef,
    private readonly store: Store<fromStates.State>
  ) {}

  ngOnInit() {
    this.store.dispatch(new fromStates.LoadDropdownRecords());

    this.statesDropdown$ = this.store.pipe(select(fromStates.getAllStates));

    // this.statesDropdown$.subscribe((data)=>{
    //   console.log(`action.payload.data:: ${JSON.stringify(data)}`)
    // })
  }

  compareStates(p1: State, p2: State) {
    return p1.id === p2.id;
  }

  onSelect(event) {
    const selectedId = event.target.value.split(':').pop().trim();
    const selectedElement = {
      id: selectedId,
      text: event.target.options[event.target.options.selectedIndex].text,
    };
    this.selectStateDropdownItem.emit(selectedElement);
  }
}

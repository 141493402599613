import { StatesModule } from './components/pages/masters/states/states.module';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutUsComponent } from './components/pages/about-us/about-us.component';
import { AuthorProfileComponent } from './components/pages/author-profile/author-profile.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { BlogGridComponent } from './components/pages/blog-grid/blog-grid.component';
import { BlogRightSidebarComponent } from './components/pages/blog-right-sidebar/blog-right-sidebar.component';
import { CartComponent } from './components/pages/cart/cart.component';
import { CategoriesComponent } from './components/pages/categories/categories.component';
import { CheckoutComponent } from './components/pages/checkout/checkout.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { DashboardComponent } from './components/pages/dashboard/dashboard.component';
import { EventsDetailsComponent } from './components/pages/events-details/events-details.component';
import { EventsComponent } from './components/pages/events/events.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { GalleryComponent } from './components/pages/gallery/gallery.component';
import { GridListingsFullWidthComponent } from './components/pages/grid-listings-full-width/grid-listings-full-width.component';
import { GridListingsLeftSidebarComponent } from './components/pages/grid-listings-left-sidebar/grid-listings-left-sidebar.component';
import { GridListingsRightSidebarComponent } from './components/pages/grid-listings-right-sidebar/grid-listings-right-sidebar.component';
import { HomeDemoOneComponent } from './components/pages/home-demo-one/home-demo-one.component';
import { HomeDemoTwoComponent } from './components/pages/home-demo-two/home-demo-two.component';
import { HowItWorksPageComponent } from './components/pages/how-it-works-page/how-it-works-page.component';
import { ListingsDetailsComponent } from './components/pages/listings-details/listings-details.component';
import { NotFoundComponent } from './components/pages/not-found/not-found.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { ProductsDetailsComponent } from './components/pages/products-details/products-details.component';
import { ProductsListComponent } from './components/pages/products-list/products-list.component';
import { TopPlaceComponent } from './components/pages/top-place/top-place.component';
import { VerticalListingsFullWidthComponent } from './components/pages/vertical-listings-full-width/vertical-listings-full-width.component';
import { VerticalListingsLeftSidebarComponent } from './components/pages/vertical-listings-left-sidebar/vertical-listings-left-sidebar.component';
import { VerticalListingsRightSidebarComponent } from './components/pages/vertical-listings-right-sidebar/vertical-listings-right-sidebar.component';
import { AuthGuard } from './system/auth.guard';
import { ColdStorageContractorsModule } from './components/pages/cold-storage-contractors/cold-storage-contractors.module';
import { HomeModule } from './components/pages/home/home.module';
import { DashboardModule } from './components/pages/dashboard/dashboard.module';
import { DistrictsModule } from './components/pages/masters/districts/districts.module';
import { ExperienceTypesModule } from './components/pages/masters/experience-types/experience-types.module';
import { ExpertTypesModule } from './components/pages/masters/expert-types/expert-types.module';
import { TypeOfStoragesModule } from './components/pages/masters/type-of-storages/type-of-storages.module';
import { EquipmentsModule } from './components/pages/masters/equipments/equipments.module';
import { ColdStorageOwnersModule } from './components/pages/cold-storage-owners/cold-storage-owners.module';
import { ColdStorageEquipmentSuppliersModule } from './components/pages/cold-storage-equipment-suppliers/cold-storage-equipment-suppliers.module';
import { ColdStorageDesignConsultantsModule } from './components/pages/cold-storage-design-consultants/cold-storage-design-consultants.module';
import { ContactUsModule } from './components/pages/contact-us/contact-us.module';
import { ListingsModule } from './components/pages/listings/listings.module';
import { HowItWorksPageModule } from './components/pages/how-it-works-page/how-it-works-page.module';
import { FaqModule } from './components/pages/faq/faq.module';
import { RegisterYourServiceModule } from './components/pages/register-your-service/register-your-service.module';

const routes: Routes = [
  { path: '', loadChildren: () => HomeModule, data: { title: 'Home' } },
  { path: 'how-it-works', loadChildren: () => HowItWorksPageModule, data: { title: 'How it works' } },
  { path: 'faq', loadChildren: () => FaqModule, data: { title: 'FAQ' } },
  { path: 'listing', loadChildren: () => ListingsModule, data: { title: 'Listing' } },
  {
    path: 'contact-us',
    loadChildren: () => ContactUsModule,
    data: { title: 'Cold Storage: Contact Us' },
  },
  {
    path: 'contractors',
    canActivate: [AuthGuard],
    loadChildren: () => ColdStorageContractorsModule,
    data: { title: 'Cold Storage: Contractors' },
  },
  {
    path: 'owners',
    canActivate: [AuthGuard],
    loadChildren: () => ColdStorageOwnersModule,
    data: { title: 'Cold Storage: Owners' },
  },
  {
    path: 'equipment-suppliers',
    canActivate: [AuthGuard],
    loadChildren: () => ColdStorageEquipmentSuppliersModule,
    data: { title: 'Cold Storage: Equipment Suppliers' },
  },
  {
    path: 'design-consultants',
    canActivate: [AuthGuard],
    loadChildren: () => ColdStorageDesignConsultantsModule,
    data: { title: 'Cold Storage: Design Consultants' },
  },
  {
    path: 'register-your-service',
    loadChildren: () => RegisterYourServiceModule,
    data: { title: 'Cold Storage: Register your Service' },
  },
  {
    path: 'states',
    canActivate: [AuthGuard],
    loadChildren: () => StatesModule,
    data: { title: 'Masters: States' },
  },
  {
    path: 'districts',
    canActivate: [AuthGuard],
    loadChildren: () => DistrictsModule,
    data: { title: 'Masters: Districts' },
  },
  {
    path: 'experience-types',
    canActivate: [AuthGuard],
    loadChildren: () => ExperienceTypesModule,
    data: { title: 'Masters: Experience Types' },
  },
  {
    path: 'expert-types',
    canActivate: [AuthGuard],
    loadChildren: () => ExpertTypesModule,
    data: { title: 'Masters: Expert Type' },
  },
  {
    path: 'type-of-storages',
    canActivate: [AuthGuard],
    loadChildren: () => TypeOfStoragesModule,
    data: { title: 'Masters: Type Of Storage' },
  },
  {
    path: 'equipments',
    canActivate: [AuthGuard],
    loadChildren: () => EquipmentsModule,
    data: { title: 'Masters: Equipment' },
  },
  { path: 'dashboard', canActivate: [AuthGuard], loadChildren: () => DashboardModule, data: { title: 'Dashboard' } },
  { path: 'index-2', component: HomeDemoTwoComponent },
  { path: 'about', component: AboutUsComponent },
  // { path: 'how-it-works', component: HowItWorksPageComponent },
  { path: 'pricing', component: PricingComponent },
  { path: 'gallery', component: GalleryComponent },
  // { path: 'faq', component: FaqComponent },
  { path: 'coming-soon', component: ComingSoonComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'blog-grid', component: BlogGridComponent },
  { path: 'blog-right-sidebar', component: BlogRightSidebarComponent },
  { path: 'blog-details', component: BlogDetailsComponent },
  { path: 'products-list', component: ProductsListComponent },
  { path: 'cart', component: CartComponent },
  { path: 'checkout', component: CheckoutComponent },
  { path: 'single-products', component: ProductsDetailsComponent },
  { path: 'user-profile', component: AuthorProfileComponent },
  { path: 'categories', component: CategoriesComponent },
  { path: 'destinations', component: TopPlaceComponent },
  {
    path: 'vertical-listings-left-sidebar',
    component: VerticalListingsLeftSidebarComponent,
  },
  {
    path: 'vertical-listings-right-sidebar',
    component: VerticalListingsRightSidebarComponent,
  },
  {
    path: 'vertical-listings-full-width',
    component: VerticalListingsFullWidthComponent,
  },
  {
    path: 'grid-listings-left-sidebar',
    component: GridListingsLeftSidebarComponent,
  },
  {
    path: 'grid-listings-right-sidebar',
    component: GridListingsRightSidebarComponent,
  },
  {
    path: 'grid-listings-full-width',
    component: GridListingsFullWidthComponent,
  },
  { path: 'single-listings', component: ListingsDetailsComponent },
  { path: 'events', component: EventsComponent },
  { path: 'single-events', component: EventsDetailsComponent },
  // Here add new pages component

  { path: '**', component: NotFoundComponent }, // This line will remain down from the whole pages component list
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { Injectable } from '@angular/core';

import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as actions from './../actions';
import { DesignConsultantService } from '../../../../services/cold-storage/design-consultants';
import { LoadListingRecords, LoadRecords } from './../actions';

@Injectable()
export class DesignConsultantListEffects {
  @Effect()
  loadList$: Observable<Action> = this.actions$.pipe(
    ofType<LoadRecords>(actions.DesignConsultantListActionTypes.LoadRecords),
    switchMap(({ gridInfo }) =>
      this.designConsultantService.getDesignConsultantsList(gridInfo).pipe(
        map((tps) => new actions.LoadRecordsSuccess(tps)),
        catchError((error) =>
          observableOf(new actions.LoadRecordsFailure(error))
        )
      )
    )
  );

  @Effect()
  loadListings$: Observable<Action> = this.actions$.pipe(
    ofType<LoadListingRecords>(actions.DesignConsultantListActionTypes.LoadListingRecords),
    switchMap(({ gridInfo }) =>
      this.designConsultantService.getDesignConsultantsListings(gridInfo).pipe(
        map((tps) => new actions.LoadListingRecordsSuccess(tps)),
        catchError((error) =>
          observableOf(new actions.LoadListingRecordsFailure(error))
        )
      )
    )
  );
  constructor(
    private actions$: Actions,
    private designConsultantService: DesignConsultantService
  ) {}
}

import { Action } from '@ngrx/store';

export const LOAD_OVERALL_STATS_REPORT_RECORDS =
  '[Overall Statistics Report] Load';
export const LOAD_OVERALL_STATS_REPORT_RECORDS_SUCCESS =
  '[Overall Statistics Report] Load Success';
export const LOAD_OVERALL_STATS_REPORT_RECORDS_FAILURE =
  '[Overall Statistics Report] Load Failure';

export const EMPTY_ACTION = '[Reports] Empty Action';

export class LoadOverallStatisticsReportRecords implements Action {
  readonly type = LOAD_OVERALL_STATS_REPORT_RECORDS;

  constructor() {}
}

export class LoadOverallStatisticsReportRecordsSuccess implements Action {
  readonly type = LOAD_OVERALL_STATS_REPORT_RECORDS_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadOverallStatisticsReportRecordsFailure implements Action {
  readonly type = LOAD_OVERALL_STATS_REPORT_RECORDS_FAILURE;

  constructor() {}
}

export class EmptyAction implements Action {
  readonly type = EMPTY_ACTION;
  constructor() {}
}
////////////

export type Actions =
  | EmptyAction
  | LoadOverallStatisticsReportRecords
  | LoadOverallStatisticsReportRecordsSuccess
  | LoadOverallStatisticsReportRecordsFailure;

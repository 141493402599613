import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeRoutingModule } from './home-routing.module';
import { HomeBannerComponent } from './home-banner/home-banner.component';
import { HomeEventsComponent } from './home-events/home-events.component';
import { HeaderModule } from '../../header/header.module';
import { NgxTypedJsModule } from 'ngx-typed-js';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { HomeComponent } from './home.component';
import { FooterModule } from '../../footer/footer.module';

@NgModule({
  declarations: [
    HomeBannerComponent,
    HomeEventsComponent,
    HomeComponent
  ],
  imports: [
    CommonModule,
    HomeRoutingModule,
    HeaderModule,
    FooterModule,
    NgxTypedJsModule,
    SelectDropDownModule
  ]
})
export class HomeModule { }

import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as fromExperienceType from '../../../../../../store/masters/experience-types';
import { ExperienceType } from '../../../../../../store/masters/experience-types/models/experience-type.model';

@Component({
  selector: 'app-experience-type-add',
  templateUrl: './experience-type-add.component.html'
})
export class ExperienceTypeAddComponent {
  pending$ = this.store.pipe(select(fromExperienceType.getExperienceTypeItemPending));
  error$ = this.store.pipe(select(fromExperienceType.getExperienceTypeItemError));

  constructor(private readonly store: Store<fromExperienceType.State>) {}

  onSubmit($event: ExperienceType) {
    this.store.dispatch(new fromExperienceType.AddRecord($event));
  }
}

import * as ReportsActions from './actions';

export interface State {
  overallStats: any;
  loading: boolean;
}

export const initialState: State = {
  overallStats: [],
  loading: false,
};

export function reducer(
  state = initialState,
  action: ReportsActions.Actions
): State {
  switch (action.type) {
    case ReportsActions.LOAD_OVERALL_STATS_REPORT_RECORDS:
      return {
        ...state,
        loading: true,
      };
    case ReportsActions.LOAD_OVERALL_STATS_REPORT_RECORDS_SUCCESS:
      return {
        ...state,
        overallStats: action.payload,
        loading: false,
      };
    case ReportsActions.LOAD_OVERALL_STATS_REPORT_RECORDS_FAILURE:
    case ReportsActions.EMPTY_ACTION:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}

export const getReportData = (state: any) => state;

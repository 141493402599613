import { Action } from '@ngrx/store';
import { TypeOfStorage } from '../models/type-of-storage.model';


export enum TypeOfStorageItemActionTypes {
  AddRecord = '[TypeOfStorages] Add Record',
  AddRecordSuccess = '[TypeOfStorages] Add Record Success',
  AddRecordFailure = '[TypeOfStorages] Add Record Failure',

  UpdateRecord = '[TypeOfStorages] Update Record',
  UpdateRecordSuccess = '[TypeOfStorages] Update Record Success',
  UpdateRecordFailure = '[TypeOfStorages] Update Record Failure',
}

////////////

export class AddRecord implements Action {
  readonly type = TypeOfStorageItemActionTypes.AddRecord;

  constructor(public payload: TypeOfStorage) {
  }
}

export class AddRecordSuccess implements Action {
  readonly type = TypeOfStorageItemActionTypes.AddRecordSuccess;

  constructor(public payload: TypeOfStorage) {
  }
}

export class AddRecordFailure implements Action {
  readonly type = TypeOfStorageItemActionTypes.AddRecordFailure;

  constructor(public payload: string) {
  }
}

export class UpdateRecord implements Action {
  readonly type = TypeOfStorageItemActionTypes.UpdateRecord;

  constructor(public payload: TypeOfStorage) {
  }
}

export class UpdateRecordSuccess implements Action {
  readonly type = TypeOfStorageItemActionTypes.UpdateRecordSuccess;

  constructor(public payload: TypeOfStorage) {
  }
}

export class UpdateRecordFailure implements Action {
  readonly type = TypeOfStorageItemActionTypes.UpdateRecordFailure;

  constructor(public payload: string) {
  }
}

////////////

export type ItemActions =
  | AddRecord
  | AddRecordSuccess
  | AddRecordFailure
  | UpdateRecord
  | UpdateRecordSuccess
  | UpdateRecordFailure;

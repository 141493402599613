import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import * as fromExpertTypes from '../../../../../../store/masters/expert-types';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ExpertType } from '../../../../../../store/masters/expert-types/models/expert-type.model';

@Component({
  selector: 'app-expert-type-dropdown',
  templateUrl: './expert-type-dropdown.component.html',
  styleUrl: './expert-type-dropdown.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpertTypeDropdownComponent implements OnInit {
  expertType$: any = '0';
  expertTypesDropdown$: Observable<any>;

  @Input('selectedExpertType')
  set selectedExpertType(value: any) {
    this.expertType$ =
      value == null || value == undefined || value == '' ? '0' : value;
  }

  @Output() selectExpertTypeDropdownItem = new EventEmitter();

  constructor(
    public cdr: ChangeDetectorRef,
    private readonly store: Store<fromExpertTypes.State>
  ) {}

  ngOnInit() {
    this.store.dispatch(new fromExpertTypes.LoadDropdownRecords());

    this.expertTypesDropdown$ = this.store.pipe(
      select(fromExpertTypes.getAllExpertTypes)
    );

    // this.expertTypesDropdown$.subscribe((data)=>{
    //   console.log(`action.payload.data:: ${JSON.stringify(data)}`)
    // })
  }

  compareExpertTypes(p1: ExpertType, p2: ExpertType) {
    return p1.id === p2.id;
  }

  onSelect(event) {
    const selectedId = event.target.value.split(':').pop().trim();
    const selectedElement = {
      id: selectedId,
      text: event.target.options[event.target.options.selectedIndex].text,
    };
    this.selectExpertTypeDropdownItem.emit(selectedElement);
  }
}

import { GlobalState } from '../../../store';
import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';

import * as fromList from './reducers/list.reducer';
import * as fromItem from './reducers/item.reducer';

export interface EquipmentSupplierState {
  list: fromList.EquipmentSupplierListState;
  item: fromItem.EquipmentSupplierItemState;
}

export interface State extends GlobalState {
  equipment_suppliers: EquipmentSupplierState;
}

export const COLD_STORAGE_EQUIPMENT_SUPPLIER_REDUCERS: ActionReducerMap<
  EquipmentSupplierState,
  any
> = {
  list: fromList.equipmentSupplierListReducer,
  item: fromItem.equipmentSupplierItemReducer,
};

////// selectors

export const getProjectsState = createFeatureSelector<State, EquipmentSupplierState>(
  'equipment_suppliers'
);

export const getEquipmentSupplierListState = createSelector(
  getProjectsState,
  (state) => state.list
);
export const getEquipmentSupplierItemState = createSelector(
  getProjectsState,
  (state) => state.item
);

export const getEquipmentSuppliersIsLoading = createSelector(
  getEquipmentSupplierListState,
  fromList.getIsLoading
);

export const getEquipmentSuppliersCount = createSelector(
  getEquipmentSupplierListState,
  fromList.getCount
);
export const getEquipmentSuppliersOffset = createSelector(
  getEquipmentSupplierListState,
  fromList.getOffset
);

export const getEquipmentSuppliersSortProp = createSelector(
  getEquipmentSupplierListState,
  fromList.getSortProp
);
export const getEquipmentSuppliersSortDir = createSelector(
  getEquipmentSupplierListState,
  fromList.getSortDir
);
export const getEquipmentSuppliersSearch = createSelector(
  getEquipmentSupplierListState,
  fromList.getSearch
);

export const getSelectedEquipmentSupplierId = createSelector(
  getEquipmentSupplierListState,
  fromList.getSelectedId
);

export const {
  selectEntities: getEquipmentSupplierEntities,
  selectAll: getAllEquipmentSuppliers,
} = fromList.adapter.getSelectors(getEquipmentSupplierListState);

export const getSelectedEquipmentSupplier = createSelector(
  getEquipmentSupplierEntities,
  getSelectedEquipmentSupplierId,
  (entities, selectedId) => {
    return selectedId !== null && entities[selectedId];
  }
);

// Item

export const getEquipmentSupplierItemPending = createSelector(
  getEquipmentSupplierItemState,
  fromItem.getPending
);
export const getEquipmentSupplierItemError = createSelector(
  getEquipmentSupplierItemState,
  fromItem.getError
);

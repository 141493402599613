import { NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EquipmentSuppliersRoutingModule } from './equipment-suppliers-routing.module';

import { HeaderModule } from '../../../../components/header/header.module';
import { FooterModule } from '../../../../components/footer/footer.module';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { COLD_STORAGE_EQUIPMENT_SUPPLIER_EFFECTS } from '../../../../store/cold-storage/equipment-suppliers/effects';
import { COLD_STORAGE_EQUIPMENT_SUPPLIER_REDUCERS } from '../../../../store/cold-storage/equipment-suppliers/equipment-supplier.state';
import { EquipmentsModule } from '../../masters/equipments/equipments.module';
import { SearchEquipmentSuppliersComponent } from './search-equipment-suppliers/search-equipment-suppliers.component';


@NgModule({
  declarations: [
    SearchEquipmentSuppliersComponent
  ],
  imports: [
    CommonModule,
    EquipmentSuppliersRoutingModule,
    
    HeaderModule,
    FooterModule,
    SelectDropDownModule,
    FormsModule,
    ReactiveFormsModule,

    EquipmentsModule,
    
    StoreModule.forFeature('equipment_suppliers', COLD_STORAGE_EQUIPMENT_SUPPLIER_REDUCERS),
    EffectsModule.forFeature(COLD_STORAGE_EQUIPMENT_SUPPLIER_EFFECTS),
  ],
  schemas:[NO_ERRORS_SCHEMA]
})
export class EquipmentSuppliersModule { }

import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as fromState from '../../../../../../store/masters/states';
import { State } from '../../../../../../store/masters/states/models/state.model';

@Component({
  selector: 'app-state-update',
  templateUrl: './state-update.component.html',
})
export class StateUpdateComponent {
  state$ = this.store.pipe(select(fromState.getSelectedState));
  stateId$ = this.store.pipe(select(fromState.getSelectedStateId));

  pending$ = this.store.pipe(select(fromState.getStateItemPending));
  error$ = this.store.pipe(select(fromState.getStateItemError));

  constructor(private readonly store: Store<fromState.State>) {}

  onSubmit($event: State) {
    this.store.dispatch(new fromState.UpdateRecord($event));
  }
}

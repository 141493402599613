import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  Optional,
} from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { ContractorModel } from '../../../../../store/cold-storage/contractors/models/contractor.model';

import * as fromContractor from '../../../../../store/cold-storage/contractors';
import { ContractorItemActionTypes } from '../../../../../store/cold-storage/contractors';
import { select, Store } from '@ngrx/store';
import { GridPayload } from '../../../../../shared/models/grid.payload';
// import { MatDialog } from "@angular/material/dialog";

import { Actions, ofType } from '@ngrx/effects';
import { takeUntil, tap } from 'rxjs/operators';
import lstore from 'store';

@Component({
  selector: 'app-search-contractors',
  templateUrl: './search-contractors.component.html',
  styleUrl: './search-contractors.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchContractorsComponent implements OnInit, OnDestroy {
  contractors$: Observable<any>;
  contractorsLoading$ = this.store.pipe(
    select(fromContractor.getContractorsIsLoading)
  );

  contractorsCount$: Observable<number> = this.store.pipe(
    select(fromContractor.getContractorsCount)
  );
  contractorsOffset$: Observable<number> = this.store.pipe(
    select(fromContractor.getContractorsOffset)
  );
  contractorsSearch$: Observable<string> = this.store.pipe(
    select(fromContractor.getContractorsSearch)
  );
  contractorsSortProp$: Observable<string> = this.store.pipe(
    select(fromContractor.getContractorsSortProp)
  );
  contractorsSortDir$: Observable<string> = this.store.pipe(
    select(fromContractor.getContractorsSortDir)
  );

  private destroyed$ = new Subject<boolean>();

  searchBy: any = '';

  constructor(private readonly store: Store<fromContractor.State>) {}

  ngOnInit(): void {
    this.loadContractors();
    this.contractors$ = this.store.pipe(
      select(fromContractor.getAllContractors)
    );
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  loadContractors() {
    const info: GridPayload = {
      offset: 0,
      sortProp: 'organisation_name',
      sortDir: 'asc',
      search:
        this.searchBy == '' ||
        this.searchBy == undefined ||
        this.searchBy == null
          ? 'all'
          : this.searchBy,
    };
    this.store.dispatch(new fromContractor.LoadListingRecords(info));
  }
  pageTitleContent = [
    {
      title: 'Find Popular Cold Storage Contractors',
    },
  ];

  // Category Select
  singleSelect: any = [];
  multiSelect: any = [];
  stringArray: any = [];
  objectsArray: any = [];
  resetOption: any;
  config: any = {
    displayKey: 'name',
    search: true,
  };
  searchChange($event) {
    console.log($event);
  }
  reset() {
    this.resetOption = [];
  }

  // Ordering Select
  options2 = [
    {
      name: 'Recommended',
    },
    {
      name: 'Default',
    },
    {
      name: 'Popularity',
    },
    {
      name: 'Latest',
    },
  ];

  rating = [
    {
      icon: 'bx bxs-star',
    },
    {
      icon: 'bx bxs-star',
    },
    {
      icon: 'bx bxs-star',
    },
    {
      icon: 'bx bxs-star',
    },
    {
      icon: 'bx bxs-star',
    },
  ];
  selectedExperienceInNames: any = '';
  selectedExperienceInIds: any = [];
  selectedExperienceTypes: any = [];

  onExperienceTypeFilterListItemSelect(selectedData: any) {
    // console.log(`Unique selected value ${selectedData.ids.length}`);
    // console.log(`Unique selected value ${JSON.stringify(selectedData.text)}`);
    // this.selectedExperienceInIds = [];
    // this.selectedExperienceInNames = selectedData.text;
    // this.selectedExperienceInIds = selectedData.ids;

    this.searchBy = selectedData;
    this.loadContractors();
  }

  
}

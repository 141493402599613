import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { District } from '../../../../../../store/masters/districts/models/district.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-district-form',
  templateUrl: './district-form.component.html',
  styleUrls: ['./district-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DistrictFormComponent {
  @Input() districtId: string | null = null;

  lastUpdatedOn: Date = null;
  @Input()
  set district(districtData: District | undefined) {
    if (districtData) {
      this.form.patchValue(districtData);
      this.selectedStateId = districtData.state_id;
      this.selectedStateName = districtData.state_name;
      this.lastUpdatedOn = districtData.updated_on;
    }
  }

  @Input()
  set pending(isPending: boolean) {
    this.isPending = isPending;

    if (isPending) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  @Input() error: string | null;
  @Output() submitted = new EventEmitter<District>();

  isPending: boolean;
  mask = [
    /[1-9]/,
    ' ',
    '(',
    /[1-9]/,
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    ' ',
    '-',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];

  form: FormGroup = new FormGroup({
    id: new FormControl(''),
    name: new FormControl(
      '',
      Validators.compose([Validators.required, Validators.maxLength(500)])
    ),
    abbr: new FormControl(
      '',
      Validators.compose([Validators.required, Validators.maxLength(10)])
    ),
    status: new FormControl(1, Validators.required),
  });

  selectedStateId: any = '0';
  selectedStateName: any = 'NA';
  stateFieldErrorMsg: boolean = false;

  constructor(public activeModal: NgbActiveModal) {}

  onStateDropdownItemSelect(selectedElement: any) {
    this.selectedStateId = selectedElement.id;
    this.selectedStateName = selectedElement.text;
  }

  submit() {
    if (
      this.selectedStateId === null ||
      this.selectedStateId === 0 ||
      this.selectedStateId === '0'
    ) {
      this.stateFieldErrorMsg = false;
    } else {
      this.stateFieldErrorMsg = true;
    }

    if (this.form.valid && this.stateFieldErrorMsg) {
      const model: District = this.form.value;

      if (
        this.selectedStateId != null &&
        (this.selectedStateId != 0 || this.selectedStateId != '0')
      ) {
        model.state_id = this.selectedStateId;
        model.state_name = this.selectedStateName;
      }

      this.submitted.emit(model);
    }
  }
}

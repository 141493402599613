import { GlobalState } from '../../../store';
import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromList from './reducers/list.reducer';
import * as fromItem from './reducers/item.reducer';


export interface ExpertTypeState {
  list: fromList.ExpertTypeListState;
  item: fromItem.ExpertTypeItemState;
}

export interface State extends GlobalState {
  expert_types: ExpertTypeState;
}

export const EXPERT_TYPE_REDUCERS: ActionReducerMap<ExpertTypeState, any> = {
  list: fromList.expertTypeListReducer,
  item: fromItem.expertTypeItemReducer
};


////// selectors

export const getProjectsState = createFeatureSelector<State, ExpertTypeState>('expert_types');


export const getExpertTypeListState = createSelector(getProjectsState, state => state.list);
export const getExpertTypeItemState = createSelector(getProjectsState, state => state.item);


export const getExpertTypesIsLoading = createSelector(getExpertTypeListState, fromList.getIsLoading);

export const getExpertTypesCount = createSelector(getExpertTypeListState, fromList.getCount);
export const getExpertTypesOffset = createSelector(getExpertTypeListState, fromList.getOffset);

export const getExpertTypesSortProp = createSelector(getExpertTypeListState, fromList.getSortProp);
export const getExpertTypesSortDir = createSelector(getExpertTypeListState, fromList.getSortDir);
export const getExpertTypesSearch = createSelector(getExpertTypeListState, fromList.getSearch);

export const getSelectedExpertTypeId = createSelector(getExpertTypeListState, fromList.getSelectedId);


export const {
  selectEntities: getExpertTypeEntities,
  selectAll: getAllExpertTypes
} = fromList.adapter.getSelectors(getExpertTypeListState);


export const getSelectedExpertType = createSelector(
  getExpertTypeEntities,
  getSelectedExpertTypeId,
  (entities, selectedId) => {
    return selectedId !== null && entities[selectedId];
  }
);


// Item

export const getExpertTypeItemPending = createSelector(getExpertTypeItemState, fromItem.getPending);
export const getExpertTypeItemError = createSelector(getExpertTypeItemState, fromItem.getError);



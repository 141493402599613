import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnInit,
} from '@angular/core';
import { Equipment } from '../../../../../../store/masters/equipments/models/equipment.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-equipment-details',
  templateUrl: './equipment-details.component.html',
  styleUrl: './equipment-details.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EquipmentDetailsComponent implements OnInit {
  equipment: Equipment;

  @Input('selectedEquipment')
  set selectedEquipment(data: any) {
    this.equipment = data;
  }
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import * as fromExperienceTypes from '../../../../../../store/masters/experience-types';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ExperienceType } from '../../../../../../store/masters/experience-types/models/experience-type.model';

@Component({
  selector: 'app-experience-type-multi-select-dropdown',
  templateUrl: './experience-type-multi-select-dropdown.component.html',
  styleUrl: './experience-type-multi-select-dropdown.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExperienceTypeMultiSelectDropdownComponent implements OnInit {
  selectedExperienceTypes$: any = [];
  experienceTypesDropdown$: Observable<any>;

  @Input('selectedExperienceType')
  set selectedExperienceType(value: any) {
    this.selectedExperienceTypes$ =
      value == null || value == undefined || value == '' ? [] : [...value];

    if (value !== null && value !== undefined && value !== '') {
      value.forEach((element) => {
        this.selectedExperienceInIds.push(element.id);
        this.selectedExperienceInNames.push(element.name);
      });
      const selectedElement = {
        ids: this.selectedExperienceInIds,
        text: this.selectedExperienceInNames,
      };
  
      this.selectExperienceTypeDropdownItem.emit(selectedElement);
    }
  }

  @Output() selectExperienceTypeDropdownItem = new EventEmitter();

  selectedExperienceInIds: any = [];
  selectedExperienceInNames: any = [];
  config: any = {
    displayKey: 'name',
    search: true,
  };

  constructor(
    public cdr: ChangeDetectorRef,
    private readonly store: Store<fromExperienceTypes.State>
  ) {}

  ngOnInit() {
    this.store.dispatch(new fromExperienceTypes.LoadDropdownRecords());

    this.experienceTypesDropdown$ = this.store.pipe(
      select(fromExperienceTypes.getAllExperienceTypes)
    );

    // this.experienceTypesDropdown$.subscribe((data)=>{
    //   console.log(`action.payload.data:: ${JSON.stringify(data)}`)
    // })
  }

  searchChange($event) {
    console.log($event);
  }

  selectionChanged($event) {
    // console.log($event);
    this.selectedExperienceInIds = [];
    this.selectedExperienceInNames = [];

    for (const value of $event.value) {
      this.selectedExperienceInIds.push(value.id);
      this.selectedExperienceInNames.push(value.name);
    }
    // console.log(
    //   `Unique selected value (IDs) ${JSON.stringify(this.selectedExperienceInIds)}`
    // );
    // console.log(
    //   `Unique selected value (Names) ${JSON.stringify(this.selectedExperienceInNames)}`
    // );
    const selectedElement = {
      ids: this.selectedExperienceInIds,
      text: this.selectedExperienceInNames,
    };

    this.selectExperienceTypeDropdownItem.emit(selectedElement);
  }

  compareExperienceTypes(p1: ExperienceType, p2: ExperienceType) {
    return p1.id === p2.id;
  }

  onSelect(event) {
    // const selectedId = event.target.value.split(':').pop().trim();
    // const selectedElement = {
    //   id: selectedId,
    //   text: event.target.options[event.target.options.selectedIndex].text,
    // };
    // this.selectExperienceTypeDropdownItem.emit(selectedElement);
  }
}

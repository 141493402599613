import { Injectable } from '@angular/core';

import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';

import { Observable, of as observableOf } from 'rxjs';
import {
  catchError,
  exhaustMap,
  map,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import * as actions from './../actions';
import {
  AddRecord,
  DesignConsultantItemActionTypes,
  UpdateRecord,
} from './../actions';
import * as fromDesignConsultant from './../design-consultant.state';

import { DesignConsultantModel } from '../models/design-consultant.model';
import { DesignConsultantService } from '../../../../services/cold-storage/design-consultants';
import { GridPayload } from '../../../../shared/models/grid.payload';

@Injectable()
export class DesignConsultantItemEffects {
  @Effect()
  addRecord$: Observable<Action> = this.actions$.pipe(
    ofType<AddRecord>(DesignConsultantItemActionTypes.AddRecord),
    map((action) => action.payload),
    exhaustMap((tp: DesignConsultantModel) =>
      this.designConsultantService.saveDesignConsultantData(tp).pipe(
        map(
          (payload: DesignConsultantModel) => new actions.AddRecordSuccess(payload)
        ),
        catchError((e) =>
          observableOf(new actions.AddRecordFailure(e.error.error))
        )
      )
    )
  );

  @Effect()
  updateRecord$: Observable<Action> = this.actions$.pipe(
    ofType<UpdateRecord>(DesignConsultantItemActionTypes.UpdateRecord),
    map((action) => action.payload),
    exhaustMap((tp: DesignConsultantModel) =>
      this.designConsultantService.saveDesignConsultantData(tp).pipe(
        map(
          (payload: DesignConsultantModel) => new actions.UpdateRecordSuccess(payload)
        ),
        catchError((e) =>
          observableOf(new actions.UpdateRecordFailure(e.error.error))
        )
      )
    )
  );

  @Effect()
  updateStatus$: Observable<Action> = this.actions$.pipe(
    ofType<actions.UpdateStatusRecord>(
      DesignConsultantItemActionTypes.UpdateStatusRecord
    ),
    map((action) => action.payload),
    exhaustMap((record: any) =>
      this.designConsultantService.updateStatus(record).pipe(
        map(
          (payload: DesignConsultantModel) =>
            new actions.UpdateStatusRecordSuccess(payload)
        ),
        catchError((e) =>
          observableOf(new actions.UpdateStatusRecordFailure(e.error.error))
        )
      )
    )
  );

  @Effect()
  updateApprovalStatus$: Observable<Action> = this.actions$.pipe(
    ofType<actions.UpdateApprovalStatusRecord>(
      DesignConsultantItemActionTypes.UpdateApprovalStatusRecord
    ),
    map((action) => action.payload),
    exhaustMap((record: any) =>
      this.designConsultantService.updateApprovalStatus(record).pipe(
        map(
          (payload: DesignConsultantModel) =>
            new actions.UpdateApprovalStatusRecordSuccess(payload)
        ),
        catchError((e) =>
          observableOf(new actions.UpdateApprovalStatusRecordFailure(e.error.error))
        )
      )
    )
  );

  @Effect({ dispatch: false })
  reloadGrid$: Observable<any> = this.actions$.pipe(
    ofType(DesignConsultantItemActionTypes.AddRecordSuccess),
    withLatestFrom(
      this.store.pipe(select(fromDesignConsultant.getDesignConsultantsOffset)),
      this.store.pipe(select(fromDesignConsultant.getDesignConsultantsSortProp)),
      this.store.pipe(select(fromDesignConsultant.getDesignConsultantsSortDir)),
      this.store.pipe(select(fromDesignConsultant.getDesignConsultantsSearch)),
      (action, offset, sortProp, sortDir, search) => ({
        offset,
        sortProp,
        sortDir,
        search,
      })
    ),
    tap((info: GridPayload) => {
      this.store.dispatch(new actions.LoadRecords(info));
    })
  );

  @Effect({ dispatch: false })
  closeModal$: Observable<any> = this.actions$.pipe(
    ofType(
      DesignConsultantItemActionTypes.AddRecordSuccess,
      DesignConsultantItemActionTypes.UpdateRecordSuccess,
      DesignConsultantItemActionTypes.UpdateStatusRecordSuccess,
      DesignConsultantItemActionTypes.UpdateApprovalStatusRecordSuccess
    ),
    withLatestFrom(
      this.store.pipe(select(fromDesignConsultant.getDesignConsultantsOffset)),
      this.store.pipe(select(fromDesignConsultant.getDesignConsultantsSortProp)),
      this.store.pipe(select(fromDesignConsultant.getDesignConsultantsSortDir)),
      this.store.pipe(select(fromDesignConsultant.getDesignConsultantsSearch)),
      (action, offset, sortProp, sortDir, search) => ({
        offset,
        sortProp,
        sortDir,
        search,
      })
    ),
    tap((info: GridPayload) => {
      this.store.dispatch(new actions.LoadRecords(info));
    })
  );

  constructor(
    private store: Store<fromDesignConsultant.State>,
    private actions$: Actions,
    private designConsultantService: DesignConsultantService
  ) {}
}

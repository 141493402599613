import { Action } from '@ngrx/store';
import { DesignConsultantModel } from '../models/design-consultant.model';

export enum DesignConsultantItemActionTypes {
  AddRecord = '[Cold Storage Design Consultants] Add Record',
  AddRecordSuccess = '[Cold Storage Design Consultants] Add Record Success',
  AddRecordFailure = '[Cold Storage Design Consultants] Add Record Failure',

  UpdateRecord = '[Cold Storage Design Consultants] Update Record',
  UpdateRecordSuccess = '[Cold Storage Design Consultants] Update Record Success',
  UpdateRecordFailure = '[Cold Storage Design Consultants] Update Record Failure',

  UpdateStatusRecord = '[Cold Storage Design Consultants] Update Status Record',
  UpdateStatusRecordSuccess = '[Cold Storage Design Consultants] Update Status Record Success',
  UpdateStatusRecordFailure = '[Cold Storage Design Consultants] Update Status Record Failure',

  UpdateApprovalStatusRecord = '[Cold Storage Design Consultants] Update Approval Status Record',
  UpdateApprovalStatusRecordSuccess = '[Cold Storage Design Consultants] Update Approval Status Record Success',
  UpdateApprovalStatusRecordFailure = '[Cold Storage Design Consultants] Update Approval Status Record Failure',
}

////////////

export class AddRecord implements Action {
  readonly type = DesignConsultantItemActionTypes.AddRecord;

  constructor(public payload: DesignConsultantModel) {}
}

export class AddRecordSuccess implements Action {
  readonly type = DesignConsultantItemActionTypes.AddRecordSuccess;

  constructor(public payload: DesignConsultantModel) {}
}

export class AddRecordFailure implements Action {
  readonly type = DesignConsultantItemActionTypes.AddRecordFailure;

  constructor(public payload: string) {}
}

export class UpdateRecord implements Action {
  readonly type = DesignConsultantItemActionTypes.UpdateRecord;

  constructor(public payload: DesignConsultantModel) {}
}

export class UpdateRecordSuccess implements Action {
  readonly type = DesignConsultantItemActionTypes.UpdateRecordSuccess;

  constructor(public payload: DesignConsultantModel) {}
}

export class UpdateRecordFailure implements Action {
  readonly type = DesignConsultantItemActionTypes.UpdateRecordFailure;

  constructor(public payload: string) {}
}

export class UpdateStatusRecord implements Action {
  readonly type = DesignConsultantItemActionTypes.UpdateStatusRecord;

  constructor(public payload: any) {}
}

export class UpdateStatusRecordSuccess implements Action {
  readonly type = DesignConsultantItemActionTypes.UpdateStatusRecordSuccess;

  constructor(public payload: DesignConsultantModel) {}
}

export class UpdateStatusRecordFailure implements Action {
  readonly type = DesignConsultantItemActionTypes.UpdateStatusRecordFailure;

  constructor(public payload: string) {}
}

export class UpdateApprovalStatusRecord implements Action {
  readonly type = DesignConsultantItemActionTypes.UpdateApprovalStatusRecord;

  constructor(public payload: any) {}
}

export class UpdateApprovalStatusRecordSuccess implements Action {
  readonly type = DesignConsultantItemActionTypes.UpdateApprovalStatusRecordSuccess;

  constructor(public payload: DesignConsultantModel) {}
}

export class UpdateApprovalStatusRecordFailure implements Action {
  readonly type = DesignConsultantItemActionTypes.UpdateApprovalStatusRecordFailure;

  constructor(public payload: string) {}
}
////////////

export type ItemActions =
  | AddRecord
  | AddRecordSuccess
  | AddRecordFailure
  | UpdateRecord
  | UpdateRecordSuccess
  | UpdateRecordFailure
  | UpdateStatusRecord
  | UpdateStatusRecordSuccess
  | UpdateStatusRecordFailure
  | UpdateApprovalStatusRecord
  | UpdateApprovalStatusRecordSuccess
  | UpdateApprovalStatusRecordFailure;

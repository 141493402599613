import { ExperienceTypeActionTypes, ExperienceTypeItemActionTypes } from '../actions';

export interface ExperienceTypeItemState {
    error: string | null;
    pending: boolean;
}

export const initialState: ExperienceTypeItemState = {
    error: null,
    pending: false,
};

export function experienceTypeItemReducer(state = initialState, action: ExperienceTypeActionTypes): ExperienceTypeItemState {
    switch (action.type) {
        case ExperienceTypeItemActionTypes.AddRecord:
        case ExperienceTypeItemActionTypes.UpdateRecord: {
            return {
                ...state,
                error: null,
                pending: true
            };
        }

        case ExperienceTypeItemActionTypes.AddRecordSuccess:
        case ExperienceTypeItemActionTypes.UpdateRecordSuccess: {
            return {
                ...state,
                error: null,
                pending: false
            };
        }

        case ExperienceTypeItemActionTypes.AddRecordFailure:
        case ExperienceTypeItemActionTypes.UpdateRecordFailure: {
            return {
                ...state,
                error: action.payload,
                pending: false
            };
        }

        default:
            return state;
    }
}

export const getError = (state: ExperienceTypeItemState) => state.error;
export const getPending = (state: ExperienceTypeItemState) => state.pending;

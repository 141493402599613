import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { District } from '../../../../../../store/masters/districts/models/district.model';

import * as fromDistrict from '../../../../../../store/masters/districts';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-district-dropdown',
  templateUrl: './district-dropdown.component.html',
  styleUrls: ['./district-dropdown.component.scss'],
})
export class DistrictDropdownComponent implements OnInit {
  district$: any = '0';
  districtsDropdown$: Observable<any>;

  @Input('selectedState')
  set selectedState(data: string) {
    if (data) {
      data == null || data == undefined ? '0' : data;
      this.store.dispatch(new fromDistrict.LoadDropdownRecords(data));
    }
  }

  @Input('selectedDistrict')
  set selectedDistrict(value: any) {
    this.district$ =
      value == null || value == undefined || value == '' ? '0' : value;
  }

  @Output() selectDistrictDropdownItem = new EventEmitter();

  constructor(
    public cdr: ChangeDetectorRef,
    private readonly store: Store<fromDistrict.State>
  ) {}

  ngOnInit() {
    // this.store.dispatch(new fromDistrict.LoadDropdownRecords());
    this.districtsDropdown$ = this.store.pipe(
      select(fromDistrict.getAllDistricts)
    );
  }

  compareBatchTypes(p1: District, p2: District) {
    return p1.id === p2.id;
  }

  onSelect(event) {
    const selectedId = event.target.value.split(':').pop().trim();
    const selectedElement = {
      id: selectedId,
      text: event.target.options[event.target.options.selectedIndex].text,
    };
    this.selectDistrictDropdownItem.emit(selectedElement);
  }
}

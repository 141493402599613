import { Component, OnInit } from '@angular/core';
import { OverallStatisticsReport } from '../../../../store/reports/models/overall-statistics-report.model';

import { select, Store } from '@ngrx/store';
import * as Reducers from '../../../../store/reducers';
import * as ReportsActions from '../../../../store/reports/actions';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss'],
})
export class StatsComponent implements OnInit {
  overallStats: Array<OverallStatisticsReport> = [];
  overallStatsData: Array<OverallStatisticsReport> = [];
  activeListingsCount: any = 0;
  pendingListingsCount: any = 0;
  totalViewsCount: any = 0;

  constructor(private store: Store<any>) {
    this.store.dispatch(
      new ReportsActions.LoadOverallStatisticsReportRecords()
    );
    this.store.pipe(select(Reducers.getReports)).subscribe((state) => {
      console.log(`Inside get reports data...`);
      if (
        state.overallStats !== 'undefined' &&
        state.overallStats !== undefined
      ) {
        this.overallStatsData = state.overallStats;
        console.log(`overallStatsData ${JSON.stringify(this.overallStatsData)}`);
        this.overallStatsData?.forEach((item) => {
          this.activeListingsCount = item.total_active_listings;
          this.pendingListingsCount = item.total_pending_listings;
          this.totalViewsCount = item.total_views;
        });
      }
    });
  }

  ngOnInit(): void {}
}

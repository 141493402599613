import { Injectable } from "@angular/core";
import { BaseService } from "../../../base/base.service";
import { map } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
import {
  District,
  DistrictApiModel,
} from "../../../store/masters/districts/models/district.model";
import { GridPayload } from '../../../shared/models/grid.payload';
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class DistrictService extends BaseService {
  apiUrl: any = environment.api_url;

  getDropdownList(id:string): Observable<any> {
    return this.http.get(`${this.apiUrl}/api/masters/districts/dropdown/records/${id}`)
      .pipe(
        map((response: DistrictApiModel[]) => ({
          data: response.map(record => new District(record))
        }))
      );
  }

  getDropdownListByUser(): Observable<any> {
    return this.http.get(`${this.apiUrl}/api/masters/districts/dropdown/records/by/user`)
      .pipe(
        map((response: DistrictApiModel[]) => ({
          data: response.map(record => new District(record))
        }))
      );
  }

  getDistrictList(gridInfo: GridPayload) {
    return this.gridRequest<DistrictApiModel>(`${this.apiUrl}/api/masters/districts/records`, gridInfo.offset, gridInfo.sortProp, gridInfo.sortDir, gridInfo.search)
      .pipe(
        map(response => ({
          ...response,
          data: response.data.map(record => new District(record))
        }))
      );
  }

  saveDistrict(payload: District): Observable<District> {
    return this.http.post<District>(`${this.apiUrl}/api/masters/districts/save`, payload);
  }
}

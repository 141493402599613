import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ExpertTypePageComponent } from './containers/expert-type-page/expert-type-page.component';

const routes: Routes = [
  {path: '', component: ExpertTypePageComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ExpertTypesRoutingModule { }

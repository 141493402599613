import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ContactUsRoutingModule } from './contact-us-routing.module';
import { ContactUsComponent } from './contact-us.component';
import { HeaderComponent } from '../../header/header.component';
import { HeaderModule } from '../../header/header.module';
import { FooterModule } from '../../footer/footer.module';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    ContactUsComponent
  ],
  imports: [
    CommonModule,
    ContactUsRoutingModule,
    HeaderModule,
    FooterModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class ContactUsModule { }

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import * as fromEquipments from '../../../../../../store/masters/equipments';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Equipment } from '../../../../../../store/masters/equipments/models/equipment.model';

@Component({
  selector: 'app-equipment-multi-select-dropdown',
  templateUrl: './equipment-multi-select-dropdown.component.html',
  styleUrl: './equipment-multi-select-dropdown.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EquipmentMultiSelectDropdownComponent  implements OnInit {
  selectedEquipments$: any = [];
  equipmentsDropdown$: Observable<any>;

  @Input('selectedEquipment')
  set selectedEquipment(value: any) {
    this.selectedEquipments$ =
      value == null || value == undefined || value == '' ? [] : [...value];

    if (value !== null && value !== undefined && value !== '') {
      value.forEach((element) => {
        this.selectedExperienceInIds.push(element.id);
        this.selectedExperienceInNames.push(element.name);
      });
      const selectedElement = {
        ids: this.selectedExperienceInIds,
        text: this.selectedExperienceInNames,
      };
  
      this.selectEquipmentDropdownItem.emit(selectedElement);
    }
  }

  @Output() selectEquipmentDropdownItem = new EventEmitter();

  selectedExperienceInIds: any = [];
  selectedExperienceInNames: any = [];
  config: any = {
    displayKey: 'name',
    search: true,
  };

  constructor(
    public cdr: ChangeDetectorRef,
    private readonly store: Store<fromEquipments.State>
  ) {}

  ngOnInit() {
    this.store.dispatch(new fromEquipments.LoadDropdownRecords());

    this.equipmentsDropdown$ = this.store.pipe(
      select(fromEquipments.getAllEquipments)
    );

    // this.equipmentsDropdown$.subscribe((data)=>{
    //   console.log(`action.payload.data:: ${JSON.stringify(data)}`)
    // })
  }

  searchChange($event) {
    console.log($event);
  }

  selectionChanged($event) {
    // console.log($event);
    this.selectedExperienceInIds = [];
    this.selectedExperienceInNames = [];

    for (const value of $event.value) {
      this.selectedExperienceInIds.push(value.id);
      this.selectedExperienceInNames.push(value.name);
    }
   
    const selectedElement = {
      ids: this.selectedExperienceInIds,
      text: this.selectedExperienceInNames,
    };

    this.selectEquipmentDropdownItem.emit(selectedElement);
  }

  compareEquipments(p1: Equipment, p2: Equipment) {
    return p1.id === p2.id;
  }

}

import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as fromTypeOfStorage from '../../../../../../store/masters/type-of-storages';
import { TypeOfStorage } from '../../../../../../store/masters/type-of-storages/models/type-of-storage.model';

@Component({
  selector: 'app-type-of-storage-add',
  templateUrl: './type-of-storage-add.component.html'
})
export class TypeOfStorageAddComponent {
  pending$ = this.store.pipe(select(fromTypeOfStorage.getTypeOfStorageItemPending));
  error$ = this.store.pipe(select(fromTypeOfStorage.getTypeOfStorageItemError));

  constructor(private readonly store: Store<fromTypeOfStorage.State>) {}

  onSubmit($event: TypeOfStorage) {
    this.store.dispatch(new fromTypeOfStorage.AddRecord($event));
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StateAddComponent } from './containers/state-add/state-add.component';
import { StatePageComponent } from './containers/state-page/state-page.component';
import { StateUpdateComponent } from './containers/state-update/state-update.component';

const routes: Routes = [
  {path: '', component: StatePageComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StatesRoutingModule { }

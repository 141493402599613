import { ExpertTypeUpdateComponent } from './../expert-type-update/expert-type-update.component';
import { ExpertTypeDetailsComponent } from './../../components/expert-type-details/expert-type-details.component';
import { ExpertTypeAddComponent } from './../expert-type-add/expert-type-add.component';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  Optional,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ExpertType } from './../../../../../../store/masters/expert-types/models/expert-type.model';

import * as fromExpertType from './../../../../../../store/masters/expert-types';
import { ExpertTypeItemActionTypes } from './../../../../../../store/masters/expert-types';
import { select, Store } from '@ngrx/store';
import { GridPayload } from './../../../../../../shared/models/grid.payload';

import { Actions, ofType } from '@ngrx/effects';
import { takeUntil, tap } from 'rxjs/operators';
import lstore from 'store';
import {
  NgbActiveModal,
  NgbModal,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-expert-type-page',
  templateUrl: './expert-type-page.component.html',
  styleUrl: './expert-type-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpertTypePageComponent implements OnInit, OnDestroy {
  loggedInUserAccess: boolean = false;
  // tps$: Observable<ExpertType[]> = this.store.pipe(select(fromExpertType.getAllExpertTypes));
  expertTypes$: Observable<any>;
  expertTypesLoading$ = this.store.pipe(select(fromExpertType.getExpertTypesIsLoading));

  expertTypesCount$: Observable<number> = this.store.pipe(
    select(fromExpertType.getExpertTypesCount)
  );
  expertTypesOffset$: Observable<number> = this.store.pipe(
    select(fromExpertType.getExpertTypesOffset)
  );
  expertTypesSearch$: Observable<string> = this.store.pipe(
    select(fromExpertType.getExpertTypesSearch)
  );
  expertTypesSortProp$: Observable<string> = this.store.pipe(
    select(fromExpertType.getExpertTypesSortProp)
  );
  expertTypesSortDir$: Observable<string> = this.store.pipe(
    select(fromExpertType.getExpertTypesSortDir)
  );

  private destroyed$ = new Subject<boolean>();
  //private dialogRef = null;
  modalReference: NgbModalRef;

  searchBy: any = '';

  constructor(
    private readonly store: Store<fromExpertType.State>,
    private modalService: NgbModal,
    @Optional() private activeModal: NgbActiveModal,
    actions$: Actions
  ) {
    this.loggedInUserAccess = lstore.get('aae');
    actions$
      .pipe(
        ofType(
          ExpertTypeItemActionTypes.AddRecordSuccess,
          ExpertTypeItemActionTypes.UpdateRecordSuccess
        ),
        takeUntil(this.destroyed$),
        tap(() => {
          this.modalReference.close();
        })
      )
      .subscribe();
  }

  ngOnInit() {
    this.loadExpertTypes();
    this.expertTypes$ = this.store.pipe(select(fromExpertType.getAllExpertTypes));
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  loadExpertTypes() {
    const info: GridPayload = {
      offset: 0,
      sortProp: 'name',
      sortDir: 'asc',
      search:
        this.searchBy == '' ||
        this.searchBy == undefined ||
        this.searchBy == null
          ? 'all'
          : this.searchBy,
    };
    this.store.dispatch(new fromExpertType.LoadRecords(info));
  }

  search() {
    this.loadExpertTypes();
  }

  clear() {
    this.searchBy = '';
    this.loadExpertTypes();
  }
  
  addItem(): void {
    this.modalReference = this.modalService.open(ExpertTypeAddComponent, {
      backdrop: 'static',
      size: 'lg',
      keyboard: false,
      centered: true,
    });
  }

  onGridUpdate(info: GridPayload) {
    this.store.dispatch(new fromExpertType.LoadRecords(info));
  }
  onItemSelected(expertTypeData: ExpertType) {
    this.modalReference = this.modalService.open(ExpertTypeDetailsComponent, {
      backdrop: 'static',
      size: 'lg',
      keyboard: false,
      centered: true,
    });
    this.modalReference.componentInstance.selectedExpertType = expertTypeData;
  }

  onItemUpdate(expertType: ExpertType) {
    this.store.dispatch(new fromExpertType.SelectRecord(expertType.id));
    this.modalReference = this.modalService.open(ExpertTypeUpdateComponent, {
      backdrop: 'static',
      size: 'lg',
      keyboard: false,
      centered: true,
    });
  }
}



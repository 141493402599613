import { Action } from '@ngrx/store';
import { LoadSuccessPayload } from '../../../../shared/models/common.payload';
import { GridPayload, LoadGridSuccessPayload } from '../../../../shared/models/grid.payload';
import { ExperienceType, ExperienceTypeApiModel } from '../../../../store/masters/experience-types/models/experience-type.model';

export enum ExperienceTypeListActionTypes {
    LoadRecords = '[ExperienceTypes] Load',
    LoadRecordsSuccess = '[ExperienceTypes] Load Success',
    LoadRecordsFailure = '[ExperienceTypes] Load Failure',

    LoadDropdownRecords = '[ExperienceTypes Dropdown] Load',
    LoadDropdownRecordsSuccess = '[ExperienceTypes Dropdown] Load Success',
    LoadDropdownRecordsFailure = '[ExperienceTypes Dropdown] Load Failure',

    LoadDropdownByUserRecords = '[ExperienceTypes Dropdown By User] Load',
    LoadDropdownByUserRecordsSuccess = '[ExperienceTypes Dropdown By User] Load Success',
    LoadDropdownByUserRecordsFailure = '[ExperienceTypes Dropdown By User] Load Failure',

    SelectRecord = '[ExperienceType] Select Record',
}

////////////

export class SelectRecord implements Action {
  readonly type = ExperienceTypeListActionTypes.SelectRecord;

  constructor(public payload: string) {
  }
}

export class LoadRecords implements Action {
  readonly type = ExperienceTypeListActionTypes.LoadRecords;

  constructor(public gridInfo: GridPayload) {
  }
}

export class LoadRecordsSuccess implements Action {
  readonly type = ExperienceTypeListActionTypes.LoadRecordsSuccess;

  constructor(public payload: LoadGridSuccessPayload<ExperienceTypeApiModel>) {
  }
}

export class LoadRecordsFailure implements Action {
  readonly type = ExperienceTypeListActionTypes.LoadRecordsFailure;

  constructor(public payload: any) {
  }
}

export class LoadDropdownRecords implements Action {
    readonly type = ExperienceTypeListActionTypes.LoadDropdownRecords;

    constructor() {
    }
}

export class LoadDropdownRecordsSuccess implements Action {
    readonly type = ExperienceTypeListActionTypes.LoadDropdownRecordsSuccess;

    constructor(public payload: LoadSuccessPayload<ExperienceTypeApiModel>) {
    }
}

export class LoadDropdownRecordsFailure implements Action {
    readonly type = ExperienceTypeListActionTypes.LoadDropdownRecordsFailure;

    constructor(public payload: any) {
    }
}


export class LoadDropdownByUserRecords implements Action {
  readonly type = ExperienceTypeListActionTypes.LoadDropdownByUserRecords;

  constructor() {
  }
}

export class LoadDropdownByUserRecordsSuccess implements Action {
  readonly type = ExperienceTypeListActionTypes.LoadDropdownByUserRecordsSuccess;

  constructor(public payload: LoadSuccessPayload<ExperienceTypeApiModel>) {
  }
}

export class LoadDropdownByUserRecordsFailure implements Action {
  readonly type = ExperienceTypeListActionTypes.LoadDropdownByUserRecordsFailure;

  constructor(public payload: any) {
  }
}
////////////

export type ListActions =
  | SelectRecord
  | LoadRecords
  | LoadRecordsSuccess
  | LoadRecordsFailure
  | LoadDropdownRecords
  | LoadDropdownRecordsSuccess
  | LoadDropdownRecordsFailure
  | LoadDropdownByUserRecords
  | LoadDropdownByUserRecordsSuccess
  | LoadDropdownByUserRecordsFailure;


import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as fromExpertType from '../../../../../../store/masters/expert-types';
import { ExpertType } from '../../../../../../store/masters/expert-types/models/expert-type.model';

@Component({
  selector: 'app-expert-type-update',
  templateUrl: './expert-type-update.component.html'
})
export class ExpertTypeUpdateComponent {
  expertType$ = this.store.pipe(select(fromExpertType.getSelectedExpertType));
  expertTypeId$ = this.store.pipe(select(fromExpertType.getSelectedExpertTypeId));

  pending$ = this.store.pipe(select(fromExpertType.getExpertTypeItemPending));
  error$ = this.store.pipe(select(fromExpertType.getExpertTypeItemError));

  constructor(private readonly store: Store<fromExpertType.State>) {}

  onSubmit($event: ExpertType) {
    this.store.dispatch(new fromExpertType.UpdateRecord($event));
  }
}

import { Action } from '@ngrx/store';
import { LoadSuccessPayload } from '../../../../shared/models/common.payload';
import { GridPayload, LoadGridSuccessPayload } from '../../../../shared/models/grid.payload';
import { District, DistrictApiModel } from '../../../../store/masters/districts/models/district.model';

export enum DistrictListActionTypes {
    LoadRecords = '[Districts] Load',
    LoadRecordsSuccess = '[Districts] Load Success',
    LoadRecordsFailure = '[Districts] Load Failure',

    LoadDropdownRecords = '[Districts Dropdown] Load',
    LoadDropdownRecordsSuccess = '[Districts Dropdown] Load Success',
    LoadDropdownRecordsFailure = '[Districts Dropdown] Load Failure',

    LoadDropdownByUserRecords = '[Districts Dropdown By User] Load',
    LoadDropdownByUserRecordsSuccess = '[Districts Dropdown By User] Load Success',
    LoadDropdownByUserRecordsFailure = '[Districts Dropdown By User] Load Failure',

    SelectRecord = '[District] Select Record',
}

////////////

export class SelectRecord implements Action {
  readonly type = DistrictListActionTypes.SelectRecord;

  constructor(public payload: string) {
  }
}

export class LoadRecords implements Action {
  readonly type = DistrictListActionTypes.LoadRecords;

  constructor(public gridInfo: GridPayload) {
  }
}

export class LoadRecordsSuccess implements Action {
  readonly type = DistrictListActionTypes.LoadRecordsSuccess;

  constructor(public payload: LoadGridSuccessPayload<DistrictApiModel>) {
  }
}

export class LoadRecordsFailure implements Action {
  readonly type = DistrictListActionTypes.LoadRecordsFailure;

  constructor(public payload: any) {
  }
}

export class LoadDropdownRecords implements Action {
    readonly type = DistrictListActionTypes.LoadDropdownRecords;

    constructor(public payload:string) {
    }
}

export class LoadDropdownRecordsSuccess implements Action {
    readonly type = DistrictListActionTypes.LoadDropdownRecordsSuccess;

    constructor(public payload: LoadSuccessPayload<DistrictApiModel>) {
    }
}

export class LoadDropdownRecordsFailure implements Action {
    readonly type = DistrictListActionTypes.LoadDropdownRecordsFailure;

    constructor(public payload: any) {
    }
}


export class LoadDropdownByUserRecords implements Action {
  readonly type = DistrictListActionTypes.LoadDropdownByUserRecords;

  constructor() {
  }
}

export class LoadDropdownByUserRecordsSuccess implements Action {
  readonly type = DistrictListActionTypes.LoadDropdownByUserRecordsSuccess;

  constructor(public payload: LoadSuccessPayload<DistrictApiModel>) {
  }
}

export class LoadDropdownByUserRecordsFailure implements Action {
  readonly type = DistrictListActionTypes.LoadDropdownByUserRecordsFailure;

  constructor(public payload: any) {
  }
}
////////////

export type ListActions =
  | SelectRecord
  | LoadRecords
  | LoadRecordsSuccess
  | LoadRecordsFailure
  | LoadDropdownRecords
  | LoadDropdownRecordsSuccess
  | LoadDropdownRecordsFailure
  | LoadDropdownByUserRecords
  | LoadDropdownByUserRecordsSuccess
  | LoadDropdownByUserRecordsFailure;


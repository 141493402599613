import { ContractorActionTypes, ContractorItemActionTypes } from '../actions';

export interface ContractorItemState {
  error: string | null;
  pending: boolean;
}

export const initialState: ContractorItemState = {
  error: null,
  pending: false,
};

export function contractorItemReducer(
  state = initialState,
  action: ContractorActionTypes
): ContractorItemState {
  switch (action.type) {
    case ContractorItemActionTypes.AddRecord:
    case ContractorItemActionTypes.UpdateRecord:
    case ContractorItemActionTypes.UpdateStatusRecord:
    case ContractorItemActionTypes.UpdateApprovalStatusRecord: {
      return {
        ...state,
        error: null,
        pending: true,
      };
    }

    case ContractorItemActionTypes.AddRecordSuccess:
    case ContractorItemActionTypes.UpdateRecordSuccess:
    case ContractorItemActionTypes.UpdateStatusRecordSuccess:
    case ContractorItemActionTypes.UpdateApprovalStatusRecordSuccess: {
      return {
        ...state,
        error: null,
        pending: false,
      };
    }

    case ContractorItemActionTypes.AddRecordFailure:
    case ContractorItemActionTypes.UpdateRecordFailure:
    case ContractorItemActionTypes.UpdateStatusRecordFailure:
    case ContractorItemActionTypes.UpdateApprovalStatusRecordFailure: {
      return {
        ...state,
        error: action.payload,
        pending: false,
      };
    }

    default:
      return state;
  }
}

export const getError = (state: ContractorItemState) => state.error;
export const getPending = (state: ContractorItemState) => state.pending;

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ExpertTypesRoutingModule } from './expert-types-routing.module';

import { ExpertTypePageComponent } from './containers/expert-type-page/expert-type-page.component';
import { ExpertTypeAddComponent } from './containers/expert-type-add/expert-type-add.component';
import { ExpertTypeUpdateComponent } from './containers/expert-type-update/expert-type-update.component';
import { ExpertTypeDetailsComponent } from './components/expert-type-details/expert-type-details.component';
import { ExpertTypeDropdownComponent } from './components/expert-type-dropdown/expert-type-dropdown.component';
import { ExpertTypeFormComponent } from './components/expert-type-form/expert-type-form.component';
import { ExpertTypeListComponent } from './components/expert-type-list/expert-type-list.component';


import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { EXPERT_TYPE_EFFECTS } from '../../../../store/masters/expert-types/effects';
import { EXPERT_TYPE_REDUCERS } from '../../../../store/masters/expert-types/expert-type.state';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { HeaderModule } from '../../../header/header.module';
import { SidemenuModule } from '../../../sidemenu/sidemenu.module';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgbAlertModule, NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { FooterModule } from '../../../../components/footer/footer.module';
import { ExpertTypeMultiSelectDropdownComponent } from './components/expert-type-multi-select-dropdown/expert-type-multi-select-dropdown.component';
import { ExpertTypeFilterListComponent } from './components/expert-type-filter-list/expert-type-filter-list.component';



@NgModule({
  declarations: [
    ExpertTypePageComponent,
    ExpertTypeAddComponent,
    ExpertTypeUpdateComponent,
    ExpertTypeDetailsComponent,
    ExpertTypeDropdownComponent,
    ExpertTypeFormComponent,
    ExpertTypeListComponent,
    ExpertTypeMultiSelectDropdownComponent,
    ExpertTypeFilterListComponent
  ],
  imports: [
    CommonModule,
    ExpertTypesRoutingModule,
    
    FormsModule,
    ReactiveFormsModule,
    
    HeaderModule,
    SidemenuModule,
    FooterModule,
    SelectDropDownModule,
    NgxDatatableModule,
    NgbModule,
    NgbPaginationModule, 
    NgbAlertModule,

    StoreModule.forFeature('expert_types', EXPERT_TYPE_REDUCERS),
    EffectsModule.forFeature(EXPERT_TYPE_EFFECTS),
  ],
  exports:[
    ExpertTypeDropdownComponent,
    ExpertTypeMultiSelectDropdownComponent,
    ExpertTypeFilterListComponent
  ]
})
export class ExpertTypesModule { }

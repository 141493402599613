import { TypeOfStorageActionTypes, TypeOfStorageItemActionTypes } from '../actions';

export interface TypeOfStorageItemState {
    error: string | null;
    pending: boolean;
}

export const initialState: TypeOfStorageItemState = {
    error: null,
    pending: false,
};

export function typeOfStorageItemReducer(state = initialState, action: TypeOfStorageActionTypes): TypeOfStorageItemState {
    switch (action.type) {
        case TypeOfStorageItemActionTypes.AddRecord:
        case TypeOfStorageItemActionTypes.UpdateRecord: {
            return {
                ...state,
                error: null,
                pending: true
            };
        }

        case TypeOfStorageItemActionTypes.AddRecordSuccess:
        case TypeOfStorageItemActionTypes.UpdateRecordSuccess: {
            return {
                ...state,
                error: null,
                pending: false
            };
        }

        case TypeOfStorageItemActionTypes.AddRecordFailure:
        case TypeOfStorageItemActionTypes.UpdateRecordFailure: {
            return {
                ...state,
                error: action.payload,
                pending: false
            };
        }

        default:
            return state;
    }
}

export const getError = (state: TypeOfStorageItemState) => state.error;
export const getPending = (state: TypeOfStorageItemState) => state.pending;

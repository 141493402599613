<app-sidemenu></app-sidemenu>

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">

    <app-header></app-header>

    <!-- Breadcrumb Area -->
    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/dashboard">Home</a></li>
            <li class="item">{{Content.subTitle}}</li>
        </ol>
    </div>
    <!-- End Breadcrumb Area -->

    <!-- Start -->
    <app-stats></app-stats>
    <!-- End -->
    
    <!-- Start -->
    <div class="row">
        <div class="col-lg-12 col-md-12">
            <!-- <app-recent-activities></app-recent-activities> -->
        </div>

        <div class="col-lg-6 col-md-12">
        </div>
    </div>
    <!-- End -->

    <div class="flex-grow-1"></div>
    
    <app-footer></app-footer>

</div>
<!-- End Main Content Wrapper Area -->
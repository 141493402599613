import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Actions, Effect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { Observable, of, empty, from } from 'rxjs';
import {
  map,
  switchMap,
  catchError,
  withLatestFrom,
  concatMap,
} from 'rxjs/operators';
import store from 'store';

import * as Reducers from '../reducers';
import * as UserActions from './actions';
import { jwtAuthService } from '../../services';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class UserEffects implements OnInitEffects {
  constructor(
    private actions: Actions,
    private jwtAuthService: jwtAuthService,
    private router: Router,
    private route: ActivatedRoute,
    private rxStore: Store<any>,
    private notification: ToastrService
  ) {}

  ngrxOnInitEffects(): Action {
    return { type: UserActions.LOAD_CURRENT_ACCOUNT };
  }

  @Effect()
  login: Observable<any> = this.actions.pipe(
    ofType(UserActions.LOGIN),
    map((action: UserActions.Login) => action.payload),
    switchMap((payload) => {
      // jwt login
      // return this.jwtAuthService.login(payload.email, payload.password).pipe(
      return this.jwtAuthService.login(payload).pipe(
        map((response) => {
          if (response && response.token) {
            //console.log("Token::", response.accessToken)
            this.jwtAuthService.setToken(response.token);
            store.set('accessToken', response.token);
            store.set('roles', response.role);
            store.set(
              'authority',
              response.authorities != null ? response.authorities[0] : ''
            );
            store.set(
              'aae',
              response.role === 'Administrator' ||
                response.role === 'State Head' ||
                response.role === 'State Dept IT Administrator'
                ? true
                : false
            );
            // this.notification.success(
            //   "Logged In",
            //   "You have successfully logged in!"
            // );
            return new UserActions.LoadCurrentAccount();
          }
          // this.notification.warning("Auth Failed", response);
          return new UserActions.LoginUnsuccessful();
        }),
        catchError((e) => {
          console.log('LOGIN ERROR: ', e);
          // this.notification.warning("Auth Failed", e.error.error);
          return from([{ type: UserActions.LOGIN_UNSUCCESSFUL }]);
        })
      );
    })
  );

  @Effect()
  userRegister: Observable<any> = this.actions.pipe(
    ofType(UserActions.USER_REGISTER),
    map((action: UserActions.UserRegister) => action.payload),
    switchMap((payload) => {
      // jwt register
      console.log(`UserRegister payload ${payload} inside effects`);
      return this.jwtAuthService.register(payload).pipe(
        map((response) => {
          console.log(`UserRegister response ${JSON.stringify(response)}`);

          if (response.status === 'request_failed') {
            this.notification.error(
              'Registration UnSuccessful',
              'Error!'
            );
            return new UserActions.UserRegisterUnsuccessful();
          } else if (response.status === 'uae') {
            this.notification.error(
              'User already exist.',
              'Error!'
            );
            return new UserActions.UserRegisterUnsuccessful();
          } else if (response.status === 'success') {
            this.jwtAuthService.setToken(response.token);
            store.set('accessToken', response.token);
            store.set('role', response.role);
            store.set(
              'authority',
              response.authorities != null ? response.authorities[0] : ''
            );
            store.set(
              'aae',
              response.role === 'Administrator' ||
                response.role === 'State Head' ||
                response.role === 'State Dept IT Administrator'
                ? true
                : false
            );
            this.notification.success(
              'Registration Successful, Please login.',
              'Success!'
            );
            //return new AuthActions.UserRegisterSuccessful(response);
            return new UserActions.LoadCurrentAccount();
          }
          return new UserActions.UserRegisterUnsuccessful();
        }),
        catchError((error) => {
          console.log('REGISTER ERROR: ', error);
          return from([{ type: UserActions.USER_REGISTER_UNSUCCESSFUL }]);
        })
      );
    })
  );

  @Effect()
  loadCurrentAccount: Observable<any> = this.actions.pipe(
    ofType(UserActions.LOAD_CURRENT_ACCOUNT),
    map((action: UserActions.LoadCurrentAccount) => true),
    switchMap(() => {
      // jwt load current account
      return this.jwtAuthService.currentAccount().pipe(
        map((response) => {
          if (response) {
            store.set('id', response.id);
            store.set('name', response.name);
            store.set('avatar', response.avatar);
            console.log(`Router URL ${this.router.url}`);
            if (this.route.snapshot.queryParams.returnUrl) {
              this.router.navigate([this.route.snapshot.queryParams.returnUrl]); // // redirect to returnUrl
            } else if (this.router.url.includes('/login')) {
              this.router.navigate(['/']); // redirect to root route on auth pages
            }else{
              this.router.navigate(['/']); // redirect to root route on auth pages
            }
            return new UserActions.LoadCurrentAccountSuccessful(response);
          }
          return new UserActions.LoadCurrentAccountUnsuccessful();
        }),
        catchError((error) => {
          console.log('ACCOUNT LOAD ERROR: ', error);
          return from([{ type: UserActions.LOGIN_UNSUCCESSFUL }]);
        })
      );

      // do nothing for firebase, as user state subscribed inside firebase service
      return of(new UserActions.EmptyAction());
    })
  );

  @Effect()
  logout: Observable<any> = this.actions.pipe(
    ofType(UserActions.LOGOUT),
    map((action: UserActions.Logout) => true),
    switchMap(() => {
      // jwt logout
      return this.jwtAuthService.logout().pipe(
        map(() => {
          store.remove('accessToken');
          store.remove('role');
          store.remove('authority');
          store.remove('aae');
          store.remove('avatar');
          store.remove('roles');
          store.remove('id');
          store.remove('name');
          this.router.navigate(['/']);
          return new UserActions.FlushUser();
        })
      );
    })
  );
}

import { Injectable } from '@angular/core';

import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';

import { Observable, of as observableOf } from 'rxjs';
import {
  catchError,
  exhaustMap,
  map,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import * as actions from './../actions';
import {
  AddRecord,
  ContractorItemActionTypes,
  UpdateRecord,
} from './../actions';
import * as fromContractor from './../contractor.state';

import { ContractorModel } from '../models/contractor.model';
import { ContractorService } from '../../../../services/cold-storage/contractors';
import { GridPayload } from '../../../../shared/models/grid.payload';

@Injectable()
export class ContractorItemEffects {
  @Effect()
  addRecord$: Observable<Action> = this.actions$.pipe(
    ofType<AddRecord>(ContractorItemActionTypes.AddRecord),
    map((action) => action.payload),
    exhaustMap((tp: ContractorModel) =>
      this.contractorService.saveContractorData(tp).pipe(
        map(
          (payload: ContractorModel) => new actions.AddRecordSuccess(payload)
        ),
        catchError((e) =>
          observableOf(new actions.AddRecordFailure(e.error.error))
        )
      )
    )
  );

  @Effect()
  updateRecord$: Observable<Action> = this.actions$.pipe(
    ofType<UpdateRecord>(ContractorItemActionTypes.UpdateRecord),
    map((action) => action.payload),
    exhaustMap((tp: ContractorModel) =>
      this.contractorService.saveContractorData(tp).pipe(
        map(
          (payload: ContractorModel) => new actions.UpdateRecordSuccess(payload)
        ),
        catchError((e) =>
          observableOf(new actions.UpdateRecordFailure(e.error.error))
        )
      )
    )
  );

  @Effect()
  updateStatus$: Observable<Action> = this.actions$.pipe(
    ofType<actions.UpdateStatusRecord>(
      ContractorItemActionTypes.UpdateStatusRecord
    ),
    map((action) => action.payload),
    exhaustMap((record: any) =>
      this.contractorService.updateStatus(record).pipe(
        map(
          (payload: ContractorModel) =>
            new actions.UpdateStatusRecordSuccess(payload)
        ),
        catchError((e) =>
          observableOf(new actions.UpdateStatusRecordFailure(e.error.error))
        )
      )
    )
  );

  @Effect()
  updateApprovalStatus$: Observable<Action> = this.actions$.pipe(
    ofType<actions.UpdateApprovalStatusRecord>(
      ContractorItemActionTypes.UpdateApprovalStatusRecord
    ),
    map((action) => action.payload),
    exhaustMap((record: any) =>
      this.contractorService.updateApprovalStatus(record).pipe(
        map(
          (payload: ContractorModel) =>
            new actions.UpdateApprovalStatusRecordSuccess(payload)
        ),
        catchError((e) =>
          observableOf(new actions.UpdateApprovalStatusRecordFailure(e.error.error))
        )
      )
    )
  );

  @Effect({ dispatch: false })
  reloadGrid$: Observable<any> = this.actions$.pipe(
    ofType(ContractorItemActionTypes.AddRecordSuccess),
    withLatestFrom(
      this.store.pipe(select(fromContractor.getContractorsOffset)),
      this.store.pipe(select(fromContractor.getContractorsSortProp)),
      this.store.pipe(select(fromContractor.getContractorsSortDir)),
      this.store.pipe(select(fromContractor.getContractorsSearch)),
      (action, offset, sortProp, sortDir, search) => ({
        offset,
        sortProp,
        sortDir,
        search,
      })
    ),
    tap((info: GridPayload) => {
      this.store.dispatch(new actions.LoadRecords(info));
    })
  );

  @Effect({ dispatch: false })
  closeModal$: Observable<any> = this.actions$.pipe(
    ofType(
      ContractorItemActionTypes.AddRecordSuccess,
      ContractorItemActionTypes.UpdateRecordSuccess,
      ContractorItemActionTypes.UpdateStatusRecordSuccess,
      ContractorItemActionTypes.UpdateApprovalStatusRecordSuccess
    ),
    withLatestFrom(
      this.store.pipe(select(fromContractor.getContractorsOffset)),
      this.store.pipe(select(fromContractor.getContractorsSortProp)),
      this.store.pipe(select(fromContractor.getContractorsSortDir)),
      this.store.pipe(select(fromContractor.getContractorsSearch)),
      (action, offset, sortProp, sortDir, search) => ({
        offset,
        sortProp,
        sortDir,
        search,
      })
    ),
    tap((info: GridPayload) => {
      this.store.dispatch(new actions.LoadRecords(info));
    })
  );

  constructor(
    private store: Store<fromContractor.State>,
    private actions$: Actions,
    private contractorService: ContractorService
  ) {}
}

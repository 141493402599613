import { GlobalState } from '../../../store';
import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';

import * as fromList from './reducers/list.reducer';
import * as fromItem from './reducers/item.reducer';

export interface DesignConsultantState {
  list: fromList.DesignConsultantListState;
  item: fromItem.DesignConsultantItemState;
}

export interface State extends GlobalState {
  design_consultants: DesignConsultantState;
}

export const COLD_STORAGE_DESIGN_CONSULTANT_REDUCERS: ActionReducerMap<
  DesignConsultantState,
  any
> = {
  list: fromList.designConsultantListReducer,
  item: fromItem.designConsultantItemReducer,
};

////// selectors

export const getProjectsState = createFeatureSelector<State, DesignConsultantState>(
  'design_consultants'
);

export const getDesignConsultantListState = createSelector(
  getProjectsState,
  (state) => state.list
);
export const getDesignConsultantItemState = createSelector(
  getProjectsState,
  (state) => state.item
);

export const getDesignConsultantsIsLoading = createSelector(
  getDesignConsultantListState,
  fromList.getIsLoading
);

export const getDesignConsultantsCount = createSelector(
  getDesignConsultantListState,
  fromList.getCount
);
export const getDesignConsultantsOffset = createSelector(
  getDesignConsultantListState,
  fromList.getOffset
);

export const getDesignConsultantsSortProp = createSelector(
  getDesignConsultantListState,
  fromList.getSortProp
);
export const getDesignConsultantsSortDir = createSelector(
  getDesignConsultantListState,
  fromList.getSortDir
);
export const getDesignConsultantsSearch = createSelector(
  getDesignConsultantListState,
  fromList.getSearch
);

export const getSelectedDesignConsultantId = createSelector(
  getDesignConsultantListState,
  fromList.getSelectedId
);

export const {
  selectEntities: getDesignConsultantEntities,
  selectAll: getAllDesignConsultants,
} = fromList.adapter.getSelectors(getDesignConsultantListState);

export const getSelectedDesignConsultant = createSelector(
  getDesignConsultantEntities,
  getSelectedDesignConsultantId,
  (entities, selectedId) => {
    return selectedId !== null && entities[selectedId];
  }
);

// Item

export const getDesignConsultantItemPending = createSelector(
  getDesignConsultantItemState,
  fromItem.getPending
);
export const getDesignConsultantItemError = createSelector(
  getDesignConsultantItemState,
  fromItem.getError
);

import { Injectable } from "@angular/core";
import { BaseService } from "../../../base/base.service";
import { map } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
import {
  ExpertType,
  ExpertTypeApiModel,
} from "../../../store/masters/expert-types/models/expert-type.model";
import { GridPayload } from '../../../shared/models/grid.payload';
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ExpertTypeService extends BaseService {
  apiUrl: any = environment.api_url;

  getDropdownList(): Observable<any> {
    return this.http.get(`${this.apiUrl}/api/masters/experttypes/dropdown/records`)
      .pipe(
        map((response: ExpertTypeApiModel[]) => ({
          data: response.map(record => new ExpertType(record))
        }))
      );
  }

  getDropdownListByUser(): Observable<any> {
    return this.http.get(`${this.apiUrl}/api/masters/experttypes/dropdown/records/by/user`)
      .pipe(
        map((response: ExpertTypeApiModel[]) => ({
          data: response.map(record => new ExpertType(record))
        }))
      );
  }

  getExpertTypeList(gridInfo: GridPayload) {
    return this.gridRequest<ExpertTypeApiModel>(`${this.apiUrl}/api/masters/experttypes/records`, gridInfo.offset, gridInfo.sortProp, gridInfo.sortDir, gridInfo.search)
      .pipe(
        map(response => ({
          ...response,
          data: response.data.map(record => new ExpertType(record))
        }))
      );
  }

  saveExpertType(payload: ExpertType): Observable<ExpertType> {
    return this.http.post<ExpertType>(`${this.apiUrl}/api/masters/experttypes/save`, payload);
  }
}

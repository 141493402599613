<div class="modalDialogBox">
  <form [formGroup]="form" (ngSubmit)="submit()">
    <div class="modal-header bg-green-400 text-white">
      <h4 class="modal-title" id="modal-basic-title">
        {{ contractorId ? "Update" : "Add" }} Contractor Detail(s)<br />
        <small class="small-text" *ngIf="contractorId">
          <strong>Created On:</strong>&nbsp;
          {{ createdOn | date : "short" }}
          &nbsp;&nbsp;
          <strong>Last Updated On:</strong>&nbsp;
          {{ lastUpdatedOn | date : "short" }}
        </small>
      </h4>
      <button
        type="button"
        class="btn btn-outline-white"
        aria-label="Close"
        (click)="activeModal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div>
        <p *ngIf="error" class="error-message">{{ error }}</p>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="add-listings-box">
            <h3>Basic Informations</h3>

            <div class="row">
              <div class="col-lg-12 col-md-12">
                <div class="form-group">
                  <label
                    ><i class="bx bx-briefcase-alt"></i> Name of the
                    Organisation:</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Name of your Organisation"
                    formControlName="organisation_name"
                  />
                </div>
              </div>

              <div class="col-lg-6 col-md-6">
                <div class="form-group">
                  <label><i class="bx bx-duplicate"></i> Contact Person:</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Name of the contact person"
                    formControlName="contact_person"
                  />
                </div>
              </div>

              <div class="col-lg-6 col-md-6">
                <div class="form-group">
                  <label><i class="bx bxs-key"></i> Contact Number:</label>
                  <input
                    type="number"
                    class="form-control"
                    placeholder="Mobile number of the contact person"
                    formControlName="contact_number"
                  />
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="form-group">
                  <label><i class="bx bxs-key"></i> Email:</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Email of the contact person"
                    formControlName="email"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="add-listings-box">
            <h3>Location</h3>

            <div class="row">
              <div class="col-lg-6 col-md-6">
                <div class="form-group">
                  <label><i class="bx bx-menu-alt-left"></i> State:</label>
                  <app-state-dropdown
                    (selectStateDropdownItem)="
                      onStateDropdownItemSelect($event)
                    "
                    [selectedState]="selectedStateId"
                  >
                  </app-state-dropdown>
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="form-group">
                  <label><i class="bx bx-menu-alt-left"></i> District:</label>
                  <app-district-dropdown
                    (selectDistrictDropdownItem)="
                      onDistrictDropdownItemSelect($event)
                    "
                    [selectedState]="selectedStateId"
                    [selectedDistrict]="selectedDistrictId"
                  >
                  </app-district-dropdown>
                </div>
              </div>

              <div class="col-lg-12 col-md-12">
                <div class="form-group">
                  <label
                    ><i class="bx bx-menu-alt-left"></i> Full Address:</label
                  >
                  <textarea
                    class="form-control"
                    placeholder="e.g. H.No 55/25/1 A, Street Name, Area, District/City, State Pincode"
                    formControlName="full_address"
                  ></textarea>
                </div>
              </div>
              <div class="col-lg-12 col-md-12">
                <div class="form-group">
                  <label
                    ><i class="bx bx-menu-alt-left"></i> Detailed Information (About you/Experience/Organization etc.):</label
                  >
                  <textarea
                    class="form-control"
                    formControlName="description"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>

          <div class="add-listings-box">
            <h3>Other Details</h3>

            <div class="row">
              <div class="col-lg-6 col-md-6">
                <div class="form-group">
                  <label
                    ><i class="bx bx-menu-alt-left"></i> Experience (in
                    years):</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Total experience in the current field"
                    formControlName="total_experience"
                  />
                </div>
              </div>

              <div class="col-lg-6 col-md-6">
                <div class="form-group">
                  <label
                    ><i class="bx bx-menu-alt-left"></i> Annual turnover:</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Annual turnover in rupees"
                    formControlName="anual_turnover"
                  />
                </div>
              </div>
              <div class="col-lg-12 col-md-12">
                <div class="form-group">
                  <label
                    ><i class="bx bx-menu-alt-left"></i> Experience in :</label
                  >

                  <app-experience-type-multi-select-dropdown
                    (selectExperienceTypeDropdownItem)="
                      onExperienceTypeDropdownItemSelect($event)
                    "
                    [selectedExperienceType]="selectedExperienceTypes"
                  >
                  </app-experience-type-multi-select-dropdown>
                </div>
              </div>

              <div
                class="col-lg-12 col-md-12"
                *ngIf="selectedExperienceInIds.length === 0"
              >
                <span class="error-message">
                  Please select atleast one experience type
                </span>
              </div>
              <div class="col-lg-12 col-md-12">
                <span
                  class="badge yellow"
                  *ngFor="let name of selectedExperienceInNames"
                >
                  {{ name }}
                </span>
              </div>
              <div class="col-lg-12 col-md-12">&nbsp;</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">&nbsp;</div>
      </div>
      <div class="row" *ngIf="loggedInUserAccess">
        <div class="col-md-6">
          <div class="form-group">
            <label class="col-form-label" for="is_approved">
              <strong>Approval Status</strong>
            </label>
            <select
              formControlName="is_approved"
              class="form-control select-box"
              required
            >
              <option value="0">On Hold/ Verification Pending</option>
              <option selected value="1">Approved</option>
              <option selected value="2">Rejected</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">&nbsp;</div>
      </div>
    </div>
    <div class="modal-footer bg-gray-300">
      <button
        type="submit"
        class="btn btn-outline-success"
        [disabled]="!form.valid"
        (click)="submit()"
      >
        Submit
      </button>
      <button
        type="button"
        class="btn btn-outline-dark"
        (click)="activeModal.close('Save click')"
      >
        Cancel
      </button>
      <!-- <button mat-stroked-button color="accent" [disabled]="form.invalid" (click)="submit()">Save</button> -->
    </div>
  </form>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EquipmentsRoutingModule } from './equipments-routing.module';

import { EquipmentPageComponent } from './containers/equipment-page/equipment-page.component';
import { EquipmentAddComponent } from './containers/equipment-add/equipment-add.component';
import { EquipmentUpdateComponent } from './containers/equipment-update/equipment-update.component';
import { EquipmentDetailsComponent } from './components/equipment-details/equipment-details.component';
import { EquipmentDropdownComponent } from './components/equipment-dropdown/equipment-dropdown.component';
import { EquipmentFormComponent } from './components/equipment-form/equipment-form.component';
import { EquipmentListComponent } from './components/equipment-list/equipment-list.component';


import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { EQUIPMENT_EFFECTS } from '../../../../store/masters/equipments/effects';
import { EQUIPMENT_REDUCERS } from '../../../../store/masters/equipments/equipment.state';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { HeaderModule } from '../../../header/header.module';
import { SidemenuModule } from '../../../sidemenu/sidemenu.module';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgbAlertModule, NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { FooterModule } from '../../../../components/footer/footer.module';
import { EquipmentMultiSelectDropdownComponent } from './components/equipment-multi-select-dropdown/equipment-multi-select-dropdown.component';
import { EquipmentFilterListComponent } from './components/equipment-filter-list/equipment-filter-list.component';


@NgModule({
  declarations: [
    EquipmentPageComponent,
    EquipmentAddComponent,
    EquipmentUpdateComponent,
    EquipmentDetailsComponent,
    EquipmentDropdownComponent,
    EquipmentFormComponent,
    EquipmentListComponent,
    EquipmentMultiSelectDropdownComponent,
    EquipmentFilterListComponent
  ],
  imports: [
    CommonModule,
    EquipmentsRoutingModule,
    
    FormsModule,
    ReactiveFormsModule,
    
    HeaderModule,
    SidemenuModule,
    FooterModule,
    SelectDropDownModule,
    NgxDatatableModule,
    NgbModule,
    NgbPaginationModule, 
    NgbAlertModule,

    StoreModule.forFeature('equipments', EQUIPMENT_REDUCERS),
    EffectsModule.forFeature(EQUIPMENT_EFFECTS),
  ],
  exports:[
    EquipmentDropdownComponent,
    EquipmentMultiSelectDropdownComponent,
    EquipmentFilterListComponent
  ]
})
export class EquipmentsModule { }

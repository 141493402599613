import { Injectable } from "@angular/core";
import { BaseService } from "../../../base/base.service";
import { map } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
import {
  TypeOfStorage,
  TypeOfStorageApiModel,
} from "../../../store/masters/type-of-storages/models/type-of-storage.model";
import { GridPayload } from '../../../shared/models/grid.payload';
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class TypeOfStorageService extends BaseService {
  apiUrl: any = environment.api_url;

  getDropdownList(): Observable<any> {
    return this.http.get(`${this.apiUrl}/api/masters/tocs/dropdown/records`)
      .pipe(
        map((response: TypeOfStorageApiModel[]) => ({
          data: response.map(record => new TypeOfStorage(record))
        }))
      );
  }

  getDropdownListByUser(): Observable<any> {
    return this.http.get(`${this.apiUrl}/api/masters/tocs/dropdown/records/by/user`)
      .pipe(
        map((response: TypeOfStorageApiModel[]) => ({
          data: response.map(record => new TypeOfStorage(record))
        }))
      );
  }

  getTypeOfStorageList(gridInfo: GridPayload) {
    return this.gridRequest<TypeOfStorageApiModel>(`${this.apiUrl}/api/masters/tocs/records`, gridInfo.offset, gridInfo.sortProp, gridInfo.sortDir, gridInfo.search)
      .pipe(
        map(response => ({
          ...response,
          data: response.data.map(record => new TypeOfStorage(record))
        }))
      );
  }

  saveTypeOfStorage(payload: TypeOfStorage): Observable<TypeOfStorage> {
    return this.http.post<TypeOfStorage>(`${this.apiUrl}/api/masters/tocs/save`, payload);
  }
}

import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as fromExperienceType from '../../../../../../store/masters/experience-types';
import { ExperienceType } from '../../../../../../store/masters/experience-types/models/experience-type.model';

@Component({
  selector: 'app-experience-type-update',
  templateUrl: './experience-type-update.component.html'
})
export class ExperienceTypeUpdateComponent {
  experienceType$ = this.store.pipe(select(fromExperienceType.getSelectedExperienceType));
  experienceTypeId$ = this.store.pipe(select(fromExperienceType.getSelectedExperienceTypeId));

  pending$ = this.store.pipe(select(fromExperienceType.getExperienceTypeItemPending));
  error$ = this.store.pipe(select(fromExperienceType.getExperienceTypeItemError));

  constructor(private readonly store: Store<fromExperienceType.State>) {}

  onSubmit($event: ExperienceType) {
    this.store.dispatch(new fromExperienceType.UpdateRecord($event));
  }
}

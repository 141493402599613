import { Injectable } from '@angular/core'
import { Observable, map } from 'rxjs'
import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { environment } from '../../../environments/environment'
import store from 'store'
import { UploadFile } from '../../store/upload/model/upload.file.model';
import { OverallStatisticsReport, OverallStatisticsReportApiModel } from '../../store/reports/models/overall-statistics-report.model';


@Injectable({
    providedIn: 'root'
  })
export class ReportsService {
  apiUrl: any = environment.api_url
  constructor(private http: HttpClient) { }

  
  getOverallStatistics(): Observable<any> {
    console.log(`Inside service get report...`);
    return this.http.get(`${this.apiUrl}/api/reports/overall/statistics`)
      .pipe(
        map((response: OverallStatisticsReportApiModel[]) => ({
          data: response.map(record => new OverallStatisticsReport(record))
        }))
      );
  }
}

<div class="modalDialogBox">
    <div class="modal-header bg-green-400 text-white">
      <h4 class="modal-title" id="modal-basic-title">
        {{ expertType.name }}
      </h4>
      <button
        type="button"
        class="btn btn-outline-white"
        aria-label="Close"
        (click)="activeModal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-md-12">
          <div class="table-responsive">
            <table class="table table-bordered mb-0">
              <tbody>
                <tr>
                  <td width="25%"><strong>Description</strong></td>
                  <td width="75%">{{ expertType.description }}</td>
                </tr>
                <tr>
                  <td width="25%"><strong>Status</strong></td>
                  <td width="75%">
                    {{ expertType.status == 1 ? "Active" : "Inactive" }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">&nbsp;</div>
      </div>
    </div>
  </div>
  
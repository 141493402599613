import { NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RegisterYourServiceRoutingModule } from './register-your-service-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RegisterYourServiceComponent } from './register-your-service.component';
import { ExperienceTypesModule } from '../masters/experience-types/experience-types.module';
import { DistrictsModule } from '../masters/districts/districts.module';
import { StatesModule } from '../masters/states/states.module';
import { ExpertTypesModule } from '../masters/expert-types/expert-types.module';
import { EquipmentsModule } from '../masters/equipments/equipments.module';
import { TypeOfStoragesModule } from '../masters/type-of-storages/type-of-storages.module';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { COLD_STORAGE_REGISTER_SERVICE_EFFECTS } from '../../../store/cold-storage/register-service/effects';
import { COLD_STORAGE_REGISTER_SERVICE_REDUCERS } from '../../../store/cold-storage/register-service/register-service.state';
import { HeaderModule } from '../../header/header.module';
import { FooterModule } from '../../footer/footer.module';
import { SelectDropDownModule } from 'ngx-select-dropdown';

@NgModule({
  declarations: [
    RegisterYourServiceComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RegisterYourServiceRoutingModule,
    HeaderModule,
    FooterModule,
    SelectDropDownModule,
    StatesModule,
    DistrictsModule,
    ExperienceTypesModule,
    ExpertTypesModule,
    EquipmentsModule,
    TypeOfStoragesModule,

    StoreModule.forFeature('register_services', COLD_STORAGE_REGISTER_SERVICE_REDUCERS),
    EffectsModule.forFeature(COLD_STORAGE_REGISTER_SERVICE_EFFECTS),
  ],
  exports:[
    // RegisterYourServiceComponent
  ],
  schemas:[NO_ERRORS_SCHEMA],
})
export class RegisterYourServiceModule { }

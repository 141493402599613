export interface ExperienceTypeApiModel {
  id: string;
  name: string;
  description: string;

  status: number;
  updated_on: Date;
  selected: boolean;
}

export class ExperienceType {
  id: string;
  name: string;
  description: string;

  status: number;
  updated_on: Date;
  selected: boolean = false;

  constructor(raw: ExperienceTypeApiModel) {
    this.id = raw.id;
    this.name = raw.name;
    this.description = raw.description;

    this.status = raw.status;
    this.updated_on = raw.updated_on;
    this.selected = raw.selected;
  }
}

import { Component } from '@angular/core';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrl: './contact-us.component.scss'
})
export class ContactUsComponent {

  constructor() { }

  ngOnInit(): void {
  }

  contactInfoBox = [
      {
          icon: 'bx bx-map',
          title: 'Our Address',
          info: [
              {
                  text: '2nd Floor,SBI, Vidyut Soudha, Gunadala, Vijayawada, Andhra Pradesh 520004'
              }
          ]
      },
  ]

  sectionTitle = [
      {
          title: 'Ready to Get Started?',
          paragraph: 'Your email address will not be published. Required fields are marked *'
      }
  ]

  contactImage = [
      {
          img: 'assets/img/contact.png'
      }
  ]

  submit(form){
      var name = form.name;
      console.log(name);
      
      var email = form.email;
      console.log(email);

      var number = form.number;
      console.log(number);
      
      var message = form.message;
      console.log(message);
  }
}

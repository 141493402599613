import { EquipmentSupplierModel } from './../../../../../store/cold-storage/equipment-suppliers/models/equipment-supplier.model';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EmailValidator } from '../../../../../shared/form-validators/email.validator';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { select, Store } from '@ngrx/store';
import * as Reducers from '../../../../../store/reducers';
import localstore from 'store';
import { SupplierEquipmentMappingModel } from '../../../../../store/cold-storage/equipment-suppliers/models/supplier-equipment-mapping.model';
import lstore from "store";

@Component({
  selector: 'app-equipment-supplier-form',
  templateUrl: './equipment-supplier-form.component.html',
  styleUrl: './equipment-supplier-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EquipmentSupplierFormComponent {
  loggedInUserAccess: boolean = false;
  @Input() equipmentSupplierId: string | null = null;

  createdOn: Date = null;
  lastUpdatedOn: Date = null;
  @Input()
  set equipmentSupplier(equipmentSupplierData: EquipmentSupplierModel | undefined) {
    if (equipmentSupplierData) {
      this.form.patchValue(equipmentSupplierData);
      this.selectedStateId = equipmentSupplierData.state_id;
      this.selectedDistrictId = equipmentSupplierData.district_id;

      this.selectedEquipments = [];
      
      equipmentSupplierData.cses_equipment_mapping_data.forEach((element) => {
        this.selectedEquipments.push({
          id: element.equipment_id,
          name: element.equipment,
        });
      });
      this.lastUpdatedOn = equipmentSupplierData.updated_on;
      this.createdOn = equipmentSupplierData.created_on;
    }
  }

  @Input()
  set pending(isPending: boolean) {
    this.isPending = isPending;
    if (isPending) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  @Input() error: string | null;
  @Output() submitted = new EventEmitter<EquipmentSupplierModel>();

  loggedInUserId: any;
  isPending: boolean;
  mask = [
    /[1-9]/,
    ' ',
    '(',
    /[1-9]/,
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    ' ',
    '-',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];
  form: FormGroup;
  selectedStateId: any = '0';
  selectedStateName: any = 'NA';
  selectedDistrictId: any = '0';
  selectedDistrictName: any = 'NA';
  selectedEquipmentInNames: any = '';
  selectedEquipmentInIds: any = [];
  selectedEquipments: any = [];

  constructor(private store: Store<any>, public activeModal: NgbActiveModal) {
    this.store.pipe(select(Reducers.getUser)).subscribe((state) => {
      this.loggedInUserId = state.id;
    });
    this.loggedInUserAccess = lstore.get("aae");

    this.form = new FormGroup({
      id: new FormControl(''),
      user_id: new FormControl(this.loggedInUserId, Validators.required),
      organisation_name: new FormControl(
        '',
        Validators.compose([Validators.required, Validators.maxLength(500)])
      ),
      contact_person: new FormControl(
        '',
        Validators.compose([Validators.required, Validators.maxLength(250)])
      ),
      contact_number: new FormControl(
        '',
        Validators.compose([Validators.required, Validators.maxLength(10)])
      ),
      email: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          EmailValidator.validate,
          Validators.maxLength(200),
        ])
      ),
      // state_id: new FormControl('', Validators.required),
      // district_id: new FormControl('', Validators.required),
      full_address: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
      total_experience: new FormControl('', Validators.required),
      anual_turnover: new FormControl(''),
      is_active: new FormControl('1'),
      is_approved: new FormControl('0'),
    });
  }

  onStateDropdownItemSelect(selectedElement: any) {
    this.selectedStateId = selectedElement.id;
    this.selectedStateName = selectedElement.text;

    this.selectedDistrictId = '0';
    this.selectedDistrictName = 'NA';
  }

  onDistrictDropdownItemSelect(selectedElement: any) {
    this.selectedDistrictId = selectedElement.id;
    this.selectedDistrictName = selectedElement.text;
  }

  searchChange($event) {
    console.log($event);
  }

  onEquipmentDropdownItemSelect(selectedData: any) {
    console.log(`Unique selected value ${selectedData.ids.length}`);
    // console.log(`Unique selected value ${JSON.stringify(selectedData.text)}`);
    this.selectedEquipmentInIds = [];
    this.selectedEquipmentInNames = selectedData.text;
    this.selectedEquipmentInIds = selectedData.ids;
  }

  submit() {
    if (this.form.valid) {
      const model = this.form.value;
      const equipment_data: Array<SupplierEquipmentMappingModel> =
        [];
      this.selectedEquipmentInIds.forEach((element) => {
        equipment_data.push({
          equipment_id: element,
        });
      });
      model.cses_equipment_mapping_data = [...equipment_data];
      model.state_id = this.selectedStateId;
      model.district_id = this.selectedDistrictId;

      if (typeof model.primary_contact_person_mobile_no === 'string') {
        model.primary_contact_person_mobile_no = Number(
          model.primary_contact_person_mobile_no.replace(/[^0-9]/g, '')
        );
      }
      console.log(`Form data: ${JSON.stringify(model)}`);
      this.submitted.emit(model);
    }
  }
}


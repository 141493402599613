<!-- Start How It Works Area -->
<div class="container">
    <div class="section-title" *ngFor="let Title of sectionTitle;">
        <h2>{{Title.title}}</h2>
        <p>{{Title.paragraph}}</p>
    </div>

    <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let Content of howItWorksBox;">
            <div class="single-how-it-works-box">
                <div class="icon">
                    <i class="{{Content.icon}}"></i>
                </div>
                <h3>{{Content.title}}</h3>
                <p>{{Content.paragraph}}</p>
            </div>
        </div>
    </div>
</div>
<!-- End How It Works Area -->
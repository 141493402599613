<div class="modalDialogBox">
    <div class="modal-header bg-green-400 text-white">
      <h4 class="modal-title" id="modal-basic-title">
        {{ owner.cold_storage_name }}
        <br />
        <small class="small-text">
          <strong>Created On:</strong>&nbsp;
          {{ owner.created_on | date : "short" }}
          &nbsp;&nbsp;
          <strong>Last Updated On:</strong>&nbsp;
          {{ owner.updated_on | date : "short" }}
        </small>
      </h4>
      <button
        type="button"
        class="btn btn-outline-white"
        aria-label="Close"
        (click)="activeModal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-md-12">
          <div class="table-responsive">
            <table class="table table-bordered mb-0">
              <tbody>
                <tr>
                  <td width="25%"><strong>State</strong></td>
                  <td width="75%">{{ owner.state }}</td>
                </tr>
                <tr>
                  <td width="25%"><strong>District</strong></td>
                  <td width="75%">{{ owner.district }}</td>
                </tr>
                <tr>
                  <td width="25%"><strong>Address</strong></td>
                  <td width="75%">{{ owner.full_address }}</td>
                </tr>
                <tr>
                  <td width="100%" colspan="2">
                    <strong>Primary Contact Person Detail(s)</strong>
                  </td>
                </tr>
                <tr>
                  <td width="25%"><strong>Name</strong></td>
                  <td width="75%">{{ owner.contact_person }}</td>
                </tr>
                <tr>
                  <td width="25%"><strong>Mobile No</strong></td>
                  <td width="75%">{{ owner.contact_number }}</td>
                </tr>
                <tr>
                  <td width="25%"><strong>Email</strong></td>
                  <td width="75%">{{ owner.email }}</td>
                </tr>
                <tr>
                  <td width="100%" colspan="2">
                    <strong>Other Detail(s)</strong>
                  </td>
                </tr>
                <tr>
                  <td width="25%"><strong>Cold Storage Capacity</strong></td>
                  <td width="75%">{{ owner.cold_storage_capacity }}</td>
                </tr>
                <tr>
                  <td width="25%"><strong>Cold Storage Area</strong></td>
                  <td width="75%">{{ owner.cold_storage_area }}</td>
                </tr>
                <tr>
                  <td width="25%"><strong>Experience In</strong></td>
                  <td width="75%">
                    <span
                      class="badge yellow"
                      *ngFor="
                        let et of owner.cso_tocs_mapping_data
                      "
                    >
                      {{ et.type_of_cold_storage }}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td width="25%"><strong>Status</strong></td>
                  <td width="75%">
                    {{ owner.is_active == 1 ? "Active" : "Inactive" }}
                  </td>
                </tr>
                <tr>
                  <td width="25%"><strong>Detailed Information</strong></td>
                  <td width="75%">{{ owner.description }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">&nbsp;</div>
      </div>
    </div>
  </div>
  
import { Injectable } from "@angular/core";

import { Actions, Effect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";

import { Observable, of as observableOf } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";

import * as actions from "./../actions";
import { ExperienceTypeService } from "../../../../services/masters/experience-types";
import { LoadDropdownByUserRecords, LoadDropdownRecords, LoadRecords } from "./../actions";

@Injectable()
export class ExperienceTypeListEffects {
  @Effect()
  loadList$: Observable<Action> = this.actions$.pipe(
    ofType<LoadRecords>(actions.ExperienceTypeListActionTypes.LoadRecords),
    switchMap(({ gridInfo }) =>
      this.experienceTypeService.getExperienceTypeList(gridInfo).pipe(
        map((records) => new actions.LoadRecordsSuccess(records)),
        catchError((error) =>
          observableOf(new actions.LoadRecordsFailure(error))
        )
      )
    )
  );

  @Effect()
  loadDropdownList$: Observable<Action> = this.actions$.pipe(
    ofType<LoadDropdownRecords>(
      actions.ExperienceTypeListActionTypes.LoadDropdownRecords
    ),
    switchMap(() =>
      this.experienceTypeService.getDropdownList().pipe(
        map((records) => new actions.LoadDropdownRecordsSuccess(records)),
        catchError((error) =>
          observableOf(new actions.LoadDropdownRecordsFailure(error))
        )
      )
    )
  );

  @Effect()
  loadDropdownByUserList$: Observable<Action> = this.actions$.pipe(
    ofType<LoadDropdownByUserRecords>(
      actions.ExperienceTypeListActionTypes.LoadDropdownByUserRecords
    ),
    switchMap(() =>
      this.experienceTypeService.getDropdownListByUser().pipe(
        map((records) => new actions.LoadDropdownByUserRecordsSuccess(records)),
        catchError((error) =>
          observableOf(new actions.LoadDropdownByUserRecordsFailure(error))
        )
      )
    )
  );

  constructor(private actions$: Actions, private experienceTypeService: ExperienceTypeService) {}
}

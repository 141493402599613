<app-sidemenu></app-sidemenu>

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">
  <app-header></app-header>
  <!-- Breadcrumb Area -->
  <div class="breadcrumb-area">
    <h1>Experience Type(s)</h1>
    <ol class="breadcrumb">
      <li class="item"><a routerLink="/dashboard">Dashboard</a></li>
      <li class="item">
        <a routerLink="/experience-types">Experience Type(s)</a>
      </li>
    </ol>
    <div class="ms-auto">
        <a href="javascript:;" class="btn btn-outline-dark" (click)="addItem()">
            <i class="fa fa-plus fa-lg me-2 ms-n2 text-white-900"></i> New Record
        </a>
    </div>
  </div>
  <!-- End Breadcrumb Area -->

  <!-- Start -->

  <div class="row">
    <div class="col-lg-12 col-md-12">
      <div class="row">
        <div class="col-lg-6">
          <div class="input-group input-group-sm mb-3">
            <input
              type="text"
              class="form-control input-white"
              placeholder="Enter search keywords here..."
              [(ngModel)]="searchBy"
              style="height: 30px !important"
            />
            <button type="button" class="btn btn-success" (click)="search()">
              <i class="fa fa-search fa-fw"></i> Search
            </button>
          </div>
        </div>
        <div class="col-lg-3">
          <button
            type="button"
            class="btn btn-success btn-sm"
            style="width: 100% !important"
            (click)="clear()"
          >
            <i class="fa fa-times fa-fw"></i> Clear Search
          </button>
        </div>
        <div class="col-lg-3">
          <button
            type="button"
            class="btn btn-warning btn-sm"
            style="width: 100% !important"
          >
            <i class="fa fa-download fa-fw me-1 text-opacity-50"></i
            >&nbsp;Export Grid Data
          </button>
        </div>
      </div>
      <div class="row">
        <!-- BEGIN col-12 -->
        <div class="col-lg-12">
          <div class="card border-0">
            <!-- <div class="table-responsive"> -->
            <app-experience-type-list
              [experienceTypesData]="experienceTypes$ | async"
              [loading]="experienceTypesLoading$ | async"
              [count]="experienceTypesCount$ | async"
              [offset]="experienceTypesOffset$ | async"
              [search]="experienceTypesSearch$ | async"
              [sortProp]="experienceTypesSortProp$ | async"
              [sortDir]="experienceTypesSortDir$ | async"
              (selectItem)="onItemSelected($event)"
              (editItem)="onItemUpdate($event)"
              (reload)="onGridUpdate($event)"
            >
            </app-experience-type-list>
            <!-- </div> -->
          </div>
        </div>
        <!-- END col-12 -->
      </div>
      <!-- End -->
      <!-- END row -->
    </div>
  </div>
  <!-- End -->

  <div class="flex-grow-1"></div>

  <app-footer></app-footer>
</div>
<!-- End Main Content Wrapper Area -->

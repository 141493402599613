import { Action } from '@ngrx/store';
import { LoadSuccessPayload } from '../../../../shared/models/common.payload';
import { GridPayload, LoadGridSuccessPayload } from '../../../../shared/models/grid.payload';
import { State, StateApiModel } from '../../../../store/masters/states/models/state.model';

export enum StateListActionTypes {
    LoadRecords = '[States] Load',
    LoadRecordsSuccess = '[States] Load Success',
    LoadRecordsFailure = '[States] Load Failure',

    LoadDropdownRecords = '[States Dropdown] Load',
    LoadDropdownRecordsSuccess = '[States Dropdown] Load Success',
    LoadDropdownRecordsFailure = '[States Dropdown] Load Failure',

    LoadDropdownByUserRecords = '[States Dropdown By User] Load',
    LoadDropdownByUserRecordsSuccess = '[States Dropdown By User] Load Success',
    LoadDropdownByUserRecordsFailure = '[States Dropdown By User] Load Failure',

    SelectRecord = '[State] Select Record',
}

////////////

export class SelectRecord implements Action {
  readonly type = StateListActionTypes.SelectRecord;

  constructor(public payload: string) {
  }
}

export class LoadRecords implements Action {
  readonly type = StateListActionTypes.LoadRecords;

  constructor(public gridInfo: GridPayload) {
  }
}

export class LoadRecordsSuccess implements Action {
  readonly type = StateListActionTypes.LoadRecordsSuccess;

  constructor(public payload: LoadGridSuccessPayload<StateApiModel>) {
  }
}

export class LoadRecordsFailure implements Action {
  readonly type = StateListActionTypes.LoadRecordsFailure;

  constructor(public payload: any) {
  }
}

export class LoadDropdownRecords implements Action {
    readonly type = StateListActionTypes.LoadDropdownRecords;

    constructor() {
    }
}

export class LoadDropdownRecordsSuccess implements Action {
    readonly type = StateListActionTypes.LoadDropdownRecordsSuccess;

    constructor(public payload: LoadSuccessPayload<StateApiModel>) {
    }
}

export class LoadDropdownRecordsFailure implements Action {
    readonly type = StateListActionTypes.LoadDropdownRecordsFailure;

    constructor(public payload: any) {
    }
}


export class LoadDropdownByUserRecords implements Action {
  readonly type = StateListActionTypes.LoadDropdownByUserRecords;

  constructor() {
  }
}

export class LoadDropdownByUserRecordsSuccess implements Action {
  readonly type = StateListActionTypes.LoadDropdownByUserRecordsSuccess;

  constructor(public payload: LoadSuccessPayload<StateApiModel>) {
  }
}

export class LoadDropdownByUserRecordsFailure implements Action {
  readonly type = StateListActionTypes.LoadDropdownByUserRecordsFailure;

  constructor(public payload: any) {
  }
}
////////////

export type ListActions =
  | SelectRecord
  | LoadRecords
  | LoadRecordsSuccess
  | LoadRecordsFailure
  | LoadDropdownRecords
  | LoadDropdownRecordsSuccess
  | LoadDropdownRecordsFailure
  | LoadDropdownByUserRecords
  | LoadDropdownByUserRecordsSuccess
  | LoadDropdownByUserRecordsFailure;


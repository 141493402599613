import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as fromEquipment from '../../../../../../store/masters/equipments';
import { Equipment } from '../../../../../../store/masters/equipments/models/equipment.model';

@Component({
  selector: 'app-equipment-add',
  templateUrl: './equipment-add.component.html'
})
export class EquipmentAddComponent {
  pending$ = this.store.pipe(select(fromEquipment.getEquipmentItemPending));
  error$ = this.store.pipe(select(fromEquipment.getEquipmentItemError));

  constructor(private readonly store: Store<fromEquipment.State>) {}

  onSubmit($event: Equipment) {
    this.store.dispatch(new fromEquipment.AddRecord($event));
  }
}

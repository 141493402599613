import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnInit,
} from '@angular/core';
import { TypeOfStorage } from '../../../../../../store/masters/type-of-storages/models/type-of-storage.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-type-of-storage-details',
  templateUrl: './type-of-storage-details.component.html',
  styleUrl: './type-of-storage-details.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TypeOfStorageDetailsComponent implements OnInit {
  typeOfStorage: TypeOfStorage;

  @Input('selectedTypeOfStorage')
  set selectedTypeOfStorage(data: any) {
    this.typeOfStorage = data;
  }
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DesignConsultantPageComponent } from './containers/design-consultant-page/design-consultant-page.component';


const routes: Routes = [
  { path: '', component: DesignConsultantPageComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ColdStorageDesignConsultantsRoutingModule { }

import { Action } from '@ngrx/store';
import { RegisterServiceModel } from '../models/register-service.model';

export enum RegisterServiceItemActionTypes {
  AddRecord = '[Cold Storage Register Services] Add Record',
  AddRecordSuccess = '[Cold Storage Register Services] Add Record Success',
  AddRecordFailure = '[Cold Storage Register Services] Add Record Failure',
}

////////////

export class AddRecord implements Action {
  readonly type = RegisterServiceItemActionTypes.AddRecord;

  constructor(public payload: RegisterServiceModel) {}
}

export class AddRecordSuccess implements Action {
  readonly type = RegisterServiceItemActionTypes.AddRecordSuccess;

  constructor(public payload: RegisterServiceModel) {}
}

export class AddRecordFailure implements Action {
  readonly type = RegisterServiceItemActionTypes.AddRecordFailure;

  constructor(public payload: string) {}
}

////////////

export type ItemActions = AddRecord | AddRecordSuccess | AddRecordFailure;

import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  Optional,
} from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { EquipmentSupplierModel } from '../../../../../store/cold-storage/equipment-suppliers/models/equipment-supplier.model';

import * as fromEquipmentSupplier from '../../../../../store/cold-storage/equipment-suppliers';
import { EquipmentSupplierItemActionTypes } from '../../../../../store/cold-storage/equipment-suppliers';
import { select, Store } from '@ngrx/store';
import { GridPayload } from '../../../../../shared/models/grid.payload';
// import { MatDialog } from "@angular/material/dialog";

import { Actions, ofType } from '@ngrx/effects';
import { takeUntil, tap } from 'rxjs/operators';
import lstore from 'store';

@Component({
  selector: 'app-search-equipment-suppliers',
  templateUrl: './search-equipment-suppliers.component.html',
  styleUrl: './search-equipment-suppliers.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchEquipmentSuppliersComponent implements OnInit, OnDestroy {
  equipmentSuppliers$: Observable<any>;
  equipmentSuppliersLoading$ = this.store.pipe(
    select(fromEquipmentSupplier.getEquipmentSuppliersIsLoading)
  );

  equipmentSuppliersCount$: Observable<number> = this.store.pipe(
    select(fromEquipmentSupplier.getEquipmentSuppliersCount)
  );
  equipmentSuppliersOffset$: Observable<number> = this.store.pipe(
    select(fromEquipmentSupplier.getEquipmentSuppliersOffset)
  );
  equipmentSuppliersSearch$: Observable<string> = this.store.pipe(
    select(fromEquipmentSupplier.getEquipmentSuppliersSearch)
  );
  equipmentSuppliersSortProp$: Observable<string> = this.store.pipe(
    select(fromEquipmentSupplier.getEquipmentSuppliersSortProp)
  );
  equipmentSuppliersSortDir$: Observable<string> = this.store.pipe(
    select(fromEquipmentSupplier.getEquipmentSuppliersSortDir)
  );

  private destroyed$ = new Subject<boolean>();

  searchBy: any = '';

  constructor(private readonly store: Store<fromEquipmentSupplier.State>) {}

  ngOnInit(): void {
    this.loadEquipmentSuppliers();
    this.equipmentSuppliers$ = this.store.pipe(
      select(fromEquipmentSupplier.getAllEquipmentSuppliers)
    );
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  loadEquipmentSuppliers() {
    const info: GridPayload = {
      offset: 0,
      sortProp: 'organisation_name',
      sortDir: 'asc',
      search:
        this.searchBy == '' ||
        this.searchBy == undefined ||
        this.searchBy == null
          ? 'all'
          : this.searchBy,
    };
    this.store.dispatch(new fromEquipmentSupplier.LoadListingRecords(info));
  }
  pageTitleContent = [
    {
      title: 'Find Popular Cold Storage Equipment Suppliers',
    },
  ];

  // Category Select
  singleSelect: any = [];
  multiSelect: any = [];
  stringArray: any = [];
  objectsArray: any = [];
  resetOption: any;
  config: any = {
    displayKey: 'name',
    search: true,
  };
  searchChange($event) {
    console.log($event);
  }
  reset() {
    this.resetOption = [];
  }

  // Ordering Select
  options2 = [
    {
      name: 'Recommended',
    },
    {
      name: 'Default',
    },
    {
      name: 'Popularity',
    },
    {
      name: 'Latest',
    },
  ];

  rating = [
    {
      icon: 'bx bxs-star',
    },
    {
      icon: 'bx bxs-star',
    },
    {
      icon: 'bx bxs-star',
    },
    {
      icon: 'bx bxs-star',
    },
    {
      icon: 'bx bxs-star',
    },
  ];
  selectedEquipmentInNames: any = '';
  selectedEquipmentInIds: any = [];
  selectedEquipments: any = [];

  onEquipmentFilterListItemSelect(selectedData: any) {
    // this.selectedEquipmentInNames = selectedData.name;
    // this.selectedEquipmentInIds.push(selectedData.id);
    // this.searchBy = this.selectedEquipmentInIds.toString();
    this.searchBy = selectedData;
    // console.log(`Updated searchBy ${this.searchBy}`);
    this.loadEquipmentSuppliers();
  }

  
}


import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-how-it-works-page',
    templateUrl: './how-it-works-page.component.html',
    styleUrls: ['./how-it-works-page.component.scss']
})
export class HowItWorksPageComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    pageTitleContent = [
        {
            title: 'How it works',
            backgroundImage: 'assets/img/page-title/page-title5.jpg'
        }
    ]

    sectionTitle = [
        {
            title: 'How It Works',
            paragraph: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra.'
        }
    ]
    howItWorksBox = [
        {
            icon: 'flaticon-placeholder',
            title: 'Find Interesting Place',
            paragraph: 'Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.'
        },
        {
            icon: 'flaticon-support-1',
            title: 'Contact a Few Owners',
            paragraph: 'Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.'
        },
        {
            icon: 'flaticon-tick',
            title: 'Make a Reservation',
            paragraph: 'Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.'
        }
    ]
    timelineContent = [
        // {
        //     number: '1',
        //     title: 'Submit Listings',
        //     description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.',
        //     buttonText: 'Submit Now',
        //     buttonLink: '#'
        // },
        // {
        //     number: '2',
        //     title: 'Choose Your Package',
        //     description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.',
        //     buttonText: 'Choose Now',
        //     buttonLink: '#'
        // },
        {
            number: '1',
            title: 'Register',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.',
            buttonText: 'Submit Now',
            buttonLink: '#'
        },
        {
            number: '2',
            title: 'Login to My Account',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.',
            buttonText: 'Login Now',
            buttonLink: '#'
        },
        {
            number: '3',
            title: 'Submit Listing',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.',
            buttonText: 'Choose Now',
            buttonLink: '#'
        },
        {
            number: '4',
            title: 'Admin Approves',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.',
            buttonText: 'Browse Now',
            buttonLink: '#'
        }
    ]

}
import { OwnerModel } from './../../../../../store/cold-storage/owners/models/owner.model';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EmailValidator } from '../../../../../shared/form-validators/email.validator';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { select, Store } from '@ngrx/store';
import * as Reducers from '../../../../../store/reducers';
import localstore from 'store';
import { OwnerTypeOfColdStorageMappingModel } from '../../../../../store/cold-storage/owners/models/owner-type-of-cold-storage-mapping.model';
import lstore from "store";

@Component({
  selector: 'app-owner-form',
  templateUrl: './owner-form.component.html',
  styleUrl: './owner-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OwnerFormComponent {
  loggedInUserAccess: boolean = false;
  @Input() ownerId: string | null = null;

  createdOn: Date = null;
  lastUpdatedOn: Date = null;
  @Input()
  set owner(ownerData: OwnerModel | undefined) {
    if (ownerData) {
      this.form.patchValue(ownerData);
      this.selectedStateId = ownerData.state_id;
      this.selectedDistrictId = ownerData.district_id;

      this.selectedTypeOfColdStorageTypes = [];
      
      ownerData.cso_tocs_mapping_data.forEach((element) => {
        this.selectedTypeOfColdStorageTypes.push({
          id: element.type_of_cold_storage_id,
          name: element.type_of_cold_storage,
        });
      });
      this.lastUpdatedOn = ownerData.updated_on;
      this.createdOn = ownerData.created_on;
    }
  }

  @Input()
  set pending(isPending: boolean) {
    this.isPending = isPending;
    
    if (isPending) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  @Input() error: string | null;
  @Output() submitted = new EventEmitter<OwnerModel>();

  loggedInUserId: any;
  isPending: boolean;
  mask = [
    /[1-9]/,
    ' ',
    '(',
    /[1-9]/,
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    ' ',
    '-',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];
  form: FormGroup;
  selectedStateId: any = '0';
  selectedStateName: any = 'NA';
  selectedDistrictId: any = '0';
  selectedDistrictName: any = 'NA';
  selectedTypeOfColdStorageInNames: any = '';
  selectedTypeOfColdStorageInIds: any = [];
  selectedTypeOfColdStorageTypes: any = [];

  constructor(private store: Store<any>, public activeModal: NgbActiveModal) {
    this.store.pipe(select(Reducers.getUser)).subscribe((state) => {
      this.loggedInUserId = state.id;
    });
    this.loggedInUserAccess = lstore.get("aae");

    this.form = new FormGroup({
      id: new FormControl(''),
      user_id: new FormControl(this.loggedInUserId, Validators.required),
      cold_storage_name: new FormControl(
        '',
        Validators.compose([Validators.required, Validators.maxLength(500)])
      ),
      contact_person: new FormControl(
        '',
        Validators.compose([Validators.required, Validators.maxLength(250)])
      ),
      contact_number: new FormControl(
        '',
        Validators.compose([Validators.required, Validators.maxLength(10)])
      ),
      email: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          EmailValidator.validate,
          Validators.maxLength(200),
        ])
      ),
      // state_id: new FormControl('', Validators.required),
      // district_id: new FormControl('', Validators.required),
      full_address: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
      cold_storage_capacity: new FormControl('', Validators.required),
      cold_storage_area: new FormControl(''),
      is_active: new FormControl('1'),
      is_approved: new FormControl('0'),
    });
  }

  onStateDropdownItemSelect(selectedElement: any) {
    this.selectedStateId = selectedElement.id;
    this.selectedStateName = selectedElement.text;

    this.selectedDistrictId = '0';
    this.selectedDistrictName = 'NA';
  }

  onDistrictDropdownItemSelect(selectedElement: any) {
    this.selectedDistrictId = selectedElement.id;
    this.selectedDistrictName = selectedElement.text;
  }

  searchChange($event) {
    console.log($event);
  }

  onTypeOfColdStorageTypeDropdownItemSelect(selectedData: any) {
    console.log(`Unique selected value ${selectedData.ids.length}`);
    console.log(`Unique selected value ${JSON.stringify(selectedData.text)}`);
    this.selectedTypeOfColdStorageInIds = [];
    this.selectedTypeOfColdStorageInNames = selectedData.text;
    this.selectedTypeOfColdStorageInIds = selectedData.ids;
  }

  submit() {
    if (this.form.valid) {
      const model = this.form.value;
      const type_of_cold_storage_data: Array<OwnerTypeOfColdStorageMappingModel> =
        [];
      this.selectedTypeOfColdStorageInIds.forEach((element) => {
        type_of_cold_storage_data.push({
          type_of_cold_storage_id: element,
        });
      });
      model.cso_tocs_mapping_data = [...type_of_cold_storage_data];
      model.state_id = this.selectedStateId;
      model.district_id = this.selectedDistrictId;

      if (typeof model.primary_contact_person_mobile_no === 'string') {
        model.primary_contact_person_mobile_no = Number(
          model.primary_contact_person_mobile_no.replace(/[^0-9]/g, '')
        );
      }
      console.log(`Form data: ${JSON.stringify(model)}`);
      this.submitted.emit(model);
    }
  }
}

import { Injectable } from "@angular/core";

import { Actions, Effect, ofType } from "@ngrx/effects";
import { Action, select, Store } from "@ngrx/store";

import { Observable, of as observableOf } from "rxjs";
import {
  catchError,
  exhaustMap,
  map,
  tap,
  withLatestFrom,
} from "rxjs/operators";

import * as actions from "./../actions";
import { AddRecord, TypeOfStorageItemActionTypes, UpdateRecord } from "./../actions";
import * as fromTypeOfStorage from "./../type-of-storage.state";

import { TypeOfStorage } from "../models/type-of-storage.model";
import { TypeOfStorageService } from "../../../../services/masters/type-of-storages";
import { GridPayload } from "../../../../shared/models/grid.payload";

@Injectable()
export class TypeOfStorageItemEffects {
  @Effect()
  addRecord$: Observable<Action> = this.actions$.pipe(
    ofType<AddRecord>(TypeOfStorageItemActionTypes.AddRecord),
    map((action) => action.payload),
    exhaustMap((record: TypeOfStorage) =>
      this.batService.saveTypeOfStorage(record).pipe(
        map((payload: TypeOfStorage) => new actions.AddRecordSuccess(payload)),
        catchError((e) =>
          observableOf(new actions.AddRecordFailure(e.error.error))
        )
      )
    )
  );

  @Effect()
  updateRecord$: Observable<Action> = this.actions$.pipe(
    ofType<UpdateRecord>(TypeOfStorageItemActionTypes.UpdateRecord),
    map((action) => action.payload),
    exhaustMap((record: TypeOfStorage) =>
      this.batService.saveTypeOfStorage(record).pipe(
        map((payload: TypeOfStorage) => new actions.UpdateRecordSuccess(payload)),
        catchError((e) =>
          observableOf(new actions.UpdateRecordFailure(e.error.error))
        )
      )
    )
  );

  @Effect({ dispatch: false })
  reloadGrid$: Observable<any> = this.actions$.pipe(
    ofType(TypeOfStorageItemActionTypes.AddRecordSuccess),
    withLatestFrom(
      this.store.pipe(select(fromTypeOfStorage.getTypeOfStoragesOffset)),
      this.store.pipe(select(fromTypeOfStorage.getTypeOfStoragesSortProp)),
      this.store.pipe(select(fromTypeOfStorage.getTypeOfStoragesSortDir)),
      this.store.pipe(select(fromTypeOfStorage.getTypeOfStoragesSearch)),
      (action, offset, sortProp, sortDir, search) => ({
        offset,
        sortProp,
        sortDir,
        search,
      })
    ),
    tap((info: GridPayload) => {
      this.store.dispatch(new actions.LoadRecords(info));
    })
  );

  @Effect({ dispatch: false })
  closeModal$: Observable<any> = this.actions$.pipe(
    ofType(
      TypeOfStorageItemActionTypes.AddRecordSuccess,
      TypeOfStorageItemActionTypes.UpdateRecordSuccess
    ),
    withLatestFrom(
      this.store.pipe(select(fromTypeOfStorage.getTypeOfStoragesOffset)),
      this.store.pipe(select(fromTypeOfStorage.getTypeOfStoragesSortProp)),
      this.store.pipe(select(fromTypeOfStorage.getTypeOfStoragesSortDir)),
      this.store.pipe(select(fromTypeOfStorage.getTypeOfStoragesSearch)),
      (action, offset, sortProp, sortDir, search) => ({
        offset,
        sortProp,
        sortDir,
        search,
      })
    ),
    tap((info: GridPayload) => {
      this.store.dispatch(new actions.LoadRecords(info));
    })
  );

  constructor(
    private store: Store<fromTypeOfStorage.State>,
    private actions$: Actions,
    private batService: TypeOfStorageService
  ) {}
}

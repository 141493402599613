import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnInit,
} from "@angular/core";
import { District } from "../../../../../../store/masters/districts/models/district.model";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-district-details',
  templateUrl: './district-details.component.html',
  styleUrls: ['./district-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DistrictDetailsComponent implements OnInit {

  district: District;

  @Input("selectedDistrict") 
  set selectedDistrict(data: any) {
    this.district = data;
  }
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}

}

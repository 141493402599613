import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { State } from '../../../../../../store/masters/states/models/state.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-state-form',
  templateUrl: './state-form.component.html',
  styleUrls: ['./state-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StateFormComponent {
  @Input() stateId: string | null = null;

  lastUpdatedOn: Date = null;
  @Input()
  set state(stateData: State | undefined) {
    if (stateData) {
      this.form.patchValue(stateData);
      this.lastUpdatedOn = stateData.updated_on;
    }
  }

  @Input()
  set pending(isPending: boolean) {
    this.isPending = isPending;

    if (isPending) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  @Input() error: string | null;
  @Output() submitted = new EventEmitter<State>();

  isPending: boolean;
  mask = [
    /[1-9]/,
    ' ',
    '(',
    /[1-9]/,
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    ' ',
    '-',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];

  form: FormGroup = new FormGroup({
    id: new FormControl(''),
    name: new FormControl(
      '',
      Validators.compose([Validators.required, Validators.maxLength(500)])
    ),
    abbr: new FormControl(
      '',
      Validators.compose([Validators.required, Validators.maxLength(10)])
    ),
    status: new FormControl(1, Validators.required),
  });

  constructor(public activeModal: NgbActiveModal) {}

  submit() {
    if (this.form.valid) {
      this.submitted.emit(this.form.value);
    }
  }
}

import { EquipmentActionTypes, EquipmentItemActionTypes } from '../actions';

export interface EquipmentItemState {
    error: string | null;
    pending: boolean;
}

export const initialState: EquipmentItemState = {
    error: null,
    pending: false,
};

export function equipmentItemReducer(state = initialState, action: EquipmentActionTypes): EquipmentItemState {
    switch (action.type) {
        case EquipmentItemActionTypes.AddRecord:
        case EquipmentItemActionTypes.UpdateRecord: {
            return {
                ...state,
                error: null,
                pending: true
            };
        }

        case EquipmentItemActionTypes.AddRecordSuccess:
        case EquipmentItemActionTypes.UpdateRecordSuccess: {
            return {
                ...state,
                error: null,
                pending: false
            };
        }

        case EquipmentItemActionTypes.AddRecordFailure:
        case EquipmentItemActionTypes.UpdateRecordFailure: {
            return {
                ...state,
                error: action.payload,
                pending: false
            };
        }

        default:
            return state;
    }
}

export const getError = (state: EquipmentItemState) => state.error;
export const getPending = (state: EquipmentItemState) => state.pending;

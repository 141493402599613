import { Action } from '@ngrx/store';
import { ExpertType } from '../models/expert-type.model';


export enum ExpertTypeItemActionTypes {
  AddRecord = '[ExpertTypes] Add Record',
  AddRecordSuccess = '[ExpertTypes] Add Record Success',
  AddRecordFailure = '[ExpertTypes] Add Record Failure',

  UpdateRecord = '[ExpertTypes] Update Record',
  UpdateRecordSuccess = '[ExpertTypes] Update Record Success',
  UpdateRecordFailure = '[ExpertTypes] Update Record Failure',
}

////////////

export class AddRecord implements Action {
  readonly type = ExpertTypeItemActionTypes.AddRecord;

  constructor(public payload: ExpertType) {
  }
}

export class AddRecordSuccess implements Action {
  readonly type = ExpertTypeItemActionTypes.AddRecordSuccess;

  constructor(public payload: ExpertType) {
  }
}

export class AddRecordFailure implements Action {
  readonly type = ExpertTypeItemActionTypes.AddRecordFailure;

  constructor(public payload: string) {
  }
}

export class UpdateRecord implements Action {
  readonly type = ExpertTypeItemActionTypes.UpdateRecord;

  constructor(public payload: ExpertType) {
  }
}

export class UpdateRecordSuccess implements Action {
  readonly type = ExpertTypeItemActionTypes.UpdateRecordSuccess;

  constructor(public payload: ExpertType) {
  }
}

export class UpdateRecordFailure implements Action {
  readonly type = ExpertTypeItemActionTypes.UpdateRecordFailure;

  constructor(public payload: string) {
  }
}

////////////

export type ItemActions =
  | AddRecord
  | AddRecordSuccess
  | AddRecordFailure
  | UpdateRecord
  | UpdateRecordSuccess
  | UpdateRecordFailure;

import { Injectable } from '@angular/core';
import { BaseService } from '../../../base/base.service';
import { map } from 'rxjs/operators';
import {
  OwnerModel,
  OwnerApiModel
} from '../../../store/cold-storage/owners/models/owner.model';

import { GridPayload } from '../../../shared/models/grid.payload';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OwnerService extends BaseService {
  apiUrl: any = environment.api_url

  getOwnersList(gridInfo: GridPayload) {
    return this.gridRequest<OwnerApiModel>(`${this.apiUrl}/api/coldstorageowners/records`, gridInfo.offset, gridInfo.sortProp, gridInfo.sortDir, gridInfo.search)
      .pipe(
        map(response => ({
          ...response,
          data: response.data.map(tp => new OwnerModel(tp))
        }))
      );
  }

  getOwnersListings(gridInfo: GridPayload) {
    return this.gridRequest<OwnerApiModel>(`${this.apiUrl}/api/coldstorageowners/listings`, gridInfo.offset, gridInfo.sortProp, gridInfo.sortDir, gridInfo.search)
      .pipe(
        map(response => ({
          ...response,
          data: response.data.map(tp => new OwnerModel(tp))
        }))
      );
  }
  saveOwnerData(payload: OwnerModel): Observable<OwnerModel> {
    return this.http.post<OwnerModel>(`${this.apiUrl}/api/coldstorageowners/save`, payload);
  }
  
  updateStatus(payload: any): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/api/coldstorageowners/update/record/status`,
      payload
    );
  }
  
  updateApprovalStatus(payload: any): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/api/coldstorageowners/update/approval/status`,
      payload
    );
  }
  
}

import { Component, OnInit } from '@angular/core';
import lstore from 'store';

import { select, Store } from '@ngrx/store';
import * as Reducers from './../../store/reducers';
import * as UserActions from './../../store/user/actions';
import { jwtAuthService } from '../../services';

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrl: './sidemenu.component.scss',
})
export class SidemenuComponent implements OnInit {
  loggedInUserAccess: boolean = false;
  isUserLoggedIn: boolean;
  constructor(private store: Store<any>, private authService: jwtAuthService) {
    this.loggedInUserAccess = lstore.get('aae');

    this.authService.isLogged.subscribe((logged) => {
      this.isUserLoggedIn = logged;
    });
  }

  ngOnInit(): void {}

  classApplied = false;
  toggleClass() {
    this.classApplied = !this.classApplied;
  }

  logout() {
    //console.log(`Logout...`)
    this.store.dispatch(new UserActions.Logout());
    this.authService.checkStatus(); // don't forget this!
    this.authService.isLogged.subscribe((logged) => {
      this.isUserLoggedIn = logged;
    });
  }
}

import { GlobalState } from '../../../store';
import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromList from './reducers/list.reducer';
import * as fromItem from './reducers/item.reducer';


export interface StateState {
  list: fromList.StateListState;
  item: fromItem.StateItemState;
}

export interface State extends GlobalState {
  states: StateState;
}

export const STATE_REDUCERS: ActionReducerMap<StateState, any> = {
  list: fromList.stateListReducer,
  item: fromItem.stateItemReducer
};


////// selectors

export const getProjectsState = createFeatureSelector<State, StateState>('states');


export const getStateListState = createSelector(getProjectsState, state => state.list);
export const getStateItemState = createSelector(getProjectsState, state => state.item);


export const getStatesIsLoading = createSelector(getStateListState, fromList.getIsLoading);

export const getStatesCount = createSelector(getStateListState, fromList.getCount);
export const getStatesOffset = createSelector(getStateListState, fromList.getOffset);

export const getStatesSortProp = createSelector(getStateListState, fromList.getSortProp);
export const getStatesSortDir = createSelector(getStateListState, fromList.getSortDir);
export const getStatesSearch = createSelector(getStateListState, fromList.getSearch);

export const getSelectedStateId = createSelector(getStateListState, fromList.getSelectedId);


export const {
  selectEntities: getStateEntities,
  selectAll: getAllStates
} = fromList.adapter.getSelectors(getStateListState);


export const getSelectedState = createSelector(
  getStateEntities,
  getSelectedStateId,
  (entities, selectedId) => {
    return selectedId !== null && entities[selectedId];
  }
);


// Item

export const getStateItemPending = createSelector(getStateItemState, fromItem.getPending);
export const getStateItemError = createSelector(getStateItemState, fromItem.getError);



<ngx-datatable class="material" [rows]="experienceTypes" [loadingIndicator]="loading" [columnMode]="'force'" [reorderable]="false"
    [headerHeight]="40" [footerHeight]="50" [rowHeight]="'auto'" [scrollbarV]="false" [scrollbarH]="true"
    [virtualization]="false" [externalSorting]="true" [sorts]="[{prop: 'name',dir: 'asc'}]" (sort)="onSort($event)"
    [externalPaging]="true" [count]="count" [offset]="offset" [limit]="10" (page)='onPaginate($event)'
    (activate)="onActive($event)">
    <ngx-datatable-column name="Name" prop="name" [maxWidth]="500">
        <ng-template let-value="value" ngx-datatable-cell-template>
            <strong>{{ value }}</strong>
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Description" prop="description" [maxWidth]="300" [sortable]="false" [cellClass]="'text-cyan-700'">
        <ng-template let-value="value" ngx-datatable-cell-template>{{ value }}
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Status" prop="status" [maxWidth]="120">
        <ng-template let-value="value" ngx-datatable-cell-template>
            <!-- {{ value == 1 ? 'Active' : 'Inactive' }} -->
            <span class="badge yellow" *ngIf="value == 1">Active</span>
            <span class="badge red" *ngIf="value == 0">Inactive</span>
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Last Updated On" prop="updated_on" [maxWidth]="200">
        <ng-template let-value="value" ngx-datatable-cell-template>
            {{value | date:'short'}}
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column [maxWidth]="100" [sortable]="false">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <!-- <button class="btn btn-yellow btn-sm" (click)="editItem.emit(row)" *ngIf="loggedInUserAccess"> -->
            <button class="btn btn-outline-success btn-sm" (click)="editItem.emit(row)">
                <i class="fa fa-edit"></i> Edit
            </button>
        </ng-template>
    </ngx-datatable-column>

</ngx-datatable>
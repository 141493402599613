import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as fromOwner from '../../../../../store/cold-storage/owners';
import { OwnerModel } from '../../../../../store/cold-storage/owners/models/owner.model';

@Component({
  selector: 'app-owner-update',
  templateUrl: './owner-update.component.html'
})
export class OwnerUpdateComponent {

  owner$ = this.store.pipe(select(fromOwner.getSelectedOwner));
  ownerId$ = this.store.pipe(select(fromOwner.getSelectedOwnerId));

  pending$ = this.store.pipe(select(fromOwner.getOwnerItemPending));
  error$ = this.store.pipe(select(fromOwner.getOwnerItemError));

  constructor(private readonly store: Store<fromOwner.State>) {
  }

  onSubmit($event: any) {
    this.store.dispatch(new fromOwner.UpdateRecord($event));
  }
}

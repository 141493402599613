export interface ExpertTypeApiModel {
    id: string;
    name: string;
    description: string;
  
    status: number;
    updated_on: Date;
  }
  
  export class ExpertType {
    id: string;
    name: string;
    description: string;
  
    status: number;
    updated_on: Date;
  
    constructor(raw: ExpertTypeApiModel) {
      this.id = raw.id;
      this.name = raw.name;
      this.description = raw.description;
  
      this.status = raw.status;
      this.updated_on = raw.updated_on;
    }
  }
  
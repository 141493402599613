import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { EquipmentSupplierModel } from '../models/equipment-supplier.model';
import { EquipmentSupplierActionTypes, EquipmentSupplierItemActionTypes, EquipmentSupplierListActionTypes } from '../actions';


export interface EquipmentSupplierListState extends EntityState<EquipmentSupplierModel> {
  isLoading: boolean;
  selectedEquipmentSupplierId: string | null;

  count: number;
  offset: number;

  sortProp: string;
  sortDir: string;

  search:string;
}


export const adapter: EntityAdapter<EquipmentSupplierModel> = createEntityAdapter<EquipmentSupplierModel>({
  selectId: (client: EquipmentSupplierModel) => client.id,
});


export const initialState: EquipmentSupplierListState = adapter.getInitialState({
  isLoading: false,
  selectedEquipmentSupplierId: null,

  count: 0,
  offset: 0,

  sortProp: 'name',
  sortDir: 'asc',
  search: 'all'
});


export function equipmentSupplierListReducer(state = initialState, action: EquipmentSupplierActionTypes): EquipmentSupplierListState {
  switch (action.type) {

    case EquipmentSupplierListActionTypes.LoadRecords: {
      return {
        ...state,
        ...action.gridInfo,
        isLoading: true,
      };
    }

    case EquipmentSupplierListActionTypes.LoadRecordsSuccess: {
      return adapter.addMany(action.payload.data as EquipmentSupplierModel[], {
        ...adapter.removeAll(state),
        isLoading: false,
        count: action.payload.count
      });
    }

    case EquipmentSupplierListActionTypes.LoadListingRecords: {
      return {
        ...state,
        ...action.gridInfo,
        isLoading: true,
      };
    }

    case EquipmentSupplierListActionTypes.LoadListingRecordsSuccess: {
      return adapter.addMany(action.payload.data as EquipmentSupplierModel[], {
        ...adapter.removeAll(state),
        isLoading: false,
        count: action.payload.count
      });
    }

    case EquipmentSupplierItemActionTypes.UpdateRecordSuccess: {
      return adapter.upsertOne(action.payload, state);
    }

    case EquipmentSupplierItemActionTypes.UpdateStatusRecordSuccess: {
      return adapter.upsertOne(action.payload, state);
    }

    case EquipmentSupplierItemActionTypes.UpdateApprovalStatusRecordSuccess: {
      return adapter.upsertOne(action.payload, state);
    }

    case EquipmentSupplierListActionTypes.SelectRecord: {
      return {
        ...state,
        selectedEquipmentSupplierId: action.payload
      };
    }

    
    default:
      return state;
  }
}


export const getIsLoading = (state: EquipmentSupplierListState) => state.isLoading;

export const getCount = (state: EquipmentSupplierListState) => state.count;
export const getOffset = (state: EquipmentSupplierListState) => state.offset;

export const getSortProp = (state: EquipmentSupplierListState) => state.sortProp;
export const getSortDir = (state: EquipmentSupplierListState) => state.sortDir;
export const getSearch = (state: EquipmentSupplierListState) => state.search;

export const getSelectedId = (state: EquipmentSupplierListState) => state.selectedEquipmentSupplierId;

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ExperienceType } from '../../../../../../store/masters/experience-types/models/experience-type.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-experience-type-form',
  templateUrl: './experience-type-form.component.html',
  styleUrl: './experience-type-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExperienceTypeFormComponent {

  @Input() experienceTypeId: string | null = null;

  lastUpdatedOn: Date = null;
  @Input()
  set experienceType(experienceTypeData: ExperienceType | undefined) {
    if (experienceTypeData) {
      this.form.patchValue(experienceTypeData);
      this.lastUpdatedOn = experienceTypeData.updated_on;
    }
  }

  @Input()
  set pending(isPending: boolean) {
    this.isPending = isPending;

    if (isPending) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  @Input() error: string | null;
  @Output() submitted = new EventEmitter<ExperienceType>();

  isPending: boolean;
  mask = [
    /[1-9]/,
    ' ',
    '(',
    /[1-9]/,
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    ' ',
    '-',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];

  form: FormGroup = new FormGroup({
    id: new FormControl(''),
    name: new FormControl(
      '',
      Validators.compose([Validators.required, Validators.maxLength(500)])
    ),
    description: new FormControl('', Validators.required),
    status: new FormControl(1, Validators.required),
  });

  constructor(public activeModal: NgbActiveModal) {}

  submit() {
    if (this.form.valid) {
      this.submitted.emit(this.form.value);
    }
  }
}

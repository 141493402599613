import { DistrictActionTypes, DistrictItemActionTypes } from '../actions';

export interface DistrictItemState {
    error: string | null;
    pending: boolean;
}

export const initialState: DistrictItemState = {
    error: null,
    pending: false,
};

export function districtItemReducer(state = initialState, action: DistrictActionTypes): DistrictItemState {
    switch (action.type) {
        case DistrictItemActionTypes.AddRecord:
        case DistrictItemActionTypes.UpdateRecord: {
            return {
                ...state,
                error: null,
                pending: true
            };
        }

        case DistrictItemActionTypes.AddRecordSuccess:
        case DistrictItemActionTypes.UpdateRecordSuccess: {
            return {
                ...state,
                error: null,
                pending: false
            };
        }

        case DistrictItemActionTypes.AddRecordFailure:
        case DistrictItemActionTypes.UpdateRecordFailure: {
            return {
                ...state,
                error: action.payload,
                pending: false
            };
        }

        default:
            return state;
    }
}

export const getError = (state: DistrictItemState) => state.error;
export const getPending = (state: DistrictItemState) => state.pending;

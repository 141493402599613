import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as fromDesignConsultant from '../../../../../store/cold-storage/design-consultants';
import { DesignConsultantModel } from '../../../../../store/cold-storage/design-consultants/models/design-consultant.model';

@Component({
  selector: 'app-design-consultant-update',
  templateUrl: './design-consultant-update.component.html'
})
export class DesignConsultantUpdateComponent {

  designConsultant$ = this.store.pipe(select(fromDesignConsultant.getSelectedDesignConsultant));
  designConsultantId$ = this.store.pipe(select(fromDesignConsultant.getSelectedDesignConsultantId));

  pending$ = this.store.pipe(select(fromDesignConsultant.getDesignConsultantItemPending));
  error$ = this.store.pipe(select(fromDesignConsultant.getDesignConsultantItemError));

  constructor(private readonly store: Store<fromDesignConsultant.State>) {
  }

  onSubmit($event: any) {
    this.store.dispatch(new fromDesignConsultant.UpdateRecord($event));
  }

}

import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  Optional,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { OwnerModel } from '../../../../../store/cold-storage/owners/models/owner.model';

import * as fromOwner from '../../../../../store/cold-storage/owners';
import { OwnerItemActionTypes } from '../../../../../store/cold-storage/owners';
import { select, Store } from '@ngrx/store';
import { GridPayload } from '../../../../../shared/models/grid.payload';
// import { MatDialog } from "@angular/material/dialog";

import { Actions, ofType } from '@ngrx/effects';
import { takeUntil, tap } from 'rxjs/operators';
import lstore from 'store';
import {
  NgbActiveModal,
  NgbModal,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import { OwnerAddComponent } from '../owner-add/owner-add.component';
import { OwnerDetailsComponent } from '../../components/owner-details/owner-details.component';
import { OwnerUpdateComponent } from '../owner-update/owner-update.component';

@Component({
  selector: 'app-owner-page',
  templateUrl: './owner-page.component.html',
  styleUrl: './owner-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OwnerPageComponent  implements OnInit, OnDestroy {
  loggedInUserAccess: boolean = false;
  // owners$: Observable<Owner[]> = this.store.pipe(select(fromOwner.getAllOwners));
  owners$: Observable<any>;
  ownersLoading$ = this.store.pipe(
    select(fromOwner.getOwnersIsLoading)
  );

  ownersCount$: Observable<number> = this.store.pipe(
    select(fromOwner.getOwnersCount)
  );
  ownersOffset$: Observable<number> = this.store.pipe(
    select(fromOwner.getOwnersOffset)
  );
  ownersSearch$: Observable<string> = this.store.pipe(
    select(fromOwner.getOwnersSearch)
  );
  ownersSortProp$: Observable<string> = this.store.pipe(
    select(fromOwner.getOwnersSortProp)
  );
  ownersSortDir$: Observable<string> = this.store.pipe(
    select(fromOwner.getOwnersSortDir)
  );

  private destroyed$ = new Subject<boolean>();

  searchBy: any = '';
  modalReference: NgbModalRef;

  constructor(
    private readonly store: Store<fromOwner.State>,
    private modalService: NgbModal,
    @Optional() private activeModal: NgbActiveModal,
    actions$: Actions
  ) {
    this.loggedInUserAccess = lstore.get('aae');
    actions$
      .pipe(
        ofType(
          OwnerItemActionTypes.AddRecordSuccess,
          OwnerItemActionTypes.UpdateRecordSuccess
        ),
        takeUntil(this.destroyed$),
        tap(() => {
          this.modalReference.close();
        })
      )
      .subscribe();
  }

  ngOnInit() {
    this.loadOwners();
    this.owners$ = this.store.pipe(
      select(fromOwner.getAllOwners)
    );
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  loadOwners() {
    const info: GridPayload = {
      offset: 0,
      sortProp: 'cold_storage_name',
      sortDir: 'asc',
      search:
        this.searchBy == '' ||
        this.searchBy == undefined ||
        this.searchBy == null
          ? 'all'
          : this.searchBy,
    };
    this.store.dispatch(new fromOwner.LoadRecords(info));
  }

  search() {
    this.loadOwners();
  }

  clear() {
    this.searchBy = '';
    this.loadOwners();
  }

  addItem(): void {
    this.modalReference = this.modalService.open(OwnerAddComponent, {
      backdrop: 'static',
      size: 'xl',
      keyboard: false,
      centered: true,
      backdropClass: 'light-blue-backdrop',
    });
  }

  onGridUpdate(info: GridPayload) {
    this.store.dispatch(new fromOwner.LoadRecords(info));
  }

  onItemSelected(ownerData: any) {
    this.modalReference = this.modalService.open(OwnerDetailsComponent, {
      backdrop: 'static',
      size: 'lg',
      keyboard: false,
      centered: true,
    });
    this.modalReference.componentInstance.selectedOwner = ownerData;
  }

  onItemUpdate(client: any) {
    this.store.dispatch(new fromOwner.SelectRecord(client.id));
    this.modalReference = this.modalService.open(OwnerUpdateComponent, {
      backdrop: 'static',
      size: 'lg',
      keyboard: false,
      centered: true,
    });
  }

  onItemUpdateStatusClick($event: OwnerModel) {
    this.store.dispatch(new fromOwner.SelectRecord($event.id));
    this.store.dispatch(new fromOwner.UpdateStatusRecord($event));
  }

  onItemUpdateApprovalStatusClick($event: OwnerModel) {
    this.store.dispatch(new fromOwner.SelectRecord($event.id));
    this.store.dispatch(new fromOwner.UpdateApprovalStatusRecord($event));
  }
}


<div
  class="input-group"
  *ngIf="
    experienceTypesDropdown$ | async as experienceTypesDropdown;
    else loading
  "
>
  <!-- <span class="input-group-text" style="width:160px !important;">State</span> -->
  <!-- <select [(ngModel)]="experienceType$" class="select" (change)="onSelect($event)">
        <option [ngValue]="0">Choose One...</option>
        <option *ngFor="let d of experienceTypesDropdown" [ngValue]="d.id">
            {{ d.name }}
        </option>
    </select> -->

  <ngx-select-dropdown
    (searchChange)="searchChange($event)"
    name="category-select"
    class="ng-dropdown-width"
    tabindex="0"
    [(ngModel)]="selectedExperienceTypes$"
    [multiple]="true"
    (change)="selectionChanged($event)"
    [config]="config"
    [options]="experienceTypesDropdown"
  ></ngx-select-dropdown>

  <ng-template #optionTemplate let-item="item" let-config="config">
    <i class="fa fa-plus"> </i>
      {{ item.name }}
    <span class="new badge"> </span>
  </ng-template>
</div>
<ng-template #loading>Loading experience types...</ng-template>

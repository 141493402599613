import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TypeOfStoragePageComponent } from './containers/type-of-storage-page/type-of-storage-page.component';


const routes: Routes = [
  {path: '', component: TypeOfStoragePageComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TypeOfStoragesRoutingModule { }

import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { OwnerModel } from '../models/owner.model';
import { OwnerActionTypes, OwnerItemActionTypes, OwnerListActionTypes } from '../actions';


export interface OwnerListState extends EntityState<OwnerModel> {
  isLoading: boolean;
  selectedOwnerId: string | null;

  count: number;
  offset: number;

  sortProp: string;
  sortDir: string;

  search:string;
}


export const adapter: EntityAdapter<OwnerModel> = createEntityAdapter<OwnerModel>({
  selectId: (client: OwnerModel) => client.id,
});


export const initialState: OwnerListState = adapter.getInitialState({
  isLoading: false,
  selectedOwnerId: null,

  count: 0,
  offset: 0,

  sortProp: 'name',
  sortDir: 'asc',
  search: 'all'
});


export function ownerListReducer(state = initialState, action: OwnerActionTypes): OwnerListState {
  switch (action.type) {

    case OwnerListActionTypes.LoadRecords: {
      return {
        ...state,
        ...action.gridInfo,
        isLoading: true,
      };
    }

    case OwnerListActionTypes.LoadRecordsSuccess: {
      return adapter.addMany(action.payload.data as OwnerModel[], {
        ...adapter.removeAll(state),
        isLoading: false,
        count: action.payload.count
      });
    }

    case OwnerListActionTypes.LoadListingRecords: {
      return {
        ...state,
        ...action.gridInfo,
        isLoading: true,
      };
    }

    case OwnerListActionTypes.LoadListingRecordsSuccess: {
      return adapter.addMany(action.payload.data as OwnerModel[], {
        ...adapter.removeAll(state),
        isLoading: false,
        count: action.payload.count
      });
    }

    case OwnerItemActionTypes.UpdateRecordSuccess: {
      return adapter.upsertOne(action.payload, state);
    }

    case OwnerItemActionTypes.UpdateStatusRecordSuccess: {
      return adapter.upsertOne(action.payload, state);
    }

    case OwnerItemActionTypes.UpdateApprovalStatusRecordSuccess: {
      return adapter.upsertOne(action.payload, state);
    }

    case OwnerListActionTypes.SelectRecord: {
      return {
        ...state,
        selectedOwnerId: action.payload
      };
    }

    
    default:
      return state;
  }
}


export const getIsLoading = (state: OwnerListState) => state.isLoading;

export const getCount = (state: OwnerListState) => state.count;
export const getOffset = (state: OwnerListState) => state.offset;

export const getSortProp = (state: OwnerListState) => state.sortProp;
export const getSortDir = (state: OwnerListState) => state.sortDir;
export const getSearch = (state: OwnerListState) => state.search;

export const getSelectedId = (state: OwnerListState) => state.selectedOwnerId;

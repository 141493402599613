import { Injectable } from "@angular/core";

import { Actions, Effect, ofType } from "@ngrx/effects";
import { Action, select, Store } from "@ngrx/store";

import { Observable, of as observableOf } from "rxjs";
import {
  catchError,
  exhaustMap,
  map,
  tap,
  withLatestFrom,
} from "rxjs/operators";

import * as actions from "./../actions";
import { AddRecord, DistrictItemActionTypes, UpdateRecord } from "./../actions";
import * as fromDistrict from "./../district.state";

import { District } from "../models/district.model";
import { DistrictService } from "../../../../services/masters/districts";
import { GridPayload } from "../../../../shared/models/grid.payload";

@Injectable()
export class DistrictItemEffects {
  @Effect()
  addRecord$: Observable<Action> = this.actions$.pipe(
    ofType<AddRecord>(DistrictItemActionTypes.AddRecord),
    map((action) => action.payload),
    exhaustMap((record: District) =>
      this.batService.saveDistrict(record).pipe(
        map((payload: District) => new actions.AddRecordSuccess(payload)),
        catchError((e) =>
          observableOf(new actions.AddRecordFailure(e.error.error))
        )
      )
    )
  );

  @Effect()
  updateRecord$: Observable<Action> = this.actions$.pipe(
    ofType<UpdateRecord>(DistrictItemActionTypes.UpdateRecord),
    map((action) => action.payload),
    exhaustMap((record: District) =>
      this.batService.saveDistrict(record).pipe(
        map((payload: District) => new actions.UpdateRecordSuccess(payload)),
        catchError((e) =>
          observableOf(new actions.UpdateRecordFailure(e.error.error))
        )
      )
    )
  );

  @Effect({ dispatch: false })
  reloadGrid$: Observable<any> = this.actions$.pipe(
    ofType(DistrictItemActionTypes.AddRecordSuccess),
    withLatestFrom(
      this.store.pipe(select(fromDistrict.getDistrictsOffset)),
      this.store.pipe(select(fromDistrict.getDistrictsSortProp)),
      this.store.pipe(select(fromDistrict.getDistrictsSortDir)),
      this.store.pipe(select(fromDistrict.getDistrictsSearch)),
      (action, offset, sortProp, sortDir, search) => ({
        offset,
        sortProp,
        sortDir,
        search,
      })
    ),
    tap((info: GridPayload) => {
      this.store.dispatch(new actions.LoadRecords(info));
    })
  );

  @Effect({ dispatch: false })
  closeModal$: Observable<any> = this.actions$.pipe(
    ofType(
      DistrictItemActionTypes.AddRecordSuccess,
      DistrictItemActionTypes.UpdateRecordSuccess
    ),
    withLatestFrom(
      this.store.pipe(select(fromDistrict.getDistrictsOffset)),
      this.store.pipe(select(fromDistrict.getDistrictsSortProp)),
      this.store.pipe(select(fromDistrict.getDistrictsSortDir)),
      this.store.pipe(select(fromDistrict.getDistrictsSearch)),
      (action, offset, sortProp, sortDir, search) => ({
        offset,
        sortProp,
        sortDir,
        search,
      })
    ),
    tap((info: GridPayload) => {
      this.store.dispatch(new actions.LoadRecords(info));
    })
  );

  constructor(
    private store: Store<fromDistrict.State>,
    private actions$: Actions,
    private batService: DistrictService
  ) {}
}

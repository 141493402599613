import { Injectable } from "@angular/core";

import { Actions, Effect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";

import { Observable, of as observableOf } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";

import * as actions from "./../actions";
import { DistrictService } from "../../../../services/masters/districts";
import { LoadDropdownByUserRecords, LoadDropdownRecords, LoadRecords } from "./../actions";

@Injectable()
export class DistrictListEffects {
  @Effect()
  loadList$: Observable<Action> = this.actions$.pipe(
    ofType<LoadRecords>(actions.DistrictListActionTypes.LoadRecords),
    switchMap(({ gridInfo }) =>
      this.districtService.getDistrictList(gridInfo).pipe(
        map((records) => new actions.LoadRecordsSuccess(records)),
        catchError((error) =>
          observableOf(new actions.LoadRecordsFailure(error))
        )
      )
    )
  );

  @Effect()
  loadDropdownList$: Observable<Action> = this.actions$.pipe(
    ofType<LoadDropdownRecords>(
      actions.DistrictListActionTypes.LoadDropdownRecords
    ),
    map((action) => action.payload),
    switchMap((id: string) =>
      this.districtService.getDropdownList(id).pipe(
        map((records) => new actions.LoadDropdownRecordsSuccess(records)),
        catchError((error) =>
          observableOf(new actions.LoadDropdownRecordsFailure(error))
        )
      )
    )
  );

  @Effect()
  loadDropdownByUserList$: Observable<Action> = this.actions$.pipe(
    ofType<LoadDropdownByUserRecords>(
      actions.DistrictListActionTypes.LoadDropdownByUserRecords
    ),
    switchMap(() =>
      this.districtService.getDropdownListByUser().pipe(
        map((records) => new actions.LoadDropdownByUserRecordsSuccess(records)),
        catchError((error) =>
          observableOf(new actions.LoadDropdownByUserRecordsFailure(error))
        )
      )
    )
  );

  constructor(private actions$: Actions, private districtService: DistrictService) {}
}

import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnInit,
} from "@angular/core";
import { State } from "../../../../../../store/masters/states/models/state.model";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";


@Component({
  selector: 'app-state-details',
  templateUrl: './state-details.component.html',
  styleUrls: ['./state-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StateDetailsComponent implements OnInit {

  state: State;

  @Input("selectedState") 
  set selectedState(data: any) {
    this.state = data;
  }
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}

}

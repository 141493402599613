import { Injectable } from '@angular/core';

import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as actions from './../actions';
import { EquipmentSupplierService } from '../../../../services/cold-storage/equipment-suppliers';
import { LoadListingRecords, LoadRecords } from './../actions';

@Injectable()
export class EquipmentSupplierListEffects {
  @Effect()
  loadList$: Observable<Action> = this.actions$.pipe(
    ofType<LoadRecords>(actions.EquipmentSupplierListActionTypes.LoadRecords),
    switchMap(({ gridInfo }) =>
      this.equipmentSupplierService.getEquipmentSuppliersList(gridInfo).pipe(
        map((tps) => new actions.LoadRecordsSuccess(tps)),
        catchError((error) =>
          observableOf(new actions.LoadRecordsFailure(error))
        )
      )
    )
  );

  @Effect()
  loadListings$: Observable<Action> = this.actions$.pipe(
    ofType<LoadListingRecords>(actions.EquipmentSupplierListActionTypes.LoadListingRecords),
    switchMap(({ gridInfo }) =>
      this.equipmentSupplierService.getEquipmentSuppliersListings(gridInfo).pipe(
        map((tps) => new actions.LoadListingRecordsSuccess(tps)),
        catchError((error) =>
          observableOf(new actions.LoadListingRecordsFailure(error))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private equipmentSupplierService: EquipmentSupplierService
  ) {}
}

import { Injectable } from '@angular/core';
import { BaseService } from '../../../base/base.service';

import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

import { RegisterServiceModel } from '../../../store/cold-storage/register-service/models/register-service.model';

@Injectable({
  providedIn: 'root',
})
export class RegisterServiceService extends BaseService {
  apiUrl: any = environment.api_url;

  saveData(payload: RegisterServiceModel): Observable<RegisterServiceModel> {
    return this.http.post<RegisterServiceModel>(
      `${this.apiUrl}/api/cold-storage/save`,
      payload
    );
  }
}

import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { DesignConsultantModel } from '../models/design-consultant.model';
import { DesignConsultantActionTypes, DesignConsultantItemActionTypes, DesignConsultantListActionTypes } from '../actions';


export interface DesignConsultantListState extends EntityState<DesignConsultantModel> {
  isLoading: boolean;
  selectedDesignConsultantId: string | null;

  count: number;
  offset: number;

  sortProp: string;
  sortDir: string;

  search:string;
}


export const adapter: EntityAdapter<DesignConsultantModel> = createEntityAdapter<DesignConsultantModel>({
  selectId: (client: DesignConsultantModel) => client.id,
});


export const initialState: DesignConsultantListState = adapter.getInitialState({
  isLoading: false,
  selectedDesignConsultantId: null,

  count: 0,
  offset: 0,

  sortProp: 'name',
  sortDir: 'asc',
  search: 'all'
});


export function designConsultantListReducer(state = initialState, action: DesignConsultantActionTypes): DesignConsultantListState {
  switch (action.type) {

    case DesignConsultantListActionTypes.LoadRecords: {
      return {
        ...state,
        ...action.gridInfo,
        isLoading: true,
      };
    }

    case DesignConsultantListActionTypes.LoadRecordsSuccess: {
      return adapter.addMany(action.payload.data as DesignConsultantModel[], {
        ...adapter.removeAll(state),
        isLoading: false,
        count: action.payload.count
      });
    }


    case DesignConsultantListActionTypes.LoadListingRecords: {
      return {
        ...state,
        ...action.gridInfo,
        isLoading: true,
      };
    }

    case DesignConsultantListActionTypes.LoadListingRecordsSuccess: {
      return adapter.addMany(action.payload.data as DesignConsultantModel[], {
        ...adapter.removeAll(state),
        isLoading: false,
        count: action.payload.count
      });
    }

    case DesignConsultantItemActionTypes.UpdateRecordSuccess: {
      return adapter.upsertOne(action.payload, state);
    }

    case DesignConsultantItemActionTypes.UpdateStatusRecordSuccess: {
      return adapter.upsertOne(action.payload, state);
    }

    case DesignConsultantItemActionTypes.UpdateApprovalStatusRecordSuccess: {
      return adapter.upsertOne(action.payload, state);
    }

    case DesignConsultantListActionTypes.SelectRecord: {
      return {
        ...state,
        selectedDesignConsultantId: action.payload
      };
    }

    
    default:
      return state;
  }
}


export const getIsLoading = (state: DesignConsultantListState) => state.isLoading;

export const getCount = (state: DesignConsultantListState) => state.count;
export const getOffset = (state: DesignConsultantListState) => state.offset;

export const getSortProp = (state: DesignConsultantListState) => state.sortProp;
export const getSortDir = (state: DesignConsultantListState) => state.sortDir;
export const getSearch = (state: DesignConsultantListState) => state.search;

export const getSelectedId = (state: DesignConsultantListState) => state.selectedDesignConsultantId;

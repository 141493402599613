import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import * as fromEquipments from '../../../../../../store/masters/equipments';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Equipment } from '../../../../../../store/masters/equipments/models/equipment.model';

@Component({
  selector: 'app-equipment-filter-list',
  templateUrl: './equipment-filter-list.component.html',
  styleUrl: './equipment-filter-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EquipmentFilterListComponent {
  equipment$: any = '0';
  equipmentsDropdown$: Observable<any>;

  @Input('selectedEquipment')
  set selectedEquipment(value: any) {
    this.equipment$ =
      value == null || value == undefined || value == '' ? '0' : value;
  }

  @Output() selectEquipmentFilterListItem = new EventEmitter();

  constructor(
    public cdr: ChangeDetectorRef,
    private readonly store: Store<fromEquipments.State>
  ) {}

  ngOnInit() {
    this.store.dispatch(new fromEquipments.LoadDropdownRecords());

    this.equipmentsDropdown$ = this.store.pipe(
      select(fromEquipments.getAllEquipments)
    );

    // this.equipmentsDropdown$.subscribe((data)=>{
    //   console.log(`action.payload.data:: ${JSON.stringify(data)}`)
    // })
  }

  compareEquipments(p1: Equipment, p2: Equipment) {
    return p1.id === p2.id;
  }

  selectedIds: any = [];
  onSelect(event, id, name) {
    if (event.target.checked == true) {
      // console.log('checkbox is checked');
      this.selectedIds = [...this.selectedIds, id];
    } else {
      // console.log('checkbox is unchecked');
      // const index: number = this.selectedIds.indexOf(id);
      // if (index !== -1) {
      //   this.selectedIds.splice(index, 1);
      // }
      const filteredItems = this.selectedIds.filter(item => item !== id);
      this.selectedIds = [...filteredItems];
    }

    /*
    const selectedId = id; 
    const selectedElement = {
      id: id,
      text: name,
    };
    */
    // console.log(`Filter componet selected ids ${this.selectedIds.toString()}`);
    this.selectEquipmentFilterListItem.emit(this.selectedIds);
  }
}

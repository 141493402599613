import { Injectable } from "@angular/core";

import { Actions, Effect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";

import { Observable, of as observableOf } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";

import * as actions from "./../actions";
import { EquipmentService } from "../../../../services/masters/equipments";
import { LoadDropdownByUserRecords, LoadDropdownRecords, LoadRecords } from "./../actions";

@Injectable()
export class EquipmentListEffects {
  @Effect()
  loadList$: Observable<Action> = this.actions$.pipe(
    ofType<LoadRecords>(actions.EquipmentListActionTypes.LoadRecords),
    switchMap(({ gridInfo }) =>
      this.equipmentService.getEquipmentList(gridInfo).pipe(
        map((records) => new actions.LoadRecordsSuccess(records)),
        catchError((error) =>
          observableOf(new actions.LoadRecordsFailure(error))
        )
      )
    )
  );

  @Effect()
  loadDropdownList$: Observable<Action> = this.actions$.pipe(
    ofType<LoadDropdownRecords>(
      actions.EquipmentListActionTypes.LoadDropdownRecords
    ),
    switchMap(() =>
      this.equipmentService.getDropdownList().pipe(
        map((records) => new actions.LoadDropdownRecordsSuccess(records)),
        catchError((error) =>
          observableOf(new actions.LoadDropdownRecordsFailure(error))
        )
      )
    )
  );

  @Effect()
  loadDropdownByUserList$: Observable<Action> = this.actions$.pipe(
    ofType<LoadDropdownByUserRecords>(
      actions.EquipmentListActionTypes.LoadDropdownByUserRecords
    ),
    switchMap(() =>
      this.equipmentService.getDropdownListByUser().pipe(
        map((records) => new actions.LoadDropdownByUserRecordsSuccess(records)),
        catchError((error) =>
          observableOf(new actions.LoadDropdownByUserRecordsFailure(error))
        )
      )
    )
  );

  constructor(private actions$: Actions, private equipmentService: EquipmentService) {}
}

import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Equipment } from '../models/equipment.model';
import { EquipmentActionTypes, EquipmentItemActionTypes, EquipmentListActionTypes } from '../actions';


export interface EquipmentListState extends EntityState<Equipment> {
  isLoading: boolean;
  selectedEquipmentId: string | null;

  count: number;
  offset: number;

  sortProp: string;
  sortDir: string;

  search:string;
}


export const adapter: EntityAdapter<Equipment> = createEntityAdapter<Equipment>({
  selectId: (bt: Equipment) => bt.id,
});


export const initialState: EquipmentListState = adapter.getInitialState({
  isLoading: false,
  selectedEquipmentId: null,

  count: 0,
  offset: 0,

  sortProp: 'name',
  sortDir: 'asc',
  search: 'all'
});


export function equipmentListReducer(state = initialState, action: EquipmentActionTypes): EquipmentListState {
  switch (action.type) {

    case EquipmentListActionTypes.LoadRecords: {
      return {
        ...state,
        ...action.gridInfo,
        isLoading: true,
      };
    }

    case EquipmentListActionTypes.LoadRecordsSuccess: {
      return adapter.addMany(action.payload.data as Equipment[], {
        ...adapter.removeAll(state),
        isLoading: false,
        count: action.payload.count
      });
    }

    case EquipmentItemActionTypes.UpdateRecordSuccess: {
      return adapter.upsertOne(action.payload, state);
    }

    case EquipmentListActionTypes.SelectRecord: {
      return {
        ...state,
        selectedEquipmentId: action.payload
      };
    }

    case EquipmentListActionTypes.LoadDropdownRecords: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case EquipmentListActionTypes.LoadDropdownRecordsSuccess: {
      return adapter.addMany(action.payload.data as Equipment[], {
        ...adapter.removeAll(state),
        isLoading: false
      });
    }


    case EquipmentListActionTypes.LoadDropdownByUserRecords: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case EquipmentListActionTypes.LoadDropdownByUserRecordsSuccess: {
      return adapter.addMany(action.payload.data as Equipment[], {
        ...adapter.removeAll(state),
        isLoading: false
      });
    }

    default:
      return state;
  }
}


export const getIsLoading = (state: EquipmentListState) => state.isLoading;

export const getCount = (state: EquipmentListState) => state.count;
export const getOffset = (state: EquipmentListState) => state.offset;

export const getSortProp = (state: EquipmentListState) => state.sortProp;
export const getSortDir = (state: EquipmentListState) => state.sortDir;
export const getSearch = (state: EquipmentListState) => state.search;

export const getSelectedId = (state: EquipmentListState) => state.selectedEquipmentId;

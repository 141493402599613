import { Injectable } from "@angular/core";
import { BaseService } from "../../../base/base.service";
import { map } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
import {
  ExperienceType,
  ExperienceTypeApiModel,
} from "../../../store/masters/experience-types/models/experience-type.model";
import { GridPayload } from '../../../shared/models/grid.payload';
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ExperienceTypeService extends BaseService {
  apiUrl: any = environment.api_url;

  getDropdownList(): Observable<any> {
    return this.http.get(`${this.apiUrl}/api/masters/experiencetypes/dropdown/records`)
      .pipe(
        map((response: ExperienceTypeApiModel[]) => ({
          data: response.map(record => new ExperienceType(record))
        }))
      );
  }

  getDropdownListByUser(): Observable<any> {
    return this.http.get(`${this.apiUrl}/api/masters/experiencetypes/dropdown/records/by/user`)
      .pipe(
        map((response: ExperienceTypeApiModel[]) => ({
          data: response.map(record => new ExperienceType(record))
        }))
      );
  }

  getExperienceTypeList(gridInfo: GridPayload) {
    return this.gridRequest<ExperienceTypeApiModel>(`${this.apiUrl}/api/masters/experiencetypes/records`, gridInfo.offset, gridInfo.sortProp, gridInfo.sortDir, gridInfo.search)
      .pipe(
        map(response => ({
          ...response,
          data: response.data.map(record => new ExperienceType(record))
        }))
      );
  }

  saveExperienceType(payload: ExperienceType): Observable<ExperienceType> {
    return this.http.post<ExperienceType>(`${this.apiUrl}/api/masters/experiencetypes/save`, payload);
  }
}

import { GlobalState } from '../../../store';
import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromList from './reducers/list.reducer';
import * as fromItem from './reducers/item.reducer';


export interface TypeOfStorageState {
  list: fromList.TypeOfStorageListState;
  item: fromItem.TypeOfStorageItemState;
}

export interface State extends GlobalState {
  type_of_storages: TypeOfStorageState;
}

export const TYPE_OF_STORAGE_REDUCERS: ActionReducerMap<TypeOfStorageState, any> = {
  list: fromList.typeOfStorageListReducer,
  item: fromItem.typeOfStorageItemReducer
};


////// selectors

export const getProjectsState = createFeatureSelector<State, TypeOfStorageState>('type_of_storages');


export const getTypeOfStorageListState = createSelector(getProjectsState, state => state.list);
export const getTypeOfStorageItemState = createSelector(getProjectsState, state => state.item);


export const getTypeOfStoragesIsLoading = createSelector(getTypeOfStorageListState, fromList.getIsLoading);

export const getTypeOfStoragesCount = createSelector(getTypeOfStorageListState, fromList.getCount);
export const getTypeOfStoragesOffset = createSelector(getTypeOfStorageListState, fromList.getOffset);

export const getTypeOfStoragesSortProp = createSelector(getTypeOfStorageListState, fromList.getSortProp);
export const getTypeOfStoragesSortDir = createSelector(getTypeOfStorageListState, fromList.getSortDir);
export const getTypeOfStoragesSearch = createSelector(getTypeOfStorageListState, fromList.getSearch);

export const getSelectedTypeOfStorageId = createSelector(getTypeOfStorageListState, fromList.getSelectedId);


export const {
  selectEntities: getTypeOfStorageEntities,
  selectAll: getAllTypeOfStorages
} = fromList.adapter.getSelectors(getTypeOfStorageListState);


export const getSelectedTypeOfStorage = createSelector(
  getTypeOfStorageEntities,
  getSelectedTypeOfStorageId,
  (entities, selectedId) => {
    return selectedId !== null && entities[selectedId];
  }
);


// Item

export const getTypeOfStorageItemPending = createSelector(getTypeOfStorageItemState, fromItem.getPending);
export const getTypeOfStorageItemError = createSelector(getTypeOfStorageItemState, fromItem.getError);



import { GlobalState } from '../../../store';
import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromList from './reducers/list.reducer';
import * as fromItem from './reducers/item.reducer';


export interface DistrictState {
  list: fromList.DistrictListState;
  item: fromItem.DistrictItemState;
}

export interface State extends GlobalState {
  districts: DistrictState;
}

export const DISTRICT_REDUCERS: ActionReducerMap<DistrictState, any> = {
  list: fromList.districtListReducer,
  item: fromItem.districtItemReducer
};


////// selectors

export const getProjectsState = createFeatureSelector<State, DistrictState>('districts');


export const getDistrictListState = createSelector(getProjectsState, state => state.list);
export const getDistrictItemState = createSelector(getProjectsState, state => state.item);


export const getDistrictsIsLoading = createSelector(getDistrictListState, fromList.getIsLoading);

export const getDistrictsCount = createSelector(getDistrictListState, fromList.getCount);
export const getDistrictsOffset = createSelector(getDistrictListState, fromList.getOffset);

export const getDistrictsSortProp = createSelector(getDistrictListState, fromList.getSortProp);
export const getDistrictsSortDir = createSelector(getDistrictListState, fromList.getSortDir);
export const getDistrictsSearch = createSelector(getDistrictListState, fromList.getSearch);

export const getSelectedDistrictId = createSelector(getDistrictListState, fromList.getSelectedId);


export const {
  selectEntities: getDistrictEntities,
  selectAll: getAllDistricts
} = fromList.adapter.getSelectors(getDistrictListState);


export const getSelectedDistrict = createSelector(
  getDistrictEntities,
  getSelectedDistrictId,
  (entities, selectedId) => {
    return selectedId !== null && entities[selectedId];
  }
);


// Item

export const getDistrictItemPending = createSelector(getDistrictItemState, fromItem.getPending);
export const getDistrictItemError = createSelector(getDistrictItemState, fromItem.getError);



<!-- Start Navbar Area -->
<div
    class="navbar-area"
    [ngClass]="{'sticky': isSticky}"
>
    <div class="vesax-nav">
        <div class="container-fluid">
            <nav class="navbar navbar-expand-xl navbar-light" [class.active]="classApplied">
                <a class="navbar-brand" routerLink="/index-2">
                    <img src="assets/img/black-logo.png" alt="logo">
                </a>
                <button class="navbar-toggler" type="button" (click)="toggleClass()">
                    <span class="burger-menu">
                        <span class="top-bar"></span>
                        <span class="middle-bar"></span>
                        <span class="bottom-bar"></span>
                    </span>
                </button>
                <div class="collapse navbar-collapse">
                    <form class="navbar-search-box">
                        <label><i class="flaticon-search"></i></label>
                        <input type="text" class="input-search" placeholder="What are you looking for?">
                    </form>
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="dropdown-toggle nav-link">
                                Home
                            </a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Demo - 1</a></li>

                                <li class="nav-item"><a routerLink="/index-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Demo - 2</a></li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="dropdown-toggle nav-link">
                                Listings
                            </a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a href="javascript:void(0)" class="nav-link">List Layout <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/vertical-listings-left-sidebar" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Left Sidebar</a></li>

                                        <li class="nav-item"><a routerLink="/vertical-listings-right-sidebar" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Right Sidebar</a></li>
                                        
                                        <li class="nav-item"><a routerLink="/vertical-listings-full-width" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Full Width</a></li>
                                    </ul>
                                </li>

                                <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Grid Layout <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/grid-listings-left-sidebar" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Left Sidebar</a></li>

                                        <li class="nav-item"><a routerLink="/grid-listings-right-sidebar" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Right Sidebar</a></li>
                                        
                                        <li class="nav-item"><a routerLink="/grid-listings-full-width" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Full Width</a></li>
                                    </ul>
                                </li>
                                
                                <li class="nav-item"><a routerLink="/single-listings" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Listings Details</a></li>
                                
                                <li class="nav-item"><a routerLink="/destinations" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Top Place</a></li>

                                <li class="nav-item"><a routerLink="/categories" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Categories</a></li>

                                <li class="nav-item"><a routerLink="/user-profile" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Author Profile</a></li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="dropdown-toggle nav-link">
                                User Panel
                            </a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/dashboard" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Dashboard</a></li>

                                <li class="nav-item"><a routerLink="/dashboard-messages" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Messages</a></li>

                                <li class="nav-item"><a routerLink="/dashboard-bookings" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Bookings</a></li>

                                <li class="nav-item"><a routerLink="/dashboard-wallet" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Wallet</a></li>
                                
                                <li class="nav-item"><a routerLink="/dashboard-my-listings" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">My Listings</a></li>

                                <li class="nav-item"><a routerLink="/dashboard-reviews" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Reviews</a></li>

                                <li class="nav-item"><a routerLink="/dashboard-bookmarks" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Bookmarks</a></li>

                                <li class="nav-item"><a routerLink="/dashboard-add-listings" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Add Listings</a></li>

                                <li class="nav-item"><a routerLink="/dashboard-my-profile" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">My Profile</a></li>

                                <li class="nav-item"><a routerLink="/dashboard-invoice" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Invoice</a></li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="dropdown-toggle nav-link">
                                Shop
                            </a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/products-list" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Products List</a></li>

                                <li class="nav-item"><a routerLink="/cart" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Cart</a></li>

                                <li class="nav-item"><a routerLink="/checkout" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Checkout</a></li>

                                <li class="nav-item"><a routerLink="/single-products" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Products Details</a></li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="dropdown-toggle nav-link">
                                Blog
                            </a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/blog-grid" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Grid</a></li>

                                <li class="nav-item"><a routerLink="/blog-right-sidebar" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Right Sidebar</a></li>

                                <li class="nav-item"><a routerLink="/blog-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Details</a></li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="dropdown-toggle nav-link">
                                Pages
                            </a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About Us</a></li>

                                <li class="nav-item"><a routerLink="/how-it-works" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">How It Work</a></li>

                                <li class="nav-item"><a routerLink="/pricing" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Pricing</a></li>

                                <li class="nav-item"><a routerLink="/gallery" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Gallery</a></li>

                                <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Events <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/events" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Events</a></li>
                                        
                                        <li class="nav-item"><a routerLink="/single-events" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Events Details</a></li>
                                    </ul>
                                </li>

                                <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">FAQ</a></li>

                                <li class="nav-item"><a routerLink="/error" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">404 Error Page</a></li>

                                <li class="nav-item"><a routerLink="/coming-soon" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Coming Soon</a></li>

                                <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">
                                    Contact Us
                                </a></li>
                            </ul>
                        </li>
                    </ul>
                    <div class="others-option d-flex align-items-center">
                        <div class="option-item">
                            <span (click)="openSigninSignupPopup()">
                                <i class="flaticon-user"></i> Login / Register
                            </span>
                        </div>
                        <div class="option-item">
                            <a routerLink="/dashboard-add-listings" class="default-btn">
                                <i class="flaticon-more"></i> Add Listing
                            </a>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</div>
<!-- End Navbar Area -->

<!-- Start Login & Register Modal -->
<div
    class="modal fade loginRegisterModal" tabindex="-1" aria-hidden="true"
    *ngIf="isSigninSignupOpen"
>
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <button type="button" class="close" (click)="closeSigninSignupPopup()">
                <i class='bx bx-x'></i>
            </button>
            <ul class="nav nav-tabs">
                <li class="nav-item">
                    <button 
                        class="nav-link"
                        (click)="switchTab($event, 'tab1')"
                        [ngClass]="{'active': currentTab === 'tab1'}"
                    >
                        Login
                    </button>
                </li>
                <li class="nav-item">
                    <button 
                        class="nav-link"
                        (click)="switchTab($event, 'tab2')"
                        [ngClass]="{'active': currentTab === 'tab2'}"
                    >
                        Register
                    </button>
                </li>
            </ul>
            <div class="tab-content">
                <div class="tab-pane fade show active" *ngIf="currentTab === 'tab1'">
                    <div class="vesax-login">
                        <div class="login-with-account">
                            <span>Login with</span>
                            <ul>
                                <li><a href="#" class="facebook"><i class='bx bxl-facebook'></i> Facebook</a></li>
                                <li><a href="#" class="twitter"><i class='bx bxl-twitter'></i> Twitter</a></li>
                            </ul>
                        </div>
                        <span class="sub-title"><span>Or login with</span></span>
                        <form>
                            <div class="form-group">
                                <input type="text" placeholder="Username or Email" class="form-control">
                            </div>
                            <div class="form-group">
                                <input type="password" placeholder="Password" class="form-control">
                            </div>
                            <button type="submit">Login Now</button>
                        </form>
                        <span class="dont-account">Don't have an account? <a href="#">Register Now</a></span>
                    </div>
                </div>
                <div class="tab-pane fade show active" *ngIf="currentTab === 'tab2'">
                    <div class="vesax-register">
                        <div class="register-with-account">
                            <span>Register with</span>
                            <ul>
                                <li><a href="#" class="facebook"><i class='bx bxl-facebook'></i> Facebook</a></li>
                                <li><a href="#" class="twitter"><i class='bx bxl-twitter'></i> Twitter</a></li>
                            </ul>
                        </div>
                        <span class="sub-title"><span>Or Register with</span></span>
                        <form>
                            <div class="form-group">
                                <input type="text" placeholder="Username" class="form-control">
                            </div>
                            <div class="form-group">
                                <input type="email" placeholder="Email" class="form-control">
                            </div>
                            <div class="form-group">
                                <input type="password" placeholder="Password" class="form-control">
                            </div>
                            <div class="form-group">
                                <input type="password" placeholder="Confirm Password" class="form-control">
                            </div>
                            <button type="submit">Register Now</button>
                        </form>
                        <span class="already-account">Already have an account? <a href="#">Login Now</a></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-backdrop fade show"></div>
</div>
<!-- End Login & Register Modal -->
import { RegisterServiceActionTypes, RegisterServiceItemActionTypes } from '../actions';

export interface RegisterServiceItemState {
  error: string | null;
  pending: boolean;
}

export const initialState: RegisterServiceItemState = {
  error: null,
  pending: false,
};

export function registerServiceItemReducer(
  state = initialState,
  action: RegisterServiceActionTypes
): RegisterServiceItemState {
  switch (action.type) {
    case RegisterServiceItemActionTypes.AddRecord: {
      return {
        ...state,
        error: null,
        pending: true,
      };
    }

    case RegisterServiceItemActionTypes.AddRecordSuccess: {
      return {
        ...state,
        error: null,
        pending: false,
      };
    }

    case RegisterServiceItemActionTypes.AddRecordFailure:{
      return {
        ...state,
        error: action.payload,
        pending: false,
      };
    }

    default:
      return state;
  }
}

export const getError = (state: RegisterServiceItemState) => state.error;
export const getPending = (state: RegisterServiceItemState) => state.pending;

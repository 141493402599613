import { Injectable, Injector } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable, of, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import store from 'store';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  apiUrl: any = environment.api_url;
  constructor(private injector: Injector) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.method === 'POST') {
      if (
        request.url !== `${this.apiUrl}/api/auth/signin` &&
        request.url !== `${this.apiUrl}/api/auth/signup` &&
        request.url !==
          `${this.apiUrl}/api/masters/districts/dropdown/records/**`
      ) {
        const accessToken = store.get('accessToken');

        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
      }
    }

    if (request.method === 'GET') {
      // logout
      if (request.url === '/api/auth/logout') {
        return of(new HttpResponse({ status: 200 }));
      }

      if (
        request.url !== '/api/auth/logout' &&
        request.url !== `${this.apiUrl}/api/equipment/suppliers/listings` &&
        request.url !== `${this.apiUrl}/api/coldstorageowners/listings` &&
        request.url !== `${this.apiUrl}/api/designconsultants/listings` &&
        request.url !== `${this.apiUrl}/api/contractors/listings` &&
        request.url !== `${this.apiUrl}/api/masters/experttypes/dropdown/records` &&
        request.url !== `${this.apiUrl}/api/masters/experiencetypes/dropdown/records` &&
        request.url !== `${this.apiUrl}/api/masters/equipments/dropdown/records` &&
        request.url !== `${this.apiUrl}/api/masters/tocs/dropdown/records` &&
        request.url !== `${this.apiUrl}/api/masters/states/dropdown/records` &&
        request.url !==
          `${this.apiUrl}/api/masters/districts/dropdown/records/**`
      ) {
        const accessToken = store.get('accessToken');

        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${accessToken}`,
            AccessToken: accessToken,
          },
        });
      }
    }

    return next.handle(request);
  }
}

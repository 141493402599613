<div class="modalDialogBox">
    <form [formGroup]="form" (ngSubmit)="submit()">
      <div class="modal-header bg-green-400 text-white">
        <h4 class="modal-title" id="modal-basic-title">
          {{ typeOfStorageId ? "Update" : "Add" }} Type Of Storage Detail(s)<br />
          <small class="text-white-100" *ngIf="typeOfStorageId"
            ><strong>Last Update On:</strong>&nbsp;{{
              lastUpdatedOn | date : "short"
            }}</small
          >
        </h4>
        <button
          type="button"
          class="btn btn-outline-white"
          aria-label="Close"
          (click)="activeModal.dismiss('Cross click')"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div>
          <p *ngIf="error" class="error-message">{{ error }}</p>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label class="col-form-label" for="name">
                <strong>Type Of Storage</strong>
                <small class="text-danger">*</small>
              </label>
              <input
                type="text"
                class="form-control"
                placeholder="Please provide type of storage."
                formControlName="name"
                required
              />
            </div>
            <div
              class="error-message"
              *ngIf="form.get('name').hasError('maxlength')"
            >
              Type Of Storage name is <strong>too long</strong>
            </div>
            <div
              class="error-message"
              *ngIf="form.get('name').hasError('required')"
            >
              Type Of Storage name is <strong>required</strong>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <label class="col-form-label" for="description">
                <strong>Description</strong>
                <small class="text-danger">*</small>
              </label>
              <textarea
                class="form-control"
                rows="3"
                formControlName="description"
                required
              ></textarea>
            </div>
            <div
              class="error-message"
              *ngIf="form.get('description').hasError('required')"
            >
              Description is <strong>required</strong>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="col-form-label" for="status">
                <strong>Status</strong>
              </label>
              <select
                formControlName="status"
                class="form-control select-box"
                required
              >
                <option selected value="1">Active</option>
                <option value="0">Inactive</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">&nbsp;</div>
        </div>
      </div>
      <div class="modal-footer bg-gray-300">
        <button
          type="submit"
          class="btn btn-outline-success"
          [disabled]="!form.valid"
          (click)="submit()"
        >
          Submit
        </button>
        <button
          type="button"
          class="btn btn-outline-dark"
          (click)="activeModal.close('Save click')"
        >
          Cancel
        </button>
        <!-- <button mat-stroked-button color="accent" [disabled]="form.invalid" (click)="submit()">Save</button> -->
      </div>
    </form>
  </div>
  
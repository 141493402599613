import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as fromOwners from '../../../../../store/cold-storage/owners';
import { OwnerModel } from '../../../../../store/cold-storage/owners/models/owner.model';

@Component({
  selector: 'app-owner-add',
  templateUrl: './owner-add.component.html'
})
export class OwnerAddComponent {

  pending$ = this.store.pipe(select(fromOwners.getOwnerItemPending));
  error$ = this.store.pipe(select(fromOwners.getOwnerItemError));

  constructor(private readonly store: Store<fromOwners.State>) {
  }

  onSubmit($event: any) {
    this.store.dispatch(new fromOwners.AddRecord($event));
  }
}

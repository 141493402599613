import { TypeOfStorageUpdateComponent } from './../type-of-storage-update/type-of-storage-update.component';
import { TypeOfStorageDetailsComponent } from './../../components/type-of-storage-details/type-of-storage-details.component';
import { TypeOfStorageAddComponent } from './../type-of-storage-add/type-of-storage-add.component';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  Optional,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { TypeOfStorage } from './../../../../../../store/masters/type-of-storages/models/type-of-storage.model';

import * as fromTypeOfStorage from './../../../../../../store/masters/type-of-storages';
import { TypeOfStorageItemActionTypes } from './../../../../../../store/masters/type-of-storages';
import { select, Store } from '@ngrx/store';
import { GridPayload } from './../../../../../../shared/models/grid.payload';

import { Actions, ofType } from '@ngrx/effects';
import { takeUntil, tap } from 'rxjs/operators';
import lstore from 'store';
import {
  NgbActiveModal,
  NgbModal,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-type-of-storage-page',
  templateUrl: './type-of-storage-page.component.html',
  styleUrl: './type-of-storage-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TypeOfStoragePageComponent  implements OnInit, OnDestroy {
  loggedInUserAccess: boolean = false;
  // tps$: Observable<TypeOfStorage[]> = this.store.pipe(select(fromTypeOfStorage.getAllTypeOfStorages));
  typeOfStorages$: Observable<any>;
  typeOfStoragesLoading$ = this.store.pipe(select(fromTypeOfStorage.getTypeOfStoragesIsLoading));

  typeOfStoragesCount$: Observable<number> = this.store.pipe(
    select(fromTypeOfStorage.getTypeOfStoragesCount)
  );
  typeOfStoragesOffset$: Observable<number> = this.store.pipe(
    select(fromTypeOfStorage.getTypeOfStoragesOffset)
  );
  typeOfStoragesSearch$: Observable<string> = this.store.pipe(
    select(fromTypeOfStorage.getTypeOfStoragesSearch)
  );
  typeOfStoragesSortProp$: Observable<string> = this.store.pipe(
    select(fromTypeOfStorage.getTypeOfStoragesSortProp)
  );
  typeOfStoragesSortDir$: Observable<string> = this.store.pipe(
    select(fromTypeOfStorage.getTypeOfStoragesSortDir)
  );

  private destroyed$ = new Subject<boolean>();
  //private dialogRef = null;
  modalReference: NgbModalRef;

  searchBy: any = '';

  constructor(
    private readonly store: Store<fromTypeOfStorage.State>,
    private modalService: NgbModal,
    @Optional() private activeModal: NgbActiveModal,
    actions$: Actions
  ) {
    this.loggedInUserAccess = lstore.get('aae');
    actions$
      .pipe(
        ofType(
          TypeOfStorageItemActionTypes.AddRecordSuccess,
          TypeOfStorageItemActionTypes.UpdateRecordSuccess
        ),
        takeUntil(this.destroyed$),
        tap(() => {
          this.modalReference.close();
        })
      )
      .subscribe();
  }

  ngOnInit() {
    this.loadTypeOfStorages();
    this.typeOfStorages$ = this.store.pipe(select(fromTypeOfStorage.getAllTypeOfStorages));
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  loadTypeOfStorages() {
    const info: GridPayload = {
      offset: 0,
      sortProp: 'name',
      sortDir: 'asc',
      search:
        this.searchBy == '' ||
        this.searchBy == undefined ||
        this.searchBy == null
          ? 'all'
          : this.searchBy,
    };
    this.store.dispatch(new fromTypeOfStorage.LoadRecords(info));
  }

  search() {
    this.loadTypeOfStorages();
  }

  clear() {
    this.searchBy = '';
    this.loadTypeOfStorages();
  }
  
  addItem(): void {
    this.modalReference = this.modalService.open(TypeOfStorageAddComponent, {
      backdrop: 'static',
      size: 'lg',
      keyboard: false,
      centered: true,
    });
  }

  onGridUpdate(info: GridPayload) {
    this.store.dispatch(new fromTypeOfStorage.LoadRecords(info));
  }
  onItemSelected(typeOfStorageData: TypeOfStorage) {
    this.modalReference = this.modalService.open(TypeOfStorageDetailsComponent, {
      backdrop: 'static',
      size: 'lg',
      keyboard: false,
      centered: true,
    });
    this.modalReference.componentInstance.selectedTypeOfStorage = typeOfStorageData;
  }

  onItemUpdate(typeOfStorage: TypeOfStorage) {
    this.store.dispatch(new fromTypeOfStorage.SelectRecord(typeOfStorage.id));
    this.modalReference = this.modalService.open(TypeOfStorageUpdateComponent, {
      backdrop: 'static',
      size: 'lg',
      keyboard: false,
      centered: true,
    });
  }
}




import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ContractorModel } from '../models/contractor.model';
import { ContractorActionTypes, ContractorItemActionTypes, ContractorListActionTypes } from '../actions';


export interface ContractorListState extends EntityState<ContractorModel> {
  isLoading: boolean;
  selectedContractorId: string | null;

  count: number;
  offset: number;

  sortProp: string;
  sortDir: string;

  search:string;
}


export const adapter: EntityAdapter<ContractorModel> = createEntityAdapter<ContractorModel>({
  selectId: (client: ContractorModel) => client.id,
});


export const initialState: ContractorListState = adapter.getInitialState({
  isLoading: false,
  selectedContractorId: null,

  count: 0,
  offset: 0,

  sortProp: 'name',
  sortDir: 'asc',
  search: 'all'
});


export function contractorListReducer(state = initialState, action: ContractorActionTypes): ContractorListState {
  switch (action.type) {

    case ContractorListActionTypes.LoadRecords: {
      return {
        ...state,
        ...action.gridInfo,
        isLoading: true,
      };
    }

    case ContractorListActionTypes.LoadRecordsSuccess: {
      return adapter.addMany(action.payload.data as ContractorModel[], {
        ...adapter.removeAll(state),
        isLoading: false,
        count: action.payload.count
      });
    }

    case ContractorListActionTypes.LoadListingRecords: {
      return {
        ...state,
        ...action.gridInfo,
        isLoading: true,
      };
    }

    case ContractorListActionTypes.LoadListingRecordsSuccess: {
      return adapter.addMany(action.payload.data as ContractorModel[], {
        ...adapter.removeAll(state),
        isLoading: false,
        count: action.payload.count
      });
    }
    
    case ContractorItemActionTypes.UpdateRecordSuccess: {
      return adapter.upsertOne(action.payload, state);
    }

    case ContractorItemActionTypes.UpdateStatusRecordSuccess: {
      return adapter.upsertOne(action.payload, state);
    }

    case ContractorItemActionTypes.UpdateApprovalStatusRecordSuccess: {
      return adapter.upsertOne(action.payload, state);
    }

    case ContractorListActionTypes.SelectRecord: {
      return {
        ...state,
        selectedContractorId: action.payload
      };
    }

    
    default:
      return state;
  }
}


export const getIsLoading = (state: ContractorListState) => state.isLoading;

export const getCount = (state: ContractorListState) => state.count;
export const getOffset = (state: ContractorListState) => state.offset;

export const getSortProp = (state: ContractorListState) => state.sortProp;
export const getSortDir = (state: ContractorListState) => state.sortDir;
export const getSearch = (state: ContractorListState) => state.search;

export const getSelectedId = (state: ContractorListState) => state.selectedContractorId;

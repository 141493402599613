import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import store from 'store';
import { AppModule } from '../app.module';
import { environment } from '../../environments/environment';

@Injectable()
export class jwtAuthService {
  apiUrl: any = environment.api_url;

  private access_token: string;
  private logged = new ReplaySubject<boolean>(1);
  isLogged = this.logged.asObservable();

  constructor(private http: HttpClient) {}

  // login(email: string, password: string): Observable<any> {
  login(payload: any): Observable<any> {
    //console.log("In jwtAuthService... ")
    // return this.http.post('/api/auth/login', { email, password })
    return this.http.post(`${this.apiUrl}/api/auth/signin`, payload);
  }

  register(payload: any): Observable<any> {
    console.log(`register payload ${payload} inside jwt`);
    return this.http.post(`${this.apiUrl}/api/auth/signup`, payload);
  }

  currentAccount(): Observable<any> {
    const accessToken = store.get('accessToken');
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            AccessToken: accessToken,
          },
        }
      : {};

    // return this.http.get('/api/auth/account', params)
    return this.http.get(`${this.apiUrl}/api/auth/account`, params);
  }

  logout(): Observable<any> {
    const userId = store.get('id')
    this.logged.next(false);
    const payload = {
      userId: userId
    }
    return this.http.post(`${this.apiUrl}/api/auth/logout`, payload);
  }

  setToken(response: any) {
    this.token = response.token;
    this.logged.next(true);
  }

  set token(token: string) {
    this.access_token = token;
    store.set('access_token', token);
  }

  get token(): string {
    if (!this.access_token) {
      this.access_token = store.get('access_token');
    }
    return this.access_token;
  }

  checkStatus() {
    console.log(`checkStatus:: ${this.token}`);
    if (this.token) {
      this.logged.next(true);
    } else {
      this.logged.next(false);
    }
  }
}

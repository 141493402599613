import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnInit,
} from "@angular/core";
import { EquipmentSupplierModel } from "../../../../../store/cold-storage/equipment-suppliers/models/equipment-supplier.model";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-equipment-supplier-details',
  templateUrl: './equipment-supplier-details.component.html',
  styleUrl: './equipment-supplier-details.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EquipmentSupplierDetailsComponent  implements OnInit {

  equipmentSupplier: EquipmentSupplierModel;

  @Input("selectedEquipmentSupplier") 
  set selectedEquipmentSupplier(data: any) {
    this.equipmentSupplier = data;
  }
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}

}

import { Action } from '@ngrx/store';
import { ContractorModel } from '../../../../store/cold-storage/contractors/models/contractor.model';

export enum ContractorItemActionTypes {
  AddRecord = '[Cold Storage Contractors] Add Record',
  AddRecordSuccess = '[Cold Storage Contractors] Add Record Success',
  AddRecordFailure = '[Cold Storage Contractors] Add Record Failure',

  UpdateRecord = '[Cold Storage Contractors] Update Record',
  UpdateRecordSuccess = '[Cold Storage Contractors] Update Record Success',
  UpdateRecordFailure = '[Cold Storage Contractors] Update Record Failure',

  UpdateStatusRecord = '[Cold Storage Contractors] Update Status Record',
  UpdateStatusRecordSuccess = '[Cold Storage Contractors] Update Status Record Success',
  UpdateStatusRecordFailure = '[Cold Storage Contractors] Update Status Record Failure',

  UpdateApprovalStatusRecord = '[Cold Storage Contractors] Update Approval Status Record',
  UpdateApprovalStatusRecordSuccess = '[Cold Storage Contractors] Update Approval Status Record Success',
  UpdateApprovalStatusRecordFailure = '[Cold Storage Contractors] Update Approval Status Record Failure',
}

////////////

export class AddRecord implements Action {
  readonly type = ContractorItemActionTypes.AddRecord;

  constructor(public payload: ContractorModel) {}
}

export class AddRecordSuccess implements Action {
  readonly type = ContractorItemActionTypes.AddRecordSuccess;

  constructor(public payload: ContractorModel) {}
}

export class AddRecordFailure implements Action {
  readonly type = ContractorItemActionTypes.AddRecordFailure;

  constructor(public payload: string) {}
}

export class UpdateRecord implements Action {
  readonly type = ContractorItemActionTypes.UpdateRecord;

  constructor(public payload: ContractorModel) {}
}

export class UpdateRecordSuccess implements Action {
  readonly type = ContractorItemActionTypes.UpdateRecordSuccess;

  constructor(public payload: ContractorModel) {}
}

export class UpdateRecordFailure implements Action {
  readonly type = ContractorItemActionTypes.UpdateRecordFailure;

  constructor(public payload: string) {}
}

export class UpdateStatusRecord implements Action {
  readonly type = ContractorItemActionTypes.UpdateStatusRecord;

  constructor(public payload: any) {}
}

export class UpdateStatusRecordSuccess implements Action {
  readonly type = ContractorItemActionTypes.UpdateStatusRecordSuccess;

  constructor(public payload: ContractorModel) {}
}

export class UpdateStatusRecordFailure implements Action {
  readonly type = ContractorItemActionTypes.UpdateStatusRecordFailure;

  constructor(public payload: string) {}
}

export class UpdateApprovalStatusRecord implements Action {
  readonly type = ContractorItemActionTypes.UpdateApprovalStatusRecord;

  constructor(public payload: any) {}
}

export class UpdateApprovalStatusRecordSuccess implements Action {
  readonly type = ContractorItemActionTypes.UpdateApprovalStatusRecordSuccess;

  constructor(public payload: ContractorModel) {}
}

export class UpdateApprovalStatusRecordFailure implements Action {
  readonly type = ContractorItemActionTypes.UpdateApprovalStatusRecordFailure;

  constructor(public payload: string) {}
}
////////////

export type ItemActions =
  | AddRecord
  | AddRecordSuccess
  | AddRecordFailure
  | UpdateRecord
  | UpdateRecordSuccess
  | UpdateRecordFailure
  | UpdateStatusRecord
  | UpdateStatusRecordSuccess
  | UpdateStatusRecordFailure
  | UpdateApprovalStatusRecord
  | UpdateApprovalStatusRecordSuccess
  | UpdateApprovalStatusRecordFailure;

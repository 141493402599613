<div class="recent-activities-box">
  <h3>Recent Activities</h3>

  <ul>
    <li class="alert alert-dismissible fade show" role="alert">
      <div class="icon">
        <i class="bx bx-layer"></i>
      </div>
      Your listing <strong><a href="#">Hills Hotel</a></strong> has been
      approved!
      <button
        type="button"
        class="close"
        data-dismiss="alert"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </li>
    <li class="alert alert-dismissible fade show" role="alert">
      <div class="icon">
        <i class="bx bx-star"></i>
      </div>
      <strong>Andy Smith</strong> left a review
      <div class="rating mid" data-rating="3.0"></div>
      on <strong><a href="#">Mad Grill</a></strong>
      <button
        type="button"
        class="close"
        data-dismiss="alert"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </li>
  </ul>
</div>

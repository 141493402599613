export interface DistrictApiModel {
  id: string;
  name: string;
  abbr: string;

  state_id?:string;
  state_name?:String;

  status: number;
  updated_on: Date;
}

export class District {
  id: string;
  name: string;
  abbr: string;

  state_id?:string;
  state_name?:String;
  

  status: number;
  updated_on: Date;

  constructor(raw: DistrictApiModel) {
    this.id = raw.id;
    this.name = raw.name;
    this.abbr = raw.abbr;

    this.state_id = raw.state_id;
    this.state_name= raw.state_name;
    
    this.status = raw.status;
    this.updated_on = raw.updated_on;
  }
}
